export function isNotNullOrUndefined(value: any) {
    return !isNull(value) && !isUndefined(value);
}

export function isNullOrUndefined(value: any) {
    return isNull(value) || isUndefined(value);
}

export function isNull(value: any): value is null {
    return null === value;
}

export function isUndefined(value: any): value is undefined {
    return undefined === value;
}

export function deepCloneObject(object: any): any {
    return JSON.parse(JSON.stringify(object));
}
