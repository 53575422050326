import React from 'react';
import {CustomModal, Loader, LoaderType, Translation} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import AddUserForm from "./AddUserForm";
import {ModalConfig, UserType} from '../../../../model/models';
import {modalSelector} from '../../../../store/selectors/sagaSelectors';
import {changeModal} from '../../../../store/reducers/sagaSlice';

interface IModalAddUserProps {
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
}

interface IModalAddUserState {
    isProcessing: boolean;
}


class ModalAddUser extends React.Component<IModalAddUserProps, IModalAddUserState> {
    constructor(props: IModalAddUserProps) {
        super(props);
        this.state = {
            isProcessing: false,
        }
    }


    render() {
        if (!this.props.modal) {
            return null;
        }

        const role = this.props.modal.role && this.props.modal.role.split('-')
            .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));

        return (
            <CustomModal isOpen={true} close={this.props.onClose} class={"add-user-modal"}>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={`modal.addUser.title.${role}`}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <AddUserForm type={(this.props.modal.role as UserType)} onClose={this.props.onClose}
                                 onProcessingChange={this.changeProcessingState}/>
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private changeProcessingState = (processingState: boolean) => {
        this.setState({isProcessing: processingState})
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state)
    }),
    {
        changeModal
    }
)(ModalAddUser);
