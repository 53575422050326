import React from 'react';
import {CustomCard, Form, IBasicFormControlConfig, Translation} from 'judo-app-common-web';
import {CalendarEvent} from "../../../../../model/models";
import {addCalendarEventFormConfig} from "./addCalendarEventFormConfig";
import moment from "moment";

interface IAddCalendarEventFormProps {
    onCalendarEventCreation: (calendarEvent: CalendarEvent) => void;
}

export interface IAddCalendarEventFormValue {
    name?: string;
    startsAt?: string;
    endsAt?: string;
}

interface IAddCalendarEventFormState {
    editedCalendarEvent: IAddCalendarEventFormValue | null;
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    value: IAddCalendarEventFormValue;
}

class AddCalendarEventForm extends React.Component<IAddCalendarEventFormProps, IAddCalendarEventFormState> {
    constructor(props: IAddCalendarEventFormProps) {
        super(props);
        const emptyCalendarEvent: IAddCalendarEventFormValue = {name: '', startsAt: moment().format('MM-DD-yy')}
        this.state = {
            editedCalendarEvent: emptyCalendarEvent,
            formConfig: addCalendarEventFormConfig(),
            value: {},
        }
    }

    render() {
        if (!this.state.editedCalendarEvent) {
            return null;
        }
        return <CustomCard showLocalLoader={false}>
            <div className="user-connection-list-wrapper add-calendar-event">
                <h4>
                    <Translation text="profileSettingsModal.form.addCalendarEventForm.header"/>
                </h4>
                <CustomCard.Body>
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                        controlName={"addCalendarEventForm"}
                        value={this.state.value}
                        submitForm={this.addCalendarEvent}
                    />
                </CustomCard.Body>
            </div>
        </CustomCard>
    }

    private updateFormData(controlName: string, data: IAddCalendarEventFormValue) {
        this.setState({
            editedCalendarEvent: data,
            formConfig: addCalendarEventFormConfig(data)
        });
    }

    private addCalendarEvent = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        if (!value.name || !value.startsAt) {
            return;
        }


        const addedCalendarEvent: CalendarEvent = {
            name: value.name,
            startsAt: moment(value.startsAt).format('DD-MM-yyyy'),
            endsAt:  undefined,
            }
        this.props.onCalendarEventCreation(addedCalendarEvent);
    };
}

export default AddCalendarEventForm;
