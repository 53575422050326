import React from 'react';
import {authTokenSelector, CustomModal, Form, IFormConfig, Loader, LoaderType, Translation} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {ModalConfig, UserType} from "../../../../model/models";
import {changeModal, changeShouldListUpdate, changeShouldViewUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import styles from "../../../Shared/UserInfoBox/styles.module.scss";
import {editClubFormConfig} from "./editClubFormConfig";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {updateUserProfileAPI} from "../../../../api/updateUserProfile";
import AccountImageHost from "../../../Shared/AccountImageHost";
import {getBaseUserDetailsAPI} from "../../../../api/getBaseUserDetails";
import UserConnectionList from "../../../Shared/UserConnectionsList";

interface IModalEditClubProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldViewUpdate: typeof changeShouldViewUpdate;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalEditClubState {
    account: any;
    clubLogoId: string | null;
    userType: string;
    isProcessing: boolean;
    formConfig: typeof IFormConfig;
    value: any;
}

class ModalEditClub extends React.Component<IModalEditClubProps, IModalEditClubState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalEditClubProps) {
        super(props);
        this.state = {
            account: null,
            clubLogoId: null,
            userType: '',
            isProcessing: true,
            formConfig: editClubFormConfig,
            value: {}
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal) this.retrieveAccount();
    }

    componentDidUpdate(
        prevProps: Readonly<IModalEditClubProps>,
        prevState: Readonly<IModalEditClubState>,
        snapshot?: any
    ): void {
        if (this.state.account !== prevState.account) {
            this.updateFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.account) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose}>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={`modal.editClub.header`}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <div className="separator"/>
                    {this.renderUserBox()}
                    <div className="separator"/>
                    {this.renderConnectedUserLists()}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private retrieveAccount() {
        if (!this.props.modal || !this.props.modal.id) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(
            getBaseUserDetailsAPI(this.props.authToken, 'clubs', this.props.modal.id).pipe(
                map((response: any) => {
                    this.updateFormConfigInit(response);
                    this.setState({userType: response.type, account: response, isProcessing: false})
                    return response;
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe())
    }

    private renderUserBox() {
        return <div className={`${styles.userInfoBox}  ${styles[this.state.userType]}`}>
            <AccountImageHost account={this.state.account} isClubProfile={true}
                              onImageChange={this.updateProfileImageId}/>
            <div className={`${styles.userPersonalInfoWrapper} ellipsis-host`}>
                {this.renderUserInfo()}
            </div>
        </div>

    }

    private renderConnectedUserLists() {
        if (!this.state.account) {
            return null;
        }
        return (
            <div className='d-flex flex-column'>
                {this.state.account.coaches &&
                <React.Fragment>
                    <UserConnectionList dataList={this.state.account.coaches}
                                        listType={UserType.COACH}
                                        account={this.state.account}
                                        isModalView={true}/>
                    <div className="separator"/>
                </React.Fragment>
                }
            </div>
        );
    }

    private renderUserInfo() {
        return (
            <Form config={this.state.formConfig}
                  submitForm={this.editClub}
                  controlName={'editClub'}
                  value={{}}/>
        )
    }

    private updateProfileImageId = (imageId: string | null) => {
        this.setState({clubLogoId: imageId})
    }
    private editClub = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken) {
            return;
        }

        let updatedAccount = deepCloneObject(this.state.account);
        updatedAccount.name = value.name_group?.name ? value.name_group.name : updatedAccount.name;
        const coachesAccountIdArray = updatedAccount.coaches.map((coachAccount: any) => coachAccount.coachId);
        const payload = {
            name: updatedAccount.name,
            coaches: coachesAccountIdArray,
            logoId: this.state.clubLogoId,
            disabled: updatedAccount.disabled
        }
        this.setState({isProcessing: true});
        return this.subscriptions.push(updateUserProfileAPI(this.props.authToken, payload, 'clubs', updatedAccount.id).pipe(
            map((response: any) => {
                this.alertManagerService.addAlert('modal.editClub.editSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldViewUpdate(true);
                this.props.changeShouldListUpdate(true);
                this.props.changeModal(null);
                return of();
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe());
    }

    private updateFormConfigInit(account: any) {
        if (!account) {
            return null
        }
        if (account.logo) {
            this.setState({clubLogoId: account.logo.id})
        }
        let updatedFormConfig = deepCloneObject(editClubFormConfig);
        updatedFormConfig.controls[0].controls.name.defaultValue = account.name;
        this.setState({formConfig: updatedFormConfig});
    }


    private updateFormConfig() {
        if (!this.state.account) {
            return null;
        }
        let updatedFormConfig = deepCloneObject(editClubFormConfig);
        updatedFormConfig.controls[0].controls.name.defaultValue = this.state.account.name;
        this.setState({formConfig: updatedFormConfig});
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldViewUpdate,
        changeShouldListUpdate,
    }
)(ModalEditClub);
