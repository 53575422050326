import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function assignAccountToAccountAPI(
    authToken: string,
    chosenAccountId: string,
    payload: any
): Observable<any> {
    return baseDataProviderAPI.put(
        `accounts/${chosenAccountId}/assign_user_to_user`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
