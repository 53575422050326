import React from 'react';
import {
    CustomCard,
    CustomModal,
    Form,
    IBasicFormControlConfig,
    ProcessVariable as GroupVariable,
    Translation
} from 'judo-app-common-web';
import {CalendarInput, CalendarInputOption, InputType} from "../../../../model/models";
import {addInputFormConfig} from "./addInputFormConfig";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import SelectOptionsList from "./SelectOptionsList";
import EditOptionForm from "./EditOptionForm";
import AddOptionForm from "./AddOptionForm";
import AddCalculatedFieldForm from "./AddCalculatedFieldForm";
import DatepickerTypeSelectForm from "./DatepickerTypeSelectForm";

interface IAddInputFormModalProps {
    readonly onClose: () => void;
    readonly onVariableCreate: (variable: CalendarInput) => void;
    readonly createdVariable: CalendarInput;
    readonly groupName?: string;
    readonly variables: typeof GroupVariable[];
}

interface IAddInputFormModalState {
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    addInputModalOpened: boolean;
    currentlyEditedOption: CalendarInputOption | null;
    currentlyEditedOptionIndex: number | null;
    addOptionCardOpen: boolean;
    newVariable: CalendarInput | null;
}

class AddInputFormModal extends React.Component<IAddInputFormModalProps, IAddInputFormModalState> {
    constructor(props: IAddInputFormModalProps) {
        super(props);

        this.state = {
            newVariable: null,
            formConfig: addInputFormConfig,
            addInputModalOpened: false,
            currentlyEditedOption: null,
            currentlyEditedOptionIndex: null,
            addOptionCardOpen: false
        }
    }


    componentDidMount() {
        if (this.props.createdVariable) this.setState({newVariable: this.props.createdVariable});
    }

    componentDidUpdate(prevProps: Readonly<IAddInputFormModalProps>, prevState: Readonly<IAddInputFormModalState>) {
        if (this.props.createdVariable !== prevProps.createdVariable) {
            this.setState({newVariable: this.props.createdVariable});
        }
    }

    render() {
        if (!this.state.newVariable) {
            return null;
        }
        const isFullWidth = !this.state.newVariable?.type || this.state.newVariable?.type === InputType.INPUT_NUMBER || this.state.newVariable?.type === InputType.INPUT_TEXT || (this.state.newVariable?.type === InputType.SELECT && !this.state.currentlyEditedOption);
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="add-input-modal">
                <CustomModal.Header>
                    <h2>
                        <Translation text={'profileSettingsModal.form.addInputForm.header'}/>
                        {this.props.groupName &&
                        <span>{`- ${this.props.groupName}`}</span>
                        }
                    </h2>
                </CustomModal.Header>
                <CustomModal.Body>
                    <div className="row justify-content-around">
                        <div className={`${isFullWidth ? '' : 'col-lg-6'}`}>
                            <CustomCard>
                                <div className="user-connection-list-wrapper new-variable-menu">
                                    <h4 className="sr-only">
                                        <Translation text="userView.variableGroup.modal.addInputForm.header"/>
                                    </h4>
                                    <Form
                                        config={this.state.formConfig}
                                        controlName={"addInputForm"}
                                        onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                                        value={{}}
                                    />
                                    {this.renderSelectOptionsList()}
                                </div>
                            </CustomCard>
                        </div>
                        <div className="col-lg-6">
                            {this.renderAddOptionCard()}
                            {this.renderEditOptionCard()}
                            {this.renderExpressionEditor()}
                            {this.renderDatepickerTypeSelector()}
                        </div>
                    </div>
                </CustomModal.Body>
                <CustomModal.Footer>
                        <div className="d-flex justify-content-end button-wrapper mb-5">
                            <button className="btn btn-theme btn-small ml-3"
                                    disabled={this.setDisabledButton()}
                                    onClick={this.addInput}>
                                <span className="feather icon-plus" aria-hidden="true"/>
                                <span><Translation text={'userList.table.button.add.variable'}/></span>
                            </button>
                        </div>
                </CustomModal.Footer>
            </CustomModal>
        );
    }

    private setDisabledButton() {
        if(!this.state.newVariable || this.state.newVariable.type === null) {
            return true
        }

        if(this.state.newVariable.name?.length === 0) {
            return true
        }

        if(this.state.newVariable.type === InputType.CALCULATED) {
            return !this.state.newVariable?.parameters?.expression
        }

        if(this.state.newVariable.type === InputType.SELECT) {
            return this.state.newVariable?.valuesVisualOptions?.length === 0
        }

        if(this.state.newVariable.type === InputType.INPUT_DATE) {
            return !this.state.newVariable.parameters?.type
        }

        return false
    }

    private renderDatepickerTypeSelector() {
        if (this.state.newVariable?.type !== InputType.INPUT_DATE) {
            return null
        }

        return <DatepickerTypeSelectForm
            setType={(type: string) => this.setType(type)}/>
    }

    private renderAddOptionCard() {
        if (this.state.addOptionCardOpen) {
            return <AddOptionForm onOptionCreation={this.createOption}/>
        }
    }

    private renderEditOptionCard() {
        if (this.state.currentlyEditedOption && this.state.currentlyEditedOptionIndex !== null) {
            return <EditOptionForm key={this.state.currentlyEditedOption.label}
                                   editedOption={this.state.currentlyEditedOption}
                                   editedOptionIndex={this.state.currentlyEditedOptionIndex}
                                   onOptionUpdate={this.updateOption}/>
        }
    }

    private renderSelectOptionsList() {
        if (this.state.newVariable?.type !== InputType.SELECT) {
            return null;
        }
        return <SelectOptionsList options={this.state.newVariable.valuesVisualOptions}
                                  onOptionsUpdate={this.updateOptionsList}
                                  onCreateOption={this.openCreateCard}
                                  onDeleteOption={this.deleteOption}
                                  onCurrentlyEditedOption={this.setEditedOption}/>
    }

    private renderExpressionEditor() {
        if (this.state.newVariable?.type !== InputType.CALCULATED) {
            return null
        }

        return <AddCalculatedFieldForm
            confirmFunction={(formula: any) => this.setFormula(formula)}
            variables={this.props.variables}
        />
    }

    private setType(type: string) {
        let updatedInput: CalendarInput = deepCloneObject(this.state.newVariable);
        updatedInput.parameters = {type: type};
        this.setState({newVariable: updatedInput});
    }

    private setFormula(formula: any) {
        let updatedInput: CalendarInput = deepCloneObject(this.state.newVariable);
        updatedInput.parameters = {expression: formula};
        this.setState({newVariable: updatedInput});
    }

    private updateFormData(controlName: string, data: any) {
        let updatedInput: CalendarInput = deepCloneObject(this.state.newVariable);
        if (data) {
            updatedInput.name = data.name;
            updatedInput.enabled = data.enabled;
            updatedInput.inputDefinition = data.inputDefinition;
            updatedInput.type = data.inputType;
        }

        if(this.state.newVariable?.type !== data.inputType) {
            updatedInput.valuesVisualOptions= []
            updatedInput.parameters = {}
        }

        if(this.state.addOptionCardOpen &&
            this.state.newVariable?.type === InputType.SELECT &&
            data.inputType !== InputType.SELECT) {
            this.setState({addOptionCardOpen: false})
        }
        this.setState({newVariable: updatedInput});
    }

    private addInput = () => {
        if (!this.state.newVariable) {
            return;
        }
        const addedInput: CalendarInput = this.state.newVariable;
        this.props.onVariableCreate(addedInput);
        this.props.onClose();
    };


    private createOption = (option: CalendarInputOption) => {
        let updatedInput = deepCloneObject(this.state.newVariable);
        updatedInput.valuesVisualOptions?.push(option);
        this.setState({newVariable: updatedInput, addOptionCardOpen: false})
    }
    private updateOption = (option: CalendarInputOption, optionIndex: number) => {
        let updatedInput = deepCloneObject(this.state.newVariable);
        updatedInput.valuesVisualOptions[optionIndex] = option;
        this.setState({newVariable: updatedInput, currentlyEditedOption: null, currentlyEditedOptionIndex: null})
    }
    private deleteOption = (optionIndex: number) => {
        let updatedInput = deepCloneObject(this.state.newVariable);
        updatedInput.valuesVisualOptions.splice(optionIndex, 1);
        this.setState({newVariable: updatedInput})
    }

    private updateOptionsList = (optionList: CalendarInputOption[]) => {
        let updatedInput = deepCloneObject(this.state.newVariable);
        updatedInput.valuesVisualOptions = optionList;
        this.setState({newVariable: updatedInput})
    }

    private openCreateCard = () => {
        this.setState({addOptionCardOpen: true, currentlyEditedOption: null, currentlyEditedOptionIndex: null})
    }

    private setEditedOption = (option: CalendarInputOption, index: number) => {
        this.setState({currentlyEditedOption: option, currentlyEditedOptionIndex: index, addOptionCardOpen: false});
    }

}

export default AddInputFormModal;

