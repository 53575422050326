import {CalendarGroup, CalendarInput, InputDefinition, InputGroupDefinition} from "../model/models";

export interface ICalendarDataGroups {
    enabled: CalendarGroup[];
    disabled: CalendarGroup[];
}


export interface IFormDataGroups {
    enabled: InputGroupDefinition[];
    disabled: InputGroupDefinition[];
}

export interface DragDropGroup {
    id: number;
    calendarGroup: CalendarGroup;
}
export interface FormDragDropGroup {
    id: number;
    profileDefinition: InputGroupDefinition;
}

export interface DragDropVariable {
    id: number;
    calendarInput: CalendarInput;
}

export interface FormDragDropVariable {
    id: number;
    inputDefinition: InputDefinition;
}

export function splitEnabledCalendarGroups(calendarGroups: CalendarGroup[]): ICalendarDataGroups {
    let enabledItems: CalendarGroup[] = [],
        disabledItems: CalendarGroup[] = [];
    calendarGroups.forEach((datalistItem: CalendarGroup) => {
        datalistItem.enabled ? enabledItems.push(datalistItem) : disabledItems.push(datalistItem);
    });

    return {enabled: enabledItems, disabled: disabledItems};
}
export function splitEnabledFormGroups(formGroups: InputGroupDefinition[]): IFormDataGroups {
    let enabledItems: InputGroupDefinition[] = [],
        disabledItems: InputGroupDefinition[] = [];
    formGroups.forEach((datalistItem: InputGroupDefinition) => {
        datalistItem.enabled ? enabledItems.push(datalistItem) : disabledItems.push(datalistItem);
    });

    return {enabled: enabledItems, disabled: disabledItems};
}


// TODO: when enabled option in groups will be ready uncomment and remove mock from up /\
//
// export function splitEnabledCalendarGroups(calendarGroups: CalendarGroup[]): IFormDataGroups {
//     let enabledItems: CalendarGroup[] = [],
//         disabledItems: CalendarGroup[] = [];
//     calendarGroups.forEach((datalistItem: CalendarGroup) => {
//         datalistItem.enabled ? enabledItems.push(datalistItem) : disabledItems.push(datalistItem);
//     });
//
//     return {enabled: enabledItems, disabled: disabledItems};
// }

export function switchGroupStatus(calendarGroups: CalendarGroup[], groupId: string, newEnabledState: boolean): CalendarGroup[] {
    const updatedGroups = calendarGroups.map((calendarGroup: CalendarGroup) => {
        if (calendarGroup.id === groupId) {
            calendarGroup.enabled = newEnabledState;
        }
        return calendarGroup;
    })
    return updatedGroups;
}
export function switchFormGroupStatus(formGroups: InputGroupDefinition[], groupId: string, newEnabledState: boolean): InputGroupDefinition[] {
    const updatedGroups = formGroups.map((formGroup: InputGroupDefinition) => {
        if (formGroup.id === groupId) {
            formGroup.enabled = newEnabledState;
        }
        return formGroup;
    })
    return updatedGroups;
}

