import {Observable} from "redux";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {IInputResultPayload} from "./createInputResult";

export function updateInputResultAPI(
    authToken: string,
    inputId: string,
    payload: IInputResultPayload
): Observable<any> {
    return baseDataProviderAPI.put(
        `input_results/${inputId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
