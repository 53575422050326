import React from 'react';
import {CalendarInput} from "../../../../model/models";
import TooltipWrapper from "../../../Shared/TooltipWrapper";
import {Translation} from "judo-app-common-web";
import Badge, {BadgeSize, BadgeType} from "../../../Shared/Badge";

interface SettingsVariableItemProps {
    listItem: CalendarInput;
    key: string;
    onArchiveItem: (variableId: string) => void;
    onUpdateItem: (variable: CalendarInput) => void;
}

class SettingsVariableItem extends React.Component<SettingsVariableItemProps> {
    constructor(props: SettingsVariableItemProps) {
        super(props);
        this.state = {}
    }

    render() {
        return <React.Fragment>
            <div className="d-flex flex-wrap profile-wrapper"> {this.props.listItem.name} </div>
            {this.renderVariableBadges()}
            {this.renderActionButtons()}
        </React.Fragment>
    }

    private renderVariableBadges() {
        return <div className="badge-wrapper">
            <TooltipWrapper tooltip={{text: `profileSettingsModal.badges.${this.props.listItem.inputDefinition ? this.props.listItem.inputDefinition.type : this.props.listItem.type}.tooltipLabel`}}
                            key={`variable-badge-${this.props.listItem.name}`}>
                <div>
                    <Badge type={BadgeType.VARIABLE} size={BadgeSize.SMALL}
                           label={`profileSettingsModal.badges.${this.props.listItem.inputDefinition ? this.props.listItem.inputDefinition.type : this.props.listItem.type}.label`}/>
                </div>
            </TooltipWrapper>
        </div>
    }

    private renderActionButtons() {
        return <div className="button-wrapper">
            <TooltipWrapper tooltip={{text: 'profileSettingsModal.button.edit.variableItem'}}
                            key={'variable-edit-button'}>
                <button className="btn btn-list fatigue-profile ml-1"
                        onClick={() => this.props.onUpdateItem(this.props.listItem)}>
                    <span className="sr-only"><Translation
                        text={'profileSettingsModal.button.edit.variableItem'}/></span>
                    <span className="feather icon-edit" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            {/*// TODO: When profile/group/variable archivisation will be ready uncomment and*/}

            {/*<TooltipWrapper tooltip={{text: 'profileSettingsModal.button.archive.variableItem'}} key={'fatigue-button'}>*/}
            {/*    <button className="btn btn-list fatigue-profile delete ml-3"*/}
            {/*            onClick={() => this.props.onArchiveItem(this.props.listItem.id)}>*/}
            {/*        <span className="sr-only"><Translation*/}
            {/*            text={'profileSettingsModal.button.archive.variableItem'}/></span>*/}
            {/*        <span className="feather icon-trash" aria-hidden="true"/>*/}
            {/*    </button>*/}
            {/*</TooltipWrapper>*/}
        </div>
    }

}

export default SettingsVariableItem;
