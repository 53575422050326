import {injectable} from 'inversify';

@injectable()
export class ExecutableExpressionDTOFormatter {
    prepareExpression(expression: any): any {
        if (!expression) {
            return expression;
        }
        if (Array.isArray(expression) && expression.length === 0) {
            return null;
        }

        if (undefined !== expression['path']) {
            return {
                'leafExecutableExpression': {
                    path: String(expression['path']),
                }
            };
        }

        return {
            'groupedExecutableExpression': {
                'expressionType': expression['type'],
                'args': expression['args'].map((arg: any) => this.prepareExpression(arg)),
            }
        };
    };
}
