import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function getAccountAPI(
    authToken: string,
    accountId: string,
    isArchiveMode?: boolean
): Observable<any> {
    const getArchivedItemsQuery = isArchiveMode ? '?show_soft_deleted=true' : '';
    return baseDataProviderAPI.get(
        `accounts/${accountId}${getArchivedItemsQuery}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
