import { createSlice } from "redux-starter-kit";
import { LanguageLocale } from "../../constants/locales";
import {ModalConfig} from '../../model/models';

export interface ISagaState {
    language: LanguageLocale;
    fontSize: number;
    isProcessing: boolean;
    fatigueProfileValid: boolean;
    modal: ModalConfig | null;
    shouldListUpdate: boolean;
    shouldViewUpdate: boolean;
    isUserProfileLoading: boolean;
}

export interface IChangeLanguage {
    readonly payload: {
        language: LanguageLocale;
    };
}

export interface IChangeFontSize {
    readonly payload: {
        fontSize: number;
    };
}

export interface IChangeIsProcessing {
    readonly payload: {
        isProcessing: boolean;
    };
}

export interface IChangeFatigueValid {
    readonly payload: {
        fatigueProfileValid: boolean;
    };
}

export interface IChangeModal {
    readonly payload: {
        modal: ModalConfig | null;
    };
}

export interface IChangeShouldListUpdate {
    readonly payload: {
        shouldListUpdate: boolean;
    };
}
export interface IChangeShouldViewUpdate {
    readonly payload: {
        shouldViewUpdate: boolean;
    };
}

export interface IChangeIsUserProfileLoading {
    readonly payload: {
        isUserProfileLoading: boolean;
    };
}

const initialState: ISagaState = {
    language: LanguageLocale.PL,
    isProcessing: false,
    fontSize: 1.6,
    fatigueProfileValid: false,
    modal: null,
    shouldListUpdate: false,
    shouldViewUpdate: false,
    isUserProfileLoading: false
};

const sagaSlice = createSlice({
    slice: "saga",
    initialState: initialState,
    reducers: {
        changeLanguage: {
            reducer: (state: ISagaState, action: IChangeLanguage) => {
                return {
                    language: action.payload.language,
                    isProcessing: state.isProcessing,
                    fontSize: state.fontSize,
                    fatigueProfileValid: state.fatigueProfileValid,
                    modal: state.modal,
                    shouldListUpdate: state.shouldListUpdate,
                    shouldViewUpdate: state.shouldViewUpdate,
                    isUserProfileLoading: state.isUserProfileLoading
                }
            },
            prepare(language: LanguageLocale) {
                return {
                    payload: { language: language }
                };
            }
        },
        changeFontSize: {
            reducer: (state: ISagaState, action: IChangeFontSize) => {
                return {
                    language: state.language,
                    isProcessing: state.isProcessing,
                    fontSize: action.payload.fontSize,
                    fatigueProfileValid: state.fatigueProfileValid,
                    modal: state.modal,
                    shouldListUpdate: state.shouldListUpdate,
                    shouldViewUpdate: state.shouldViewUpdate,
                    isUserProfileLoading: state.isUserProfileLoading
                }
            },
            prepare(fontSize: number) {
                return {
                    payload: { fontSize: fontSize }
                };
            }
        },
        changeIsProcessing: {
            reducer: (state: ISagaState, action: IChangeIsProcessing) => {
                return {
                    language: state.language,
                    isProcessing: action.payload.isProcessing,
                    fontSize: state.fontSize,
                    fatigueProfileValid: state.fatigueProfileValid,
                    modal: state.modal,
                    shouldListUpdate: state.shouldListUpdate,
                    shouldViewUpdate: state.shouldViewUpdate,
                    isUserProfileLoading: state.isUserProfileLoading
                };
            },
            prepare(isProcessing: boolean) {
                return {
                    payload: { isProcessing: isProcessing }
                };
            }
        },
        changeFatigueProfileValid: {
            reducer: (state: ISagaState, action: IChangeFatigueValid) => {
                return {
                    language: state.language,
                    isProcessing: state.isProcessing,
                    fontSize: state.fontSize,
                    fatigueProfileValid: action.payload.fatigueProfileValid,
                    modal: state.modal,
                    shouldListUpdate: state.shouldListUpdate,
                    shouldViewUpdate: state.shouldViewUpdate,
                    isUserProfileLoading: state.isUserProfileLoading
                };
            },
            prepare(fatigueProfileValid: boolean) {
                return {
                    payload: { fatigueProfileValid: fatigueProfileValid }
                };
            }
        },
        changeModal: {
            reducer: (state: ISagaState, action: IChangeModal) => {
                return {
                    language: state.language,
                    isProcessing: state.isProcessing,
                    fontSize: state.fontSize,
                    fatigueProfileValid: state.fatigueProfileValid,
                    modal: action.payload.modal,
                    shouldListUpdate: state.shouldListUpdate,
                    shouldViewUpdate: state.shouldViewUpdate,
                    isUserProfileLoading: state.isUserProfileLoading
                };
            },
            prepare(modal: ModalConfig | null) {
                return {
                    payload: { modal: modal }
                };
            }
        },
        changeShouldListUpdate: {
            reducer: (state: ISagaState, action: IChangeShouldListUpdate) => {
                return {
                    language: state.language,
                    isProcessing: state.isProcessing,
                    fontSize: state.fontSize,
                    fatigueProfileValid: state.fatigueProfileValid,
                    modal: state.modal,
                    shouldListUpdate: action.payload.shouldListUpdate,
                    shouldViewUpdate: state.shouldViewUpdate,
                    isUserProfileLoading: state.isUserProfileLoading
                };
            },
            prepare(shouldListUpdate: boolean) {
                return {
                    payload: { shouldListUpdate: shouldListUpdate }
                };
            }
        },
        changeShouldViewUpdate: {
            reducer: (state: ISagaState, action: IChangeShouldViewUpdate) => {
                return {
                    language: state.language,
                    isProcessing: state.isProcessing,
                    fontSize: state.fontSize,
                    fatigueProfileValid: state.fatigueProfileValid,
                    modal: state.modal,
                    shouldListUpdate: state.shouldListUpdate,
                    shouldViewUpdate: action.payload.shouldViewUpdate,
                    isUserProfileLoading: state.isUserProfileLoading
                };
            },
            prepare(shouldViewUpdate: boolean) {
                return {
                    payload: { shouldViewUpdate: shouldViewUpdate }
                };
            }
        },
        changeIsUserProfileLoading: {
            reducer: (state: ISagaState, action: IChangeIsUserProfileLoading) => {
                return {
                    language: state.language,
                    isProcessing: state.isProcessing,
                    fontSize: state.fontSize,
                    fatigueProfileValid: state.fatigueProfileValid,
                    modal: state.modal,
                    shouldListUpdate: state.shouldListUpdate,
                    shouldViewUpdate: state.shouldViewUpdate,
                    isUserProfileLoading: action.payload.isUserProfileLoading
                };
            },
            prepare(isUserProfileLoading: boolean) {
                return {
                    payload: { isUserProfileLoading: isUserProfileLoading }
                };
            }
        },
    }
});

export const {
    changeLanguage,
    changeIsProcessing,
    changeFontSize,
    changeFatigueProfileValid,
    changeModal,
    changeShouldListUpdate,
    changeShouldViewUpdate,
    changeIsUserProfileLoading
} = sagaSlice.actions;

export default sagaSlice.reducer;
