import {injectable} from 'inversify';
import {addAlert} from 'judo-app-common-web';
import {fixInjectedProperties} from '../ioc';
import store from '../store';

export enum AlertType {
    THEME = 'theme',
    INFO = 'info',
    WARNING = 'warning',
    SUCCESS = 'success',
    DANGER = 'error',
}

export interface IAlertManagerService {
    handleApiError(error?: any, type?: AlertType): void;

    loginSuccess(): void;

    loginFailure(): void;

    logoutSuccess(): void;

    addAlert(message: string, type?: AlertType, displayFor?: number): void;
}

const AlertMessage = {
    base: 'Something went wrong.',
    paymentSuccess: 'Payment completed. Please wait for a conformation email with insurance number.',
    paymentProcessing: 'Your payment is being processed. Please wait.',
    patient: {},
    clinic: {},
    axaInsurance: {
        loginFailure: 'You are not authorized to login to Clinic Hunter Cover Medical Insurance.'
    }
};

@injectable()
class AlertManagerService implements IAlertManagerService {

    constructor() {
        fixInjectedProperties(this);
    }

    public handleApiError(error: any = null, type: AlertType = AlertType.THEME) {
        let message = error ? error : AlertMessage.base;

        if (error) {
            if (error['hydra:description']) {
                message = error['hydra:description'];
            } else if (error.error && error.error.message) {
                message = error.error.message;
            } else if (error.message) {
                message = error.message;
                if(error.message === 'ajax timeout') {
                    message = 'Problem z siecią, przekroczono czas połączenia. Sprawdź swoje połączenie internetowe i spróbuj ponownie. Jeśli problem będzie się powtarzał skontaktuj się z Administratorem'
                }
            } else if (typeof error === "string") {
                message = error;
            }
        }
        this.addAlert(message, type, 10000);
    };

    public loginSuccess() {
        this.addAlert('Login success. Welcome!', AlertType.THEME);
    };

    public loginFailure() {
        return this.addAlert('Login failed. You have no access to the Panel. Try again or contact with our Admin.', AlertType.THEME);
    };

    public logoutSuccess() {
        return this.addAlert('You have been logged out.', AlertType.THEME);
    }

    public addAlert(message: string, type: AlertType = AlertType.THEME, displayFor: number = 2000) {
        const alert = {
            message: message,
            type: type,
            displayFor: displayFor,
        };

        store.dispatch(addAlert(alert));
    }
}

export default AlertManagerService;
