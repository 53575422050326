import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function resetPasswordAPI(
    plainPassword: string,
    resetPasswordToken: string
): Observable<any> {
    return baseDataProviderAPI.post(
        `reset_password`,
        {
            plainPassword: plainPassword,
        },
        new RestQueryParams(),
        {
            'X-Authentication-Token': resetPasswordToken,
        },
    );
}
