import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function getBaseUserDetailsAPI(
    authToken: string,
    endpoint: string,
    userId: string
): Observable<any> {
    return baseDataProviderAPI.get(
        `${endpoint}/${userId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
