import {
    BasicFormControlType,
    FormButtonType,
    IFormConfig,
    IFormControlConfig,
    InputType,
    MultiSelectType,
    ValidationRules
} from "judo-app-common-web";
import store from "../../../../../store";
import {changeModal} from "../../../../../store/reducers/sagaSlice";
import {UserType} from "../../../../../model/models";


export const addUserFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },

    controls: [
        {
            key: 'add_user_base',
            controlType: 'group',
            class: '',
            controls: {
                email: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: `modal.addUser.form.email.placeholder`,
                    isLabelHidden: false,
                    label: `modal.addUser.form.email.label`,
                    type: InputType.EMAIL,
                    hostClass: ""
                }
            }
        }]
};

export const firstNameInput: typeof IFormConfig = (type: UserType) => ({
    firstName: {
        controlType: "control",
        defaultValue: "",
        formControlType: BasicFormControlType.INPUT,
        validationRules: [
            {name: ValidationRules.IS_REQUIRED, params: {}},
        ],
        placeholder: `modal.addUser.form.firstName.placeholder.${type}`,
        isLabelHidden: false,
        label: `modal.addUser.form.firstName.label.${type}`,
        type: InputType.TEXT,
        hostClass: ""
    },
});

export const lastNameInput: typeof IFormConfig = (type: UserType) => ({
    lastName: {
        controlType: "control",
        defaultValue: "",
        formControlType: BasicFormControlType.INPUT,
        validationRules: [],
        placeholder: `modal.addUser.form.lastName.placeholder.${type}`,
        isLabelHidden: false,
        label: `modal.addUser.form.lastName.label.${type}`,
        type: InputType.TEXT,
        hostClass: ""
    }
});


export const clubNameInput: typeof IFormControlConfig = {
    clubName: {
        controlType: "control",
        defaultValue: "",
        formControlType: BasicFormControlType.INPUT,
        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
        placeholder: `modal.addUser.form.clubName.placeholder`,
        isLabelHidden: false,
        label: `modal.addUser.form.clubName.label`,
        type: InputType.TEXT,
        hostClass: ""
    }
};

export const addClubInput: typeof IFormControlConfig = {
    club: {
        hostClass: 'form-select-input',
        controlType: 'control',
        defaultValue: null,
        placeholder: 'modal.addUser.form.club.placeholder',
        formControlType: BasicFormControlType.AUTOCOMPLETE,
        multiselectType: MultiSelectType.SINGLE,
        firstValue: 'modal.addUser.form.club.placeholder',
        label: 'modal.addUser.form.club.label',
        validationRules: [],
        multiselectOptions: [],
        inputDataMapper: (data: any) => {
            if (!data) {
                return [];
            }
            return data.map((category: any) => ({
                value: category.id,
                label: category.name,
            }));
        },
        outputDataMapper: (data: any) => {
            if (Array.isArray(data)) {
                return (data.length > 0) ? data[0].value : null;
            }
            if (data && typeof data === 'object') {
                return data.value;
            }

            return data;
        },
    }
};
export const addSpecialistTypeInput: typeof IFormControlConfig = {
    specialistType: {
        hostClass: 'form-select-input',
        controlType: 'control',
        defaultValue: null,
        placeholder: 'modal.addUser.form.specialistType.placeholder',
        formControlType: BasicFormControlType.AUTOCOMPLETE,
        multiselectType: MultiSelectType.SINGLE,
        label: 'modal.addUser.form.specialistType.label',
        validationRules: [],
        multiselectOptions: [],
        inputDataMapper: (data: any) => {
            if (!data) {
                return [];
            }
            return data.map((specialistType: any) => ({
                value: specialistType.value,
                label: specialistType.label,
            }));
        },
        outputDataMapper: (data: any) => {
            if (Array.isArray(data)) {
                return (data.length > 0) ? data[0].value : null;
            }
            if (data && typeof data === 'object') {
                return data.value;
            }

            return data;
        },
    }
};

export const buttonGroup: typeof IFormConfig = (type: UserType) => ({

    key: 'buttons',
    controlType: 'group',
    class: 'button-group-wrapper mt-5',
    controls: {
        cancelButton: {
            controlType: 'control',
            formControlType: BasicFormControlType.BUTTON,
            buttonType: FormButtonType.BUTTON,
            hostClass: 'button-container',
            defaultContainerStyles: 'd-flex justify-content-end mx-3',
            defaultInputStyles: 'btn btn-theme-outline',
            defaultValue: null,
            buttonDisabled: false,
            btnText: `button.cancel`,
            isLabelHidden: true,
            customClickHandler: () => store.dispatch(changeModal(null))
        },
        submitButton: {
            controlType: 'control',
            formControlType: BasicFormControlType.BUTTON,
            buttonType: FormButtonType.SUBMIT,
            hostClass: 'button-container',
            defaultContainerStyles: 'd-flex justify-content-end',
            defaultInputStyles: 'btn btn-theme',
            defaultValue: null,
            btnText: `modal.addUser.title.${type}`,
            isLabelHidden: true,
        }
    }
});

export const addSpecialistTypeFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'add_specialist_type',
            controlType: 'group',
            class: '',
            controls: {
                name: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                    ],
                    placeholder: 'modal.addUser.form.specialistName.placeholder',
                    isLabelHidden: false,
                    label: 'modal.addUser.form.specialistName.label',
                    type: InputType.EMAIL,
                    hostClass: ""
                }
            }
        }
    ]
};
