import React from 'react';
import {authTokenSelector, CustomCard, CustomModal, Translation} from 'judo-app-common-web';
import UserInfoBox, {UserInfoType} from "../../Shared/UserInfoBox";
import UserConnectionList from "../../Shared/UserConnectionsList";
import {Role, UserType} from '../../../model/models';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {isUserProfileLoadingSelector} from "../../../store/selectors/sagaSelectors";
import EditProfileButton from "../../Shared/EditProfileButton";
import ArchiveProfileButton from "../../Shared/ArchiveProfileButton";
import {userRoleSelector} from "../../../store/selectors/userSelector";

interface ISpecialistViewProps {
    onViewChange: (id: string | null) => void;
    itemView?: any;
    isModalView: boolean;
    isArchiveMode?: boolean;
    authToken: string;
    userRole: Role;
    isUserProfileLoading: boolean;
    changeModal: typeof changeModal;
}

interface ISpecialistViewState {
    specialistView: any;
}

class SpecialistView extends React.Component<ISpecialistViewProps, ISpecialistViewState> {
    constructor(props: ISpecialistViewProps) {
        super(props);
        this.state = {
            specialistView: null,
        };
    }

    componentDidMount() {
        if (this.props.itemView && this.props.itemView.id) this.setState({specialistView: this.props.itemView});
    }

    componentDidUpdate(prevProps: Readonly<ISpecialistViewProps>, prevState: Readonly<ISpecialistViewState>) {
        if (this.props.itemView !== prevProps.itemView) {
            this.setState({specialistView: this.props.itemView})
        }
    }

    render() {
        if (!this.state.specialistView) {
            return (<CustomCard showLocalLoader={true} type={"view-upload"}/>);
        }

        return this.renderSpecialistView()
    }

    private renderSpecialistView() {
        let header: any = null;
        if (this.props.isModalView) {
            header = (
                <CustomModal.Header>
                    <h2><Translation text={'userView.header.specialist'}/></h2>
                </CustomModal.Header>
            );
        } else {
            header = (
                <CustomCard.Header>
                    <div className="user-view-header">
                        <h2><Translation text={'userView.header.specialist'}/></h2>
                        <button className="btn-close" onClick={() => this.props.onViewChange(null)}>
                            <span className="feather icon-x" aria-hidden="true"/>
                        </button>
                    </div>
                </CustomCard.Header>
            );
        }

        if (this.props.isModalView) {
            return <React.Fragment>
                {header}
                <CustomModal.Body>
                    {this.renderBody()}
                    {this.renderConnectedUserLists()}
                </CustomModal.Body>
            </React.Fragment>
        }
        return (
            <CustomCard showLocalLoader={this.props.isUserProfileLoading}>
                {header}
                <CustomCard.Body>
                    {this.renderBody()}
                    {this.renderActionButtons()}
                    <div className="separator"/>
                    {this.renderConnectedUserLists()}
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderBody() {
        return (
            <React.Fragment>
                <div className="separator"/>
                <UserInfoBox user={this.state.specialistView} infoCellType={UserInfoType.Extended}/>
                <div className="separator"/>
            </React.Fragment>
        )
    }

    private renderActionButtons() {
        if (this.props.userRole === Role.Admin ||
            this.props.userRole === Role.HeadCoach) {
            return <div className="action-buttons-wrapper">
                {!this.props.isArchiveMode &&
                <EditProfileButton profileId={this.state.specialistView.id}/>}
                <ArchiveProfileButton isArchiveMode={this.props.isArchiveMode} profile={this.state.specialistView}/>
            </div>
        }
        return null
    }

    private renderConnectedUserLists() {
        if (this.props.isArchiveMode) {
            return null;
        }
        return <div className='d-flex flex-column'>
            <UserConnectionList dataList={this.state.specialistView.coaches}
                                listType={UserType.COACH}
                                account={this.state.specialistView}
                                isModalView={this.props.isModalView}/>
            <div className="separator"/>
            <UserConnectionList dataList={this.state.specialistView.contestants}
                                listType={UserType.CONTESTANT}
                                account={this.state.specialistView}
                                isModalView={this.props.isModalView}/>
            <div className="separator"/>
        </div>
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isUserProfileLoading: isUserProfileLoadingSelector(state),
        userRole: userRoleSelector(state)
    }),
    {
        changeModal
    }
)(SpecialistView);
