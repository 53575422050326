import {
    BasicFormControlType,
    IFormConfig,
    MultiSelectType
} from "judo-app-common-web";
import i18next from "i18next";
import {DatepickerType} from "judo-app-common-web";

export const datepickerTypeSelectFormConfig: typeof IFormConfig =  (defValue?: typeof DatepickerType) => ({
    controlType: 'form',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'datepicker_type',
            controlType: 'group',
            class: '',
            controls: {
                datepickerType: {
                    disabled: !!defValue,
                    hostClass: 'form-select-input',
                    controlType: 'control',
                    defaultValue: defValue ? ({
                        value: defValue,
                        label: i18next.t(`profileSettingsModal.form.addInputForm.datepickerType.options.${defValue}`)
                    }) : null,
                    placeholder: 'profileSettingsModal.form.addInputForm.type.placeholder',
                    formControlType: BasicFormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    firstValue: 'profileSettingsModal.form.addInputForm.type.placeholder',
                    label: 'profileSettingsModal.form.addInputForm.datepickerType.label',
                    validationRules: [],
                    multiselectOptions: () => {
                        const inputTypes = [
                            {
                                value: DatepickerType.DATE,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.datepickerType.options.${DatepickerType.DATE}`)
                            },
                            {
                                value: DatepickerType.TIME,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.datepickerType.options.${DatepickerType.TIME}`)
                            },
                            {
                                value: DatepickerType.DATE_TIME,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.datepickerType.options.${DatepickerType.DATE_TIME}`)
                            },

                        ]
                        return inputTypes;
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }
                        return data
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                },
            }
        }
    ]

});

export default datepickerTypeSelectFormConfig;
