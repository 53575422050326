import React from 'react';
import {CustomCard, Form, FormControlChangeType, IFormConfig} from 'judo-app-common-web';
import {updateGroupFormConfig} from "./updateGroupFormConfig";

interface IUpdateNameFormProps {
    calendarName: string;
    onNameUpdate: (calendarName: string) => void;
}

interface IUpdateNameFormState {
    calendarName: string;
    formConfig: typeof IFormConfig;
    value: any;
}

class UpdateNameForm extends React.Component<IUpdateNameFormProps, IUpdateNameFormState> {
    constructor(props: IUpdateNameFormProps) {
        super(props);
        this.state = {
            calendarName: this.props.calendarName,
            formConfig: updateGroupFormConfig(
                this.props.calendarName,
                true
            ),
            value: '',
        };
    }


    componentDidUpdate(prevProps: Readonly<IUpdateNameFormProps>, prevState: Readonly<IUpdateNameFormState>) {
        if (this.props.calendarName !== prevProps.calendarName) {
            const updatedFormConfig =  updateGroupFormConfig(
                this.props.calendarName,
                true
            );
            this.setState({formConfig: updatedFormConfig})

        }
    }


    render() {
        return <CustomCard showLocalLoader={false}>
            <CustomCard.Body>
                <Form
                    config={this.state.formConfig}
                    submitForm={this.updateName}
                    onValueStateChange={this.inputNameChange}
                    controlName={"updateProfileName"}
                    value={{}}
                />
            </CustomCard.Body>
        </CustomCard>
    }

    private inputNameChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        const disabledButton = value.name === this.props.calendarName;
            const updatedFormConfig =  updateGroupFormConfig(
                value.name,
                disabledButton
            )
            this.setState({formConfig: updatedFormConfig, value: value})
    }

    private updateName = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        if (!value.name) {
            return;
        }
        this.props.onNameUpdate(value.name);
    };
}

export default UpdateNameForm;
