import React from 'react';
import {authTokenSelector, CustomCard, CustomModal, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {of, Subscription} from 'rxjs';
import UserInfoBox, {UserInfoType} from '../../Shared/UserInfoBox';
import UserConnectionList from '../../Shared/UserConnectionsList';
import {ModalType, ProfileDefinition, Role, UserType} from '../../../model/models';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {isUserProfileLoadingSelector} from '../../../store/selectors/sagaSelectors';
import TooltipWrapper, {ITooltip} from '../../Shared/TooltipWrapper';
import {userRoleSelector} from '../../../store/selectors/userSelector';
import {catchError, filter, tap} from 'rxjs/operators';
import EditProfileButton from '../../Shared/EditProfileButton';
import ArchiveProfileButton from '../../Shared/ArchiveProfileButton';
import {getDevelopmentProfiles} from '../../../api/getDevelopmentProfiles';

interface IContestantViewProps {
    readonly onViewChange: (id: string | null) => void;
    readonly itemView?: any;
    readonly isModalView: boolean;
    readonly authToken: string;
    readonly userRole: Role;
    readonly isArchiveMode?: boolean;
    readonly isUserProfileLoading: boolean;
    readonly changeModal: typeof changeModal;
}

interface IContestantViewState {
    contestantView: any;
}

class ContestantView extends React.Component<IContestantViewProps, IContestantViewState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IContestantViewProps) {
        super(props);
        this.state = {
            contestantView: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.props.itemView && this.props.itemView.id) this.setState({contestantView: this.props.itemView});
    }

    componentDidUpdate(prevProps: Readonly<IContestantViewProps>, prevState: Readonly<IContestantViewState>) {
        if (this.props.itemView !== prevProps.itemView) {
            this.setState({contestantView: this.props.itemView});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        if (!this.state.contestantView) {
            return <CustomCard showLocalLoader={true} type={'view-upload'} />;
        }

        if (this.props.isModalView) {
            return this.renderContestantModal();
        }
        return this.renderContestantView();
    }

    private renderContestantView() {
        return (
            <CustomCard showLocalLoader={this.props.isUserProfileLoading}>
                <CustomCard.Header>
                    <div className="user-view-header">
                        <h2>
                            <Translation text={'userView.header.contestant'} />
                        </h2>
                        <button className="btn-close" onClick={() => this.props.onViewChange(null)}>
                            <span className="feather icon-x" aria-hidden="true" />
                        </button>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className="separator" />
                    <UserInfoBox user={this.state.contestantView} infoCellType={UserInfoType.Extended} />
                    <div className="separator" />
                    {this.renderActionButtons()}
                    {this.renderFatigueProfileButton()}
                    {this.renderConnectedUserLists()}
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderContestantModal() {
        return (
            <React.Fragment>
                <CustomModal.Header>
                    <h2>
                        <Translation text={'userView.header.contestant'} />
                    </h2>
                </CustomModal.Header>
                <CustomModal.Body>
                    <div className="separator" />
                    <UserInfoBox user={this.state.contestantView} infoCellType={UserInfoType.Extended} />
                    <div className="separator" />
                    {this.renderFatigueProfileButton()}
                    {this.renderConnectedUserLists()}
                </CustomModal.Body>
            </React.Fragment>
        );
    }

    private renderConnectedUserLists() {
        if (this.props.isArchiveMode) {
            return null;
        }
        return (
            <div className="d-flex flex-column">
                <UserConnectionList
                    dataList={this.state.contestantView.coaches}
                    listType={UserType.COACH}
                    account={this.state.contestantView}
                    isModalView={this.props.isModalView}
                />
                <div className="separator" />
                <UserConnectionList
                    dataList={this.state.contestantView.specialists}
                    listType={UserType.SPECIALIST}
                    account={this.state.contestantView}
                    isModalView={this.props.isModalView}
                />
            </div>
        );
    }

    private renderFatigueProfileButton() {
        if (this.props.isArchiveMode) return null;
        if (this.state.contestantView.profileDefinitions.length) {
            return this.renderButton('show');
        }
        if (
            (this.props.userRole === Role.Admin || this.props.userRole === Role.HeadCoach || this.props.userRole === Role.Coach) &&
            !this.state.contestantView.profileDefinitions.length
        ) {
            return this.renderButton('assign');
        }
    }

    private renderButton(type: string) {
        const tooltip: ITooltip = {text: `userList.table.button.${type}.fatigueProfile`},
            showAdditionalButtons = type === 'show' && this.props.userRole !== Role.Specialist;
        // const onClick = type === 'show' ? this.openFatigueProfileModal : this.openAssignFatigueProfileModal;
        return (
            <>
                <div className="action-buttons-wrapper flex-column">
                    <div className={`d-flex justify-content-between ${showAdditionalButtons ? 'mb-3' : ''}`}>
                        <div className="action-button">
                            <TooltipWrapper tooltip={tooltip} key={'assign-fatigue-profile-button'}>
                                <button
                                    className="btn btn-list"
                                    onClick={() =>
                                        type === 'show' ? this.openFatigueProfileModal() : this.openAssignFatigueProfileModal()
                                    }>
                                    <span className="feather icon-file-plus" aria-hidden="true" />
                                    <Translation text={`userList.table.button.${type}.fatigueProfile`} />
                                </button>
                            </TooltipWrapper>
                        </div>
                        {showAdditionalButtons && (
                            <div className="action-button">
                                <TooltipWrapper
                                    tooltip={{text: `userList.table.button.unassign.developmentProfile`}}
                                    key={'unassign-development-profile-button'}>
                                    <button className="btn btn-list delete" onClick={this.openUnassignFatigueProfileModal}>
                                        <span className="feather icon-trash" aria-hidden="true" />
                                        <span className="sr-only">
                                            <Translation text={`userList.table.button.unassign.developmentProfile`} />
                                        </span>
                                    </button>
                                </TooltipWrapper>
                            </div>
                        )}
                    </div>
                    {showAdditionalButtons && (
                        <div className="d-flex">
                            <div className="action-button">
                                <TooltipWrapper
                                    tooltip={{text: `userList.table.button.show.trainingProfile`}}
                                    key={'show-training-profile-button'}>
                                    <button className="btn btn-list" onClick={() => this.openFatigueProfileModal(true)}>
                                        <span className="feather icon-activity" aria-hidden="true" />
                                        <Translation text={`userList.table.button.show.trainingProfile`} />
                                    </button>
                                </TooltipWrapper>
                            </div>
                        </div>
                    )}
                </div>
                <div className="separator" />
            </>
        );
    }

    private renderActionButtons() {
        if (this.props.userRole === Role.Admin || this.props.userRole === Role.HeadCoach) {
            return (
                <React.Fragment>
                    <div className="action-buttons-wrapper">
                        {!this.props.isArchiveMode && <EditProfileButton profileId={this.state.contestantView.id} />}
                        <ArchiveProfileButton isArchiveMode={this.props.isArchiveMode} profile={this.state.contestantView} />
                    </div>
                    <div className="separator" />
                </React.Fragment>
            );
        }
        return null;
    }

    private openFatigueProfileModal = (trainingProfile?: boolean) => {
        const userProfileDefinitionId = this.state.contestantView.profileDefinitions.filter(
            (profileDefinition: ProfileDefinition) => profileDefinition.name !== 'Trening'
        )[0].id;
        this.subscriptions.push(
            // getBaseListAPI(
            //     this.props.authToken as string,
            //     EndpointNames.CALENDARS,
            //     undefined,
            //     1000
            // ).pipe(
            getDevelopmentProfiles(this.props.authToken as string, 1, 1000)
                .pipe(
                    filter((response) => !!response),
                    tap(
                        (resp: any) => {
                            if (resp['hydra:member']) {
                                const calendarList = resp['hydra:member'];
                                const usedCalendar = calendarList.find(
                                    (calendar: any) => calendar.profileDefinition.id === userProfileDefinitionId
                                );
                                trainingProfile
                                    ? this.props.changeModal({
                                          type: ModalType.TRAINING_PLAN,
                                          id: usedCalendar.trainingProfile.id,
                                          userId: this.state.contestantView?.id,
                                          data: {isPreview: true, fatigueProfileName: usedCalendar.name},
                                      })
                                    : this.props.changeModal({
                                          type: ModalType.CALENDAR,
                                          id: trainingProfile ? usedCalendar.trainingProfile.id : usedCalendar.id,
                                          userId: this.state.contestantView?.id,
                                      });
                                // this.props.changeModal({type: ModalType.CALENDAR, id: usedCalendar.id, userId: this.state.contestantView?.id})
                            }
                        },
                        catchError((err: any) => {
                            this.alertManagerService.handleApiError(err);
                            return of();
                        })
                    )
                )
                .subscribe()
        );
    };

    private openAssignFatigueProfileModal = () => {
        this.props.changeModal({
            type: ModalType.ASSIGN_FATIGUE_PROFILE,
            id: this.state.contestantView.id,
        });
    };
    private openUnassignFatigueProfileModal = () => {
        const userProfileDefinitionId = this.state.contestantView.profileDefinitions[0].id,
            multipleIds = this.state.contestantView.profileDefinitions.map((profile: any) => profile.id);
        this.props.changeModal({
            type: ModalType.UNASSIGN,
            id: userProfileDefinitionId,
            multipleIds: multipleIds,
            data: {hostAccountId: this.state.contestantView.id},
            role: UserType.FATIGUE_PROFILE,
        });
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isUserProfileLoading: isUserProfileLoadingSelector(state),
        userRole: userRoleSelector(state),
    }),
    {
        changeModal,
    }
)(ContestantView);
