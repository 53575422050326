import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import TooltipWrapper, {ITooltip} from "../../TooltipWrapper";
import {ModalType, Role, UserType} from '../../../../model/models';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {changeModal} from '../../../../store/reducers/sagaSlice';
import {userRoleSelector} from '../../../../store/selectors/userSelector';
import {getBaseListAPI} from "../../../../api/getBaseList";
import {EndpointNames} from "../../AnimatedLayoutHost";
import {catchError, filter, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {authTokenSelector} from "judo-app-common-web";

interface MatchProcessParams {
    host?: string;
    userId?: string;
    profileId?: string
    type?: ModalType
}

interface IUserConnectionItemState {
    tableItemHeight: number;
}

interface UserConnectionItemProps extends RouteComponentProps<MatchProcessParams> {
    listItem: any;
    type: UserType;
    hostUserId: string;
    connectedUserAccountId: string;
    isModalView: boolean;
    key: string;
    firstChild?: boolean;
    onHeightChange: (height: number) => void;
    userRole: Role;
    authToken: string;
    changeModal: typeof changeModal;
}

class UserConnectionItem extends React.Component<UserConnectionItemProps, IUserConnectionItemState> {
    readonly tableItemRef: any;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: UserConnectionItemProps) {
        super(props);
        this.state = {
            tableItemHeight: 0,
        };
        this.tableItemRef = React.createRef();
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.tableItemRef.current && this.tableItemRef.current.clientHeight && this.props.firstChild) {
            const tableBodyHeight = this.tableItemRef.current.clientHeight;
            this.setState({tableItemHeight: tableBodyHeight});
            this.props.onHeightChange(tableBodyHeight);

        }
    }

    componentDidUpdate(
        prevProps: Readonly<UserConnectionItemProps>,
        prevState: Readonly<IUserConnectionItemState>,
        snapshot?: any
    ) {
        if (this.tableItemRef.current && this.tableItemRef.current.clientHeight !== this.state.tableItemHeight && this.props.firstChild) {
            const tableBodyHeight = this.tableItemRef.current.clientHeight;
            this.setState({tableItemHeight: tableBodyHeight});
            this.props.onHeightChange(tableBodyHeight);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        let name = `${this.props.listItem.firstName} ${this.props.listItem.lastName}`;
        if (this.props.type === UserType.FATIGUE_PROFILE) {
            name = this.props.listItem.name;
        }
        return <li key={this.props.key}
                   ref={this.tableItemRef}
                   className="user-connection-list-item  ellipsis-host">
            <div className="ellipsis-host d-flex flex-wrap mr-2">
                <div className={'ellipsis-item'}>{name}</div>
                {this.renderItemType()}</div>
            <div>
                {this.renderActionButtons(this.props.type)}
            </div>
        </li>;
    }


    private renderItemType() {
        if (!this.props.listItem.specialistType || !this.props.listItem.specialistType.name) {
            return null;
        }

        return (
            <div className="list-item-type ellipsis-item ml-2"> {`( ${this.props.listItem.specialistType.name} )`}</div>
        );
    }

    private renderActionButtons(connectedType: UserType) {
        let buttons = [];
        if (this.props.userRole === Role.Contestant || this.props.userRole === Role.Specialist || this.props.userRole === Role.Coach) {
            return null;
        }
        if (connectedType === UserType.CONTESTANT ||
            connectedType === UserType.SPECIALIST ||
            connectedType === UserType.COACH) {
            const tooltip: ITooltip = {text: 'userList.table.button.show.personalProfile'};
            buttons.push(
                <TooltipWrapper tooltip={tooltip} key={'profile-button'}>
                    <button className="btn btn-list mr-l" onClick={this.openUserProfileModal}>
                        <span className="feather icon-user" aria-hidden="true"/>
                    </button>
                </TooltipWrapper>
            );
        }

        if (connectedType === UserType.CONTESTANT && (this.props.listItem.profileDefinitions && this.props.listItem.profileDefinitions.length)) {
            const tooltip: ITooltip = {text: 'userList.table.button.show.fatigueProfile'};
            buttons.push(
                <TooltipWrapper tooltip={tooltip} key={'fatigue-button'}>
                    <button className="btn btn-list ml-3"
                            onClick={() => this.openFatigueProfileModal(this.props.listItem.profileDefinitions[0].id)}>
                        <span className="feather icon-file-minus" aria-hidden="true"/>
                    </button>
                </TooltipWrapper>
            );
        }

        if (this.props.userRole === Role.Admin || this.props.userRole === Role.HeadCoach) {
            const tooltip: ITooltip = {text: 'userList.table.button.redirect.profile'}
            buttons.push(
                <TooltipWrapper tooltip={tooltip} key={'redirect-profile-button'}>
                    <button className="btn btn-list ml-3" onClick={() => this.redirectToProfile(connectedType, this.props.listItem.id)}>
                        <span className="feather icon-external-link" aria-hidden="true"/>
                    </button>
                </TooltipWrapper>
            );
        }

        if ((this.props.userRole === Role.Admin || this.props.userRole === Role.HeadCoach) && !this.props.isModalView) {
            const tooltip: ITooltip = {text: `userList.table.button.unassign.${this.props.type}`}
            buttons.push(
                <TooltipWrapper tooltip={tooltip} key={'unassign-profile-button'}>
                    <button className="btn btn-list delete ml-3" onClick={this.openRemoveFromListModal}>
                        <span className="feather icon-trash" aria-hidden="true"/>
                    </button>
                </TooltipWrapper>
            );
        }
        return buttons;
    }


    private redirectToProfile = (connectedType: UserType, profileId: string) => {
        let hostType: string = '';
        switch (connectedType) {
            case UserType.CONTESTANT:
                hostType = 'contestants'
                break;
            case UserType.SPECIALIST:
                hostType = 'specialists'
                break;
            case UserType.COACH:
                hostType = 'coaches'
                break;
            case UserType.CLUB:
                hostType = 'clubs'
                break;
            case UserType.FATIGUE_PROFILE:
                hostType = 'profiles'
                break;

            default:
                throw new Error(`Bad User role: ${connectedType}`)
        }
        if (this.props.isModalView) {
            this.props.changeModal(null);
        }

        if (connectedType === UserType.FATIGUE_PROFILE) {
            return this.props.history.replace(`/${hostType}/${profileId}/calendar`);
        }
        return this.props.history.replace(`/${hostType}/${profileId}`);
    }

    private openUserProfileModal = () => {
        this.props.changeModal({
            type: ModalType.PROFILE,
            id: this.props.listItem.id,
            role: this.props.listItem.type
        })
    };
    private openRemoveFromListModal = () => {
        this.props.changeModal({
            type: ModalType.UNASSIGN,
            id: this.props.connectedUserAccountId,
            data: {hostAccountId: this.props.hostUserId},
            role: this.props.listItem.type ? this.props.listItem.type : this.props.type
        })
    };

    private openFatigueProfileModal = (userProfileDefinitionId: string) => {
        this.subscriptions.push(getBaseListAPI(
            this.props.authToken as string,
            EndpointNames.CALENDARS).pipe(
            filter(response => !!response),
            tap((resp: any) => {
                    if (resp['hydra:member']) {
                        const calendarList = resp['hydra:member'];
                        const usedCalendar = calendarList.find((calendar: any) => calendar.profileDefinition.id === userProfileDefinitionId);
                        if (usedCalendar) {
                            this.props.changeModal({type: ModalType.CALENDAR, id: usedCalendar.id})
                        }
                        return of();
                    }
                },
                catchError((err: any) => {
                    this.alertManagerService.handleApiError(err);
                    return of();
                }),
            )
        ).subscribe());
    }
}

export default connect(
    (state: RootState) => (
        {
            userRole: userRoleSelector(state),
            authToken: authTokenSelector(state),
        }
    ),
    {
        changeModal
    }
)(withRouter(UserConnectionItem));
