import React from 'react';
import {
    authTokenSelector,
    CustomCard,
    IBasicFormControlConfig,
    Loader,
    LoaderType,
    Translation
} from 'judo-app-common-web';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {MatchProcessParams} from "../../PanelHost";
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {
    CalendarGroup,
    CalendarInput,
    CalendarInputOption,
    CurrentFatigueCalendarView,
    InputDefinition,
    InputType,
    ModalConfig,
} from "../../../model/models";
import {createGroupFormConfig} from "./CreateGroupList/createGroupFormConfig";
import UpdateGroupList from "./UpdateGroupList";
import SettingsGroupList from "./SettingsGroupList";
import AddInputFormModal from "./ModalAddInput";
import CreateGroupList from './CreateGroupList';
import {of, Subscription} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {addInputGroupDefinitionAPI} from "../../../api/addInputGroupDefinition";
import {addInputDefinitionAPI} from "../../../api/addInputDefinition";
import {updateCalendarGroupAPI} from "../../../api/updateFatigueCalendarGroup";
import ModalUpdateCalendarVariable from "./ModalUpdateCalendarVariable";
import {updateFatigueCalendarInputAPI} from "../../../api/updateFatigueCalendarInput";
import ModalArchiveCalendarGroup from "./ModalArchiveCalendarGroup";
import ModalArchiveCalendarVariable from './ModalArchiveCalendarVariable';
import {updateInputDefinitionAPI} from "../../../api/updateInputDefinition";
import {deepCloneObject} from "../../../utils/runtimeUtils";
import {VariablesFromGroupService} from "../../../service";
import {changeModal} from '../../../store/reducers/sagaSlice';
import {EndpointNames} from "../../Shared/AnimatedLayoutHost";
import {getBaseUserDetailsAPI} from "../../../api/getBaseUserDetails";
import {modalSelector} from "../../../store/selectors/sagaSelectors";
import UpdateNameForm from "./ChangeNameForm";
import {updateFatigueCalendarAPI} from "../../../api/updateFatigueCalendar";
import {updateProfileDefinitionAPI} from "../../../api/updateProfileDefinition";

interface IFatigueProfileSettingsProps extends RouteComponentProps<MatchProcessParams> {
    readonly onClose: () => void;
    readonly authToken: string;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
}

interface IFatigueProfileSettingsState {
    fatigueCalendarSettingsView?: CurrentFatigueCalendarView;
    isProcessing: boolean;
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    selectedGroup: CalendarGroup | null;
    createdGroup: CalendarGroup | null;
    createdVariable: CalendarInput | null;
    currentlyEditedVariable: CalendarInput | null;
    groupToArchiveId: string | null;
    variableToArchiveId: string | null;
}

class FatigueProfileSettings extends React.Component<IFatigueProfileSettingsProps, IFatigueProfileSettingsState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('VariablesFromGroupService') private variablesFromGroupService: VariablesFromGroupService;

    private subscriptions: Subscription[] = [];

    constructor(props: IFatigueProfileSettingsProps) {
        super(props);
        this.state = {
            fatigueCalendarSettingsView: undefined,
            isProcessing: true,
            formConfig: createGroupFormConfig,
            selectedGroup: null,
            createdGroup: null,
            createdVariable: null,
            currentlyEditedVariable: null,
            groupToArchiveId: null,
            variableToArchiveId: null,
        };
        fixInjectedProperties(this);
    }

    private get groupVariables() {
        if (this.state.createdGroup && this.state.createdGroup?.inputGroupDefinition?.id) {
            const groupVariables = deepCloneObject(this.state.createdGroup.calendarViewGroupItems)
            return this.variablesFromGroupService.createNameWithPrefix(this.variablesFromGroupService.getGroupVariables(groupVariables || [], this.state.createdGroup?.inputGroupDefinition?.id))
        }
        if (this.state.selectedGroup && this.state.selectedGroup?.inputGroupDefinition?.id) {
            const groupVariables = deepCloneObject(this.state.selectedGroup.calendarViewGroupItems)
            return this.variablesFromGroupService.createNameWithPrefix(this.variablesFromGroupService.getGroupVariables(groupVariables || [], this.state.selectedGroup?.inputGroupDefinition?.id))
        }
        return []
    }

    componentDidMount() {
        this.retrieveCalendarData();
    }

    componentDidUpdate(prevProps: Readonly<IFatigueProfileSettingsProps>, prevState: Readonly<IFatigueProfileSettingsState>) {
        if (this.props.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
            this.retrieveCalendarData();
        }
        if (this.state.fatigueCalendarSettingsView !== prevState.fatigueCalendarSettingsView && this.state.fatigueCalendarSettingsView) {
            const currentlyChosenGroupId = this.state.selectedGroup ? this.state.selectedGroup.id : null;
            if (currentlyChosenGroupId) {
                const chosenGroup = this.state.fatigueCalendarSettingsView.calendarViewGroups.find((calendarViewGroup: CalendarGroup) => calendarViewGroup.id === currentlyChosenGroupId);
                this.setState({selectedGroup: chosenGroup ? chosenGroup : null});
            }
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.fatigueCalendarSettingsView) {
            return null;
        }

        return <>
            {this.renderProfileSettingsHeader()}
            {this.renderProfileSettingsBody(this.state.fatigueCalendarSettingsView)}
        </>
    }

    private renderProfileSettingsHeader() {
        return <CustomCard.Header>
            <div className="user-view-header">
                <h2><Translation text={'profileSettingsModal.header'}/>
                    {this.state.fatigueCalendarSettingsView &&
                    <span>{` - ${this.state.fatigueCalendarSettingsView.name}`}</span>
                    }
                </h2>
                <button className="btn-close text" onClick={() => this.props.onClose()}>
                    <span>
                        <Translation text={'button.back'}/>
                    </span>
                </button>
            </div>
        </CustomCard.Header>
    }

    private renderProfileSettingsBody(profileView: CurrentFatigueCalendarView) {
        return <CustomCard.Body>
            <div className="row profile-settings-body">
                <Loader type={LoaderType.Local} show={this.state.isProcessing}/>
                <div className="col-lg-6">
                    <UpdateNameForm calendarName={this.state.fatigueCalendarSettingsView ? this.state.fatigueCalendarSettingsView.name  : ''} onNameUpdate={(name: string) =>  this.updateProfileName(name)} />
                    <SettingsGroupList calendarData={profileView}
                                       onGroupEditSelected={this.setSubviewUpdate}
                                       onGroupCreationSelected={this.setSubviewCreate}
                                       onGroupArchive={this.openArchiveGroupModal}/>
                </div>
                <div className="col-lg-6">
                    {this.renderSettingsSubview()}
                </div>
            </div>
            {this.state.groupToArchiveId &&
            <ModalArchiveCalendarGroup onGroupArchiveConfirm={this.archiveItem}
                                       onClose={this.closeArchiveGroupModal}/>}
            {this.state.variableToArchiveId &&
            <ModalArchiveCalendarVariable onVariableArchiveConfirm={this.archiveVariable}
                                          onClose={this.closeArchiveVariableModal}/>}
            {this.state.createdVariable &&
            <AddInputFormModal createdVariable={this.state.createdVariable}
                               variables={this.groupVariables}
                               onVariableCreate={this.addVariable}
                               onClose={this.closeAddInputModal}/>}
            {this.state.currentlyEditedVariable &&
            <ModalUpdateCalendarVariable editedVariable={this.state.currentlyEditedVariable}
                                         groupName={this.state.selectedGroup?.name}
                                         variables={this.groupVariables}
                                         onVariableUpdate={this.updateVariable}
                                         onClose={this.closeUpdateVariableModal}/>}
        </CustomCard.Body>
    }

    private renderSettingsSubview() {
        if (this.state.createdGroup) {
            return <CreateGroupList createdCalendarGroup={this.state.createdGroup}
                                    onGroupCreation={this.createGroup}/>
        }
        if (this.state.selectedGroup) {
            return <UpdateGroupList calendarGroup={this.state.selectedGroup}
                                    onVariableSelected={this.selectVariableToEdit}
                                    onGroupUpdate={this.updateGroup}
                                    onOpenAddInputModal={this.openAddInputModal}
                                    onGroupArchive={this.openArchiveGroupModal}
                                    onVariableArchive={this.openArchiveVariableModal}/>
        }
    }

    private setSubviewUpdate = (group: CalendarGroup) => {
        this.setState({createdGroup: null, selectedGroup: group})
    };

    private setSubviewCreate = () => {
        const emptyCalendarGroup: CalendarGroup = {
            id: '',
            itemOrder: 0,
            calendarViewGroupItems: [],
            enabled: true,
            visualOptions: {},
            name: '',
        }
        this.setState({selectedGroup: null, createdGroup: emptyCalendarGroup})
    };

    private updateProfileName = (name: string) => {
        if (!this.state.fatigueCalendarSettingsView || !this.state.fatigueCalendarSettingsView.profileDefinition || !this.state.fatigueCalendarSettingsView.profileDefinition.id) {
            return null;
        }
        const updatedCalendar = this.state.fatigueCalendarSettingsView,
            payload = {
                name: name,
                profileDefinitionId: updatedCalendar.profileDefinition?.id
        };
        this.setState({isProcessing: true});
        return this.subscriptions.push(updateFatigueCalendarAPI(this.props.authToken, updatedCalendar.id, payload).pipe(
            switchMap((value: any) => {
              return  updateProfileDefinitionAPI(this.props.authToken, {name: name}, value.profileDefinition.id);
            }),
            catchError((error: any) => {
                this.setState({isProcessing: false});
                this.alertManagerService.handleApiError(error);
                return of();
            }),
            tap((inputDefinitionGroupResponse) => {
                this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateFatigueProfileSuccess');
                this.retrieveCalendarData();
                this.setState({isProcessing: false, createdGroup: null});
                return of();
            })
        ).subscribe());
    };


    private createGroup = (calendarGroupDraft: CalendarGroup) => {
        if (!this.state.fatigueCalendarSettingsView || !this.state.fatigueCalendarSettingsView?.profileDefinition || !this.state.fatigueCalendarSettingsView.profileDefinition.id) {
            return null;
        }
        const newItemIndex = this.state.fatigueCalendarSettingsView.calendarViewGroups.length;
        const addedCalendarGroup = {
            name: calendarGroupDraft.name,
            itemOrder: newItemIndex ? newItemIndex : 0,
            calendarId: this.state.fatigueCalendarSettingsView.id,
            visualOptions: calendarGroupDraft.visualOptions,
            enabled: calendarGroupDraft.enabled,
        }
        const inputGroupDefinition = {
            name: calendarGroupDraft.name,
            profileDefinitionId: this.state.fatigueCalendarSettingsView?.profileDefinition.id,
            calendarViewGroups: [addedCalendarGroup],
            type: calendarGroupDraft?.inputGroupDefinition?.type
        };
        this.setState({isProcessing: true});
        return this.subscriptions.push(addInputGroupDefinitionAPI(this.props.authToken, inputGroupDefinition).pipe(
            catchError((error: any) => {
                this.setState({isProcessing: false});
                this.alertManagerService.handleApiError(error);
                return of();
            }),
            tap((inputDefinitionGroupResponse) => {
                this.alertManagerService.addAlert('alert.fatigueProfileAlert.createGroupSuccess');
                // this.props.changeShouldViewUpdate(true);
                this.retrieveCalendarData();
                this.setState({isProcessing: false, createdGroup: null});
                return of();
            })
        ).subscribe());
    };

    private addVariable = (newInput: CalendarInput) => {
        if (this.state.selectedGroup && this.state.selectedGroup.inputGroupDefinition) {
            let tempVar: any = {
                name: newInput.name,
                itemOrder: 0,
                enabled: newInput.enabled || false,
                visualOptions: {},
                // type: newInput.type,
                // inputDefinition: {type: newInput.type},
                valuesVisualOptions: newInput.valuesVisualOptions,
                calendarViewGroupId: this.state.selectedGroup.id
            }


            let selectParameters: CalendarInputOption[] = [];
            newInput.valuesVisualOptions.forEach((visualValue: CalendarInputOption) => selectParameters.push(visualValue))

            if (newInput.type === InputType.CALCULATED) {
                tempVar.parameters = {
                    ...tempVar.parameters,
                    expression: newInput?.parameters?.expression
                }
            }

            const newInputDefinitionPayload: InputDefinition = {
                type: newInput.type,
                name: newInput.name,
                enabled: newInput.enabled || false,
                inputGroupDefinitionId: this.state.selectedGroup.inputGroupDefinition.id,
                visualOptions: '',
                parameters: {
                    ...newInput.parameters,
                    selectValues: selectParameters
                },
                itemOrder: this.state.selectedGroup.calendarViewGroupItems.length,
                calendarViewGroupItems: [tempVar]
            };
            this.setState({isProcessing: true});
            return this.subscriptions.push(
                addInputDefinitionAPI(this.props.authToken, newInputDefinitionPayload).pipe(
                    catchError((error: any) => {
                        this.setState({isProcessing: false});
                        this.alertManagerService.handleApiError(error);
                        return of();
                    }),
                    tap(() => {
                        this.alertManagerService.addAlert('alert.fatigueProfileAlert.createVariableSuccess');
                        this.retrieveCalendarData();
                        this.setState({isProcessing: false})
                    })
                ).subscribe());
        }
    }

    private updateVariable = (variable: CalendarInput) => {
        if (!this.state.currentlyEditedVariable || !this.state.selectedGroup) {
            return null;
        }
        let updatedVariablePayload: any = {
            name: variable.name,
            itemOrder: variable.itemOrder,
            calendarViewGroupId: this.state.selectedGroup.id,
            visualOptions: {},
            inputDefinitionId: variable.inputDefinition?.id,
            valuesVisualOptions: variable.valuesVisualOptions ? variable.valuesVisualOptions : [],
            enabled: variable.enabled,
        }

        let selectParameters: CalendarInputOption[] = [];
        if (variable.valuesVisualOptions) {
            variable.valuesVisualOptions.forEach((visualValue: CalendarInputOption) => selectParameters.push(visualValue))
        }
        this.setState({isProcessing: true});
        return this.subscriptions.push(updateFatigueCalendarInputAPI(this.props.authToken, variable.id, updatedVariablePayload).pipe(
            map((inputDefinitionResponse: any) => {
                this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateVariableSuccess');
                return inputDefinitionResponse.inputDefinition;
            }),
            catchError((error: any) => {
                this.setState({isProcessing: false});
                this.alertManagerService.handleApiError(error)
                return of();
            }),
            switchMap((response: any) => {
                if (!response) {
                    return of();
                }
                const inputDefinitionId = response.id,
                    updatedInputDefinition = deepCloneObject(variable.inputDefinition);
                updatedInputDefinition.name = variable.name;
                updatedInputDefinition.type = response.type;
                updatedInputDefinition.visualOptions = {};
                updatedInputDefinition.parameters = {...variable.parameters, selectValues: selectParameters};
                updatedInputDefinition.inputGroupDefinitionId = this.state.selectedGroup?.inputGroupDefinition?.id;
                updatedInputDefinition.visualOptions = response.visualOptions ? response.visualOptions : {};

                return updateInputDefinitionAPI(this.props.authToken, inputDefinitionId, updatedInputDefinition).pipe(
                    catchError((error: any) => {
                        this.setState({isProcessing: false});
                        this.alertManagerService.handleApiError(error);
                        return of();
                    }),
                    tap(() => {
                        this.retrieveCalendarData();
                        this.setState({isProcessing: false})
                    }),
                )
            })
        ).subscribe());
    }

    private closeUpdateVariableModal = () => {
        this.setState({currentlyEditedVariable: null})
    }

    private archiveItem = () => {
        if (this.state.fatigueCalendarSettingsView && this.state.groupToArchiveId) {
            // TODO: when move to archive will be ready
        }
    }

    private archiveVariable = () => {
        if ((this.state.createdGroup || this.state.selectedGroup) && this.state.variableToArchiveId) {
            // TODO: when move to archive will be ready
        }
    };

    private updateGroup = (group: CalendarGroup) => {
        if (!this.state.fatigueCalendarSettingsView) {
            return null;
        }

        let updatedGroupItems: any = [];
        if (group.calendarViewGroupItems) {
            group.calendarViewGroupItems.forEach((calendarItem: any) => {
                const itemPayload = {
                    id: calendarItem.id,
                    name: calendarItem.name,
                    calendarViewGroupId: calendarItem.calendarViewGroupId,
                    visualOptions: calendarItem.visualOptions,
                    inputDefinitionId: calendarItem.inputDefinition.id,
                    valuesVisualOptions: calendarItem.valuesVisualOptions,
                    enabled: calendarItem.enabled
                }
                updatedGroupItems.push(itemPayload);
            })
        }
        const updatedCalendarGroup = {
            name: group.name,
            itemOrder: group.itemOrder,
            calendarId: this.state.fatigueCalendarSettingsView.id,
            visualOptions: group.visualOptions,
            inputGroupDefinitionId: group.inputGroupDefinitionId,
            calendarViewGroupItems: updatedGroupItems,
            enabled: group.enabled,
        }

        if (this.state.fatigueCalendarSettingsView) {
            this.setState({isProcessing: true});
            return this.subscriptions.push(updateCalendarGroupAPI(this.props.authToken, group.id, updatedCalendarGroup).pipe(
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                }),
                tap((inputDefinitionGroupResponse) => {
                    this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateGroupSuccess');
                    this.retrieveCalendarData();
                    this.setState({isProcessing: false})
                })
            ).subscribe());
        }
    }

    private closeAddInputModal = () => {
        this.setState({createdVariable: null})
    }

    private closeArchiveGroupModal = () => {
        this.setState({groupToArchiveId: null})
    }

    private closeArchiveVariableModal = () => {
        this.setState({variableToArchiveId: null})
    }

    private openArchiveGroupModal = (groupId: string) => {
        this.setState({groupToArchiveId: groupId})
    }

    private openArchiveVariableModal = (variableId: string) => {
        this.setState({variableToArchiveId: variableId})
    }

    private selectVariableToEdit = (selectedVariable: CalendarInput) => {
        if (this.state.createdVariable) {
            this.setState({createdVariable: null});
        }
        this.setState({currentlyEditedVariable: selectedVariable})
    }

    private openAddInputModal = (currentGroupState: CalendarGroup) => {
        const emptyVariable: CalendarInput = {
            id: `input-${currentGroupState.calendarViewGroupItems.length + 1}`,
            name: '',
            itemOrder: currentGroupState.calendarViewGroupItems.length,
            type: InputType.INPUT_TEXT,
            valuesVisualOptions: [],
            enabled: true,
        }

        this.setState({
            currentlyEditedVariable: null,
            createdVariable: emptyVariable,
            createdGroup: this.state.selectedGroup ? null : currentGroupState
        })
    }

    private retrieveCalendarData() {
        if (!this.props.match.params.id) return null;
        const id = this.props.match.params.id;
        this.setState({isProcessing: true});
        this.subscriptions.push(
            getBaseUserDetailsAPI(this.props.authToken, EndpointNames.CALENDARS, id).pipe(
                tap((response: any) => {
                    if (response) {
                        return this.setState({fatigueCalendarSettingsView: response, isProcessing: false})
                    }
                }),
                catchError((err: any) => {
                    this.alertManagerService.handleApiError(err);
                    this.setState({isProcessing: false});
                    return of(err);
                })
            ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        modal: modalSelector(state)
    }),
    {
        changeModal,
    }
)(withRouter((FatigueProfileSettings)));
