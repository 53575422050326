import React from 'react';
import {CustomCard, Translation} from 'judo-app-common-web';
import {InputGroupDefinition, ProfileDefinition} from "../../../../model/models";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {IFormDataGroups, splitEnabledFormGroups, switchFormGroupStatus} from "../../../../utils/fatigueProfilesUtils";
import FormGroupList from "./FormGroupList";


interface IFatigueFormProps {
    readonly  onViewChange: (id: string | null) => void;
    readonly  onFormDataChange: (groups: InputGroupDefinition[]) => void;
    readonly  formData: ProfileDefinition;
    readonly calendarId?: string;
    readonly  isProcessing: boolean;
}

interface IFatigueFormState {
    isProcessing: boolean;
    fatigueFormView?: ProfileDefinition;
    formGroups: IFormDataGroups;
}

class FatigueForm extends React.Component<IFatigueFormProps, IFatigueFormState> {
    constructor(props: IFatigueFormProps) {
        super(props);
        this.state = {
            isProcessing: false,
            fatigueFormView: undefined,
            formGroups: {enabled: [], disabled: []},
        }
    }

    componentDidMount() {
        this.splitEnabledGroupsState();
    }

    componentDidUpdate(prevProps: Readonly<IFatigueFormProps>, prevState: Readonly<IFatigueFormState>) {
        if (prevProps.formData !== this.props.formData) {
            this.splitEnabledGroupsState();
        }
    }

    render() {
        if (!this.state.fatigueFormView) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderFatigueProfileHeader()}
                {this.renderFatigueProfileBody()}
            </React.Fragment>
        )
    }

    private renderFatigueProfileHeader() {
        return <CustomCard.Header>
            <div className="user-view-header">
                <h2>
                    <Translation text={'userView.header.fatigueForm'}/>
                </h2>
                <button className="btn-close text" onClick={() => this.props.onViewChange(null)}>
                    <span>
                        <Translation text={'button.back'}/>
                    </span>
                </button>
            </div>
        </CustomCard.Header>
    }

    private renderFatigueProfileBody() {
        if (this.state.fatigueFormView) {
            const profile = this.state.fatigueFormView;
            return <React.Fragment>
                <CustomCard.Body>
                    <div className="separator"/>
                    <p className="user-view-subheader">{profile.name}</p>
                    <div className="separator"/>
                    {this.renderFatigueProfileGroups()}
                </CustomCard.Body>
            </React.Fragment>
        }
        return null
    }

    private renderFatigueProfileGroups() {
        if (this.state.formGroups && this.state.formGroups.enabled.length) {
            const formGroups = this.state.formGroups;
            return <div className='d-flex flex-column'>
                <FormGroupList enabledDataList={formGroups.enabled}
                               disabledDataList={formGroups.disabled}
                               onGroupOrderChange={this.changeEnabledGroupOrder}
                               onGroupDisabled={this.setEnabledState}
                               onGroupEnabled={this.setEnabledState}
                               onFormDataChange={this.props.onFormDataChange}/>
            </div>
        }
        return null;
    }

    private setEnabledState = (groupId: string, newEnabledState: boolean) => {
        if (!this.state.fatigueFormView || !this.state.fatigueFormView.inputGroupDefinitions.length) {
            return null;
        }
        const updatedGroups = switchFormGroupStatus(this.state.fatigueFormView.inputGroupDefinitions, groupId, newEnabledState)
        this.props.onFormDataChange(updatedGroups);
    }

    private splitEnabledGroupsState() {
        let sortedGroup: any = [];
        this.props.formData.inputGroupDefinitions.forEach((inputGroupDefinition: any, index: number) => {
            sortedGroup[index] = (inputGroupDefinition);
        })
        const formGroups = splitEnabledFormGroups(sortedGroup);
        this.setState({
            fatigueFormView: this.props.formData,
            formGroups: formGroups
        })
    }

    private changeEnabledGroupOrder = (groups: InputGroupDefinition[]) => {
        let updatedEnabledGroups = deepCloneObject(this.state.formGroups);
        updatedEnabledGroups.enabled = groups;
        const groupListArray = updatedEnabledGroups.enabled.concat(updatedEnabledGroups.disabled);
        this.setState({formGroups: updatedEnabledGroups});
        this.props.onFormDataChange(groupListArray);
    }
}

export default FatigueForm;
