import {Observable} from "redux";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {UserType} from '../model/models';

export interface IAddress {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    postcode?: string;
    city?: string;
    country?: string;
}

export interface IAccount {
    type: UserType;
    firstName: string;
    lastName: string;
    birthDate?: string;
    phone?: string;
    address?: IAddress
}

export interface IInvitationPayload {
    login: string;
    account: IAccount;
    returnUrl: string;
    specialistTypeId?: string;
    clubId?: string;
}

export function sendRegistrationInvitationAPI(
    authToken: string,
    invitationPayload: IInvitationPayload
): Observable<any> {
    return baseDataProviderAPI.post(
        "send_registration_invitation",
        invitationPayload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
