import {BasicFormControlType, IFormConfig, InputType, ValidationRules} from "judo-app-common-web";

const customPalette = ['#ecf7ed', '#fef5e8', '#fdedee', '#f1eef6', '#e6f4fc', '#f5f5f5', '#f37046', '#daefdc', '#feecd1', '#fcdddd', '#e4deed', '#cde9f9', '#ececec', '#faa62a', '#b6dfb9', '#fdd9a4', '#f9babb', '#c9bddb', '#9bd4f4', '#d9d9d9', '#fec110', '#91d095', '#fcc576', '#f5989a', '#ad9dca', '#6abeee', '#c5c5c5', '#f9ed37', '#6dc072', '#fbb249', '#f27578', '#927cb8', '#38a9e9', '#b2b2b2', '#cedd38', '#48b04f', '#fa9f1b', '#ef5356', '#775ba6', '#0693e3', '#9f9f9f', '#8ac44b'];

export const createGroupFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'create-group-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'new_group',
            controlType: 'group',
            class: 'd-flex flex-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                    ],
                    placeholder: 'profileSettingsModal.form.addGroupForm.name.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addGroupForm.name.label',
                    type: InputType.INPUT_TEXT,
                    hostClass: ''
                },
                color: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.COLORPICKER,
                    customColorArray: customPalette,
                    validationRules: [
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'profileSettingsModal.form.addGroupForm.color.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addGroupForm.color.label',
                    type: InputType.COLOR,
                    hostClass: 'w-auto align-items-center',
                    triangleVisible: 'hide',
                    colorpickerWidth: '20rem'
                },
            }
        }
    ]
};

