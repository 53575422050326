import React, {FC} from "react";
import styles from "./styles.module.scss";
import {Translation} from 'judo-app-common-web';

export enum BadgeType {
    THEME = 'theme',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
    DANGER = 'danger',
    VARIABLE = 'variable'
}

export enum BadgeSize {
    SMALL = 'small'
}

interface IBadgeProps {
    label: string;
    type?: BadgeType;
    size?: BadgeSize;
    className?: string;
}

const Badge: FC<IBadgeProps> = (props: IBadgeProps) => {
    const type: BadgeType = props.type ? props.type : BadgeType.THEME,
        size = props.size ? styles[props.size] : '',
        classes = props.className ? props.className : '';
    return (
        <span className={`${styles.badge} ${styles[type]} ${size} ${classes}`}>
            <Translation text={`${props.label}`}/>
        </span>)};

export default Badge;
