import React from 'react';
import {Translation} from "judo-app-common-web";
import {CalendarEvent} from "../../../../../../model/models";
import TooltipWrapper from "../../../../../Shared/TooltipWrapper";
import moment from "moment";

interface CalendarEventListItemProps {
    listItem: CalendarEvent;
    listItemIndex: number;
    onDeleteItem: (index: number) => void;
    onUpdateItem: (option: CalendarEvent, index: number) => void;
    onCreateItem: (option: CalendarEvent) => void;
    editMode?: boolean;
}

interface CalendarEventListItemState {
    editMode: boolean;
    createMode: boolean;
    newOption: CalendarEvent | null;
}

class CalendarEventListItem extends React.Component<CalendarEventListItemProps, CalendarEventListItemState> {
    constructor(props: CalendarEventListItemProps) {
        super(props);
        this.state = {
            editMode: false,
            createMode: false,
            newOption: null,
        }
    }

    render() {
        return <React.Fragment>
            <div className="profile-wrapper event">
                <div className="event-name">{this.props.listItem.name}</div>
                {this.renderStartEndDate(this.props.listItem.startsAt, this.props.listItem.endsAt)}
            </div>
            {this.renderActionButtons()}
        </React.Fragment>
    }


    private renderStartEndDate(startDate: string, endDate?: string) {
        const eventDuration = `${moment(startDate).format('DD-MM-yyyy')} ${endDate ? '- ' + moment(endDate).format('DD-MM-yyyy') : ''}`;
        return <div className="event-duration">
            {eventDuration}
        </div>
    }


    private renderActionButtons() {
        return <div className="button-wrapper">
            <TooltipWrapper tooltip={{text: 'profileSettingsModal.button.edit.variableOption'}}
                            key={'variable-edit-button'}>
                <button className="btn btn-list fatigue-profile mr-l"
                        onClick={() => this.props.onUpdateItem(this.props.listItem, this.props.listItemIndex)}>
                    <span className="sr-only"><Translation
                        text={'profileSettingsModal.button.edit.variableOption'}/></span>
                    <span className="feather icon-edit" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            <TooltipWrapper tooltip={{text: 'profileSettingsModal.button.delete.variableOption'}}
                            key={'fatigue-button'}>
                <button className="btn btn-list fatigue-profile delete ml-3"
                        onClick={() => this.props.onDeleteItem(this.props.listItemIndex)}>
                    <span className="sr-only"><Translation
                        text={'profileSettingsModal.button.delete.variableOption'}/></span>
                    <span className="feather icon-trash" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            <TooltipWrapper tooltip={{text: 'userList.table.button.clickToDrag'}} key={'drag-handle'}>
                <div data-movable-handle className="drag-handle ml-3">
                    <span className="sr-only"><Translation text={'userList.table.button.clickToDrag'}/></span>
                    <span className="feather icon-menu" aria-hidden="true"/>
                </div>
            </TooltipWrapper>
        </div>
    }
}

export default CalendarEventListItem;
