import React from 'react';
import {Translation} from "judo-app-common-web";

export interface IPaginationData {
    lastPage: number,
    currentPage: number
}

interface IPaginationProps {
    paginationData: IPaginationData | null;
    onSpecificPageLoad: (pageNumber: number) => void;
}

interface IPaginationState {
    paginationData: IPaginationData | null;
    isLastPage: boolean;
    isFirstPage: boolean;
}


class Pagination extends React.Component<IPaginationProps, IPaginationState> {

    constructor(props: IPaginationProps) {
        super(props);
        this.state = {
            paginationData: null,
            isLastPage: false,
            isFirstPage: true,
        }
    }

    componentDidMount() {
        if (this.props.paginationData) {
            const isLastPage = this.props.paginationData.currentPage === this.props.paginationData.lastPage,
                isFirstPage = Number(this.props.paginationData.currentPage) === 1;
            this.setState({paginationData: this.props.paginationData, isFirstPage, isLastPage});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IPaginationProps>,
        prevState: Readonly<IPaginationState>,
        snapshot?: any
    ): void {
        if (this.props.paginationData && this.props.paginationData !== prevProps.paginationData) {
                const isLastPage = this.props.paginationData.currentPage === this.props.paginationData.lastPage,
                    isFirstPage = Number(this.props.paginationData.currentPage) === 1;
                this.setState({paginationData: this.props.paginationData, isFirstPage, isLastPage});
        }
    }

    render() {
        if (!this.state.paginationData) {
            return null;

        }
        return <div className="d-flex align-items-center justify-content-center my-5">
            <button disabled={this.state.isFirstPage} className="btn btn-theme btn-small"
                    onClick={this.previousPageLoad}>
                <span className="feather icon-chevron-left" aria-hidden="true"/>
                <span>
                    <Translation text={'button.prevPage'}/>
                </span>
            </button>
            <button disabled={this.state.isLastPage} className="btn btn-theme btn-small ml-3"
                    onClick={this.nextPageLoad}>
                <span>
                    <Translation text={'button.nextPage'}/>
                </span>
                <span className="feather icon-chevron-right" aria-hidden="true"/>
            </button>
        </div>;
    }

    private previousPageLoad = () => {
        if (this.state.paginationData && this.state.paginationData.currentPage && !this.state.isFirstPage) {
            this.props.onSpecificPageLoad((Number(this.state.paginationData.currentPage) - 1));
        }
    };
    private nextPageLoad = () => {
        const paginationData = this.props.paginationData;
        if (paginationData && paginationData.currentPage && !this.state.isLastPage) {
            this.props.onSpecificPageLoad((Number(paginationData.currentPage) + 1));
        }
    };
}

export default Pagination;
