import {createAPIHandler} from "judo-app-common-web";
import {ProfileStatus, RawProfileInput} from "../../model/models";
import {deepCloneObject} from "../../utils/runtimeUtils";

export const trainingProfilesProvider = createAPIHandler({
    url: process.env.REACT_APP_API_URL as string,
    defaultHeaders: {
        "Accept": "application/ld+json",
        "Content-type": "application/json"
    },
    mapper: (data: any): any => {
        if (data && data.profiles && data.profiles.length > 0) {
            const baseTrainingProfile = deepCloneObject(data.profiles[0]);
            let profileDatesArray: string[] = [],
                trainingProfilesArray: any[] = [];
            baseTrainingProfile.inputGroups.forEach((inputGroup: any) => inputGroup.profileInputs.forEach((profileInput: any) => {
                if (profileInput.resultForDate !== null && !profileDatesArray.includes(profileInput.resultForDate)) {
                    profileDatesArray.push(profileInput.resultForDate);
                }
            }));
            profileDatesArray.forEach((profileDate: string) => {
                let updatedTrainingProfile = deepCloneObject(baseTrainingProfile);
                const filteredGroups = baseTrainingProfile.inputGroups
                    .map((inputGroup: any) => {
                        let updatedInputGroup = deepCloneObject(inputGroup);
                        updatedInputGroup.isFilled = true;
                        updatedInputGroup.profileInputs = inputGroup.profileInputs.filter((profileInput: any) => (profileInput.resultForDate === profileDate));
                        if (updatedInputGroup.profileInputs.length > 0) {
                            const trainingFreeGroupInput = updatedInputGroup.profileInputs.length && updatedInputGroup.profileInputs.some((input: RawProfileInput) => input.inputResultValue.value === 'WoT');
                            if (!trainingFreeGroupInput) {
                                updatedInputGroup.profileInputs.forEach((profileInput: any) => {
                                    if (profileInput.inputResultValue.value === null) {
                                        updatedInputGroup.isFilled = false;
                                    }
                                });
                            }
                        }
                        return updatedInputGroup;
                    })
                    .filter((inputGroup: any) => inputGroup.profileInputs.length > 0);
                updatedTrainingProfile.inputGroups = filteredGroups;
                updatedTrainingProfile.status = filteredGroups.every((inputGroup: any) => inputGroup.isFilled) ? ProfileStatus.FILLED : ProfileStatus.TO_FILL;
                updatedTrainingProfile.validFrom = profileDate;
                trainingProfilesArray.push(updatedTrainingProfile);
            });
            let updatedData = deepCloneObject(data);
            updatedData.profiles = trainingProfilesArray;
            return updatedData;
        }
        return data;
    },
    isList: false
});
