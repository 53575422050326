import React from 'react';
import {authTokenSelector, CustomCard, Translation} from 'judo-app-common-web';
import {
    CalendarEvent,
    CalendarGroup,
    CurrentFatigueCalendarView,
    ModalType,
    ProfileType
} from "../../../../model/models";
import CalendarGroupList from "./CalendarGroupList";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {
    ICalendarDataGroups,
    splitEnabledCalendarGroups,
    switchGroupStatus
} from "../../../../utils/fatigueProfilesUtils";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {changeModal} from "../../../../store/reducers/sagaSlice";
import moment from "moment";
import {Subscription} from "rxjs";
import {lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";

interface IFatigueCalendarProps {
    readonly onViewChange: (id: string | null, profileType?: ProfileType) => void;
    readonly onCalendarDataChange: (groups: CalendarGroup[]) => void;
    readonly calendarData: CurrentFatigueCalendarView;
    readonly isProcessing: boolean;
    readonly changeModal: typeof changeModal;
    readonly authToken: string;
    readonly updateCalendarEvents: (calendar: CurrentFatigueCalendarView) => void
}

interface IFatigueCalendarState {
    isProcessing: boolean;
    fatigueCalendarView?: CurrentFatigueCalendarView;
    calendarGroups: ICalendarDataGroups;
}

class FatigueCalendar extends React.Component<IFatigueCalendarProps, IFatigueCalendarState> {
    subscriptions: Subscription[] = []
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IFatigueCalendarProps) {
        super(props);
        this.state = {
            isProcessing: false,
            fatigueCalendarView: undefined,
            calendarGroups: {enabled: [], disabled: []},
        }
    }

    componentDidMount() {
        this.splitEnabledGroupsState();
    }

    componentDidUpdate(prevProps: Readonly<IFatigueCalendarProps>, prevState: Readonly<IFatigueCalendarState>) {
        if (prevProps.calendarData !== this.props.calendarData) {
            this.splitEnabledGroupsState();
        }
    }

    render() {
        if (!this.state.fatigueCalendarView) {
            return null
        }
        return (
            <React.Fragment>
                {this.renderFatigueProfileHeader()}
                {this.renderFatigueProfileBody()}
            </React.Fragment>
        )
    }

    private renderFatigueProfileHeader() {
        return <CustomCard.Header>
            <div className="user-view-header">
                <h2>
                    <Translation text={'userView.header.fatigueCalendar'}/>
                </h2>
                <button className="btn-close text" onClick={() => this.props.onViewChange(null)}>
                    <Translation text={'button.back'} />
                </button>
            </div>
        </CustomCard.Header>
    }

    private renderCalendarEvents(calendar: CurrentFatigueCalendarView) {
        if (calendar.calendarEvents) {
            let calendarEventsList: any = [];
            const calendarEvents = calendar.calendarEvents,
                renderListItems = (): any => {
                    calendarEvents?.forEach((calendarEvent: CalendarEvent, index: number) => {
                            const calendarEventStartDate = moment(calendarEvent.startsAt).format('DD-MM-YYYY');
                            calendarEventsList.push(<li key={`${calendarEvent.name}-${index}`}
                                                        className="drag-and-drop-list-item no-drag pr-3 pl-3 pt-2 pb-2">
                                <div className="ellipsis-host w-100">
                                    <div className="ellipsis-item d-inline-flex align-items-center pr-3">
                                        <span className="text-truncate">{calendarEvent.name}</span>
                                    </div>
                                    <div className="d-inline-flex align-items-center ">
                                        <span>{calendarEventStartDate}</span>
                                        <button className="btn btn-list fatigue-profile delete ml-3 bg-transparent border-0"
                                                onClick={() => this.deleteCalendarEvent((calendarEvent as any).id)}>
                                            <span className="sr-only"><Translation
                                                text={'userList.table.button.remove.variableGroup'}/></span>
                                            <span className="feather icon-trash" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </div>
                            </li>)
                        }
                    );
                    if (!calendarEventsList.length) {
                        calendarEventsList.push(<div key="no-data-info" className="no-data">
                            <Translation text={'userView.noData.calendarEvent'}/>
                        </div>)
                    }
                    return calendarEventsList;
                };
            return <section className="user-connection-list-wrapper">
                <div className="user-connection-list-header fatigue-header">
                    <h3>
                        <Translation text={'userView.header.calendarEvent'}/>
                        <span className="item-counter">
                            {` ( ${calendar.calendarEvents.length} )`}
                        </span>
                    </h3>
                    {this.renderAddCalendarEventButton()}
                </div>
                <div className="separator"/>
                <ul className="drag-and-drop-list events-list">
                    {renderListItems()}
                </ul>
            </section>
        }
    }

    private deleteCalendarEvent(id: string) {
        const calendarData = deepCloneObject(this.props.calendarData)
        calendarData.calendarEvents = calendarData.calendarEvents.filter(
            (calendarEvent: CalendarEvent) => calendarEvent.id !== id)
        this.props.updateCalendarEvents(calendarData)
    }

    private renderFatigueProfileBody() {
        if (this.state.fatigueCalendarView) {
            const profile = this.state.fatigueCalendarView;
            return <React.Fragment>
                <CustomCard.Body>
                    <div className="separator"/>
                    <p className="user-view-subheader">{profile.name}</p>
                    <div className="separator"/>
                    {this.renderCalendarEvents(profile)}

                    {this.renderFatigueProfileGroups()}
                </CustomCard.Body>
                <button className="btn btn-theme btn-medium" onClick={this.openFatigueCalendarModal}>

                    <Translation text={'userView.button.showFatigueCalendar'}/>
                </button>
            </React.Fragment>
        }
        return null;
    }

    private renderAddCalendarEventButton() {
        return <button className="btn btn-theme btn-small" onClick={() => this.openAddCalendarEventModal()}>
            <span className="feather icon-plus" aria-hidden="true"/>
            <Translation text='userView.button.addCalendarEvent'/>
        </button>
    }

    private renderFatigueProfileGroups() {
        if (this.state.calendarGroups && this.state.calendarGroups.enabled.length) {
            const profileGroups = this.state.calendarGroups;
            return <div className='d-flex flex-column'>
                {this.state.fatigueCalendarView &&
                <CalendarGroupList enabledDataList={profileGroups.enabled} disabledDataList={profileGroups.disabled}
                                   onCalendarDataChange={this.props.onCalendarDataChange}
                                   onGroupOrderChange={this.changeEnabledGroupOrder}
                                   onGroupDisabled={this.setEnabledState}
                                   onGroupEnabled={this.setEnabledState}
                                   isProcessing={this.props.isProcessing}
                />}
            </div>
        }
        return null;
    }

    private setEnabledState = (groupId: string, newEnabledState: boolean) => {
        if (!this.state.fatigueCalendarView || !this.state.fatigueCalendarView.calendarViewGroups.length) {
            return null;
        }
        const updatedGroups = switchGroupStatus(this.state.fatigueCalendarView.calendarViewGroups, groupId, newEnabledState)
        this.props.onCalendarDataChange(updatedGroups);
    }

    private splitEnabledGroupsState() {
        const calendarGroups = splitEnabledCalendarGroups(this.props.calendarData.calendarViewGroups);
        this.setState({
            fatigueCalendarView: this.props.calendarData,
            calendarGroups: calendarGroups
        })
    }

    private openAddCalendarEventModal = () => {
        if (!this.state.fatigueCalendarView) {
            return null
        }
        this.props.changeModal({
            type: ModalType.ADD_CALENDAR_EVENT,
            id: this.state.fatigueCalendarView.id,
            data: this.state.fatigueCalendarView
        })
    }
    private openFatigueCalendarModal = () => {
        if (!this.state.fatigueCalendarView) {
            return null
        }
        this.props.changeModal({type: ModalType.CALENDAR, id: this.state.fatigueCalendarView.id})
    }

    private changeEnabledGroupOrder = (groups: CalendarGroup[]) => {
        let updatedEnabledGroups = deepCloneObject(this.state.calendarGroups);
        updatedEnabledGroups.enabled = groups;
        let groupListArray = updatedEnabledGroups.enabled.concat(updatedEnabledGroups.disabled);
        groupListArray.map((groupListItem: any, index: number) => groupListItem.itemOrder = index);
        this.props.onCalendarDataChange(groupListArray);
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
    }
)(FatigueCalendar);
