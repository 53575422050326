import React, {CSSProperties} from 'react';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IDayItem} from "../index";
import FatigueProfilesCell from "./FatigueProfileCell";
import {transformObjectToCSSProperties} from "../../../../utils/fatigueTableUtils";
import {
    CalendarGroup,
    CalendarInput,
    InputType,
    RawInputGroup,
    RawProfile,
    RawProfileInput
} from "../../../../model/models";
import {Subscription} from "rxjs";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import TooltipWrapper, {TooltipPosition} from "../../../Shared/TooltipWrapper";
import {DatepickerType, Translation} from "judo-app-common-web";
import i18next from "i18next";
import moment from "moment";
import {CalendarIntoRawProfileConverterService} from "../../../../service";

interface IFatigueProfilesGroupProps {
    calendarGroup: CalendarGroup;
    profilesFromMonth: RawProfile[];
    month: IDayItem[];
    authToken: string;
    setEditedCellAndGroup: (editedCell?: RawProfileInput | CalendarInput, editedGroup?: RawInputGroup | CalendarGroup, date?: Date) => void
}

interface IFatigueProfilesGroupState {
    fatigueProfilesData: any;
    summaryCalculations: SummaryCalculations;
    isGroupCollapsed: boolean;
}

interface GroupNumberCalculations {
    min: number | null;
    max: number | null;
    avg: number | null;
    sum: number | null;
}

interface GroupDateCalculations {
    mostOftenId: string
}

interface SummaryCalculations {
    [inputDefinitionId: string]: GroupNumberCalculations | GroupDateCalculations
}

export interface InputToGenerateTable {
    input: RawProfileInput;
    inputDefinitionId: string;
    calculatedGroup: RawInputGroup | undefined;
}

class FatigueProfilesGroup extends React.Component<IFatigueProfilesGroupProps, IFatigueProfilesGroupState> {
    private subscription: Subscription | null;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('CalendarIntoRawProfileConverterService') private calendarIntoRawProfileConverterService: CalendarIntoRawProfileConverterService;


    constructor(props: IFatigueProfilesGroupProps) {
        super(props);
        this.state = {
            fatigueProfilesData: null,
            summaryCalculations: {},
            isGroupCollapsed: false,
        }
        fixInjectedProperties(this);
    }

    //todo do better! now! check all
    shouldComponentUpdate(nextProps: Readonly<IFatigueProfilesGroupProps>, nextState: Readonly<IFatigueProfilesGroupState>, nextContext: any): boolean {
        if (this.props.profilesFromMonth !== nextProps.profilesFromMonth ||
            this.props.calendarGroup !== nextProps.calendarGroup ||
            this.props.month !== nextProps.month ||
            this.state.isGroupCollapsed !== nextState.isGroupCollapsed) {
            return true
        }
        return false
    }


    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        if (!this.props.month) {
            return null;
        }
        const calendarGroup = this.props.calendarGroup;
        return <React.Fragment>
            {this.renderGroupInputs(calendarGroup.calendarViewGroupItems, this.state.isGroupCollapsed)}
        </React.Fragment>
    }

    private renderGroupInputs(inputs: CalendarInput[], isGroupCollapsed: boolean): CalendarInput[] {
        const calendarGroup = this.props.calendarGroup;
        const sortedInputs = inputs.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        let inputsTable: any = [],
            rowVisualOptions: CSSProperties | undefined = undefined;
        if (calendarGroup.visualOptions) {
            rowVisualOptions = transformObjectToCSSProperties(calendarGroup.visualOptions);
        }
        isGroupCollapsed ?
            inputsTable = [<tr style={rowVisualOptions}
                key={`row-${calendarGroup.id}`}>
                <th className="fatigue-profile-group-name" colSpan={this.props.month.length + 2}>
                    <div>
                        <button className="collapse-button horizontal" onClick={() => this.toggleGroupCollapse()}>
                            <Translation text={'button.expand'} />
                        </button>
                        <span className="group-name">
                            {calendarGroup.name}
                        </span>
                    </div>
                </th>
                <th key={'row-sum'} />
                    <th key={'row-avg'} />
                    <th key={'row-min'} />
                    <th key={'row-max'} />
            </tr>]
            :
            sortedInputs.forEach((input: any, i: number) => inputsTable.push(<tr style={rowVisualOptions}
                key={`row-${input.id}`}>
                {i === 0 && <th className="fatigue-profile-group-name" scope="rowGroup" rowSpan={inputs.length}>
                    <div className="group-name-wrapper">
                        <button className="collapse-button vertical" onClick={() => this.toggleGroupCollapse()}>
                            <Translation text={'button.collapse'} />
                        </button>
                        <span className="group-name">
                            {calendarGroup.name}
                        </span>
                    </div>
                </th>}
                {input.enabled && this.renderInputRow(input, this.props.month, i)}
            </tr>));
        return inputsTable;
    }

    private toggleGroupCollapse() {
        this.setState({isGroupCollapsed: !this.state.isGroupCollapsed});
    }

    private renderInputRow = (input: CalendarInput, month: IDayItem[], inputIndex: number) => {
        let inputRow: any[] = [<th scope="row" key={`${input.id + inputIndex}`}
            className="input-name">{input.name}</th>];

        let allRowCellValues: (number | string)[] = [];
        let singleInputData: RawProfileInput | null = null;
        month.forEach((day: IDayItem) => {
            const profileFromDay = this.getProfileFromDay(day.dateObject);
            let inputsFromDay: InputToGenerateTable[] = [];
            if (profileFromDay) {
                profileFromDay.inputGroups.forEach((inputGroup: RawInputGroup) => {
                    const calculated = !!inputGroup.profileInputs.find(groupInput => {
                        return groupInput.inputType === InputType.CALCULATED
                    })
                    inputGroup.profileInputs.forEach((inputItem: RawProfileInput) => {
                        inputsFromDay.push({
                            input: inputItem,
                            inputDefinitionId: inputItem?.inputDefinitionId,
                            calculatedGroup: calculated ? inputGroup : undefined
                        })
                    })
                })
                let inputData: InputToGenerateTable | undefined;
                inputData = inputsFromDay.find((inputFromDay: InputToGenerateTable) => inputFromDay.inputDefinitionId === input.inputDefinition?.id);
                if (inputData) {
                    if (!singleInputData) {
                        singleInputData = inputData.input
                    }
                    const result = inputData.input.inputType === InputType.INPUT_DATE ? this.formatDate(inputData?.input) : inputData?.input?.inputResultValue?.value;
                    if (result) {
                        allRowCellValues.push(result);
                    }
                    return inputRow.push(
                        <FatigueProfilesCell key={`cell-${day.dayNumber}-${day.daysName}`}
                            groupType={this.props.calendarGroup.inputGroupDefinition?.type}
                            inputData={inputData.input}
                            calculatedGroup={inputData.calculatedGroup}
                            setEditedCellAndGroup={this.props.setEditedCellAndGroup}
                            valuesVisualOptions={input.valuesVisualOptions}
                            inputName={input.name} />
                    )
                }
            }

            const calculatedCalendarGroup = !!this.props.calendarGroup.calendarViewGroupItems.find(groupInput => {
                return groupInput.inputDefinition?.type === InputType.CALCULATED
            })


            if (input.inputDefinition?.inputResults && input.inputDefinition?.inputResults?.length > 0) {
                const dayPlanResult = input.inputDefinition?.inputResults.find(
                    (result) =>
                        new Date(moment(day.dateObject).format('YYYY-MM-DD')).getTime() === new Date(result.resultForDate).getTime())
                if (dayPlanResult) {
                    //todo check this
                    const result = input.inputDefinition?.type === InputType.INPUT_DATE ? this.formatDate(this.calendarIntoRawProfileConverterService.convertCalendarInputIntoRawProfileInput(input, day.dateObject)) : dayPlanResult.value?.value;
                    allRowCellValues.push(result as string)
                }
            }

            if (!singleInputData) {
                singleInputData = this.calendarIntoRawProfileConverterService.convertCalendarInputIntoRawProfileInput(input, day.dateObject)
            }
            return inputRow.push(
                <FatigueProfilesCell key={`cell-${day.dayNumber}-${day.daysName}`}
                    day={day}
                    calendarInput={input}
                    groupType={this.props.calendarGroup.inputGroupDefinition?.type}
                    calendarGroup={calculatedCalendarGroup ? this.props.calendarGroup : undefined}
                    setEditedCellAndGroup={this.props.setEditedCellAndGroup}
                    valuesVisualOptions={input.valuesVisualOptions}
                    inputName={input.name} />
            )
        })
        inputRow.push(this.renderRowSummary(allRowCellValues, singleInputData, input.name))
        return inputRow;
    }


    private formatDate(input: RawProfileInput): string | null {
        if (!input?.inputResultValue?.value) {
            return null
        }

        if (isNaN(+input?.inputResultValue?.value)) {
            return `${input?.inputResultValue?.value}`
        }

        if (input.inputParameters.type === DatepickerType.DATE_TIME) {
            return moment(+input?.inputResultValue?.value).format('YYYY-MM-DD hh:mm')
        }

        if (input.inputParameters.type === DatepickerType.DATE) {
            return moment(+input?.inputResultValue?.value).format('YYYY-MM-DD')
        }

        return moment(+input?.inputResultValue?.value).format('hh:mm')
    }

    private getProfileFromDay(dayDate: Date): any {
        let profileFromDay = null;

        if (this.props.profilesFromMonth.length) {
            const profile = this.props.profilesFromMonth.find((profile: any) => new Date(profile.validFrom).toDateString() === dayDate.toDateString());
            if (profile) {
                profileFromDay = profile;
            }
        }

        return profileFromDay;
    }

    private renderRowSummary(rowInputsValues: (number | string)[], inputData: RawProfileInput | null, inputName: string) {

        if (!rowInputsValues || rowInputsValues.length === 0 || !inputData) {
            return (
                <React.Fragment key={`rowSummary-${inputName}`}>
                    <th key={'row-sum'} />
                    <th key={'row-avg'} />
                    <th key={'row-min'} />
                    <th key={'row-max'} />
                </React.Fragment>)
        }

        const type = inputData.inputType
        const cellInfo = `${inputName} - `;

        if (type === InputType.INPUT_DATE || type === InputType.SELECT) {
            const mostCommonNumericValue = this.calculateMostCommonValue(rowInputsValues)
            let mostCommonOptionLabel = inputData.inputParameters.selectValues?.find(op => +op.value === +mostCommonNumericValue)?.label

            if (type === InputType.SELECT && isNaN(+mostCommonNumericValue)) {
                mostCommonOptionLabel = mostCommonNumericValue
                    .split('&')
                    .map((part: string) =>
                        `${inputData.inputParameters.selectValues?.find(op => op.value === (part.trim()))?.label}`)
                    .join(' i ')
            }

            const displayedValue = (type === InputType.SELECT && mostCommonOptionLabel) ? mostCommonOptionLabel : mostCommonNumericValue
            return (
                <React.Fragment key={`rowSummary-${inputName}`}>
                    <TooltipWrapper tooltip={FatigueProfilesGroup.createTooltipConfig(`${i18next.t('profiles.modal.table.summary.mostCommonValue')} ${cellInfo} `, displayedValue)} key={'row-1'}>
                        <th colSpan={3}> <Translation text={'profiles.modal.table.summary.mostCommonValue'} /></th>
                    </TooltipWrapper>
                    <TooltipWrapper tooltip={FatigueProfilesGroup.createTooltipConfig(cellInfo, displayedValue)} key={'row-2'}>
                        <th >{displayedValue}</th>
                    </TooltipWrapper>
                </React.Fragment>)
        }

        rowInputsValues = rowInputsValues.filter(val => !isNaN(+val))

        //text fields without numbers in them
        if (Array.isArray(rowInputsValues) && rowInputsValues.length === 0) {
            return (<React.Fragment key={`rowSummary-${inputName}`}>
                <th />
                <th />
                <th />
                <th />
            </React.Fragment>)
        }


        let sum = 0;
        let min = 0;
        let max = 0;
        let avg = 0;

        if (Array.isArray(rowInputsValues) && rowInputsValues.length > 1) {
            sum = +(rowInputsValues as number[]).reduce((val, num) => +val + +num).toFixed(2);
            min = Math.min(...(rowInputsValues as number[]));
            max = Math.max(...(rowInputsValues as number[]));
            avg = +(sum / rowInputsValues.length).toFixed(2);
        }

        if (Array.isArray(rowInputsValues) && rowInputsValues.length === 1) {
            sum = +rowInputsValues[0];
            min = +rowInputsValues[0];
            max = +rowInputsValues[0];
            avg = +rowInputsValues[0];
        }

        return (
            <React.Fragment key={`rowSummary-${inputName}`}>
                <TooltipWrapper tooltip={FatigueProfilesGroup.createTooltipConfig(cellInfo, sum)} key={'row-sum'}>
                    <th>{sum}</th>
                </TooltipWrapper>
                <TooltipWrapper tooltip={FatigueProfilesGroup.createTooltipConfig(cellInfo, avg)} key={'row-avg'}>
                    <th>{avg}</th>
                </TooltipWrapper>
                <TooltipWrapper tooltip={FatigueProfilesGroup.createTooltipConfig(cellInfo, max)} key={'row-max'}>
                    <th>{max}</th>
                </TooltipWrapper>
                <TooltipWrapper tooltip={FatigueProfilesGroup.createTooltipConfig(cellInfo, min)} key={'row-min'}>
                    <th>{min}</th>
                </TooltipWrapper>
            </React.Fragment>)
    }

    private static createTooltipConfig(cellInfo: string, inputValue: number) {
        return ({text: cellInfo, value: inputValue, position: TooltipPosition.Top})
    }

    private calculateMostCommonValue(array: any[] = []) {
        if (array.length === 0) return null;

        let modeMap: any = {},
            maxEl = array[0],
            maxCount: number = 1;
        for (let i = 0; i < array.length; i++) {
            const el = array[i];

            if (modeMap[el] == null) modeMap[el] = 1;
            else modeMap[el]++;

            if (i > 0 && modeMap[el] > maxCount) {
                maxEl = el.toString();
                maxCount = modeMap[el];
            } else if (i > 0 && modeMap[el] === maxCount) {
                maxEl += " & " + el.toString();
                maxCount = modeMap[el];
            }

        }
        return maxEl;
    }
}

export default FatigueProfilesGroup;
