import React from 'react';
import {changeLoginLoading, setAuthState} from 'judo-app-common-web';
import 'react-datepicker/dist/react-datepicker.css';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {ModalConfig, ModalType} from '../../../model/models';
import {modalSelector} from '../../../store/selectors/sagaSelectors';
import {changeModal} from '../../../store/reducers/sagaSlice';
import ModalAddUser from './ModalAddUser';
import ModalUserProfile from './ModalUserProfile';
import ModalLogout from './ModalLogout';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import ModalEditPersonalProfile from './ModalEditPersonalProfile';
import ModalFatigueProfile from "../../ModalFatigueProfile";
import ModalAssignFatigueProfile from './ModalAssignFatigueProfile';
import ModalAssignContestant from "./ModalAssignContestant";
import ModalAssignCoach from "./ModalAssignCoach";
import ModalAssignSpecialist from "./ModalAssignSpecialist";
import ModalAddCalendarEvent from "./ModalAddCalendarEvent";
import ModalUnassign from "./ModalUnassign";
import ModalArchiveProfile from "./ModalArchiveProfile";
import ModalRestoreArchivedProfile from "./ModalRestoreArchivedProfile";
import {changeUser} from '../../../store/reducers/userSlice';
import ModalChangePassword from "./ModalChangePassword";
import ModalEditClub from "./ModalEditClub";
import ModalFormVariableList from './ModalFormVariableList';
import ModalRemoveSpecialistType from "./ModalRemoveSpecialistType";
import ModalCalendarVariableList from "./ModalCalendarVariableList";
import ModalTrainingPlan from '../../ModalTrainingPlan';

interface IModalsProps {
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeLoginLoading: typeof changeLoginLoading;
    readonly changeUser: typeof changeUser;
    readonly setAuthState: typeof setAuthState;
}

class Modals extends React.Component<IModalsProps> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IModalsProps) {
        super(props);
        fixInjectedProperties(this);
    }

    render() {
        if (!this.props.modal) {
            return null;
        }

        switch (this.props.modal.type) {
            case ModalType.CREATE:
                return <ModalAddUser onClose={this.closeModal}/>;
            case ModalType.CHANGE_PASSWORD:
                return <ModalChangePassword onClose={this.closeModal}/>;
            case ModalType.ARCHIVE:
                return <ModalArchiveProfile onClose={this.closeModal}/>;
            case ModalType.RESTORE:
                return <ModalRestoreArchivedProfile onClose={this.closeModal}/>;
            case ModalType.PROFILE:
                return <ModalUserProfile onClose={this.closeModal}/>;
            case ModalType.EDIT_PROFILE:
                return <ModalEditPersonalProfile onClose={this.closeModal}/>;
            case ModalType.EDIT_CLUB:
                return <ModalEditClub onClose={this.closeModal}/>;
            case ModalType.FATIGUE_PROFILE:
                return <ModalUserProfile onClose={this.closeModal}/>;
            case ModalType.ASSIGN_FATIGUE_PROFILE:
                return <ModalAssignFatigueProfile onClose={this.closeModal}/>;
            case ModalType.ASSIGN_CONTESTANT:
                return <ModalAssignContestant onClose={this.closeModal}/>;
            case ModalType.ASSIGN_COACH:
                return <ModalAssignCoach onClose={this.closeModal}/>;
            case ModalType.ASSIGN_SPECIALIST:
                return <ModalAssignSpecialist onClose={this.closeModal}/>;
            case ModalType.UNASSIGN:
                return <ModalUnassign onClose={this.closeModal}/>;
            case ModalType.ADD_CALENDAR_EVENT:
                return <ModalAddCalendarEvent onClose={this.closeModal}/>;
            case ModalType.TRAINING_PLAN:
                return <ModalTrainingPlan onClose={this.closeModal}/>;
            case ModalType.LOGOUT:
                return <ModalLogout onClose={this.closeModal} onLogout={this.logout}/>;
            case ModalType.CALENDAR:
                return <ModalFatigueProfile onClose={this.closeModal}/>
            case ModalType.FORM_GROUP_VARIABLES:
                return <ModalFormVariableList onClose={this.closeModal}/>
            case ModalType.CALENDAR_GROUP_VARIABLES:
                return <ModalCalendarVariableList onClose={this.closeModal}/>
            case ModalType.REMOVE:
                return <ModalRemoveSpecialistType onClose={this.closeModal}/>
        }
    }

    private closeModal = () => {
        this.props.changeModal(null)
    };

    private logout = () => {
        this.props.setAuthState(null, null, null, false);
        this.props.changeLoginLoading(false);
        this.props.changeUser(null);
        this.closeModal();
        this.alertManagerService.addAlert('alert.logout.success')
    };
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state)
    }),
    {
        changeModal,
        changeUser,
        setAuthState,
        changeLoginLoading
    }
)(Modals);
