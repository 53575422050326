import React from 'react';
import styles from './styles.module.scss'
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {fontSizeSelector} from '../../../store/selectors/sagaSelectors';
import {changeFontSize} from '../../../store/reducers/sagaSlice';

interface IFontSizerProps {
    fontSize: number;
    changeFontSize: typeof changeFontSize;
}

export enum FontSizerParams {
    Max = 2.2,
    Min = 1.4,
    Eps = 0.2,
}

class FontSizer extends React.Component<IFontSizerProps> {

    render() {
        return (
            <div className={styles.fontSizerContainer}>
                <button className={this.props.fontSize <= FontSizerParams.Min ? styles.disabled : ''}
                        onClick={() => this.changeFontSize(-1)}>A</button>
                <button className={this.props.fontSize >= FontSizerParams.Max ? styles.disabled : ''}
                        onClick={() => this.changeFontSize(1)}>A</button>
            </div>
        )
    }

    changeFontSize(sign: number): void {
        if (this.props.fontSize <= FontSizerParams.Min && sign === -1) return;
        if (this.props.fontSize >= FontSizerParams.Max && sign === 1) return;

        this.props.changeFontSize(this.props.fontSize + FontSizerParams.Eps * sign);
    }
}

export default connect(
    (state: RootState) => ({
        fontSize: fontSizeSelector(state),
    }),
    {
        changeFontSize
    }
)(FontSizer);