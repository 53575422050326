import React from 'react';
import {Form, IFormConfig} from 'judo-app-common-web';
import {passwordChangeFormConfig} from "./passwordChangeFormConfig";
import {connect} from "react-redux";
import {changePasswordStart} from '../../../../../store/reducers/changePasswordSlice';

interface IConnectedChangePasswordFormProps {
    readonly changePasswordStart: typeof changePasswordStart;
}

interface IChangePasswordFormState {
    formConfig: typeof IFormConfig,
}

class ChangePasswordForm extends React.Component<IConnectedChangePasswordFormProps, IChangePasswordFormState> {
    constructor(props: IConnectedChangePasswordFormProps) {
        super(props);
        this.state = {
            formConfig: passwordChangeFormConfig
        };
    }

    render() {
        return (
            <Form
                config={this.state.formConfig}
                submitForm={this.changePassword}
                controlName={"changePasswordForm"}
                value={{}}
            />
        )
    }

    private changePassword = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        if (!value.oldPassword || !value.newPassword) {
            return null;
        }
        return this.props.changePasswordStart(value.oldPassword, value.newPassword);
    }
}

export default connect(
    () => ({}),
    {
        changePasswordStart
    }
)(ChangePasswordForm);
