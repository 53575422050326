import React from "react";
import styles from '../styles.module.scss';
import 'moment/locale/pl';
import moment from 'moment';
moment.locale('pl')

interface Props {
    numberOfDaysInSelectedMonth: number | null;
    selectedDay: number;
    selectDay: (day: number) => void;
    selectedMonth: number | null,
    selectedYear: number | null;
}

class DaySelector extends React.Component<Props> {

    render() {
        return (
            <div>
                <h1 className={styles.listTitle}>{this.getFormattedDate(this.props.selectedDay)}</h1>
                <input className={`w-100 ${styles.slider} mt-3`}
                       type={"range"}
                       min={1}
                       max={this.renderDays?.length}
                       value={this.props.selectedDay}
                       onChange={(e) => this.props.selectDay(+e.target.value)}/>
                <div className="w-100 d-flex justify-content-between">
                    <span>{this.getFormattedDate(1)}</span>
                    <span>{this.getFormattedDate(this.renderDays?.length)}</span>
                </div>
            </div>);
    }

    private  getFormattedDate(dayNumber: number) {
        if(this.props.selectedMonth === null || !this.props.selectedYear === null || isNaN(dayNumber)) {
            return '';
        }
        const month = this.props.selectedMonth.toString().length < 2 ?
            `0${this.props.selectedMonth +1}` :
            `${this.props.selectedMonth +1}`;
        const day = dayNumber.toString().length < 2 ?
            `0${dayNumber}` :
            `${dayNumber}`;

        return moment(`${this.props.selectedYear}-${month}-${day}`)
            .format("dddd DD MMMM YYYY");
    }

    private get renderDays(): number[] {
        if(!this.props.numberOfDaysInSelectedMonth) {
            return [];
        }

        let daysArray: number[] = [];

        for(let i=0; i < this.props.numberOfDaysInSelectedMonth; i++) {
            daysArray.push(i)
        }

        return daysArray;
    }
}

export default DaySelector
