import React from 'react';
import {authTokenSelector, Form, IFormConfig} from 'judo-app-common-web';
import {withTranslation, WithTranslation} from "react-i18next";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {
    addClubInput,
    addSpecialistTypeFormConfig,
    addSpecialistTypeInput,
    addUserFormConfig,
    buttonGroup,
    clubNameInput,
    firstNameInput,
    lastNameInput,
} from "./addUserFormConfig";
import {deepCloneObject} from "../../../../../utils/runtimeUtils";
import {ModalConfig, UserType} from '../../../../../model/models';
import {IInvitationPayload, sendRegistrationInvitationAPI} from "../../../../../api/sendRegistrationInvitation";
import {catchError, map, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {getSpecialistTypesAPI} from "../../../../../api/getSpecialistTypes";
import {getBaseListAPI} from "../../../../../api/getBaseList";
import {EndpointNames} from "../../../../Shared/AnimatedLayoutHost";
import {addSpecialistTypeAPI} from "../../../../../api/addSpecialistType";
import {modalSelector} from "../../../../../store/selectors/sagaSelectors";
import {changeModal, changeShouldListUpdate} from '../../../../../store/reducers/sagaSlice';
import {createClubAPI} from "../../../../../api/createClub";

interface IConnectedAddUserFormProps {
    readonly authToken: string;
    readonly modal: ModalConfig | null;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
    readonly changeModal: typeof changeModal;
}

interface IAddUserFormProps extends IConnectedAddUserFormProps,
    WithTranslation {
    type: UserType;
    onClose: () => void;
    onProcessingChange: (processingState: boolean) => void;
}

interface IAddUserFormState {
    formConfig: typeof IFormConfig;
    value: any;
    specialistTypes: { [key: string]: any }[];
    clubListOptions: { [key: string]: any }[];
    isProcessing: boolean;
}

class AddUserForm extends React.Component<IAddUserFormProps, IAddUserFormState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IAddUserFormProps) {
        super(props);
        this.state = {
            formConfig: addUserFormConfig,
            value: {},
            specialistTypes: [],
            clubListOptions: [],
            isProcessing: false,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.type === UserType.SPECIALIST) {
            this.getSpecialistTypes();
        }

        if (this.props.type === UserType.COACH || this.props.type === UserType.CONTESTANT) {
            this.getClubList();
        }

        this.updateFormConfig();
    }

    componentDidUpdate(
        prevProps: Readonly<IAddUserFormProps>,
        prevState: Readonly<IAddUserFormState>,
        snapshot?: any
    ): void {
        if (this.state.specialistTypes !== prevState.specialistTypes ||
            this.state.clubListOptions !== prevState.clubListOptions) {
            this.updateFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <Form config={this.state.formConfig}
                  submitForm={this.addUser}
                  controlName={'addUser'}
                  value={{}}/>
        )
    }

    private addUser = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || this.state.isProcessing || !this.props.authToken) {
            return;
        }

        this.setState({isProcessing: true})
        this.props.onProcessingChange(true);
        if (this.props.type === UserType.SPECIALIST_TYPE) {
            let typeName = value.add_specialist_type.name;
            return this.subscriptions.push(
                (addSpecialistTypeAPI(this.props.authToken, typeName) as any).pipe(
                    tap(() => {
                        this.props.changeShouldListUpdate(true);
                        const {t} = this.props;
                        const alertMessage = t(`alert.specialistType.success`);
                        this.props.onClose();
                        this.alertManagerService.addAlert(alertMessage);
                    }),
                    catchError((error: any) => {
                        this.alertManagerService.handleApiError(error);
                        return of();
                    })
                ).subscribe()
            )
        }

        let formValues = value.add_user_base;

        const payload: IInvitationPayload = {
            login: formValues.email,
            account: {
                type: this.props.type,
                firstName: formValues.firstName,
                lastName: formValues.lastName
            },
            returnUrl: `${process.env.REACT_APP_CLIENT_URL}/auth/register`,
        };

        if (formValues.specialistType) {
            payload['specialistTypeId'] = formValues.specialistType;
        }

        if (formValues.club) {
            payload['clubId'] = formValues.club;
        }


        if (this.props.modal && this.props.modal.role &&
            this.props.modal.role === UserType.CLUB) {
            return this.subscriptions.push(
                createClubAPI(this.props.authToken, value.add_user_base.clubName).pipe(
                    tap((resp: any) => {
                        const {t} = this.props;
                        const alertMessage = t(`invitation.${this.props.type}`);
                        this.setState({isProcessing: false})
                        this.props.onProcessingChange(false);
                        this.props.changeModal(null);
                        this.props.changeShouldListUpdate(true);
                        this.alertManagerService.addAlert(alertMessage);
                    }),
                    catchError((error: any) => {
                        this.setState({isProcessing: false})
                        this.props.onProcessingChange(false);
                        this.alertManagerService.handleApiError(error);
                        return of();
                    })
                ).subscribe()
            );
        }

        return this.subscriptions.push(
            (sendRegistrationInvitationAPI(this.props.authToken, payload) as any).pipe(
                tap(() => {
                    const {t} = this.props;
                    const alertMessage = t(`invitation.${this.props.type}`)
                    this.setState({isProcessing: false});
                    this.props.onProcessingChange(false);
                    this.props.changeModal(null);
                    this.props.changeShouldListUpdate(true);
                    this.alertManagerService.addAlert(alertMessage);
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false})
                    this.props.onProcessingChange(false);
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe());
    };

    private updateFormConfig() {
        const type = this.props.type.split('-')
            .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));
        let updatedFormConfig = deepCloneObject(addUserFormConfig);
        if (this.props.type === UserType.SPECIALIST_TYPE) {
            let specialistTypeFormConfig = deepCloneObject(addSpecialistTypeFormConfig);
            specialistTypeFormConfig.controls.push(buttonGroup(type));
            return this.setState({formConfig: specialistTypeFormConfig});
        }

        if (this.props.type === UserType.COACH || this.props.type === UserType.CONTESTANT) {
            let updatedClubInput = addClubInput;
            updatedClubInput.club.multiselectOptions = this.state.clubListOptions;
            updatedFormConfig.controls[0].controls = Object.assign(
                updatedFormConfig.controls[0].controls,
                firstNameInput(type),
                lastNameInput(type),
                updatedClubInput
            );
            ;
            updatedFormConfig.controls.push(buttonGroup(type));
            return this.setState({formConfig: updatedFormConfig});
        }
        if (this.props.type === UserType.SPECIALIST) {
            let updatedSpecialistTypeInput = addSpecialistTypeInput;
            updatedSpecialistTypeInput.specialistType.multiselectOptions = this.state.specialistTypes;
            updatedFormConfig.controls[0].controls = Object.assign(
                updatedFormConfig.controls[0].controls,
                firstNameInput(type), lastNameInput(type),
                updatedSpecialistTypeInput
            );
            updatedFormConfig.controls.push(buttonGroup(type));
            return this.setState({formConfig: updatedFormConfig});
        }
        if (this.props.type === UserType.CLUB) {
            updatedFormConfig.controls[0].controls = Object.assign({}, clubNameInput);
            updatedFormConfig.controls.push(buttonGroup(type));
            return this.setState({formConfig: updatedFormConfig});
        }
        return null;
    }

    private getSpecialistTypes = () => {
        return this.subscriptions.push(
            getSpecialistTypesAPI(this.props.authToken).pipe(
                map((response: any) => {
                    let types = response['hydra:member'];
                    let multiselectTypeOptions: { [key: string]: any }[] = [];
                    if (types) {
                        types.forEach((type: { [key: string]: any }) => {
                            multiselectTypeOptions.push({
                                value: type.id,
                                label: type.name
                            })
                        })
                    }

                    this.setState({specialistTypes: multiselectTypeOptions})
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe())
    };

    private getClubList = () => {
        return this.subscriptions.push(
            getBaseListAPI(this.props.authToken, EndpointNames.CLUBS).pipe(
                map((response: any) => {
                    const clubs = response['hydra:member'];
                    let multiselectTypeOptions: { [key: string]: any }[] = [];
                    if (clubs) {
                        clubs.forEach((type: { [key: string]: any }) => {
                            multiselectTypeOptions.push({
                                value: type.id,
                                label: type.name
                            })
                        })
                    }

                    this.setState({clubListOptions: multiselectTypeOptions});
                }),
                catchError((err: any) => {
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
            ).subscribe()
        )
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        modal: modalSelector(state),
    }),
    {
        changeShouldListUpdate,
        changeModal
    }
)(AddUserForm));
