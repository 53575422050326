import React from 'react';
import {
    authTokenSelector,
    CustomModal,
    Loader,
    LoaderType,
    Translation
} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {CalendarEvent, ModalConfig} from '../../../../model/models';
import {modalSelector} from '../../../../store/selectors/sagaSelectors';
import {changeModal, changeShouldViewUpdate} from '../../../../store/reducers/sagaSlice';
import AddCalendarEventForm from "./AddCalendarEventForm";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {of, Subscription} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {updateFatigueCalendarAPI} from "../../../../api/updateFatigueCalendar";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {passwordLoadingSelector} from "../../../../store/selectors/changePasswordSelectors";
import moment from "moment";

interface IModalAddCalendarEventProps {
    readonly onClose: () => void;
    readonly isLoading: boolean;
    readonly authToken: string;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldViewUpdate: typeof changeShouldViewUpdate;
}

interface IModalAddCalendarEventState {
    calendar: any;  // toDo interface
    isProcessing: boolean;
    calendarEvents: CalendarEvent[];
}

class ModalAddCalendarEvent extends React.Component<IModalAddCalendarEventProps, IModalAddCalendarEventState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalAddCalendarEventProps) {
        super(props);
        this.state = {
            calendar: null,
            isProcessing: true,
            calendarEvents: []
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal) this.retrieveCalendar();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.props.modal) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class={"add-calendar-event-modal"}>
                <Loader show={this.props.isLoading} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={`profileSettingsModal.form.addCalendarEventForm.header`}/>
                </CustomModal.Header>
                <AddCalendarEventForm onCalendarEventCreation={this.addCalendarEvent}/>
            </CustomModal>
        );
    }

    private retrieveCalendar() {
        if (!this.props.modal || !this.props.modal.data) {
            return null;
        }
        const calendar = this.props.modal.data
        this.setState({calendar})
    }

    private addCalendarEvent = (calendarEvent: CalendarEvent) => {
        if (!this.props.authToken || !this.state.calendar) {
            return null;
        }
        const calendarId = this.state.calendar.id;
        let updatedCalendar = deepCloneObject(this.state.calendar);


        let updatedGroupsPayload: any = [];
        this.state.calendar.calendarViewGroups.forEach((calendarGroup: any) => {
            let updatedGroupItems: any = [];
            if (calendarGroup.calendarViewGroupItems) {
                calendarGroup.calendarViewGroupItems.forEach((calendarItem: any) => {
                    const itemPayload = {
                        id: calendarItem.id,
                        name: calendarItem.name,
                        calendarViewGroupId: calendarItem.calendarViewGroupId,
                        visualOptions: calendarItem.visualOptions,
                        inputDefinitionId: calendarItem.inputDefinition.id,
                        valuesVisualOptions: calendarItem.valuesVisualOptions,
                        enabled: calendarItem.enabled
                    }
                    updatedGroupItems.push(itemPayload);
                })
            }
            const calendarGroupPayload = {
                id: calendarGroup.id,
                name: calendarGroup.name,
                itemOrder: calendarGroup.itemOrder,
                calendarId: calendarGroup.calendarId,
                visualOptions: calendarGroup.visualOptions,
                inputGroupDefinitionId: calendarGroup.inputGroupDefinition.id,
                calendarViewGroupItems: updatedGroupItems,
                enabled: calendarGroup.enabled,
            }
            updatedGroupsPayload.push(calendarGroupPayload);
        })

        let updatedCalendarEvents = deepCloneObject(updatedCalendar.calendarEvents);
        updatedCalendarEvents.push(calendarEvent);
        let updatedCalendarEventsPayload: any = [];

        updatedCalendarEventsPayload = updatedCalendarEvents.map((calendarEv: any) => {

            if(!calendarEv.id) {
                return ({
                    name: calendarEv.name,
                    startsAt: moment(calendarEv.startsAt, 'DD-MM-YYYY').toISOString(true),
                    endsAt: moment(calendarEv.endsAt, 'DD-MM-YYYY').toISOString(true)
                })
            }

            return ({
                name: calendarEv.name,
                startsAt:calendarEv.startsAt,
                endsAt: calendarEv.endsAt
            })
        })

        this.setState({isProcessing: true});
        updatedCalendar.calendarEvents = updatedCalendarEventsPayload;

        const updatedCalendarPayload = {
            name: updatedCalendar.name,
            calendarViewGroups: updatedGroupsPayload,
            calendarEvents: updatedCalendarEventsPayload,
            profileDefinitionId: updatedCalendar.profileDefinition.id,
        }

        return this.subscriptions.push(updateFatigueCalendarAPI(this.props.authToken, calendarId, updatedCalendarPayload).pipe(
            catchError((error: any) => {
                this.setState({isProcessing: false});
                this.alertManagerService.handleApiError(error);
                return of();
            }),
            tap((response) => {
                this.setState({isProcessing: false});
                this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateCalendarSuccess');
                this.props.changeShouldViewUpdate(true);
                this.props.changeModal(null);
            })
        ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: passwordLoadingSelector(state),
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldViewUpdate
    }
)(ModalAddCalendarEvent);
