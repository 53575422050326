import {
    FormButtonType,
    InputType, ValidationRules,
    IFormConfig, BasicFormControlType
} from "judo-app-common-web";

export const passwordChangeFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "auth-form",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: "change_password_form",
            controlType: "group",
            class: "",
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.newPassword, (value: any) => value.repeatPassword]}
                },
            ],
            controls: {
                oldPassword: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}},
                    ],
                    placeholder: 'account.changePassword.form.oldPassword.placeholder',
                    label: 'account.changePassword.form.oldPassword.label',
                    isLabelHidden: false,
                    type: InputType.PASSWORD,
                    hostClass: ""
                },
                newPassword: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}},
                    ],
                    isLabelHidden: false,
                    placeholder: 'account.changePassword.form.newPassword.placeholder',
                    label: 'account.changePassword.form.newPassword.label',
                    type: InputType.PASSWORD,
                    hostClass: ""
                },
                repeatPassword: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}},
                    ],
                    isLabelHidden: false,
                    placeholder: 'account.changePassword.form.repeatPassword.placeholder',
                    label: 'account.changePassword.form.repeatPassword.label',
                    type: InputType.PASSWORD,
                    hostClass: ""
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'button-container',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'account.changePassword.button',
                    isLabelHidden: true,
                }
            }
        }
    ]
};
