import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function updateFatigueCalendarAPI(
    authToken: string,
    calendarId: string,
    payload: any,
): Observable<any> {
    return baseDataProviderAPI.put(
        `calendars/${calendarId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
