import {ExpressionParser,IntoServerLogicParser, createTranslationPayload} from 'judo-app-common-web';

const addCalculatedFieldFormConfig = (locale: string, extra?: any) => {
    const formConfig: any = {
        layouts: {},
        controls: []
    };

    formConfig.layouts = {
        'lg': [
            {'w': 12, 'h': 7, 'x': 0, 'y': 0, 'i': 'expression', 'moved': false, 'static': false}
        ],
        'md': [
            {'w': 12, 'h': 7, 'x': 0, 'y': 0, 'i': 'expression', 'moved': false, 'static': false}
        ],
        'sm': [
            {'w': 12, 'h': 7, 'x': 0, 'y': 0, 'i': 'expression', 'moved': false, 'static': false}
        ],
    };

    formConfig.controls.unshift(
        {
            inputType: 'text',
            formControlType: 'expression_editor',
            additionalClasses: ['formVertical'],
            outputDataMapper: (data: any) => {
                if (data) {
                    return new IntoServerLogicParser().parse(data, extra?.variables);
                }
            },
            inputDataMapper: (data: any) => data ? new ExpressionParser().parse(data).toJsonLogic() : [],
            name: createTranslationPayload('Kalkulowane', 'Calculated'),
            controlOptions: {
                label: createTranslationPayload('Generator Formuły', 'Formula generator'),
                showLabel: true,
                required: false,
                defaultValue: null,
                variables: extra?.variables || [],
            },
            validationRules: [
                {
                    name: "isJSONLogic",
                    params: []
                }
            ],
            controlName: 'expression'
        });

    return formConfig;
};

export default addCalculatedFieldFormConfig;
