import { Observable } from "rxjs";
import {RestQueryParams} from "judo-app-common-web";
import {userAccountProviderAPI} from './provider/userAccountProviderAPI';

export function getUserMeAPI(
    authToken: string
): Observable<any> {
    return userAccountProviderAPI.get(
        `me`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
