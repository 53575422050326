import { Observable } from "rxjs";
import {RestQueryParams} from "judo-app-common-web";
import {baseDataProviderAPI} from './provider/baseDataProviderAPI';

export function archiveProfileAPI(
    authToken: string,
    endpoint: string,
    id: string,
): Observable<any> {
    return baseDataProviderAPI.delete(
        `${endpoint}/${id}/archive`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
