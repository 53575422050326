import React from 'react';
import {CustomModal, Translation} from 'judo-app-common-web';
import Button, {BtnType} from "../../../Shared/Button";
import "react-datepicker/dist/react-datepicker.css";

interface IModalLogoutProps {
    onClose: () => void;
    onLogout: () => void;
}

class ModalLogout extends React.Component<IModalLogoutProps> {

    render() {
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class={"add-user-modal"}>
                <CustomModal.Header>
                    <Translation text={'modal.logout.title'}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <p>
                        <Translation text={'modal.logout.question'}/>
                    </p>
                </CustomModal.Body>
                <CustomModal.Footer>
                    <Button type={BtnType.ThemeOutline}
                            label={'button.cancel'}
                            onClick={this.props.onClose}/>
                    <Button type={BtnType.Theme}
                            label={'button.confirm'}
                            onClick={this.props.onLogout}/>
                </CustomModal.Footer>
            </CustomModal>
        );
    }

}

export default ModalLogout;
