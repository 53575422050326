import {ValidationRules, IFormConfig, BasicFormControlType} from "judo-app-common-web";

const initialDate = () => {
  const date = new Date()
  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}


export const calendarFormConfig: typeof IFormConfig = {
  controlType: 'form',
  class: '',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'date',
      class: 'form-row',
      controls: {
        date: {
          hostClass: 'col-100 month-calendar',
          controlType: 'control',
          defaultValue: null,
          placeholder: 'Choose date',
          selectedDate: initialDate(),
          formControlType: BasicFormControlType.CALENDAR,
          // label: "Choose date",
          checkboxSlots: true,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
        }
      }
    },
  ]
};
