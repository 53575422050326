import React from 'react';
import {
    authTokenSelector,
    CustomModal,
    Form,
    IFormConfig,
    Loader,
    LoaderType,
    setAuthState,
    Translation
} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {ContestantView, ModalConfig} from "../../../../model/models";
import {changeModal, changeShouldListUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {catchError, map, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {assignContestantFormConfig, buttonGroup} from "./assignContestantFormConfig";
import {getBaseListAPI} from "../../../../api/getBaseList";
import {assignAccountToAccountAPI} from "../../../../api/assignAccountToAccount";

interface IModalAssignContestantProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalAssignContestantState {
    accountId: string;
    isProcessing: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    contestants: ContestantView[];
}

class ModalAssignContestant extends React.Component<IModalAssignContestantProps, IModalAssignContestantState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalAssignContestantProps) {
        super(props);
        this.state = {
            accountId: '',
            isProcessing: true,
            formConfig: assignContestantFormConfig,
            value: {},
            contestants: [],
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.id) {
            this.setState({accountId: this.props.modal.id})
            this.retrieveContestants()
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IModalAssignContestantProps>,
        prevState: Readonly<IModalAssignContestantState>,
        snapshot?: any
    ): void {
        if (this.state.contestants !== prevState.contestants) {
            let updatedFormConfig = deepCloneObject(assignContestantFormConfig);
            let multiselectProfiles: any[] = [];
            this.state.contestants.forEach((contestant: any) => multiselectProfiles.push({
                value: contestant.account.id,
                label: `${contestant.account.firstName} ${contestant.account.lastName}`
            }));
            updatedFormConfig.controls[0].controls.contestants.multiselectOptions = multiselectProfiles;
            updatedFormConfig.controls.push(buttonGroup);
            this.setState({formConfig: updatedFormConfig});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.accountId) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class='assign-profile-modal'>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={'modal.assignContestant.title'}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <Form config={this.state.formConfig}
                          submitForm={this.assignContestant}
                          controlName={'assignContestant'}
                          value={{}}/>
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private retrieveContestants = () => {
        if (!this.props.authToken) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(getBaseListAPI(this.props.authToken, 'contestants', undefined, 1000).pipe(
            map((response: any) => {
                if (response && response['hydra:member']) {
                    let contestantsList = response['hydra:member'];

                    if (this.props.modal?.data) {
                        const assignedProfilesId = this.props.modal.data;
                        contestantsList = contestantsList.filter((listItem: any) => !assignedProfilesId.includes(listItem.account.id));
                    }
                    return this.setState({contestants: contestantsList, isProcessing: false})
                }
            }),
            catchError((err: any) => {
                this.setState({isProcessing: false});
                this.alertManagerService.handleApiError(err);
                return of();
            })
        ).subscribe());
    };


    private assignContestant = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken) {
            return;
        }
        const chosenProfileId = value.contestantGroup.contestants.value;
        const assignAccountPayload = {
            accountId: chosenProfileId,
        }

        this.setState({isProcessing: true});
        return this.subscriptions.push(assignAccountToAccountAPI(this.props.authToken, this.state.accountId, assignAccountPayload).pipe(
            tap((response: any) => {
                this.alertManagerService.addAlert('modal.assignContestant.assignSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldListUpdate(true);
                this.props.changeModal(null);
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldListUpdate,
        setAuthState,
    }
)(ModalAssignContestant);
