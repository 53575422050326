import React from 'react';
import {CustomCard, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import UserInfoBox, {UserInfoType} from '../../Shared/UserInfoBox';
import {ModalType, Role, UserType} from '../../../model/models';
import {connect} from 'react-redux';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {RootState} from '../../../store/reducers';
import {userRoleSelector} from '../../../store/selectors/userSelector';
import Pagination, {IPaginationData} from "../../Shared/Pagination";

interface IContestantListProps {
    onViewChange: (id: string | null, profileType?: UserType) => void;
    list: any[] | null;
    onSpecificPageLoad: (pageNumber: number) => void;
    paginationData: IPaginationData | null;
    userRole: Role;
    isLoading: boolean;
    isArchiveMode?: boolean;
    changeModal: typeof changeModal;
}

interface IContestantListState {
    contestantList: any[] | null; // toDo interface
}

class ContestantList extends React.Component<IContestantListProps, IContestantListState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IContestantListProps) {
        super(props);
        this.state = {
            contestantList: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.list) this.setState({contestantList: this.props.list});
    }

    componentDidUpdate(
        prevProps: Readonly<IContestantListProps>,
        prevState: Readonly<IContestantListState>,
        snapshot?: any
    ): void {
        if (this.props.list && this.props.list !== prevProps.list) this.setState({contestantList: this.props.list})
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Body>
                    {this.renderButton()}
                    {this.renderTable()}
                </CustomCard.Body>
                <CustomCard.Footer>
                    <Pagination paginationData={this.props.paginationData}
                                onSpecificPageLoad={this.props.onSpecificPageLoad}/>
                </CustomCard.Footer>
            </CustomCard>
        )
    }

    private renderButton() {
        if ((this.props.userRole !== Role.Admin && this.props.userRole !== Role.HeadCoach) || this.props.isArchiveMode) return;

        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-theme btn-small"
                        onClick={this.openCreateContestantModal}>
                    <span aria-hidden="true" className="feather icon-plus"/>
                    <Translation text={'userList.table.button.add.contestant'}/>
                </button>
            </div>
        )
    }

    private renderTable() {
        if (!this.state.contestantList) {
            return;
        }
        const noContestantsMessage = this.props.isArchiveMode ?
            <Translation text={'userList.table.noData.noArchivedContestants'}/> :
            <Translation text={'userList.table.noData.noContestants'}/>;

        if (!this.state.contestantList.length) {
            return (
                <div className="d-flex justify-content-center display-4 my-5">
                    <span>
                        {noContestantsMessage}
                    </span>
                </div>
            );
        }

        const rows: any = [];

        this.state.contestantList.forEach((contestant: any) => {
            rows.push(
                <tr key={contestant.id}>
                    <td><UserInfoBox user={contestant} infoCellType={UserInfoType.Table}/></td>
                    <td> {contestant.countCoaches}</td>
                    <td> {contestant.countSpecialists}</td>
                    <td className="text-right">
                        <button className="btn btn-theme btn-small"
                                onClick={() => this.props.onViewChange(contestant.id, this.props.isArchiveMode ? UserType.CONTESTANT : undefined)}>
                            <span className="feather icon-edit" aria-hidden="true"/>
                            <Translation text={'userList.table.button.details'}/>
                        </button>
                    </td>
                </tr>
            )
        });

        return <div className="mt-20">
            <table className="data-table mt-20">
                <thead>
                <tr>
                    <th><Translation text={'userList.table.header.contestants'}/></th>
                    <th><Translation text={'userList.table.header.coaches'}/></th>
                    <th><Translation text={'userList.table.header.specialists'}/></th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    }

    private openCreateContestantModal = () => {
        this.props.changeModal({
            type: ModalType.CREATE,
            role: UserType.CONTESTANT,
        })
    }
}

export default connect(
    (state: RootState) => ({
        userRole: userRoleSelector(state)
    }),
    {
        changeModal
    }
)(ContestantList);
