import React from 'react';
import TooltipWrapper, {ITooltip, TooltipPosition} from "../../../../Shared/TooltipWrapper";
import {
    CalendarGroup,
    CalendarInput,
    CalendarInputOption,
    InputGroupDefinitionType,
    InputType,
    RawInputGroup,
    RawProfileInput,
    Role,
} from "../../../../../model/models";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IFormConfigConverterService} from "../../../../../service/formConfigConverter";
import {connect} from "react-redux";
import {userRoleSelector} from "../../../../../store/selectors/userSelector";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {DatepickerType} from "judo-app-common-web";
import moment from "moment";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {IDayItem} from "../../index";
import {CalendarIntoRawProfileConverterService} from "../../../../../service";

type MatchParamsTypes = {
    host: string | undefined
    id: string | undefined
    profileId: string | undefined
    type: string | undefined
}

interface IFatigueProfilesCellProps extends RouteComponentProps<MatchParamsTypes> {
    inputData?: RawProfileInput;
    valuesVisualOptions?: CalendarInputOption[];
    inputName?: string;
    calculatedGroup?: RawInputGroup;
    userRole: string;
    setEditedCellAndGroup: (editedCell?: RawProfileInput | CalendarInput, editedGroup?: RawInputGroup | CalendarGroup, date?: Date) => void
    day?: IDayItem;
    calendarInput?: CalendarInput;
    calendarGroup?: CalendarGroup;
    groupType: InputGroupDefinitionType | undefined;
}

interface IFatigueProfilesCellState {
    isEdited: boolean;
    value: any;
    isFormValid: boolean;
    isLoading: boolean;
}

class FatigueProfilesCell extends React.Component<IFatigueProfilesCellProps, IFatigueProfilesCellState> {
    @lazyInject('FormConfigConverterService') private formConfigConverterService: IFormConfigConverterService;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('CalendarIntoRawProfileConverterService') private calendarIntoRawProfileConverterService: CalendarIntoRawProfileConverterService;

    constructor(props: IFatigueProfilesCellProps) {
        super(props);
        this.state = {
            isEdited: false,
            value: null,
            isFormValid: false,
            isLoading: false
        }
        fixInjectedProperties(this);
    }

    render() {
        const inputData = this.inputData();
        let visualOptions = undefined;

        if(!inputData ||
            (!this.props.inputData && this.props.groupType === InputGroupDefinitionType.NORMAL) ||
               ( this.props.groupType === InputGroupDefinitionType.TRAINING && !this.props.day)
        ) {
            return <td onDoubleClick={this.toggleEdit} />
        }

        const inputResult = inputData.inputResultValue;
        let inputValue = inputData.inputType === InputType.INPUT_DATE ? this.formatDate(inputData) : inputResult?.value;
        if (this.props.valuesVisualOptions && this.props.valuesVisualOptions.length > 0) {
            const inputOption = this.props.valuesVisualOptions.find((option: CalendarInputOption) => option.value === inputValue?.toString());
            visualOptions = {backgroundColor: inputOption?.backgroundColor};
            inputValue = inputOption ? inputOption.value : inputValue;
        }

        const cellInfo = `${this.props.inputName || inputData.inputName} - `;
        const tooltip: ITooltip = {text: cellInfo, value: inputValue, position: TooltipPosition.Top};

        return (
            <TooltipWrapper tooltip={tooltip} key={'profile-button'}>
                <td onDoubleClick={this.toggleEdit} style={visualOptions ? visualOptions : undefined}>
                    {inputValue}
                </td>
            </TooltipWrapper>
          )

    }

    private inputData(): RawProfileInput | undefined {
        const calendarInput = this.props.calendarInput

        if(!this.props.inputData && !calendarInput) {
            return undefined
        }

        if(this.props.inputData) {
            return this.props.inputData
        }

        if(calendarInput) {
            return this.calendarIntoRawProfileConverterService.convertCalendarInputIntoRawProfileInput(calendarInput, this.props?.day?.dateObject)
        }
    }

    private formatDate(input: RawProfileInput): string | null {
        if(!input?.inputResultValue?.value) {
            return null
        }

        if(isNaN(+input?.inputResultValue?.value)) {
            return `${input?.inputResultValue?.value}`
        }

        if(input.inputParameters.type ===  DatepickerType.DATE_TIME) {
            return moment(+input?.inputResultValue?.value).format('YYYY-MM-DD hh:mm')
        }

        if(input.inputParameters.type ===  DatepickerType.DATE) {
            return moment(+input?.inputResultValue?.value).format('YYYY-MM-DD')
        }

        return moment(+input?.inputResultValue?.value).format('hh:mm')
    }

    private toggleEdit = () => {
        if(this.props.inputData?.inputType === InputType.CALCULATED ||
            this.props.calendarInput?.inputDefinition?.type === InputType.CALCULATED ||
            (this.props.userRole !== Role.Admin && this.props.userRole !== Role.HeadCoach)) {
            return
        }

        //Training Plan edit after the generation date is blocked or we are in contestants subview
        if(
            (this.props.groupType === InputGroupDefinitionType.TRAINING && this.props.match.params?.host === 'contestants') ||
            (!this.props.inputData && this.props.day?.dateObject && new Date().getTime() < this.props.day?.dateObject?.getTime()) ||
            (!this.props.inputData && this.props.groupType === InputGroupDefinitionType.NORMAL)) {
            return
        }

        if(!this.props.inputData) {
            return this.props.setEditedCellAndGroup(this.props.calendarInput, this.props.calendarGroup, this.props.day?.dateObject)
        }

        return this.props.setEditedCellAndGroup(this.props.inputData, this.props.calculatedGroup)
    }
}


export default connect(
    (state: any) => ({
        userRole: userRoleSelector(state)
    }),
    {}
)(withRouter(FatigueProfilesCell));

