import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Toast} from "judo-app-common-web";
import './App.scss';
import PanelHost from './components/PanelHost';
import Auth from "./components/Auth";

interface IAppProps {
}

class App extends React.Component<IAppProps> {

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/auth/:type" component={Auth} key="auth"/>
                    <Route path="/:host?/:id?/:type?/:profileId?" component={PanelHost} key="panelHost" />
                </Switch>
                <Toast/>
            </React.Fragment>
        );
    }
}

export default App;
