const pl_PL = {
    menu: {
        clubCoaches: 'Trenerzy Klubowi',
        contestants: 'Zawodnicy',
        specialists: 'Specjaliści',
        developmentProfiles: 'Profile Rozwoju Zawodnika',
        fatigueProfile: 'Profil Zmęczeniowy',
        clubs: 'Kluby',
        specialistTypes: 'Typy Specjalistów',
        archive: 'Archiwum',
        account: 'Konto',
        logout: 'Wyloguj'
    },
    formControl: {
        error: {
            isRequired: "Pole wymagane",
            minLength: "Wartość pola zbyt krótka",
            isEmail: "Wpisz poprawny adres email",
            areFieldsSame: "Hasła muszą być takie same",
        }
    },
    button: {
        yes: 'Tak',
        no: 'Nie',
        delete: 'Usuń',
        duplicate: 'Duplikuj',
        remove: 'Skasuj',
        edit: 'Edytuj',
        create: 'Utwórz',
        confirm: 'Potwierdź',
        cancel: 'Anuluj',
        close: 'Zamknij',
        save: 'Zapisz',
        next: 'Następny',
        prev: 'Poprzedni',
        nextPage: 'Następna strona',
        prevPage: 'Poprzednia strona',
        submit: 'Wyślij',
        change: 'Zmień',
        archive: 'Archiwizuj',
        restore: 'Przywróć',
        assign: 'Przypisz',
        unassign: 'Usuń przypisanie',
        add: 'Dodaj',
        collapse: 'Zwiń',
        expand: 'Rozwiń',
        back: 'Powrót',
        fillTraining:'Wypełnij trening',
    },
    profileSettingsModal: {
        header: 'Ustawienia Profilu Zmęczeniowego',
        groupList: {
            header: 'Grupy Zmiennych',
            noData: 'Brak Grup do wyświetlenia'
        },
        variableList: {
            header: 'Zmienne Grupy',
            noData: 'Brak Zmiennych do wyświetlenia'
        },
        optionList: {
            header: 'Opcje zmiennej select',
            noData: 'Brak opcji do wyświetlenia'
        },
        calendarEventList: {
            header: 'Wydarzenia',
            noData: 'Brak wydarzeń do wyświetlenia'
        },
        button: {
            add: {
                variableGroup: 'Dodaj Grupę',
                variableItem: 'Dodaj Zmienną',
                variableOption: 'Dodaj Opcję',
                calendarEvent: 'Dodaj Wydarzenie'
            },
            create: {
                variableGroup: 'Utwórz Grupę',
                variableItem: 'Utwórz Zmienną',
            },
            archive: {
                variableGroup: 'Archiwizuj Grupę',
                variableItem: 'Archiwizuj Zmienną'
            },
            delete: {
                variableOption: 'Usuń Opcję Zmiennej',
            },
            edit: {
                variableGroup: 'Edytuj Grupę',
                variableItem: 'Edytuj Zmienną',
                variableOption: 'Edytuj Opcję Zmiennej',
                calendarEvent: 'Edytuj Wydarzenie'
            },
            update: {
                variableGroup: 'Aktualizuj Grupę',
                variableItem: 'Aktualizuj Zmienną'
            }
        },
        badges: {
            textarea: {
                label: 'TEKSTOWA',
                tooltipLabel: 'Zmienna typu tekstowego'
            },
            numeric: {
                label: 'NUMERYCZNA',
                tooltipLabel: 'Zmienna typu numerycznego'
            },
            number: {
                label: 'LICZBA',
                tooltipLabel: 'Zmienna liczbowa'
            },
            datepicker: {
                label: 'DATA',
                tooltipLabel: 'Zmienna typu Daty'
            },
            select: {
                label: 'SELECT',
                tooltipLabel: 'Zmienna typu Select'
            },
            calculated: {
                label: 'FORMUŁA',
                tooltipLabel: 'Zmienna typu Formuła'
            },
            trainingPlan: {
                label: 'Plan Treningowy',
                tooltipLabel: 'Wybrana grupa jest ustawiona jako Plan Treningowy'
            }
        },
        form: {
            addInputForm: {
                headerSR: 'Formularz dodawania nowej zmiennej',
                header: 'Nowa Zmienna grupy ',
                name: {
                    label: 'Nazwa Zmiennej',
                    placeholder: `Np 'samopoczucie'`
                },
                type: {
                    label: 'Typ zmiennej',
                    options: {
                        textarea: 'Pole tekstowe',
                        number: 'Pole liczbowe',
                        datepicker: 'Data',
                        select: 'Pole wyboru',
                        calculated: 'Pole funkcji'
                    },
                    placeholder: 'Wybierz rodzaj pola'
                },
                datepickerType: {
                    label: 'Typ zmiennej',
                    options: {
                        time: 'Czas',
                        date: 'Data',
                        date_time: 'Data i Czas',
                    },
                    placeholder: 'Wybierz rodzaj pola'
                },
                enabled: {
                    label: 'Dodaj do Kalendarza i Fromularza Zmęczeniowego'
                },
            },
            updateInputForm: {
                header: 'Edycja Zmiennej Grupy '
            },
            addOptionForm: {
                headerSR: 'Formularz dodawania nowej opcji zmiennej',
                header: 'Nowa Opcja',
                headerEdit: 'Edytuj Opcję',
                label: {
                    label: 'Nazwa Opcji',
                    placeholder: `Np 'Bardzo wysokie'`
                },
                value: {
                    label: 'Wartość Opcji',
                    placeholder: `Np 1`
                },
                color: {
                    label: 'Kolor',
                    placeholder: 'Kod koloru w formacie #XXX'
                }
            },
            addCalendarEventForm: {
                headerSR: 'Formularz dodawania nowego Wydarzenia',
                header: 'Nowe Wydarzenie',
                headerEdit: 'Edytuj Wydarzenie',
                name: {
                    label: 'Nazwa Wydarzenia',
                    placeholder: `Np 'Igrzyska Olimpijskie 2020'`
                },
                startsAt: {
                    label: 'Data rozpoczęcia',
                    placeholder: `Wybierz dzień`
                }
            },
            addGroupForm: {
                headerSR: 'Formularz dodawania nowej grupy',
                header: 'Nowa Grupa',
                name: {
                    label: 'Nazwa Grupy',
                    placeholder: `Np 'samopoczucie'`
                },
                color: {
                    label: 'Kolor',
                    placeholder: 'Kod koloru w formacie #XXX'
                },
                plan: {
                    label: 'Plan Treningowy'
                }
            },
            updateGroupForm: {
                headerSR: 'Formularz uaktualniania grupy',
                header: 'Nowa Grupa',
                name: {
                    label: 'Nazwa Grupy',
                    placeholder: `Np 'samopoczucie'`
                },
                color: {
                    label: 'Kolor',
                    placeholder: 'Kod koloru w formacie #XXX'
                }
            }
        },
        modal: {
            archiveGroup: {
                header: 'Potwierdź archiwizację Grupy',
                question: 'Czy na pewno chcesz archiwizować Grupę Zmiennych?'
            },
            archiveVariable: {
                header: 'Potwierdź archiwizację Zmiennej',
                question: 'Czy na pewno chcesz archiwizować Zmienną?'
            }
        }
    },
    userList: {
        table: {
            header: {
                coaches: 'Trenerzy',
                contestants: 'Zawodnicy',
                specialists: 'Specjaliści',
                fatigueProfiles: 'Profile Rozwoju Zawodnika',
                clubs: 'Kluby',
                name: "Nazwa",
                actions: "Przyciski Akcji"
            },
            button: {
                details: 'Szczegóły',
                clickToDrag: 'Przytrzymaj przycisk myszy aby przesunąć element',
                duplicateFatigueProfile: 'Duplikowanie Profilu Zmęczeniowego',
                add: {
                    coach: 'Dodaj Trenera',
                    contestant: 'Dodaj Zawodnika',
                    specialist: 'Dodaj Specjalistę',
                    specialistType: 'Dodaj Typ Specjalisty',
                    developmentProfile: 'Dodaj Profil Rozwoju Zawodnika',
                    club: 'Dodaj Klub',
                    user: 'Dodaj Użytkownika',
                    variable: 'Dodaj Zmienną',
                    group: 'Dodaj Grupę',
                    userPhoto: 'Dodaj zdjęcie'
                },
                edit: {
                    clubProfile: 'Edytuj profil Klubu',
                    fatigueProfile: 'Profil Zmęczeniowy',
                    fatigueCalendar: 'Kalendarz',
                    fatigueCalendarSettings: 'Ustawienia Kalendarza Zmęczeniowego',
                    developmentProfileSettings: 'Ustawienia Profilu Zmęczeniowego',
                    fatigueForm: 'Formularz',
                    profile: 'Edytuj Informacje o Zawodniku',
                    profileDefinitionName: 'Edytuj nazwę Profilu Zmęczeniowego',
                    settings: 'Ustawienia',
                    trainingPlan: 'Plan treningowy',
                    variable: 'Edytuj Zmienną',
                    userPhoto: 'Edytuj zdjęcie'
                },
                archive: {
                    fatigueProfile: 'Usuń Profil Zmęczeniowy',
                    variableGroup: 'Usuń Grupę Zmiennych',
                    variable: 'Usuń Zmienną',
                    club: 'Usuń Klub',
                    coach: 'Usuń Trenera',
                    specialist: 'Usuń Specjalistę',
                    contestant: 'Usuń Zawodnika',
                    profile: 'Usuń Profil',
                },
                remove: {
                    fatigueProfile: 'Usuń Profil Zmęczeniowy z listy',
                    variableGroup: 'Usuń Grupę Zmiennych z listy',
                    variable: 'Usuń Zmienną z listy',
                    specialistType: 'Usuń Typ Specjalisty'
                },
                assign: {
                    fatigueProfile: 'Przypisz Profil Rozwoju Zawodnika',
                    contestant: 'Przypisz Zawodnika',
                    coach: 'Przypisz Trenera',
                    specialist: 'Przypisz Specjalistę',
                },
                unassign: {
                    developmentProfile: 'Usuń Profil Rozwojowy Zawodnikowi',
                    contestant: 'Usuń Zawodnika z listy',
                    coach: 'Usuń Trenera z listy',
                    specialist: 'Usuń Specjalistę z listy',
                },
                restore: {
                    fatigueProfile: 'Przywróć Profil Zmęczeniowy',
                    variableGroup: 'Przywróć Grupę Zmiennych',
                    variable: 'Przywróć Zmienną',
                    coach: 'Przywróć Trenera',
                    specialist: 'Przywróć Specjalistę',
                    contestant: 'Przywróć Zawodnika',
                    profile: 'Przywróć Profil',
                },
                show: {
                    personalProfile: 'Wyświetl Profil Osobowy',
                    fatigueProfile: 'Wyświetl Profil Zmęczeniowy',
                    variableGroup: 'Wyświetl Grupę Zmiennych',
                    trainingProfile: 'Wyświetl Plan Treningowy',
                },
                redirect:  {
                    profile: 'Przejdź do Edycji Profilu'
                },
                view: 'Podgląd'
            },
            noData: {
                noCoaches: 'Brak Trenerów',
                noContestants: 'Brak Zawodników',
                noDevelopmentProfiles: 'Brak Profili Rozwoju Zawodnika',
                noSpecialists: 'Brak Specjalistów',
                noSpecialistTypes: 'Brak Typów Specjalistów',
                noFatigueProfiles: 'Brak Profili Zmęczeniowych',
                noFatigueCalendars: 'Brak Kalendarzy Zmęczeniowych',
                noClubs: 'Brak Klubów',
                noArchivedClubs: 'Brak zarchiwizowanych Klubów',
                noArchivedContestants: 'Brak zarchiwizowanych Zawodników',
                noArchivedCoaches: 'Brak zarchiwizowanych Trenerów',
                noArchivedSpecialists: 'Brak zarchiwizowanych Specjalistów',
                noArchivedProfiles: 'Brak zarchiwizowanych Profili Zmęczeniowych'
            }
        },
        addDevelopmentProfile: {
            form: {
                header: 'Formularz dodawania Profilu Rozwoju Zawodnika',
                name: {
                    label: 'Nazwa profilu rozwoju zawodnika',
                    placeholder: 'np Przygotowania do olimpiady'
                }
            }
        }
    },
    userView: {
        header: {
            coach: 'Trener Klubowy',
            fatigueProfile: 'Profil Zmęczeniowy',
            fatigueCalendar: 'Kalendarz Zmęczeniowy',
            fatigueCalendarSettings: 'Ustawienia Kalendarza Zmęczeniowego',
            fatigueForm: 'Formularz Zmęczeniowy',
            contestant: 'Zawodnik',
            specialist: 'Specjalista',
            club: 'Klub',
            variableGroup: 'Grupy zmiennych',
            calendarEvent: 'Wydarzenia'
        },
        userTypes: {
            coach: 'Trenerzy Klubowi',
            fatigueProfile: 'Profile Rozwoju Zawodnika',
            contestant: 'Zawodnicy',
            specialist: 'Specjaliści',
            club: 'Kluby'
        },
        noData: {
            coach: 'Brak Trenerów do wyświetlenia',
            fatigueProfile: 'Brak Profili Zmęczeniowych do wyświetlenia',
            contestant: 'Brak Zawodników do wyświetlenia',
            specialist: 'Brak Specjalistów do wyświetlenia',
            club: 'Brak Klubów do wyświetlenia',
            calendarEvent: 'Brak Wydarzeń do wyświetlenia',
            assignedCoach: 'Nie przypisano Trenera',
            groupVariable: 'Brak Zmiennych Grupy do wyświetlenia',
            formGroup: 'Brak Grup Zmiennych do wyświetlenia'
        },
        variableGroup: {
            modal: {
                header: 'Zmienne grupy: ',
                listHeader: 'Lista zmiennych grupy',
                addInputForm: {
                   header: 'Formularz dodawania zmiennej grupy'
                },
                button: {
                    addVariable: 'Dodaj zmienną',
                    manageVariables: 'Zarządzaj zmiennymi',
                    deleteVariable: 'Usuń zmienną'
                },

            },
        },
        button: {
            showUserModal: 'Pokaż Modal Użytkownika',
            fatigueProfile: 'Profil Zmęczeniowy',
            addVariableGroup: 'Dodaj Grupę Zmiennych',
            addDevelopmentProfile: 'Dodaj Profil Rozwoju Zawodnika',
            addFatigueCalendar: 'Dodaj Kalendarz Zmęczeniowy',
            addCalendarEvent: 'Dodaj Wydarzenie',
            showFatigueProfile: 'Podgląd Profilu Zmęczeniowego',
            showFatigueCalendar: 'Podgląd Kalendarza Zmęczeniowego',
            showFatigueForm: 'Podgląd Formularza Zmęczeniowego',
            settings: 'Ustawienia',
            settingsFatigueForm: 'Ustawienia Formularza Zmęczeniowego',
            settingsFatigueCalendar: 'Ustawienia Kalendarza Zmęczeniowego',
            settingsFatigueProfile: 'Ustawienia Profilu Zmęczeniowego',
        }
    },
    archive: {
        header: 'Archiwum',
        tabs: {
            clubs: {
                list: {
                    header: 'Zarchiwizowane Kluby',
                },
                view: {
                    header: 'Podgląd zarchiwizowanego Klubu'
                }
            },
            coaches: {
                list: {
                    header: 'Zarchiwizowani Trenerzy',
                },
                view: {
                    header: 'Podgląd zarchiwizowanego Trenera'
                }
            },
            contestants: {
                list: {
                    header: 'Zarchiwizowani Zawodnicy',
                },
                view: {
                    header: 'Podgląd zarchiwizowanego Zawodnika'
                }
            },
            specialists: {
                list: {
                    header: 'Zarchiwizowani Specjaliści',
                },
                view: {
                    header: 'Podgląd zarchiwizowanego Specjalisty'
                }
            },
            profiles: {
                list: {
                    header: 'Zarchiwizowane Profile Rozwoju Zawodnika',
                },
                view: {
                    header: 'Podgląd zarchiwizowanego Profilu'
                }
            }
        },
        restoreModal: {
            header: {
                club: 'Przywracanie Klubu',
                coach: 'Przywracanie Trenera',
                contestant: 'Przywracanie Zawodnika',
                specialist: 'Przywracanie Specjalisty',
                profile: 'Przywracanie Profilu Zmęczeniowego',
            },
            question: 'Czy na pewno chcesz przywrócić ',
            restoreSuccess: 'Przywracanie Profilu zakończone sukcesem!'
        },
        archiveModal: {
            header: {
                club: 'Archiwizaca Klubu',
                coach: 'Archiwizaca Trenera',
                contestant: 'Archiwizaca Zawodnika',
                specialist: 'Archiwizaca Specjalisty',
                profile: 'Archiwizaca Profilu Zmęczeniowego',
            },
            question: 'Czy na pewno chcesz zarchiwizować ',
            archiveSuccess: 'Przeniesienie Profilu do Archiwum zakończone sukcesem!'
        }
    },
    clubs: {
        header: 'Kluby'
    },
    coaches: {
        header: 'Trenerzy Klubowi'
    },
    contestants: {
        header: 'Zawodnicy'
    },
    specialists: {
        header: 'Specjaliści'
    },
    specialistTypes: {
        header: 'Typy Specjalistów'
    },
    profile: {
        header: 'Profil Zmęczeniowy'
    },
    profiles: {
        header: 'Profile Rozwoju Zawodnika',
        modal: {
            header: 'Profil Zmęczeniowy',
            button: {
                charts: 'Wykresy',
                settings: 'Ustawienia',
                calendar: 'Kalendarz'
            },
            table: {
                monthDay: 'Dzień miesiąca',
                weekDay: 'Dzień tygodnia',
                calendarEvent: {
                    header: 'Ilość dni do wydarzeń',
                    startsToday: 'Wydarzenie rozpoczyna się dziś',
                    isDue: 'Wydarzenie trwa lub się odbyło',
                },
                summary: {
                  sum: 'Suma wyników',
                  min: 'Najniższy wynik',
                  max: 'Najwyższy wynik',
                  avg: 'Średnia z wyników',
                  mostCommonValue: 'Najczęstsza wartość'
                }
            },
            chart: {
                noDate: 'Wyniki z wybranego dnia',
                noDataToGraph: 'Wybierz datę z wynikami i parametry by wygenerować graf',
                parameters: 'Dostępne Parametry',
                noMonthData: 'W wybranym miesiącu nie ma żadnych wyników',
                line: 'Liniowy',
                radar: 'Radar'
            }
        },
        editCellModal: {
            title: 'Edycja pola'
        },
        view: {
            header: 'Profil Zmęczeniowy Zawodnika',
            dailyForm: {
                header: 'Formularz dzienny',
                form: {
                    title: ''
                },
                wrongDay: 'Twój Profil Zmęczeniowy jest generowany na bieżący dzień, \n poczekaj do {{date}} aby go wyświetlić.',
                profileCreatedOnSameDay: 'Profil Zmęczeniowy został przypisany przez Trenera dziś, formularz dzienny będzie wygenerowany po 24:00.',
                profileIsGenerating: 'Trwa  generowanie formularza. Proszę odświeżyć stronę za parę minut. ',
                createdOnSameDayWarning: 'Jeśli Profil Zmęczeniowy został przypisany dziś, formularz dzienny będzie dostępny następnego dnia.',
                showFatigueCalendar: 'Wyświetl Kalendarz Zmęczeniowy',
                pastMonth: 'Wybrałeś miesiąc poprzedzający, możesz edytować formularze tylko do 3 dni wstecz.',
                notCurrentMonth: 'Wybrałeś inny miesiąc niż bieżący,  aby podejrzeć formularz wybierz konkretny dzień na kalendarzu.',
                noProfileDefinition: 'Nie posiadasz Profilu Zmęczeniowego, skontaktuj się ze swoim Trenerem.'
            }
        }
    },
    logout: {
        modal: {
            title: 'Logout modal',
            question: 'Do you want to log out?'
        },
        alerts: {
            success: 'Logged out successfully',
        }
    },
    userModal: {
        header: 'Profil ',
        contestant: 'Zawodnika',
        coach: 'Trenera',
        specialist: 'Specjalisty',
    },
    formList: {
        header: 'Forms',
        noSearchItems: 'There are no forms suited your filters setup.',
        label: {
            performance: `Lead's Performance`,
            name: 'Form name',
            createdAt: 'Created at',
            leadsNumber: 'Leads',
            status: 'Status'
        },
        action: {
            create: {
                label: 'Create Form',
                modal: {
                    title: 'New Form creation',
                    question: 'Are you sure you want create Form?',
                    form: {
                        newFormName: {
                            label: "New Form name",
                            placeholder: "Type new Form name"
                        }
                    }
                }
            },

            duplicate: {
                label: 'Duplicate Form',
                modal: {
                    title: 'Duplicate Form',
                    question: 'Are you sure you want duplicate Form',
                    form: {
                        duplicationFormName: {
                            label: "Duplicated Form name",
                            placeholder: "Type name of duplicated Form"
                        }
                    }
                }
            },
            delete: {
                label: 'Delete Form',
                modal: {
                    title: 'Delete Form',
                    question: 'Are you sure you want delete Form',
                    warning: 'That action will be irreversible.',
                }
            },
        }
    },
    account: {
        header: 'Konto',
        user: {
            header: 'Użytkownik'
        },
        languageSelect: {
            header: "Wybierz język",
            option: {
                en_US: "angielski",
                pl_PL: "polski"
            }
        },
        changePassword: {
            form: {
                login: {
                    label: 'Login',
                },
                oldPassword: {
                    label: 'Stare hasło',
                    placeholder: 'Bieżące hasło'
                },
                newPassword: {
                    label: 'Nowe hasło',
                    placeholder: 'Wpisz nowe hasło'
                },
                repeatPassword: {
                    label: 'Potwierdź nowe hasło',
                    placeholder: 'Powtórz nowe hasło'
                }
            },
            button: 'Zmień hasło',
            alert: {
                success: 'Twoje hasło zostało zmienione.',
                oldPasswordInvalid: 'Podane stare hasło jest nieprawidłowe.'
            }
        },
        personalProfile: {
            header: 'Profil Osobowy',
            button: 'Edytuj',
        }
    },
    auth: {
        register: {
            title: 'Rejestracja',
            form: {
                repeatPassword: {
                    label: "Powtórz hasło",
                    placeholder: "Powtórz hasło"
                }
            },
            button: 'Zarejestruj'
        },
        login: {
            title: 'Logowanie',
            form: {
                email: {
                    label: 'E-mail',
                    placeholder: 'example@xxx.pl'
                },
                password: {
                    label: "Hasło",
                    placeholder: 'Wpisz hasło'
                },
            },
            button: "Zaloguj"
        },
        resetPassword: {
            title: 'Zresetuj hasło',
            button: 'Zresetuj'
        },
        setNewPassword: {
           title: "Podaj nowe hasło",
            form: {
               password: {
               label: 'Nowe hasło',
                   placeholder: 'Minimum 6 znaków'
               },
                newPassword: {
                    label: 'Powtórz nowe hasło',
                    placeholder: 'Wpisz hasło powtórnie'
                }
            },
           button: "Zapisz hasło"
        },
        forgotPassword: 'Zapomniałeś hasła?',
        haveAnAccount: 'Posiadasz już konto?',
        haveAnAccountLogin: 'Zaloguj sie',
        noAccount: 'Nie masz konta?',
        noAccountRegister: 'Zarejestruj się',
        title: 'Tytuł ?'
    },
    invitation: {
        contestant: 'Zaproszenie do zawodnika zostało wysłane',
        specialist: 'Zaproszenie do specjalisty zostało wysłane',
        coach: 'Zaproszenie do trenera zostało wysłane',
        club: 'Klub został dodany'
    },
    alert: {
        fatigueCalendarAlert: {
            calendarGroupCreateSuccess: 'Tworzenie Grupy zakończone sukcesem!',
            calendarCreateSuccess: 'Tworzenie Kalendarza Zmęczeniowego zakończone sukcesem!'
        },
        fatigueProfileAlert: {
            profileInvalid: 'Twój Profil Zmęczeniowy jest niepełny!',
            addDays: 'Uzupełnij brakujące dni.',
            createProfileSuccess: 'Tworzenie Profilu Rozwoju Zawodnika zakończone sukcesem!',
            createGroupSuccess: 'Tworzenie Grupy Profilu zakończone sukcesem!',
            createVariableSuccess: 'Tworzenie Zmiennej Grupy zakończone sukcesem!',
            updateCalendarSuccess: 'Zmiana kalendarza zakończona sukcesem!',
            updateGroupSuccess: 'Zmiana Grupy Profilu zakończona sukcesem!',
            updateFormSuccess: 'Zmiana Formularza zakończona sukcesem!',
            updateVariableSuccess: 'Zmiana Zmiennej Grupy zakończona sukcesem!',
            updateFatigueProfileSuccess: 'Zmiana Profilu Rozwoju Zawodnika zakończona sukcesem!',
        },
        logout: {
            success: 'Wylogowanie zakończone sukcesem.',
        },
        registration: {
            success: 'Rejestracja przebiegła pomyślnie. Zaloguj się do panelu.'
        },
        sendResetPasswordMail: {
            success: 'Na podany adres mailowy wysłano link do resetowania hasła'
        },
        resetPassword: {
            success: "Resetowanie hasła powiodło się, teraz możesz zalogować się za pomocą nowego hasła."
        },
        specialistType: {
            success: 'Typ specjalisty został dodany',
            removalSuccess: 'Usunięcie typu Specjalisty zakończone powodzeniem!'
        },
        getUserProfileDefinitions: {
            error: 'Użytkownik nie ma przypisanego Profilu Zmęczeniowego'
        }
    },

    badge: {
        draft: 'Szablon',
        active: 'Aktywny',
        inactive: 'Nieaktywny'
    },
    list: {
        filter: {
            byType: {
                title: 'Filtruj po typie',
                options: {
                    all: 'Wszystkie',
                    draft: 'Szablon',
                    active: 'Aktywny',
                    inactive: 'Nieaktywny'
                }
            },
            byDate: 'Filtruj po dacie',

        },
        itemsPerPageLabel: 'Formularzy na stronę:',
        search: 'Szukaj'
    },
    modal: {
        trainingPlanView: {
            title: 'Plan treningowy',
            addTrainingProfile: 'Kliknij dwukrotnie aby dodać Plan Treningowy'
        },
        editPersonalProfile: {
            title: 'Edycja Profilu Osobistego',
            type: {
                coach: 'Edycja Profilu Osobistego Trenera',
                contestant: 'Edycja Profilu Osobistego Zawodnika',
                club: 'Edycja Profilu Osobistego Klubu',
                specialist: 'Edycja Profilu Osobistego Specjalisty',
                admin: 'Edycja Profilu Osobistego Administratora'
            },
            form: {
                firstName: {
                    label: 'Imię',
                    placeholder: 'Wpisz swoje imię'
                },
                lastName: {
                    label: 'Nazwisko',
                    placeholder: 'Wpisz swoje nazwisko'
                },
                club: {
                    label: 'Klub',
                    placeholder: 'Wybierz klub z listy'
                },
                specialistType: {
                    label: 'Specjalizacja:',
                    placeholder: 'Wybierz specjalizację z listy'
                },
                phone: {
                    label: 'Numer Telefonu:',
                    placeholder: '+48 123 456 789'
                }
            },
            editSuccess: 'Zmiana Profilu zakończona sukcesem!',
            editImageSuccess: 'Uaktualnienie Zdjęcia zakończone sukcesem!',
            editProfileButton: 'Edytuj profil',
            saveProfileImage: 'Zatwierdź zmianę zdjęcia profilowego',
            cancelProfileImage: 'Anuluj zmianę  zdjęcia profilowego',
            imageTooBig: 'Zdjęcie profilowe musi mieć wielkość poniżej 1MB.',
            imageSizeWarning: 'Zdjęcie może mieć maksymalnie 1MB wielkości'
        },
        editClub: {
            title: 'Edycja Profilu Klubu',
            header:  'Edycja Profilu Osobistego Klubu',
            form: {
                name: {
                    label: 'Nazwa Klubu',
                    placeholder: 'Wpisz nazwę Klubu'
                }
            },
            editSuccess: 'Zmiana Profilu Klubu zakończona sukcesem!',
        },
        changePassword: {
            title: 'Zmiana hasła',
        },
        assignDevelopmentProfile: {
            title: 'Przypisz Profil Rozwoju Zawodnika',
            form: {
                fatigueProfiles: {
                    label: 'Profile Rozwoju Zawodnika',
                    placeholder: 'Wybierz Profil Rozwoju Zawodnika z listy'
                }
            },
            assignSuccess: 'Przypisanie Profilu Zmęczeniowego do Użytkownika zakończone sukcesem!'
        },
        unassignAccount: {
            title: 'Usuń przypisanie Użytkownika',
            question: 'Czy na pewno chcesz usunąć powiązanie z tym Użytkownikiem?',
            profileQuestion: 'Czy na pewno chcesz usunąć przypisanie Profilu Zmęczeniowego do tego Użytkownika?',
            unassignSuccess: 'Usunięcie powiązania Użytkownika zakończone sukcesem!',
            unassignFatigueProfileSuccess: 'Usunięcie przypisania Profilu Zmęczeniowego zakończone sukcesem!'
        },
        assignContestant: {
            title: 'Przypisz Zawodnika',
            form: {
                contestants: {
                    label: 'Zawodnicy',
                    placeholder: 'Wybierz Zawodnika z listy'
                }
            },
            assignSuccess: 'Przypisanie Zawodnika do Użytkownika zakończone sukcesem!'
        },
        assignCoach: {
            title: 'Przypisz Trenera',
            form: {
                coaches: {
                    label: 'Trenerzy',
                    placeholder: 'Wybierz Trenera z listy'
                }
            },
            assignSuccess: 'Przypisanie Trenera do Użytkownika zakończone sukcesem!'
        },
        assignSpecialist: {
            title: 'Przypisz Specjalistę',
            form: {
                specialists: {
                    label: 'Specjaliści',
                    placeholder: 'Wybierz Specjalistę z listy'
                }
            },
            assignSuccess: 'Przypisanie Specjalisty do Użytkownika zakończone sukcesem!'
        },
        addUser: {
            title: {
                coach: 'Dodaj Trenera',
                contestant: 'Dodaj Zawodnika',
                specialist: 'Dodaj Specjalistę',
                specialistType: 'Dodaj Typ Specjalisty',
                club: 'Dodaj Klub',
                fatigueProfile: 'Dodaj Profil Zmęczeniowy'
            },
            form: {
                email: {
                    label: 'E-mail',
                    placeholder: 'przykład@xxx.com'
                },
                firstName: {
                    label: {
                        coach: 'Imię Trenera',
                        contestant: 'Imię Zawodnika',
                        specialist: 'Imię Specjalisty',
                    },
                    placeholder:  {
                        coach: 'Wpisz imię Trenera',
                        contestant: 'Wpisz imię Zawodnika',
                        specialist: 'Wpisz imię Specjalisty',
                    }
                },
                clubName: {
                    label: 'Nazwa Klubu',
                    placeholder: 'Wpisz nazwę Klubu',
                },
                lastName: {
                    label: {
                        coach: 'Nazwisko Trenera',
                        contestant: 'Nazwisko Zawodnika',
                        specialist: 'Nazwisko Specjalisty',
                    },
                    placeholder:  {
                        coach: 'Wpisz nazwisko Trenera',
                        contestant: 'Wpisz nazwisko Zawodnika',
                        specialist: 'Wpisz nazwisko Specjalisty',
                    }
                },
                club: {
                    label: 'Klub',
                    placeholder: 'Wybierz klub z listy'
                },
                specialistType: {
                    label: 'Specjalizacja:',
                    placeholder: 'Wybierz specjalizację z listy'
                },
                specialistName: {
                    label: 'Nazwa Typu:',
                    placeholder: 'Nazwa Typu'
                },
            },
            button: {
                addUser: 'Dodaj'
            }
        },
        deleteProcessStepDefinition: {
            title: 'Usuń krok Formularza ',
            question: 'Czy na pewno chcesz usunąć krok formularza',
            warning: 'Zmiany te nie będą mogły zostać cofnięte.',
        },
        removeSpecialistType: {
           header: 'Usuń typ Specjalisty' ,
            question: 'Czy na pewno chcesz usunąć typ Specjalisty: ',
        },
        logout: {
            title: 'Wylogowanie',
            question: 'Czy napewno chcesz się wylogować?'
        },
    },
    controlEditor: {
        selectValidators: 'Wybierz zasady walidacji',
        validatorsConfig: 'Skonfiguruj wybrane zasady',
    },
    general: {
        actionButtons: {
            options: 'Opcje',
            visual: 'Opcje Graficzne',
            duplicate: 'Kopiuj',
            delete: 'Usuń',
            validation: 'Zasady walidacji',
            cancel: 'Anuluj',
            save: 'Zapisz',
            close: 'Zamknij'
        },
        alert: {
            error: {
                loginFailed: 'Logowanie nie powiodło się. Spróbuj ponowanie.',
                authenticationErrorLogout: 'Wystąpił błąd uwierzytelniania, wylogowano użytkownika.',
                networkProblem: 'Problem z siecią, przekroczono czas połączenia. Sprawdź swoje połączenie internetowe i spróbuj ponownie. Jeśli problem będzie się powtarzał skontaktuj się z Administratorem',
            }
        }
    },
    formValidation: {
        errors: {
            minLength: "Wprowadzona wartość jest zbyt krótka",
            isEmailValid: "Podaj właściwy adres e-mail",
            isRequired: "Pole wymagane",
            isNumber: 'Wartość musi być liczbą',
            isTrue: "Musi być zaznaczony",
            isNotEmpty: 'Pole nie może być puste',
            isPasswordSame: "Hasła muszą być jednakowe",
            isStartDateValid: "Data nie może być w przeszłości",
            isStartDateGreaterOrEvenValid: "Podaj dzisiejszą datę lub datę z przyszłości",
            isEndDateValid: "Data wyjazdu musi być po dacie powrotu.",
            isCheckedTrue: 'Pole musi być zaznaczone',
            areFieldsSame:  "Wartości pól muszą być jednakowe."
        }
    }
};

export default pl_PL;
