import React from 'react';
import {CustomCard, Form, IBasicFormControlConfig, Translation} from 'judo-app-common-web';
import {CalendarGroup, InputGroupDefinitionType} from "../../../../model/models";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {createGroupFormConfig} from "./createGroupFormConfig";

interface ICreateGroupListProps {
    createdCalendarGroup: CalendarGroup;
    onGroupCreation: (group: CalendarGroup) => void;
}

interface ICreateGroupListState {
    newCalendarGroup: CalendarGroup | null;
    isProcessing: boolean;
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    value: any;
}

class CreateGroupList extends React.Component<ICreateGroupListProps, ICreateGroupListState> {
    constructor(props: ICreateGroupListProps) {
        super(props);

        this.state = {
            newCalendarGroup: null,
            isProcessing: false,
            formConfig: createGroupFormConfig,
            value: {},
        }
    }

    componentDidMount() {
        if (this.props.createdCalendarGroup) this.setState({newCalendarGroup: this.props.createdCalendarGroup})
    }

    componentDidUpdate(prevProps: Readonly<ICreateGroupListProps>, prevState: Readonly<ICreateGroupListState>) {
        if (this.props.createdCalendarGroup !== prevProps.createdCalendarGroup) {
            this.setState({newCalendarGroup: this.props.createdCalendarGroup});
        }
    }

    render() {
        if (!this.state.newCalendarGroup) {
            return null;
        }
        return <CustomCard showLocalLoader={false}>
            <div className="user-connection-list-wrapper">
                <CustomCard.Header>
                    <h3 className="user-connection-list-header fatigue-header">
                    <span aria-hidden="true">
                        <Translation text={'profileSettingsModal.form.addGroupForm.header'}/>
                    </span>
                        <span className="sr-only">
                        <Translation text={'profileSettingsModal.form.addGroupForm.headerSR'}/>
                    </span>
                        <button className="btn btn-theme btn-small ml-3"
                                onClick={this.createGroup}>
                            <span className="feather icon-check" aria-hidden="true"/>
                            <span><Translation text={'profileSettingsModal.button.create.variableGroup'}/></span>
                        </button>
                    </h3>
                    <div className="separator"/>
                    {this.renderAddGroupForm()}
                </CustomCard.Header>
            </div>
        </CustomCard>
    }

    private renderAddGroupForm() {
        return <div className="add-group-form-wrapper">
            <h4 className="sr-only">
                <Translation text="userView.variableGroup.modal.addGroupForm.header"/>
            </h4>
            <Form
                config={this.state.formConfig}
                onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                controlName={"addGroupForm"}
                value={{}}
            />
        </div>
    }

    private updateFormData(controlName: string, data: any) {
        let updatedGroup: CalendarGroup = deepCloneObject(this.state.newCalendarGroup);
        updatedGroup.inputGroupDefinition = {
            itemOrder: 0,
            name: 'New',
            profileDefinitionId: '',
            inputDefinitions: [],
            type: InputGroupDefinitionType.NORMAL
        }
        if (data) {
            updatedGroup.inputGroupDefinition.type = InputGroupDefinitionType.NORMAL;
            updatedGroup.name = data.name;
            updatedGroup.visualOptions.backgroundColor = data.color;
        }
        this.setState({newCalendarGroup: updatedGroup});
    }

    private createGroup = () => {
        if (!this.state.newCalendarGroup?.name || !this.props.createdCalendarGroup) {
            return;
        }

        const addedGroup: CalendarGroup = {
            id: "",
            itemOrder: 0,
            name: this.state.newCalendarGroup.name,
            enabled: true,
            visualOptions: this.state.newCalendarGroup.visualOptions,
            calendarViewGroupItems: this.state.newCalendarGroup.calendarViewGroupItems,
            inputGroupDefinition: this.state.newCalendarGroup.inputGroupDefinition
        }
        this.props.onGroupCreation(addedGroup);
    };
}

export default CreateGroupList;
