import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import AnimationHost, {LayoutState} from './AnimationHost';
import {ProfileType, Role} from "../../../model/models";
import {catchError, filter, map, switchMap, tap} from "rxjs/operators";
import {Observable, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {MatchProcessParams} from "../../PanelHost";
import {getBaseListAPI} from "../../../api/getBaseList";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {authTokenSelector} from "judo-app-common-web";
import {shouldListUpdateSelector, shouldViewUpdateSelector} from "../../../store/selectors/sagaSelectors";
import {
    changeIsUserProfileLoading,
    changeShouldListUpdate,
    changeShouldViewUpdate
} from '../../../store/reducers/sagaSlice';
import {getAccountListFromUserListAPI} from "../../../api/getAccountListFromUserList";
import {getConnectedAccountsAPI} from "../../../api/getConnectedAccounts";
import {getAccountAPI} from '../../../api/getAccount';
import {userRoleSelector, userSelector} from '../../../store/selectors/userSelector';
import {getBaseViewAPI} from '../../../api/getBaseView';
import {IPaginationData} from "../Pagination";
import {deepCloneObject} from "../../../utils/runtimeUtils";
import {getAccountsFromUserItem} from "../../../utils/dataAccess";

export enum EndpointNames {
    COACHES = 'coaches',
    CONTESTANTS = 'contestants',
    CLUBS = 'clubs',
    SPECIALISTS = 'specialists',
    FATIGUE_PROFILES = 'profile_definitions',
    CALENDARS = 'calendars',
    PROFILES = 'profiles',
    RAW_PROFILES = 'profiles/raw_inputs'
}

interface IAnimatedLayoutHostExternalProps extends RouteComponentProps<MatchProcessParams> {
    readonly host: string;
    readonly listComponent: any;
    readonly viewComponent: any;
    readonly endpointName: EndpointNames;
    readonly isArchiveMode?: boolean;
}

interface IAnimatedLayoutHostConnectedProps extends RouteComponentProps<MatchProcessParams> {
    readonly authToken: string | null;
    readonly account: any;
    readonly userRole: Role;
    readonly shouldListUpdate: boolean;
    readonly shouldViewUpdate: boolean;
    readonly changeIsUserProfileLoading: typeof changeIsUserProfileLoading;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
    readonly changeShouldViewUpdate: typeof changeShouldViewUpdate;
}

interface IAnimatedLayoutHostProps extends IAnimatedLayoutHostExternalProps,
    IAnimatedLayoutHostConnectedProps,
    RouteComponentProps<MatchProcessParams> {
}

interface IAnimatedLayoutHostState {
    accountId: string | null;
    layoutState: LayoutState;
    list: any[] | null;
    itemView: any;
    isListLoading: boolean;
    paginationData: IPaginationData | null;
}

class AnimatedLayoutHost extends React.Component<IAnimatedLayoutHostProps, IAnimatedLayoutHostState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IAnimatedLayoutHostProps) {
        super(props);
        this.state = {
            accountId: null,
            layoutState: LayoutState.List,
            list: null,
            itemView: null,
            isListLoading: true,
            paginationData: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.account) this.retrieveList();
        const accountId = this.props.match.params?.id;
        if (!accountId || accountId === 'calendar') return;
        this.manageMainView(accountId);
    }

    componentDidUpdate(
        prevProps: Readonly<IAnimatedLayoutHostProps>,
        prevState: Readonly<IAnimatedLayoutHostState>,
    ): void {
        if (this.props.match.params.id !== prevProps.match.params.id && this.props.match.params.id === undefined) {
            this.manageMainView(null);
        }

        if (this.state.list !== prevState.list && this.state.list) {
            const accountId = this.props.match.params?.id;
            if (!accountId || accountId === 'calendar') return;
            if (accountId) this.updateView(accountId);
        }

        if (this.props.shouldListUpdate &&
            this.props.shouldListUpdate !== prevProps.shouldListUpdate) {
            this.retrieveList();
            this.props.changeShouldListUpdate(false);
        }

        if (this.state.itemView &&
            this.props.shouldViewUpdate &&
            this.props.shouldViewUpdate !== prevProps.shouldViewUpdate) {
            const accountId = this.props.match.params?.id;
            if (accountId) this.updateView(accountId);
            this.props.changeShouldViewUpdate(false);
        }

        if (!prevProps.account && this.props.account) {
            this.retrieveList();
        }
    }

    componentWillUnmount() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        const List = this.props.listComponent;
        const View = this.props.viewComponent;
        return (<AnimationHost layoutState={this.state.layoutState}
                               list={<List list={this.state.list}
                                           onViewChange={this.manageMainView.bind(this)}
                                           isLoading={this.state.isListLoading}
                                           paginationData={this.state.paginationData}
                                           isArchiveMode={this.props.isArchiveMode}
                                           onSpecificPageLoad={(pageNumber: number) => this.retrieveList(pageNumber)}/>}
                               view={<View userId={this.state.accountId}
                                           itemView={this.state.itemView}
                                           onViewChange={this.manageMainView.bind(this)}
                                           isArchiveMode={this.props.isArchiveMode}/>}
            />
        );
    }

    private manageMainView(accountId: string | null, profileType?: ProfileType): void {
        let layoutState = LayoutState.List;
        let profileTypeUpdated = profileType;
        if (this.props.host === 'profiles') {
            profileTypeUpdated = profileTypeUpdated ? profileTypeUpdated : ProfileType.Calendar;
        }
        if (accountId) {
            layoutState = LayoutState.View;
            this.updateView(accountId);
        }

        this.setState({
            accountId: accountId,
            layoutState: layoutState
        });

        let path = ['', this.props.host];
        if (this.props.isArchiveMode) {
            path = ['', `archive/${this.props.host}`];
        }

        if (accountId) path.push(accountId);
        if (profileTypeUpdated) path.push(profileTypeUpdated);

        this.props.history.push(path.join('/'));
    }

    private retrieveList = (pageNumber?: number) => {
        if (!this.props.authToken || !this.props.account) {
            return null;
        }

        this.setState({isListLoading: true});

        switch (this.props.userRole) {
            case Role.Admin:
            case Role.HeadCoach:
                this.retrieveListAsAdmin(pageNumber);
                break;
            case Role.Coach:
            case Role.Specialist:
            case Role.Contestant:
                this.retrieveListAsNotAdmin(pageNumber);
                break;
            default:
                throw new Error(`Forbidden User role access: ${this.props.userRole}`)
        }
    }

    private retrieveListAsAdmin(pageNumber?: number): void {
        if (this.props.endpointName === EndpointNames.CLUBS ||
            this.props.endpointName === EndpointNames.CALENDARS) {
            this.retrieveListData(getBaseListAPI(
                this.props.authToken as string,
                this.props.endpointName,
                pageNumber,
                1000,
                this.props.isArchiveMode
            ));
            return;
        }

        this.retrieveListData(getAccountListFromUserListAPI(
            this.props.authToken as string,
            this.props.endpointName,
            pageNumber,
            undefined,
            this.props.isArchiveMode
        ));
    }

    private retrieveListAsNotAdmin(pageNumber?: number, itemsPerPage?: number): void {
        const account = this.props.account;

        if (this.props.endpointName === EndpointNames.CALENDARS) {
            this.setState({list: account.profileDefinitions, isListLoading: false})
            return;
        }

        if (this.props.endpointName === EndpointNames.COACHES) {
            this.setState({list: account.coaches, isListLoading: false})
            return;
        }

        if (this.props.endpointName === EndpointNames.SPECIALISTS) {
            this.setState({list: account.specialists, isListLoading: false})
            return;
        }

        if (this.props.endpointName === EndpointNames.CONTESTANTS) {
            let userTypeId = this.props.account.coach ? this.props.account.coach.id : this.props.account.specialist.id;
            this.retrieveListData(getConnectedAccountsAPI(
                this.props.authToken as string,
                this.props.userRole === Role.Coach ? EndpointNames.COACHES : EndpointNames.SPECIALISTS, // toDO słabe nie uwzględnia zawodników
                userTypeId,
                pageNumber,
                itemsPerPage
            ));
        }
    };

    private retrieveListData(api: Observable<any>) {
        this.subscriptions.push(
            api.pipe(
                filter(response => !!response),
                tap((resp: any) => {
                        if (resp['hydra:view'] && resp['hydra:view']['hydra:last']) this.setPaginationData(resp['hydra:view']);
                        if (resp['hydra:member']) this.setState({list: resp['hydra:member']})
                        this.setState({isListLoading: false})
                    },
                    catchError((err: any) => {
                        this.setState({isListLoading: false});
                        this.alertManagerService.handleApiError(err);
                        return of();
                    }),
                )).subscribe()
        );
    }

    private setPaginationData(paginationResponse: any) {
        const currentPageNumber = paginationResponse['@id'].split('page=').pop(),
            lastPageNumber = paginationResponse['hydra:last'].split('page=').pop();
        if (!currentPageNumber || !lastPageNumber) {
            return this.setState({paginationData: null});
        }
        this.setState({paginationData: {lastPage: lastPageNumber, currentPage: currentPageNumber}})
    }

    private updateView = (itemId: string) => {
        if (!this.state.list || !this.props.authToken) {
            this.setState({itemView: null});
            return;
        }
        this.props.changeIsUserProfileLoading(true);

        if (this.props.endpointName === EndpointNames.CALENDARS) {

            const profileDefinitionId = itemId,
                calendar = this.state.list.find((calendar: any) => calendar.profileDefinition.id === profileDefinitionId);
            this.retrieveViewData(getBaseViewAPI(
                this.props.authToken,
                this.props.endpointName,
                calendar ? calendar.id : itemId,
                this.props.isArchiveMode
            ));
            return;
        }

        if ((this.props.userRole === Role.Coach || this.props.userRole === Role.Specialist) && (this.props.endpointName === EndpointNames.COACHES || this.props.endpointName === EndpointNames.SPECIALISTS)) {
            let account = this.state.list.find(item => item.id === itemId);
            let coachId = account.coach ? account.coach.id : null;
            let specialistId = account.specialist ? account.specialist.id : null;
            if (typeof account.specialist === "string") {
                specialistId = account.specialist.split('/').pop()
            }
            if (typeof account.coach === "string") {
                coachId = account.coach.split('/').pop()
            }
            const userTypeId = coachId ? coachId : specialistId;
            if (userTypeId !== undefined) {
                return this.subscriptions.push(getConnectedAccountsAPI(
                    this.props.authToken as string,
                    this.props.endpointName,
                    userTypeId,
                    undefined,
                    1000
                    ).pipe(
                    tap((response: any) => {
                            let connectedAccounts = [];
                            if (response && response['hydra:member']) {
                                connectedAccounts = response['hydra:member'];
                            }
                            let itemView = deepCloneObject(account);
                            itemView = getAccountsFromUserItem(itemView)
                            itemView.contestants = connectedAccounts;
                            this.setState(
                                {accountId: response.id, itemView: itemView},
                                () => this.props.changeIsUserProfileLoading(false)
                            )
                        }
                    ),
                    catchError((err: any) => {
                        this.alertManagerService.handleApiError(err);
                        return of();
                    })
                    ).subscribe()
                )
            }
        }


        if (this.props.endpointName === EndpointNames.CLUBS) {
            this.retrieveViewData(getBaseViewAPI(
                this.props.authToken,
                this.props.endpointName,
                itemId,
                this.props.isArchiveMode
            ));
            return;
        }

        if (this.props.endpointName === EndpointNames.COACHES || this.props.endpointName === EndpointNames.SPECIALISTS) {
            return this.retrieveViewDataWithContestants(getAccountAPI(
                this.props.authToken,
                itemId,
                this.props.isArchiveMode
            ))
        }
        this.retrieveViewData(getAccountAPI(
            this.props.authToken,
            itemId,
            this.props.isArchiveMode
        ));
    }

    private retrieveViewData(api: Observable<any>): void {
        this.subscriptions.push(
            api.pipe(
                tap((resp: any) => {
                        return this.setState(
                            {accountId: resp.id, itemView: resp},
                            () => this.props.changeIsUserProfileLoading(false)
                        )
                    }
                ),
                catchError((err: any) => {
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
            ).subscribe()
        );
    }

    private retrieveViewDataWithContestants(api: Observable<any>, account?: any): void {
        let userViewWithContestants = {};
        this.subscriptions.push(
            api.pipe(
                map((response: any) => {
                    if (account) return account;
                    userViewWithContestants = response;
                    this.setState({accountId: response.id});
                    return response;
                }),
                switchMap((response: any) => {
                    if (!response || (!response.coach && !response.specialist)) return of();
                    const userTypeId = response.coach ? response.coach.id : response.specialist.id;

                    return getConnectedAccountsAPI(
                        this.props.authToken as string,
                        this.props.endpointName,
                        userTypeId,
                        undefined,
                        1000
                    ).pipe(
                        map((response: any) => {
                                let connectedAccounts = [];
                                if (response && response['hydra:member']) {
                                    connectedAccounts = response['hydra:member'];
                                }
                                const itemView = Object.assign(userViewWithContestants, {contestants: connectedAccounts});
                                this.setState(
                                    {itemView: itemView},
                                    () => this.props.changeIsUserProfileLoading(false)
                                )
                                return response
                            }
                        ))
                }),
                catchError((err: any) => {
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
            ).subscribe()
        );
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        shouldListUpdate: shouldListUpdateSelector(state),
        shouldViewUpdate: shouldViewUpdateSelector(state),
        userRole: userRoleSelector(state),
        account: userSelector(state),
    }),
    {
        changeIsUserProfileLoading,
        changeShouldListUpdate,
        changeShouldViewUpdate,
    }
)(withRouter(AnimatedLayoutHost));


