import React from "react";
import {IProcess, ProcessMapperService, Translation} from "judo-app-common-web";
import StepView from "./StepView/index";

interface IDailyProfileProps {
    process: typeof IProcess | null;
    profile: {[key: string]: any};
    authToken: string;
    updateProfile: (profile: any) => void
    serverProfileWasUpdated?: (shouldRun: boolean) => void;
}

interface IDailyProfileState {
    process: typeof IProcess | null;
}

class DailyProfile extends React.Component<IDailyProfileProps, IDailyProfileState> {

    constructor(props: IDailyProfileProps) {
        super(props);

        this.state = {
            process: null
        }

    }

    componentDidMount(): void {
        this.setState({process: this.props.process});
    }

    componentDidUpdate(prevProps: Readonly<IDailyProfileProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.process !== prevProps.process) {
            this.setState({process: this.props.process});
        }
    }

    render() {
        return (
            <>
                {(this.state.process &&
                    this.state.process.steps &&
                    Array.isArray(this.state.process.steps) &&
                    this.state.process.steps.length > 0) ?
                    <StepView
                        updateProfile={this.props.updateProfile}
                        process={new ProcessMapperService().map(this.state.process)}
                        // onSubmit={this.submit.bind(this)}
                        profile={this.props.profile}
                        authToken={this.props.authToken}
                        serverProfileWasUpdated={this.props.serverProfileWasUpdated}
                    /> :
                    <div>
                        <Translation text={'editor.topBar.preview.noSteps'}/>
                    </div>
                }
            </>
        )
    }
}

export default DailyProfile;
