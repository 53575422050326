import React from 'react';
import {CustomModal, Form, IBasicFormControlConfig, Translation} from 'judo-app-common-web';
import {CalendarEvent, CurrentFatigueCalendarView} from "../../../../model/models";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {addDevelopmentProfileFormConfig} from "../addDevelopmentProfileFormConfig";
import CalendarEventList from './CalendarEventList';
import EditCalendarEventForm from './EditCalendarEventForm';
import AddCalendarEventForm from "../../../PanelHost/Modals/ModalAddCalendarEvent/AddCalendarEventForm";
import moment from "moment";

interface IAddDevelopmentProfileFormModalProps {
    onClose: () => void;
    onDevelopmentProfileAdd: (fatigueProfile: CurrentFatigueCalendarView) => void;
    createdDevelopmentProfile: CurrentFatigueCalendarView;
}

interface IAddDevelopmentProfileFormModalState {
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    newDevelopmentProfile: CurrentFatigueCalendarView | null;
    currentlyEditedCalendarEvent: CalendarEvent | null;
    currentlyEditedCalendarEventIndex: number | null;
    addCalendarEventCardOpen: boolean;
}

class AddDevelopmentProfileFormModal extends React.Component<IAddDevelopmentProfileFormModalProps, IAddDevelopmentProfileFormModalState> {
    constructor(props: IAddDevelopmentProfileFormModalProps) {
        super(props);

        this.state = {
            newDevelopmentProfile: null,
            formConfig: addDevelopmentProfileFormConfig,
            currentlyEditedCalendarEvent: null,
            currentlyEditedCalendarEventIndex: null,
            addCalendarEventCardOpen: false
        }
    }


    componentDidMount() {
        if (this.props.createdDevelopmentProfile) this.setState({newDevelopmentProfile: this.props.createdDevelopmentProfile});
    }

    componentDidUpdate(prevProps: Readonly<IAddDevelopmentProfileFormModalProps>, prevState: Readonly<IAddDevelopmentProfileFormModalState>) {
        if (this.props.createdDevelopmentProfile !== prevProps.createdDevelopmentProfile) {
            this.setState({newDevelopmentProfile: this.props.createdDevelopmentProfile});
        }
    }

    render() {
        if (!this.state.newDevelopmentProfile) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="add-calendar-event-modal">
                <CustomModal.Header>
                    <h4 className="mr-5">
                        <Translation text="userList.addDevelopmentProfile.form.header"/>
                    </h4>
                </CustomModal.Header>
                <CustomModal.Body>
                    <Form
                        config={this.state.formConfig}
                        submitForm={this.addDevelopmentProfile}
                        controlName={"addDevelopmentProfileForm"}
                        value={{}}
                        onButtonClicked={this.props.onClose}
                    />

                    {this.renderCalendarEventsList()}
                    {/*<div className="col-lg-6">*/}
                    {this.renderAddCalendarEventCard()}
                    {this.renderEditCalendarEventCard()}
                    {/*</div>*/}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private renderCalendarEventsList() {
        if (!this.state.newDevelopmentProfile) {
            return null;
        }
        return <CalendarEventList calendarEvents={this.state.newDevelopmentProfile.calendarEvents}
                                  onCalendarEventsUpdate={this.updateCalendarEventsList}
                                  onCreateCalendarEvent={this.openCreateCard}
                                  onDeleteCalendarEvent={this.deleteCalendarEvent}
                                  onCurrentlyEditedCalendarEvent={this.setEditedCalendarEvent}/>
    }


    private renderAddCalendarEventCard() {
        if (this.state.addCalendarEventCardOpen) {
            return <AddCalendarEventForm onCalendarEventCreation={this.createCalendarEvent}/>
        }
    }

    private renderEditCalendarEventCard() {
        if (this.state.currentlyEditedCalendarEvent && this.state.currentlyEditedCalendarEventIndex !== null) {
            return <EditCalendarEventForm editedCalendarEvent={this.state.currentlyEditedCalendarEvent}
                                          editedCalendarEventIndex={this.state.currentlyEditedCalendarEventIndex}
                                          onCalendarEventUpdate={this.updateCalendarEvent}/>
        }
    }


    private createCalendarEvent = (option: CalendarEvent) => {
        let updatedDevelopmentProfile = deepCloneObject(this.state.newDevelopmentProfile);
        let updatedOption = deepCloneObject(option);
        updatedOption.startsAt = moment(updatedOption.startsAt, 'DD-MM-yyyy').toISOString();

        updatedDevelopmentProfile.calendarEvents?.push(updatedOption);
        this.setState({newDevelopmentProfile: updatedDevelopmentProfile, addCalendarEventCardOpen: false})
    }
    private updateCalendarEvent = (option: CalendarEvent, optionIndex: number) => {
        let updatedDevelopmentProfile = deepCloneObject(this.state.newDevelopmentProfile);

        let updatedOption = deepCloneObject(option);

        updatedOption.startsAt =  moment(updatedOption.startsAt, moment.ISO_8601, true).isValid()  ?
            updatedOption.startsAt :
            moment(updatedOption.startsAt, 'MM-DD-YYYY').toISOString();

        updatedOption.endsAt = moment(updatedOption.endsAt, moment.ISO_8601, true).isValid()  ?
            updatedOption.endsAt :
            moment(updatedOption.endsAt, 'MM-DD-YYYY').toISOString();


        updatedDevelopmentProfile.calendarEvents[optionIndex] = updatedOption;

        this.setState({
            newDevelopmentProfile: updatedDevelopmentProfile,
            currentlyEditedCalendarEvent: null,
            currentlyEditedCalendarEventIndex: null
        })
    }
    private deleteCalendarEvent = (optionIndex: number) => {
        let updatedDevelopmentProfile = deepCloneObject(this.state.newDevelopmentProfile);
        updatedDevelopmentProfile.calendarEvents.splice(optionIndex, 1);
        if (this.state.currentlyEditedCalendarEventIndex === optionIndex) {
            this.setState({currentlyEditedCalendarEvent: null, currentlyEditedCalendarEventIndex: null});
        }
        this.setState({newDevelopmentProfile: updatedDevelopmentProfile})
    }

    private updateCalendarEventsList = (optionList: CalendarEvent[]) => {
        let updatedDevelopmentProfile = deepCloneObject(this.state.newDevelopmentProfile);
        updatedDevelopmentProfile.calendarEvents = optionList;
        this.setState({newDevelopmentProfile: updatedDevelopmentProfile})
    }

    private openCreateCard = () => {
        this.setState({addCalendarEventCardOpen: true, currentlyEditedCalendarEvent: null, currentlyEditedCalendarEventIndex: null})
    }

    private setEditedCalendarEvent = (option: CalendarEvent, index: number) => {
        this.setState({currentlyEditedCalendarEvent: option, currentlyEditedCalendarEventIndex: index, addCalendarEventCardOpen: false});
    }


    private addDevelopmentProfile = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        if (!value.name || !this.props.createdDevelopmentProfile || !this.state.newDevelopmentProfile) {
            return;
        }
        const addedDevelopmentProfile: CurrentFatigueCalendarView = {
            id: ``,
            name: value.name,
            calendarViewGroups: [],
            calendarEvents: this.state.newDevelopmentProfile.calendarEvents
        };
        this.props.onDevelopmentProfileAdd(addedDevelopmentProfile);
        this.props.onClose();
    };
}


export default AddDevelopmentProfileFormModal;

