import React from "react";
import {Form, IBaseFormConfig} from "judo-app-common-web";
import datepickerTypeSelectFormConfig from "./datepickerTypeSelectFormConfig";

interface IDatepickerTypeSelectFormProps {
    initialValue?: any;
    setType: (type: string) => void;
}

interface IDatepickerTypeSelectFormState {
    readonly control: typeof IBaseFormConfig | null;
    value: any;
    isFormValid: boolean;
}

class DatepickerTypeSelectForm extends React.Component<IDatepickerTypeSelectFormProps, IDatepickerTypeSelectFormState> {

    state = {
        control: null,
        value: null,
        isFormValid: true,
    };

    render() {
        return (
            <div>
                <Form
                    config={datepickerTypeSelectFormConfig(this.props.initialValue)}
                    controlName={"datepickerTypeForm"}
                    onValueStateChange={(controlName: string, data: any) => this.onValueStateChange(controlName, data)}
                    value={{}}
                />
            </div>)

    }

    private onValueStateChange = (controlName: string, value: any,) => {
        this.setState({
            value
        }, () => this.props.setType(value?.['datepickerType']));
    };

}

export default DatepickerTypeSelectForm;
