import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function unassignProfileDefinitionAPI(
    authToken: string,
    chosenAccountId: string,
    profileDefinitionId: string
): Observable<any> {
    return baseDataProviderAPI.put(
        `accounts/${chosenAccountId}/remove_profile_definition`,
        {profileDefinitionId: profileDefinitionId},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
