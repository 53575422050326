import React from 'react';
import AnimatedLayoutHost, {EndpointNames} from "../Shared/AnimatedLayoutHost";
import ContestantList from "./ContestantList";
import ContestantView from "./ContestantView";

interface IContestantsProps {
    isArchiveMode: boolean;
}

class Contestants extends React.Component<IContestantsProps> {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <AnimatedLayoutHost host={"contestants"}
                                            listComponent={ContestantList}
                                            viewComponent={ContestantView}
                                            endpointName={EndpointNames.CONTESTANTS}
                                            isArchiveMode={this.props.isArchiveMode}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default Contestants;

