import React from 'react';
import {authTokenSelector, CustomModal, Loader, LoaderType, setAuthState, Translation} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {ModalConfig, UserType} from "../../../../model/models";
import {changeModal, changeShouldListUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {catchError, tap} from "rxjs/operators";
import {Observable, of, Subscription, forkJoin} from "rxjs";
import {unassignAccountFromAccountAPI} from "../../../../api/unassignAccountFromAccount";
import {unassignProfileDefinitionAPI} from "../../../../api/unassignProfileDefinition";

interface IModalUnassignProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalUnassignState {
    hostAccountId: string;
    unassignedAccountId: string;
    profileDefinitionArray?: string[];
    isProcessing: boolean;
}

class ModalUnassign extends React.Component<IModalUnassignProps, IModalUnassignState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalUnassignProps) {
        super(props);
        this.state = {
            hostAccountId: '',
            profileDefinitionArray: [],
            unassignedAccountId: '',
            isProcessing: true
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.id && this.props.modal.data.hostAccountId) {
            this.setState({
                hostAccountId: this.props.modal.id,
                profileDefinitionArray: this.props.modal.multipleIds,
                unassignedAccountId: this.props.modal.data.hostAccountId,
                isProcessing: false
            })
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.hostAccountId) {
            return null;
        }
        let modalQuestion = 'modal.unassignAccount.question';
        if (this.props.modal && this.props.modal.role === UserType.FATIGUE_PROFILE) {
            modalQuestion = 'modal.unassignAccount.profileQuestion'
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class='assign-profile-modal'>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={'modal.unassignAccount.title'}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <p>
                        <Translation text={modalQuestion}/>
                    </p>
                </CustomModal.Body>
                <CustomModal.Footer>
                    <button className="btn btn-theme btn-small ml-3" onClick={() => this.props.changeModal(null)}>
                        <span>
                                <Translation text={'button.cancel'}/>
                            </span>
                    </button>
                    <button className="btn btn-theme btn-small ml-3" onClick={this.unassignAccount}>
                        <span>
                                <Translation text={'button.unassign'}/>
                            </span>
                    </button>
                </CustomModal.Footer>
            </CustomModal>
        );
    }

    private unassignAccount = () => {
        if (!this.state.hostAccountId || !this.state.unassignedAccountId || !this.props.authToken) {
            return null;
        }

        this.setState({isProcessing: true});
        let successMessage = 'modal.unassignAccount.unassignSuccess';
        if (this.props.modal && this.props.modal.role === UserType.FATIGUE_PROFILE && this.state.profileDefinitionArray?.length) {
            successMessage = 'modal.unassignAccount.unassignFatigueProfileSuccess';
            const requestArray = this.state.profileDefinitionArray.map((profileDefinitionId: string) => unassignProfileDefinitionAPI(this.props.authToken, this.state.unassignedAccountId,  profileDefinitionId), successMessage),
                request = forkJoin(requestArray);
            return this.unassignFromAccount(request, successMessage);
        }
        return this.unassignFromAccount(unassignAccountFromAccountAPI(this.props.authToken, this.state.unassignedAccountId,  this.state.hostAccountId), successMessage);
    }

    private unassignFromAccount(api: Observable<any>, message: string): void {
        this.subscriptions.push(
            api.pipe(
                tap(() => {
                        this.alertManagerService.addAlert(message);
                        this.setState({isProcessing: false});
                        this.props.changeShouldListUpdate(true);
                        this.props.changeModal(null);
                    }
                ),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error.response);
                    this.setState({isProcessing: false});
                    return of();
                })
            ).subscribe()
        );
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldListUpdate,
        setAuthState,
    }
)(ModalUnassign);
