import React from "react";
import {CircularGridLines, Hint, RadarChart as RadarChartGraph} from "react-vis";
import {Translation} from "judo-app-common-web";
import {GraphInput, GraphParameter} from "../index";
import {format} from "d3-format";
import {CalendarInputOption, InputType} from "../../../../model/models";

interface GraphDataBase {
    name: string;
    [series: string]: number | string;
}

interface Props {
    selectedParameters: GraphParameter[];
    selectedProfile: GraphInput[];
    selectedDate: string;
}

interface State {
    hoveredCell: any;
}


const wideFormat = format('.3r');

const tipStyle = {
    display: 'flex',
    color: '#fff',
    background: '#000',
    alignItems: 'center',
    padding: '5px'
};

class RadarChart extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props);
        this.state={
            hoveredCell: null
        }
    }

    render() {
        return (
            <>
                {(this.props.selectedProfile.length > 0 && this.props.selectedParameters.length > 0) ?
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <RadarChartGraph
                        onValueMouseOver={(v: GraphInput, c: any ) => {
                            this.setState({hoveredCell: v});
                        }}
                        onValueMouseOut={(v: GraphInput) => this.setState({hoveredCell: null})}
                        domains={this.domains}
                        width={450}
                        height={450}
                        tickFormat={t => wideFormat(t)}
                        startingAngle={15}
                        data={this.dataToGraph()}>
                        <CircularGridLines
                            tickValues={[...new Array(11)].map((v, i) => i / 10 - 1)}
                        />
                        {this.state.hoveredCell && (
                            <Hint value={this.state.hoveredCell}>
                                <div style={tipStyle}>
                                    {this.state.hoveredCell.domain}: {this.state.hoveredCell.value}
                                </div>
                            </Hint>
                        )}
                    </RadarChartGraph>
                </div>
                :
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <Translation text={'profiles.modal.chart.noDataToGraph'}/>
                </div>
                }
            </>
        )
    }

    private get domains() {
        //todo min/max domain and maybe tick format?
        const domains = this.props.selectedParameters.map((parameter, index) => ({
            name: parameter.name,
            domain: parameter.parameter.inputDefinition?.type === 'select' ? this.selectScale(parameter.parameter.valuesVisualOptions) : [5*index, 35*(index+1)],
            getValue: (d:any) => typeof d[parameter.name] === "object" ? d[parameter.name].value : d[parameter.name],
        }))

        if(domains.length === 1) {
            domains.push({
                name: '',
                domain: domains[0].domain,
                getValue: () => null,
            })
        }
        return domains
    }

    private selectScale(data: CalendarInputOption[]): [number, number] {
        const valuesArray: number[] = data.map(el => (el.value && !isNaN(+el.value)) ? +el.value : 0)
        return [0, Math.max(...valuesArray)]
    }

    private dataToGraph() {
        let dataToGenerateGraph: GraphDataBase = { name:  this.props.selectedDate}
        if(this.props.selectedProfile && this.props.selectedParameters) {
            this.props.selectedParameters.forEach(parameter => {

                const result = this.props.selectedProfile.find(input => input?.inputDefinitionId === parameter.id)
                if(result) {
                    const value = result?.input?.inputResultValue.value
                    const graphInputValue = result.input.inputType === InputType.SELECT && result.input.inputParameters.selectValues ?
                        result.input.inputParameters.selectValues.find((option: any) => option.value === value) : value
                    dataToGenerateGraph[result?.input?.inputName] =  graphInputValue || 0;
                }
            })
        }

        return [dataToGenerateGraph]
    }
}

export default RadarChart;
