import React from 'react';
import {authTokenSelector, CustomCard, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {of, Subscription} from 'rxjs';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {MatchProcessParams} from "../PanelHost";
import {IFormConfigConverterService} from "../../service/formConfigConverter";
import {ModalType, UserType} from "../../model/models";
import {connect} from "react-redux";
import {changeModal, changeShouldListUpdate} from "../../store/reducers/sagaSlice";
import {getSpecialistTypesAPI} from "../../api/getSpecialistTypes";
import {catchError, map} from "rxjs/operators";
import {RootState} from "../../store/reducers";
import {shouldListUpdateSelector} from "../../store/selectors/sagaSelectors";
import TooltipWrapper from "../Shared/TooltipWrapper";

interface IConnectedSpecialistTypesProps {
    readonly authToken: string;
    readonly shouldListUpdate: boolean;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface ISpecialistTypesProps extends IConnectedSpecialistTypesProps,
    RouteComponentProps<MatchProcessParams> {
}

interface ISpecialistTypesState {
    specialistTypes: any; // toDo interface
    isLoading: boolean;
}

class SpecialistTypes extends React.Component<ISpecialistTypesProps, ISpecialistTypesState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('FormConfigConverterService') private formConfigConverterService: IFormConfigConverterService;
    private subscriptions: Subscription[] = [];

    constructor(props: ISpecialistTypesProps) {
        super(props);
        this.state = {
            specialistTypes: null,
            isLoading: true
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getSpecialistTypes();
    }

    componentDidUpdate(
        prevProps: Readonly<ISpecialistTypesProps>,
        prevState: Readonly<ISpecialistTypesState>,
        snapshot?: any): void {
        if (this.props.shouldListUpdate !== prevProps.shouldListUpdate &&
            this.props.shouldListUpdate) {
            this.setState({isLoading: true});
            this.getSpecialistTypes();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.state.isLoading}>
                <CustomCard.Body>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-theme btn-small"
                                onClick={this.openCreateTypeModal}>
                            <span aria-hidden="true" className="feather icon-plus"/>
                            <Translation text={'userList.table.button.add.specialistType'}/>
                        </button>
                    </div>
                    {this.renderTable()}
                </CustomCard.Body>
            </CustomCard>
        )
    }

    private renderTable() {
        if (!this.state.specialistTypes) {
            return;
        }

        if (!this.state.specialistTypes.length) {
            return (
                <div className="d-flex justify-content-center display-4">
                    <span>
                        <Translation text={'userList.table.noData.noSpecialistTypes'}/>
                    </span>
                </div>
            );
        }

        const rows: any = [];

        this.state.specialistTypes.forEach((type: any) => rows.push(
            <tr key={type.id}>
                <td>
                    <p className="specialist-type-name">{type.name}</p>
                </td>
                <td className="text-right">
                    <TooltipWrapper tooltip={{text: `userList.table.button.remove.specialistType`}}
                                    key={'remove-specialist-type-button'}>
                        <button className="btn btn-list delete ml-3" disabled={!type.deletable}
                                onClick={() => this.openRemoveFromListModal(type)}>
                            <span className="feather icon-trash" aria-hidden="true"/>
                            <span><Translation text={`userList.table.button.remove.specialistType`}/></span>
                        </button>
                    </TooltipWrapper>
                </td>
            </tr>
        ));

        return (
            (
                <div className="mt-20">
                    <table className="data-table">
                        <thead>
                        <tr>
                            <th><Translation text={'userList.table.header.name'}/></th>
                            <th className="text-right">
                                    <span className="sr-only">
                                        <Translation text={'userList.table.header.actions'}/>
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            )
        )
    }

    private openRemoveFromListModal = (type: any) => {
        this.props.changeModal({
            type: ModalType.REMOVE,
            id: type.id,
            data: type,
            role: UserType.SPECIALIST_TYPE
        })
    };

    private openCreateTypeModal = () => {
        this.props.changeModal({
            type: ModalType.CREATE,
            role: UserType.SPECIALIST_TYPE,
        })
    };

    private getSpecialistTypes = () => {
        return this.subscriptions.push(
            getSpecialistTypesAPI(this.props.authToken).pipe(
                map((response: any) => {
                    if (response && response['hydra:member']) {
                        this.props.changeShouldListUpdate(false);
                        return this.setState({
                            specialistTypes: response['hydra:member'],
                            isLoading: false
                        })
                    }
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe())
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        shouldListUpdate: shouldListUpdateSelector(state)
    }),
    {
        changeModal,
        changeShouldListUpdate
    }
)(withRouter(SpecialistTypes));
