import React from 'react';
import {IAlertManagerService} from '../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {Subscription} from 'rxjs';
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import {ModalType, ProfileType} from "../../model/models";
import {MatchProcessParams} from "../PanelHost";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {Translation} from 'judo-app-common-web';
import Contestants from "../Contestants";
import Specialists from "../Specialists";
import Coaches from '../Coaches';
import Clubs from '../Clubs';
import {EndpointNames} from "../Shared/AnimatedLayoutHost";

interface IFatigueProfilesViewProps extends RouteComponentProps<MatchProcessParams> {
}

interface IFatigueProfilesViewState {
    archiveType: ModalType | ProfileType | EndpointNames | null;
}

class FatigueProfilesView extends React.Component<IFatigueProfilesViewProps, IFatigueProfilesViewState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscription: Subscription | null;

    constructor(props: IFatigueProfilesViewProps) {
        super(props);
        this.state = {
            archiveType: null
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.match.params.type) this.setState({archiveType: this.props.match.params.type})
    }

    componentDidUpdate(prevProps: Readonly<IFatigueProfilesViewProps>, prevState: Readonly<IFatigueProfilesViewState>) {
        if (this.props.match.params && prevProps.match.params.type !== this.props.match.params.type) {
            if (this.props.match.params.type) {
                this.setState({archiveType: this.props.match.params.type})
            }
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return <React.Fragment>
            <div className="row">
                <nav className="navigation-tabs">
                    <ul className="navigation-tab-list">
                        <li className="navigation-tab-item">
                            <NavLink className="navigation-tab-item-link" to="/archive/contestants">
                                <Translation text={'archive.tabs.contestants.list.header'}/>
                            </NavLink>
                        </li>
                        <li className="navigation-tab-item">
                            <NavLink className="navigation-tab-item-link" to="/archive/coaches">
                                <Translation text={'archive.tabs.coaches.list.header'}/>
                            </NavLink>
                        </li>
                        <li className="navigation-tab-item">
                            <NavLink className="navigation-tab-item-link" to="/archive/specialists">
                                <Translation text={'archive.tabs.specialists.list.header'}/>
                            </NavLink>
                        </li>
                        <li className="navigation-tab-item">
                            <NavLink className="navigation-tab-item-link" to="/archive/clubs">
                                <Translation text={'archive.tabs.clubs.list.header'}/>
                            </NavLink>
                        </li>
                        {/*<li className="navigation-tab-item">*/}
                        {/*    <NavLink className="navigation-tab-item-link" to="/archive/profile">*/}
                        {/*        <Translation text={'archive.tabs.profiles.list.header'}/>*/}
                        {/*    </NavLink> */}
                        {/* </li>*/}

                    </ul>
                </nav>
            </div>
            {this.renderCurrentView()}
        </React.Fragment>
    }

    private renderCurrentView() {

        if (this.state.archiveType) {
            switch (this.state.archiveType) {

                case EndpointNames.CONTESTANTS:
                    return <Contestants isArchiveMode={true}/>;
                case EndpointNames.SPECIALISTS:
                    return <Specialists isArchiveMode={true}/>;
                case EndpointNames.COACHES:
                    return <Coaches isArchiveMode={true}/>;
                case EndpointNames.CLUBS:
                    return <Clubs isArchiveMode={true}/>;
                // case ArchiveType.Profile:
                //     return <FatigueProfiles isArchiveMode={true}/>;

                default:
                    return null;
            }
        }
        return null;
    }
}

export default connect(
    (state: RootState) => ({}),
    {}
)(withRouter(FatigueProfilesView));
