import {Observable} from "redux";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function addSpecialistTypeAPI(
    authToken: string,
    name: string,
): Observable<any> {
    return baseDataProviderAPI.post(
        "specialist_types",
        {
            name: name
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
