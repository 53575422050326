import {BasicFormControlType, FormButtonType, IFormConfig, InputType, ValidationRules} from "judo-app-common-web";
import {IAddCalendarEventFormValue} from "./index";

export const addCalendarEventFormConfig: typeof IFormConfig = (data: IAddCalendarEventFormValue = {}) => ({
    controlType: 'form',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'new_option',
            controlType: 'group',
            class: 'd-flex flex-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED },
                        {name: ValidationRules.MIN_LENGTH, params: {length: 1}}
                    ],
                    placeholder: 'profileSettingsModal.form.addCalendarEventForm.name.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addCalendarEventForm.name.label',
                    type: InputType.INPUT_TEXT,
                    hostClass: ''
                },
                startsAt: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.DATE,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                    ],
                    maxDate: data['endsAt'],
                    placeholder: 'profileSettingsModal.form.addCalendarEventForm.startsAt.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addCalendarEventForm.startsAt.label',
                    hostClass: 'ml-4',
                    dateFormat: "dd-MM-yyyy"
                }
            }
        },
        {
            key: 'buttons',
            controlType: 'group',
            class: 'button-group-wrapper mt-5',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'button-container',
                    defaultContainerStyles: 'd-flex justify-content-end',
                    defaultInputStyles: 'btn btn-theme btn-small',
                    defaultValue: null,
                    btnText: `button.create`,
                    isLabelHidden: true,
                }
            }
        }
    ]
});

