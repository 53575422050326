import React from 'react';
import {Link, Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    CustomCard,
    Form,
    getLoginCredentials,
    IBasicFormControlConfig,
    ILoginProps,
    ILoginState,
    isAuthenticatedSelector,
    loginLoadingSelector,
    Translation
} from "judo-app-common-web";
import {loginFormConfig} from './loginFormConfig';

interface ILoginProps extends RouteComponentProps {
    isLoading: boolean;
    isAuthenticated: boolean;
    getLoginCredentials: typeof getLoginCredentials;

}

interface ILoginState {
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[],
    email: { value: any, isValid: boolean },
    password: { value: any, isValid: boolean },
}

class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);

        this.state = {
            email: {value: null, isValid: false},
            password: {value: null, isValid: false},
            formConfig: loginFormConfig,
        };
    }

    componentDidMount(): void {}

    componentDidUpdate(): void {}

    render() {
        if (this.props.isAuthenticated) {
            return (<Redirect push to={'/'} />);
        }

        return (
            <CustomCard showLocalLoader={this.props.isLoading} type={"auth"} >
                <CustomCard.Header>
                    <h3><Translation text={'auth.login.title'}/></h3>
                </CustomCard.Header>
                <CustomCard.Body>
                    <Form config={this.state.formConfig}
                          submitForm={this.login}
                          controlName={"loginForm"}
                          value={{}}/>

                    <p className="mb-2 text-muted">
                        <Link className="f-w-400" to="/auth/reset-password">
                            <Translation text={'auth.forgotPassword'}/>
                        </Link>
                    </p>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private login =(event: any, value: any,) => {
        if (!value.email || !value.password) {
            return;
        }
        this.props.getLoginCredentials(
            value.email,
            value.password
        );
    };
}

export default connect(
    (state: any) => ({
        isLoading: loginLoadingSelector(state),
        isAuthenticated: isAuthenticatedSelector(state)
    }),
    {
        getLoginCredentials
    }
)(withRouter(Login));
