import React from 'react';
import {Translation} from 'judo-app-common-web';
import {CalendarEvent} from "../../../../../model/models";
import CalendarEventListItem from './CalendarEventListItem';
import {arrayMove, IItemProps, List} from "react-movable";

export interface DragDropOption {
    id: number;
    calendarEvent: CalendarEvent;
}

interface ICalendarEventListProps {
    onCalendarEventsUpdate: (calendarEvents: CalendarEvent[]) => void;
    onCurrentlyEditedCalendarEvent: (currentlyEditedCalendarEvent: CalendarEvent, index: number) => void;
    onCreateCalendarEvent: () => void;
    onDeleteCalendarEvent: (itemIndex: number) => void;
    calendarEvents: CalendarEvent[];
}

interface ICalendarEventListState {
    newCalendarEvents: CalendarEvent[];
    items: DragDropOption[];
    isProcessing: boolean;
    value: any;
}

class CalendarEventList extends React.Component<ICalendarEventListProps, ICalendarEventListState> {
    constructor(props: ICalendarEventListProps) {
        super(props);

        this.state = {
            newCalendarEvents: [],
            items: [],
            isProcessing: false,
            value: {},
        }
    }

    private static getDataListFromGroup(calendarEventList: CalendarEvent[]): DragDropOption[] {
        return calendarEventList.map((datalistItem: CalendarEvent, i: number) => {
            return {id: i, calendarEvent: datalistItem}
        });
    }

    componentDidMount() {
        if (this.props.calendarEvents) {
            const dataItems: DragDropOption[] = CalendarEventList.getDataListFromGroup(this.props.calendarEvents);
            this.setState({items: dataItems})
        }
    }

    componentDidUpdate(prevProps: Readonly<ICalendarEventListProps>, prevState: Readonly<ICalendarEventListState>) {
        if (this.props.calendarEvents !== prevProps.calendarEvents) {
            const dataItems: DragDropOption[] = CalendarEventList.getDataListFromGroup(this.props.calendarEvents);
            this.setState({items: dataItems})
        }
    }

    render() {
        return <div className="user-connection-list-wrapper">
            {this.renderCalendarEventsList(this.state.items)}
        </div>
    }

    private renderCalendarEventsList(group: DragDropOption[]) {
        if (group) {
            return <React.Fragment>
                <h4 className="user-connection-list-header fatigue-header">
                    <span>
                        <Translation text={'profileSettingsModal.calendarEventList.header'}/>
                    </span>
                    <button className="btn btn-theme btn-small ml-3"
                            onClick={() => this.createCalendarEvent()}>
                        <span className="feather icon-plus" aria-hidden="true"/>
                        <span><Translation text={'profileSettingsModal.button.add.calendarEvent'}/></span>
                    </button>
                </h4>
                <div className="separator"/>
                {this.renderListItems()}
            </React.Fragment>
        }
    }

    private renderListItems() {
        if (this.state.items) {
            const itemList = this.state.items;
            // const removeCalendarEvent = (index: number | undefined) => {
            //     if (index !== undefined) {
                    // const groupId = this.state.items[index].option.id;
                    // this.props.onGroupDisabled(groupId, false);
            //     }
            // };

            const liStyles = (props: IItemProps, isDragged: boolean, isSelected: boolean) => {

                return {
                    ...props.style,
                    cursor: isDragged ? 'grabbing' : 'grab',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem 1rem',
                    listStyle: 'none',
                    fontSize: '1.6rem',
                    fontFamily: `'Nunito', sans-serif`,
                    borderRadius: '5px',
                    verticalAlign: 'middle',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF'
                }
            }
            if (!this.state.items.length) {
                return <div className="no-data">
                    <Translation text={'profileSettingsModal.calendarEventList.noData'}/>
                </div>
            }
            return <List
                lockVertically
                values={itemList}
                onChange={({oldIndex, newIndex}) => {
                    const updatedList = arrayMove(itemList, oldIndex, newIndex);
                    this.listOrderChange(updatedList)
                }
                }
                renderList={({children, props}) => {
                    return <ul className="drag-and-drop-list" {...props}>{children}</ul>
                }}
                renderItem={({value, props, index, isDragged, isSelected}) => {
                    return <li {...props} className="drag-and-drop-list-item fatigue-group-item"
                               style={liStyles(props, isDragged, isSelected)}>
                        <CalendarEventListItem listItem={value.calendarEvent}
                                               listItemIndex={value.id}
                                               key={value.calendarEvent?.name}
                                               onDeleteItem={this.deleteCalendarEvent}
                                               onUpdateItem={this.updateCalendarEvent}
                                               onCreateItem={this.createCalendarEvent}/>
                    </li>
                }}
            />
        }
        return null;
    }

    private listOrderChange(items: DragDropOption[]) {
        const updatedEnabledOptions = items.map((groupItem: DragDropOption) => groupItem.calendarEvent);
        this.props.onCalendarEventsUpdate(updatedEnabledOptions);
    }

    private deleteCalendarEvent = (index: number) => {
        this.props.onDeleteCalendarEvent(index);
    }

    private updateCalendarEvent = (editedCalendarEvent: CalendarEvent, index: number) => {
        this.props.onCurrentlyEditedCalendarEvent(editedCalendarEvent, index);
    }

    private createCalendarEvent = () => {
        this.props.onCreateCalendarEvent();
    }

}

export default CalendarEventList;
