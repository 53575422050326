import React from 'react';
import {
    CustomCard,
    CustomModal,
    Form,
    IFormConfig,
    ProcessVariable as GroupVariable,
    Translation
} from 'judo-app-common-web';
import {CalendarInput, CalendarInputOption, InputType} from "../../../../model/models";
import {updateInputFormConfig} from "./updateInputFormConfig";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import SelectOptionsList from "../ModalAddInput/SelectOptionsList";
import AddOptionForm from "../ModalAddInput/AddOptionForm";
import EditOptionForm from "../ModalAddInput/EditOptionForm";
import AddCalculatedFieldForm from "../ModalAddInput/AddCalculatedFieldForm";
import DatepickerTypeSelectForm from "../ModalAddInput/DatepickerTypeSelectForm";

interface IModalUpdateCalendarVariableProps {
    onClose: () => void;
    onVariableUpdate: (variable: CalendarInput) => void;
    editedVariable: CalendarInput;
    groupName?: string;
    variables: typeof GroupVariable[];
}

interface IModalUpdateCalendarVariableState {
    editedVariable: CalendarInput | null;
    formConfig: typeof IFormConfig;
    addInputModalOpened: boolean;
    currentlyEditedOption: CalendarInputOption | null;
    currentlyEditedOptionIndex: number | null;
    addOptionCardOpen: boolean;
    inputType: string | undefined;
}

class ModalUpdateCalendarVariable extends React.Component<IModalUpdateCalendarVariableProps, IModalUpdateCalendarVariableState> {
    constructor(props: IModalUpdateCalendarVariableProps) {
        super(props);

        this.state = {
            editedVariable: null,
            formConfig: updateInputFormConfig,
            addInputModalOpened: false,
            currentlyEditedOption: null,
            currentlyEditedOptionIndex: null,
            addOptionCardOpen: false,
            inputType: undefined
        }
    }

    componentDidMount() {
        if (this.props.editedVariable) this.setState({editedVariable: this.props.editedVariable});
        const updatedFormConfig = this.state.formConfig;
        updatedFormConfig.controls[0].controls.name.value = this.props.editedVariable.name;
        updatedFormConfig.controls[0].controls.name.defaultValue = this.props.editedVariable.name;
        updatedFormConfig.controls[0].controls.inputType.defaultValue = this.props.editedVariable.inputDefinition?.type;
        updatedFormConfig.controls[0].controls.inputType.disabled = true;
        updatedFormConfig.controls[0].controls.enabled.defaultValue = this.props.editedVariable.enabled;
        this.setState({formConfig: updatedFormConfig, inputType: this.props.editedVariable.inputDefinition?.type});
    }

    render() {
        if (!this.state.editedVariable) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="add-input-modal">
                <CustomModal.Header>
                    <h2>
                        <Translation text={'profileSettingsModal.form.updateInputForm.header'}/>
                        {this.props.groupName &&
                        <span>{`- ${this.props.groupName}`}</span>
                        }
                    </h2>
                </CustomModal.Header>
                <CustomModal.Body>
                    <div className="row">
                        <div className="col">
                            <CustomCard>
                                <div className="user-connection-list-wrapper">
                                    <h4 className="sr-only">
                                        <Translation text="userView.variableGroup.modal.updateInputForm.header"/>
                                    </h4>
                                    <Form
                                        config={this.state.formConfig}
                                        submitForm={this.updateInput}
                                        controlName={"updateInputForm"}
                                        onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                                        value={{}}
                                        onButtonClicked={this.props.onClose}
                                    />
                                    <div className="d-flex justify-content-end button-wrapper mb-5">
                                        <button className="btn btn-theme-outline btn-small "
                                                disabled={false}
                                                onClick={this.props.onClose}>
                                            <span><Translation text={'button.cancel'}/></span>
                                        </button>
                                        <button className="btn btn-theme btn-small ml-3"
                                                disabled={this.setDisabledButton()}
                                                onClick={this.updateInput}>
                                            <span className="feather icon-check" aria-hidden="true"/>
                                            <span><Translation text={'userList.table.button.edit.variable'}/></span>
                                        </button>
                                    </div>
                                    {this.renderSelectOptionsList()}
                                </div>
                            </CustomCard>
                        </div>
                        {this.renderAddOptionCard()}
                        {this.renderEditOptionCard()}
                        {this.renderExpressionEditor()}
                        {this.renderDatepickerTypeSelector()}
                    </div>
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private renderDatepickerTypeSelector() {
        if(this.state.editedVariable?.inputDefinition?.type !== InputType.INPUT_DATE) {
            return null
        }

        return <div className="col">
                    <DatepickerTypeSelectForm
                        setType={(type:string) => this.setType(type)}
                        initialValue={this.state.editedVariable?.inputDefinition?.parameters?.type}/>
                </div>
    }


    private renderExpressionEditor() {
        if(this.state.editedVariable?.inputDefinition?.type !== InputType.CALCULATED) {
            return null
        }

        return <AddCalculatedFieldForm
            initialValue={this.state.editedVariable.inputDefinition.parameters?.expression}
            confirmFunction={(formula: any) => this.setFormula(formula)}
            variables={this.variablesWithoutSelectedControl}
        />
    }

    private get variablesWithoutSelectedControl() {
        return this.props.variables.filter(variable => !variable.path.includes(this.state?.editedVariable?.inputDefinition?.id))
    }

    private setFormula(formula: any) {
        let updatedInput: CalendarInput = deepCloneObject(this.state.editedVariable);
        updatedInput.parameters = {expression: formula};
        this.setState({editedVariable: updatedInput});
    }

    private renderAddOptionCard() {
        if (this.state.addOptionCardOpen) {
            return <div className="col">
                        <AddOptionForm onOptionCreation={this.createOption}/>
                    </div>
        }
    }

    private renderEditOptionCard() {
        if (this.state.currentlyEditedOption && this.state.currentlyEditedOptionIndex !== null) {
            return <div className="col">
                        <EditOptionForm editedOption={this.state.currentlyEditedOption}
                                       editedOptionIndex={this.state.currentlyEditedOptionIndex}
                                       onOptionUpdate={this.updateOption}/>
                    </div>
        }
    }

    private renderSelectOptionsList() {
        if (this.state.inputType !== InputType.SELECT || !this.state.editedVariable?.valuesVisualOptions) {
            return null;
        }

        return <div className="col">
                    <SelectOptionsList options={this.state.editedVariable.valuesVisualOptions}
                                      onOptionsUpdate={this.updateOptionsList}
                                      onCreateOption={this.openCreateCard}
                                      onDeleteOption={this.deleteOption}
                                      onCurrentlyEditedOption={this.setEditedOption}/>;
                </div>
    }

    private setType(type: string) {
        let updatedInput: CalendarInput = deepCloneObject(this.state.editedVariable);
        updatedInput.parameters = {type: type};
        this.setState({editedVariable: updatedInput});
    }

    private setDisabledButton() {
        if(!this.state.editedVariable || this.state.editedVariable.type === null) {
            return true
        }

        if((this.state.editedVariable?.name !== this.props.editedVariable?.name &&
            this.state.editedVariable?.name.length > 0) ||
            this.state.editedVariable?.enabled !== this.props.editedVariable?.enabled) {
            return false
        }

        if(this.state.editedVariable.type === InputType.CALCULATED) {
            return !this.state.editedVariable?.parameters?.expression ||
                this.state.editedVariable?.parameters?.expression === this.props.editedVariable?.parameters?.expression
        }

        if(this.state.editedVariable.type === InputType.SELECT) {
            return this.state.editedVariable?.valuesVisualOptions?.length === 0
        }

        return true
    }

    private updateFormData(controlName: string, data: any) {
        let updatedInput: CalendarInput = deepCloneObject(this.state.editedVariable);
        if (data) {
            updatedInput.name = data.name;
            updatedInput.enabled = data.enabled;
            updatedInput.type = data.inputType;

        }
        this.setState({editedVariable: updatedInput, inputType: data.inputType});
    }

    private updateInput = () => {
        if (!this.state.editedVariable) {
            return;
        }
        const updatedInput: CalendarInput = this.state.editedVariable;
        this.props.onVariableUpdate(updatedInput);
        this.props.onClose();
    };

    private createOption = (option: CalendarInputOption) => {
        let updatedInput = deepCloneObject(this.state.editedVariable);
        updatedInput.valuesVisualOptions?.push(option);
        this.setState({editedVariable: updatedInput, addOptionCardOpen: false})
    }
    private updateOption = (option: CalendarInputOption, optionIndex: number) => {
        let updatedInput = deepCloneObject(this.state.editedVariable);
        updatedInput.valuesVisualOptions[optionIndex] = option;
        this.setState({editedVariable: updatedInput, currentlyEditedOption: null, currentlyEditedOptionIndex: null})
    }

    private deleteOption = (optionIndex: number) => {
        let updatedInput = deepCloneObject(this.state.editedVariable);
        updatedInput.valuesVisualOptions.splice(optionIndex, 1);
        this.setState({editedVariable: updatedInput})
    }

    private updateOptionsList = (optionList: CalendarInputOption[]) => {
        let updatedInput = deepCloneObject(this.state.editedVariable);
        updatedInput.valuesVisualOptions = optionList;
        this.setState({editedVariable: updatedInput})
    }

    private openCreateCard = () => {
        this.setState({addOptionCardOpen: true})
    }

    private setEditedOption = (option: CalendarInputOption, index: number) => {
        this.setState({currentlyEditedOption: option, currentlyEditedOptionIndex: index});
    }

}


export default ModalUpdateCalendarVariable;

