import React from 'react';
import {authTokenSelector, CustomModal, Loader, LoaderType, setAuthState, Translation} from 'judo-app-common-web';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {ModalConfig, UserType} from "../../../../model/models";
import {changeModal, changeShouldListUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {of, Subscription} from "rxjs";
import {EndpointNames} from "../../../Shared/AnimatedLayoutHost";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {catchError, tap} from "rxjs/operators";
import {restoreProfileAPI} from "../../../../api/restoreProfile";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface IModalRestoreArchivedProfileProps extends RouteComponentProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalRestoreArchivedProfileState {
    profile: any;
    isProcessing: boolean;
}

class ModalRestoreArchivedProfile extends React.Component<IModalRestoreArchivedProfileProps, IModalRestoreArchivedProfileState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalRestoreArchivedProfileProps) {
        super(props);
        this.state = {
            profile: null,
            isProcessing: true
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.data) {
            let updatedProfile = deepCloneObject(this.props.modal.data);
            if (this.props.modal.data.name) {
                updatedProfile.type = UserType.CLUB;
            }
            this.setState({
                profile: updatedProfile, isProcessing: false
            })
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.profile || !this.state.profile.id || !this.props.modal) {
            return null;
        }
        const profileName = this.state.profile.name ? this.state.profile.name : `${this.state.profile.firstName} ${this.state.profile.lastName}`
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class='assign-profile-modal'>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={`archive.restoreModal.header.${this.state.profile.type}`}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <p>
                        <span>
                            <Translation text={'archive.restoreModal.question'}/>
                        </span>
                        <span className="outlined-box">{profileName}</span>
                        <span>?</span>
                    </p>
                </CustomModal.Body>
                <CustomModal.Footer>
                    <button className="btn btn-theme btn-small ml-3" onClick={() => this.props.changeModal(null)}>
                        <span>
                            <Translation text={'button.cancel'}/>
                        </span>
                    </button>
                    <button className="btn btn-danger btn-small ml-3" onClick={this.restoreProfile}>
                        <span>
                            <Translation text={'button.restore'}/>
                        </span>
                    </button>
                </CustomModal.Footer>
            </CustomModal>
        );
    }


    private restoreProfile = () => {
        if (!this.state.profile || !this.state.profile.type || !this.props.authToken) {
            return null;
        }
        let endpointName: any = null,
            profile = null;
        switch (this.state.profile.type) {
            case UserType.CONTESTANT:
                endpointName = EndpointNames.CONTESTANTS;
                profile = this.state.profile.contestant;
                break;
            case UserType.COACH:
                endpointName = EndpointNames.COACHES;
                profile = this.state.profile.coach;
                break;
            case UserType.SPECIALIST:
                endpointName = EndpointNames.SPECIALISTS;
                profile = this.state.profile.specialist;
                break;
            case UserType.CLUB:
                endpointName = EndpointNames.CLUBS;
                profile = this.state.profile;
                break;
            default:
                return null;
        }
        if (!endpointName || !profile) return null;
        this.setState({isProcessing: true});
        return this.subscriptions.push(restoreProfileAPI(this.props.authToken, endpointName, profile.id).pipe(
            tap(() => {
                this.alertManagerService.addAlert('archive.restoreModal.restoreSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldListUpdate(true);
                this.changeViewToList(endpointName);
                this.props.changeModal(null);
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe());
    }
    private changeViewToList(endpointName: any) {
        this.props.history.push(`/archive/${endpointName}`);
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldListUpdate,
        setAuthState,
    }
)(withRouter(ModalRestoreArchivedProfile));
