import React from 'react';
import {authTokenSelector, Translation} from "judo-app-common-web";
import UserConnectionItem from "./UserConnectionItem";
import {ModalType, Role, UserType} from '../../../model/models';
import TooltipWrapper, {ITooltip} from "../TooltipWrapper";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {changeModal} from "../../../store/reducers/sagaSlice";
import {userRoleSelector} from '../../../store/selectors/userSelector';

interface IUserConnectionListState {
    tableItemHeight: number;
}

interface IUserConnectionListProps {
    readonly dataList: any[];
    readonly listType: UserType;
    readonly account: any;
    readonly isModalView: boolean;
    readonly userRole: Role;
    readonly changeModal: typeof changeModal;
}

class UserConnectionList extends React.Component<IUserConnectionListProps, IUserConnectionListState> {
    readonly tableItemRef: any;

    constructor(props: IUserConnectionListProps) {
        super(props);
        this.state = {
            tableItemHeight: 0,
        }
    }


    render() {
        if (!this.props.dataList) {
            return null
        }
        let displayedListLength = this.props.dataList.length;
        if (this.props.account.type === UserType.CONTESTANT && this.props.listType === UserType.COACH) {
            displayedListLength = this.props.dataList.length ? 1 : 0;
        }
        return (
            <section className="user-connection-list-wrapper">
                <h3 className="user-connection-list-header">
                    {this.renderListHeader()}
                </h3>

                <ul className={`user-connection-list scrollable ${displayedListLength > 3 ? 'long-list' : ''}`}
                    style={this.displayTableItemsNumber(this.state.tableItemHeight, 3)}>
                    {this.renderListItems()}
                </ul>
            </section>
        )
    }

    private renderListItems() {

        if (this.props.dataList) {
            if (this.props.dataList.length === 0) {
                if (this.props.account.type === UserType.CONTESTANT && this.props.listType === UserType.COACH) {
                    return <div>
                        <Translation text={`userView.noData.assignedCoach`}/>
                    </div>
                }
                return <div>
                    <Translation text={`userView.noData.${this.props.listType}`}/>
                </div>
            }
            let rows: any[] = [];

            this.props.dataList.forEach((listItem, i) => {
                rows.push(
                    <UserConnectionItem listItem={listItem}
                                        firstChild={i === 0}
                                        key={listItem.id}
                                        onHeightChange={(height: number) => this.changeListHeight(height)}
                                        type={this.props.listType}
                                        connectedUserAccountId={listItem.id}
                                        hostUserId={this.props.account.id}
                                        isModalView={this.props.isModalView}/>
                )
            });
            if (this.props.account.type === UserType.CONTESTANT && this.props.listType === UserType.COACH) {
                return rows[0];
            }
            return rows;
        }
        return null;
    }

    private displayTableItemsNumber(tableItemHeight: number, tableItemsCount: number) {
        if (tableItemHeight > 0) {
            return {
                maxHeight: (tableItemHeight * (tableItemsCount / 10)) + 'rem'
            }
        }
        return {};
    }

    private changeListHeight(height: number) {
        if (this.state.tableItemHeight !== height) {
            this.setState({tableItemHeight: height});
        }
    }

    private renderListHeader() {
        if (this.props.account.type === UserType.CONTESTANT && this.props.listType === UserType.COACH && !this.props.isModalView) {
            return <div className='d-flex justify-content-between align-items-center'>
                <span> <Translation text={'userView.header.coach'}/> </span>
                {this.props.dataList.length === 0 && this.renderAssignFatigueProfileButton()}
            </div>
        }
        return <div className='d-flex justify-content-between align-items-center'>
            <span>
                <Translation text={`userView.userTypes.${this.props.listType}`}/>
                <span className="item-counter">{` ( ${this.props.dataList.length} )`}</span>
            </span>
            {this.renderAssignFatigueProfileButton()}
        </div>;
    }

    private renderAssignFatigueProfileButton() {
        if ((this.props.userRole === Role.Admin || this.props.userRole === Role.HeadCoach) && this.props.listType !== UserType.CLUB && !this.props.isModalView) {
            if (this.props.account.name) {
                return null;
            }
            const tooltip: ITooltip = {text: `userList.table.button.assign.${this.props.listType}`}
            return <TooltipWrapper tooltip={tooltip} key={'assign-fatigue-profile-button'}>
                <button className="btn btn-list ml-3" onClick={this.openAssignAccountModal}>
                    <span className="feather icon-file-plus" aria-hidden="true"/>
                    <span>
                                <Translation text={'button.assign'}/>
                            </span>
                </button>
            </TooltipWrapper>
        }
    }

    private openAssignAccountModal = () => {
        let modalType = () => {
            switch (this.props.listType) {
                case UserType.SPECIALIST:
                    return ModalType.ASSIGN_SPECIALIST;
                case UserType.COACH:
                    return ModalType.ASSIGN_COACH;
                case UserType.CONTESTANT:
                    return ModalType.ASSIGN_CONTESTANT;
                case UserType.FATIGUE_PROFILE:
                    return ModalType.ASSIGN_FATIGUE_PROFILE;
                default:
                    return ModalType.NULL;
            }
        }

        let alreadyAssignedProfilesId: string[] = [];
        this.props.dataList.forEach((dataItem: any) => alreadyAssignedProfilesId.push(dataItem.id));
        this.props.changeModal({
            type: modalType(),
            id: this.props.account.id,
            data: alreadyAssignedProfilesId.length ? alreadyAssignedProfilesId : null
        })
    }

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        userRole: userRoleSelector(state)
    }),
    {
        changeModal
    }
)(UserConnectionList);
