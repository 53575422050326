import React from 'react';
import {
    authTokenSelector,
    CustomModal,
    Form,
    IFormConfig,
    Loader,
    LoaderType,
    Translation
} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {ModalConfig, Role, UserType} from "../../../../model/models";
import {changeModal, changeShouldListUpdate, changeShouldViewUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {getSpecialistTypesAPI} from "../../../../api/getSpecialistTypes";
import {catchError, filter, map, switchMap, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {getAccountAPI} from "../../../../api/getAccount";
import UserConnectionList from "../../../Shared/UserConnectionsList";
import styles from "../../../Shared/UserInfoBox/styles.module.scss";
import {
    addClubInput,
    addSpecialistTypeGroup,
    buttonGroup,
    editPersonalProfileFormConfig
} from "./editPersonalProfileFormConfig";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {getBaseListAPI} from "../../../../api/getBaseList";
import {EndpointNames} from "../../../Shared/AnimatedLayoutHost";
import {updateAccountAPI} from "../../../../api/updateAccount";
import {getConnectedAccountsAPI} from "../../../../api/getConnectedAccounts";
import {updateUserProfileAPI} from "../../../../api/updateUserProfile";
import AccountImageHost from "../../../Shared/AccountImageHost";
import {userRoleSelector} from "../../../../store/selectors/userSelector";

interface IModalEditPersonalProfileProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly userRole: Role;
    readonly changeModal: typeof changeModal;
    readonly changeShouldViewUpdate: typeof changeShouldViewUpdate;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalEditPersonalProfileState {
    account: any;  // toDo interface
    userType: string;
    isProcessing: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    specialistTypes: { [key: string]: any }[];
    clubListOptions: { [key: string]: any }[];
}

class ModalEditPersonalProfile extends React.Component<IModalEditPersonalProfileProps, IModalEditPersonalProfileState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalEditPersonalProfileProps) {
        super(props);
        this.state = {
            account: null,
            userType: '',
            isProcessing: true,
            formConfig: editPersonalProfileFormConfig,
            value: {},
            specialistTypes: [],
            clubListOptions: []
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal) this.retrieveAccount();
    }

    componentDidUpdate(
        prevProps: Readonly<IModalEditPersonalProfileProps>,
        prevState: Readonly<IModalEditPersonalProfileState>,
        snapshot?: any
    ): void {
        if (this.state.account !== prevState.account) {
            if (this.state.account.type === UserType.SPECIALIST) {
                this.getSpecialistTypes();
            }
            if (this.state.account.type === UserType.COACH || this.state.account.type === UserType.CONTESTANT) {
                this.getClubList();
            }
            this.updateFormConfig();
        }
        if (this.state.specialistTypes !== prevState.specialistTypes ||
            this.state.clubListOptions !== prevState.clubListOptions) {
            this.updateFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.account) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose}>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={`modal.editPersonalProfile.type.${this.state.account.type}`}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <div className="separator"/>
                    {this.renderUserBox()}
                    <div className="separator"/>
                    {this.renderConnectedUserLists()}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private retrieveAccount() {
        if (!this.props.modal || !this.props.modal.id) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(
            getAccountAPI(this.props.authToken, this.props.modal.id).pipe(
                map((response: any) => {
                    this.updateFormConfigInit(response);
                    this.setState({userType: response.type, account: response})
                    if (response.type !== UserType.COACH || response.type !== UserType.SPECIALIST) {
                        this.setState({isProcessing: false})
                    }
                    return response;
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                }),
                filter((response: any) => {
                    return response.type === UserType.SPECIALIST || response.type === UserType.COACH
                }),
                switchMap((coachOrSpecialistAccount: any) => {
                    if (!coachOrSpecialistAccount) return of();
                    this.setState({isProcessing: true});
                    const coachOrSpecialistAccountId = coachOrSpecialistAccount.type === UserType.COACH ? coachOrSpecialistAccount.coach.id : coachOrSpecialistAccount.specialist.id,
                        responseEndpoint = coachOrSpecialistAccount.type === UserType.COACH ? EndpointNames.COACHES : EndpointNames.SPECIALISTS;
                    return getConnectedAccountsAPI(this.props.authToken, responseEndpoint, coachOrSpecialistAccountId, undefined, 1000).pipe(
                        tap((response) => {
                            if (response && response['hydra:member']) {
                                const coachAssignedAccounts = response['hydra:member'];
                                let updatedAccount = coachOrSpecialistAccount;
                                updatedAccount.contestants = coachAssignedAccounts;
                                return this.setState({account: updatedAccount, isProcessing: false})
                            }
                        }),
                        catchError((error: any) => {
                            this.setState({isProcessing: false});
                            this.alertManagerService.handleApiError(error);
                            return of();
                        }),
                    )
                })
            ).subscribe())
    }

    private renderUserBox() {
        return <div className={`${styles.userInfoBox}  ${styles[this.state.userType]}`}>
            <AccountImageHost account={this.state.account} onImageChange={() => null}/>
            <div className={`${styles.userPersonalInfoWrapper} ellipsis-host`}>
                {this.renderUserInfo()}
            </div>
        </div>

    }

    private renderConnectedUserLists() {
        if (!this.state.account) {
            return null;
        }
        return (
            <div className='d-flex flex-column'>
                {this.state.account.coaches &&
                <React.Fragment>
                    <UserConnectionList dataList={this.state.account.coaches}
                                        listType={UserType.COACH}
                                        account={this.state.account}
                                        isModalView={true}/>
                    <div className="separator"/>
                </React.Fragment>
                }
                {(this.state.account.specialists && this.state.account.type !== UserType.SPECIALIST) &&
                <React.Fragment>
                    <UserConnectionList dataList={this.state.account.specialists}
                                        listType={UserType.SPECIALIST}
                                        account={this.state.account}
                                        isModalView={true}/>
                    <div className="separator"/>
                </React.Fragment>
                }

                {this.state.account.contestants &&
                <React.Fragment>
                    <UserConnectionList dataList={this.state.account.contestants}
                                        listType={UserType.CONTESTANT}
                                        account={this.state.account}
                                        isModalView={true}/>
                    <div className="separator"/>
                </React.Fragment>
                }
                {this.state.account.clubs &&
                <React.Fragment>
                    <UserConnectionList dataList={this.state.account.clubs}
                                        listType={UserType.CLUB}
                                        account={this.state.account}
                                        isModalView={true}/>
                    <div className="separator"/>
                </React.Fragment>
                }
            </div>
        );
    }

    private renderUserInfo() {
        return (
            <Form config={this.state.formConfig}
                  submitForm={this.editProfile}
                  controlName={'editProfile'}
                  value={{}}/>
        )
    }

    private editProfile = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken) {
            return;
        }

        let updatedAccount = deepCloneObject(this.state.account);
        updatedAccount.phone = value.details_group?.phone.length ? value.details_group.phone : null;
        if (updatedAccount.type === UserType.CONTESTANT) {
            updatedAccount.contestant.club = value.details_group?.club ? value.details_group.club : updatedAccount.contestant.club;
        }
        updatedAccount.firstName = value.name_group?.firstName ? value.name_group.firstName : updatedAccount.firstName;
        updatedAccount.lastName = value.name_group?.lastName ? value.name_group.lastName : updatedAccount.lastName;
        if (updatedAccount.type === UserType.SPECIALIST) {
            const specialistTypeId = value.specialist_group?.specialistType ? value.specialist_group.specialistType : null,
                specialistType = specialistTypeId ? this.state.specialistTypes.find((specialistType) => specialistType.value === specialistTypeId) : null;
            updatedAccount.specialist.type = specialistType ? specialistType : updatedAccount.specialist.type;
        }
        const specialistAccountIdArray = updatedAccount.specialists.map((specialistAccount: any) => {
            const specialistId = specialistAccount.specialist.split('/').pop();
            return specialistId;
        });
        const coachesAccountIdArray = updatedAccount.coaches.map((coachAccount: any) => {
            const coachId = coachAccount.coach.split('/').pop();
            return coachId;
        });
        const payload = {
            firstName: updatedAccount.firstName,
            lastName: updatedAccount.lastName,
            birthDate: updatedAccount.birthDate,
            phone: updatedAccount.phone,
            address: updatedAccount.address,
            specialists: specialistAccountIdArray,
            coaches: coachesAccountIdArray,
        }
        this.setState({isProcessing: true});
        const shouldUpdateContestantProfile = (updatedAccount.type === UserType.CONTESTANT && value.details_group?.club),
            shouldUpdateSpecialistProfile = (updatedAccount.type === UserType.SPECIALIST && value.specialist_group?.specialistType);
        return this.subscriptions.push(updateAccountAPI(this.props.authToken, updatedAccount.id, payload).pipe(
            map((response: any) => {
                if (shouldUpdateContestantProfile && updatedAccount.contestant) {
                    return updatedAccount.contestant
                }
                if (shouldUpdateSpecialistProfile && updatedAccount.specialist) {
                    return updatedAccount.specialist;
                }
                this.alertManagerService.addAlert('modal.editPersonalProfile.editSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldViewUpdate(true);
                this.props.changeShouldListUpdate(true);
                this.props.changeModal(null);
                return of();
            }),
            filter(response => !!response),
            switchMap((userProfile: any) => {
                const userId = userProfile.id,
                    contestantPayload = {
                        weightCategoryId: userProfile.weightCategoryId,
                        clubId: value.details_group?.club ? value.details_group.club.value : null
                    },
                    specialistPayload = {typeId: value.specialist_group?.specialistType ? value.specialist_group.specialistType.value : null},
                    endpointName = updatedAccount.type === UserType.CONTESTANT ? 'contestants' : 'specialists',
                    payload = updatedAccount.type === UserType.CONTESTANT ? contestantPayload : specialistPayload;

                return updateUserProfileAPI(this.props.authToken, payload, endpointName, userId,).pipe(
                    tap((response) => {
                        this.alertManagerService.addAlert('modal.editPersonalProfile.editSuccess');
                        this.setState({isProcessing: false});
                        this.props.changeShouldViewUpdate(true);
                        this.props.changeShouldListUpdate(true);
                        this.props.changeModal(null);
                    }))
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe());
    }

    private updateFormConfigInit(account: any) {
        if (!account) {
            return null
        }
        let updatedFormConfig = deepCloneObject(editPersonalProfileFormConfig);
        updatedFormConfig.controls[0].controls.firstName.defaultValue = account.firstName;
        updatedFormConfig.controls[0].controls.lastName.defaultValue = account.lastName;
        updatedFormConfig.controls[1].controls.phone.defaultValue = account.phone ? account.phone : "";
        if (account.type === UserType.CONTESTANT) {
            let clubInput = deepCloneObject(addClubInput);
            clubInput.club.multiselectOptions = this.state.clubListOptions;
            let clubData: any = {value: "", label: ""};
            if (account.contestant.club) {
                clubData = {value: account.contestant.club.id, label: account.contestant.club.name};
            }
            clubInput.club.defaultValue = clubData;
            if (this.props.userRole === Role.Contestant) {
                clubInput.club.disabled = true;
            }
            let updatedGroup = deepCloneObject(updatedFormConfig.controls[1].controls);
            Object.assign(updatedGroup, clubInput);
            updatedFormConfig.controls[1].controls = updatedGroup;
        }
        if (account.type === UserType.COACH) {
            let clubInput = deepCloneObject(addClubInput);
            clubInput.club.multiselectOptions = this.state.clubListOptions;
            if (account.coach.clubs.length) {
                clubInput.club.defaultValue = {value: account.coach.clubs[0].id, label: account.coach.clubs[0].name};
                clubInput.club.disabled = true;
            }
            let updatedGroup = deepCloneObject(updatedFormConfig.controls[1].controls);
            Object.assign(updatedGroup, clubInput);
            updatedFormConfig.controls[1].controls = updatedGroup;
        }
        if (account.type === UserType.SPECIALIST) {
            let specialistTypeGroup = deepCloneObject(addSpecialistTypeGroup);
            specialistTypeGroup.controls.specialistType.multiselectOptions = this.state.specialistTypes;
            specialistTypeGroup.controls.specialistType.defaultValue = {
                value: account.specialist?.type.id,
                label: account.specialist.type.name
            };
            updatedFormConfig.controls.push(specialistTypeGroup);
        }
        updatedFormConfig.controls.push(buttonGroup);
        this.setState({formConfig: updatedFormConfig});
    }


    private updateFormConfig() {
        if (!this.state.account) {
            return null;
        }
        let updatedFormConfig = deepCloneObject(editPersonalProfileFormConfig);
        updatedFormConfig.controls[0].controls.firstName.value = this.state.account.firstName;
        updatedFormConfig.controls[0].controls.lastName.value = this.state.account.lastName;
        updatedFormConfig.controls[1].controls.phone.value = this.state.account.phone ? this.state.account.phone : null;
        if (this.state.account.type === UserType.CONTESTANT) {
            let clubInput = deepCloneObject(addClubInput);
            clubInput.club.multiselectOptions = this.state.clubListOptions;
            let clubData: any = {value: undefined, label: undefined};
            if (this.state.account.contestant.club) {
                clubData = {
                    value: this.state.account.contestant.club.id,
                    label: this.state.account.contestant.club.name
                };
            }
            clubInput.club.defaultValue = clubData;
            clubInput.club.value = clubData

            if (this.props.userRole === Role.Contestant) {
                clubInput.club.disabled = true;
            }
            let updatedGroup = deepCloneObject(updatedFormConfig.controls[1].controls);
            Object.assign(updatedGroup, clubInput);
            updatedFormConfig.controls[1].controls = updatedGroup;
        }
        if (this.state.account.type === UserType.SPECIALIST) {
            let specialistTypeGroup = deepCloneObject(addSpecialistTypeGroup);
            specialistTypeGroup.controls.specialistType.multiselectOptions = this.state.specialistTypes;
            specialistTypeGroup.controls.specialistType.defaultValue = {
                value: this.state.account.specialist?.type.id,
                label: this.state.account.specialist.type.name
            };
            updatedFormConfig.controls.push(specialistTypeGroup);
        }
        updatedFormConfig.controls.push(buttonGroup);
        this.setState({formConfig: updatedFormConfig});
    }


    private getSpecialistTypes = () => {
        return this.subscriptions.push(getSpecialistTypesAPI(this.props.authToken).pipe(
            map((response: any) => {
                let types = response['hydra:member'];
                let multiselectTypeOptions: { [key: string]: any }[] = [];
                if (types) {
                    types.forEach((type: { [key: string]: any }) => {
                        multiselectTypeOptions.push({
                            value: type.id,
                            label: type.name
                        })
                    })
                }
                this.setState({specialistTypes: multiselectTypeOptions})
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error);
                return of();
            }),
        ).subscribe());
    };

    private getClubList = () => {
        return this.subscriptions.push(
            getBaseListAPI(this.props.authToken, EndpointNames.CLUBS, undefined, 1000).pipe(
                map((response: any) => {
                    const clubs = response['hydra:member'];
                    let multiselectTypeOptions: { [key: string]: any }[] = [];
                    if (clubs) {
                        clubs.forEach((club: { [key: string]: any }) => {
                            multiselectTypeOptions.push({
                                value: club.id,
                                label: club.name
                            })
                        })
                    }

                    this.setState({clubListOptions: multiselectTypeOptions});
                }),
                catchError((err: any) => {
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
            ).subscribe()
        )
    };
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
        userRole: userRoleSelector(state)
    }),
    {
        changeModal,
        changeShouldViewUpdate,
        changeShouldListUpdate,
    }
)(ModalEditPersonalProfile);
