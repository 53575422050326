import { createSelector } from "redux-starter-kit";
import { IUserState } from "../reducers/userSlice";

export const selectAccount = (state: any): IUserState => {
  return (state as any).user;
};

export const userSelector = createSelector(
  [selectAccount],
  (state: IUserState) => state.user
);

export const userRoleSelector = createSelector(
    [selectAccount],
    (state: IUserState) => state.user.user.roles[0]
);

export const userLoadingSelector = createSelector(
  [selectAccount],
  (state: IUserState) => state.isLoading
);
