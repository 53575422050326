import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function addInputGroupDefinitionAPI(
    authToken: string,
    payload: any,
): Observable<any> {
    return baseDataProviderAPI.post(
        "input_group_definitions",
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
