import React from 'react';
import {CustomCard, Form, IFormConfig, Translation} from 'judo-app-common-web';
import {deepCloneObject} from "../../../../../utils/runtimeUtils";
import {CalendarInputOption} from "../../../../../model/models";
import {editOptionFormConfig} from "./editOptionFormConfig";

interface IEditOptionFormProps {
    editedOption: CalendarInputOption;
    editedOptionIndex: number;
    onOptionUpdate: (option: CalendarInputOption, optionIndex: number) => void;
}

interface IEditOptionFormState {
    editedOption: CalendarInputOption | null;
    formConfig: typeof IFormConfig;
    value: any;
}

class EditOptionForm extends React.Component<IEditOptionFormProps, IEditOptionFormState> {
    constructor(props: IEditOptionFormProps) {
        super(props);

        this.state = {
            editedOption: null,
            formConfig: editOptionFormConfig({
                label: this.props.editedOption.label,
                value: this.props.editedOption.value,
                color: this.props.editedOption.backgroundColor
            }),
            value: {},
        }
    }


    componentDidMount() {
        const option = this.props.editedOption;

        if (this.props.editedOption) {
            let formConfigUpdated = deepCloneObject(this.state.formConfig);
            formConfigUpdated.controls[0].controls.label.value = option.label;
            formConfigUpdated.controls[0].controls.label.defaultValue = option.label;
            formConfigUpdated.controls[0].controls.value.value = option.value;
            formConfigUpdated.controls[0].controls.value.defaultValue = option.value;
            formConfigUpdated.controls[0].controls.color.color = option.backgroundColor;
            formConfigUpdated.controls[0].controls.color.defaultColor = option.backgroundColor;
            this.setState({formConfig: formConfigUpdated, editedOption: option})
        }
    }

    componentDidUpdate(prevProps: Readonly<IEditOptionFormProps>, prevState: Readonly<IEditOptionFormState>) {
        if (this.props.editedOption !== prevProps.editedOption) {
            const option = this.props.editedOption;

            let formConfigUpdated = deepCloneObject(this.state.formConfig);
            formConfigUpdated.controls[0].controls.label.value = option.label;
            formConfigUpdated.controls[0].controls.label.defaultValue = option.label;
            formConfigUpdated.controls[0].controls.value.value = option.value;
            formConfigUpdated.controls[0].controls.value.defaultValue = option.value;
            formConfigUpdated.controls[0].controls.color.color = option.backgroundColor;
            formConfigUpdated.controls[0].controls.color.defaultColor = option.backgroundColor;
            this.setState({formConfig: formConfigUpdated, editedOption: option});
        }
    }

    componentWillUnmount() {
        this.setState({editedOption: null, value: null, formConfig: null})
    }


    render() {
        if (!this.props.editedOption) {
            return null;
        }

        return <CustomCard showLocalLoader={false}>
            <div className="user-connection-list-wrapper">
                <h4>
                    <Translation text="profileSettingsModal.form.addOptionForm.headerEdit"/>
                </h4>
                <CustomCard.Body>
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                        controlName={"editOptionForm"}
                        key="edit_option_form"
                        value={{}}
                        submitForm={this.submitOption}
                    />
                </CustomCard.Body>
            </div>
        </CustomCard>
    }

    private updateFormData(controlName: string, data: any) {
        let updatedOption: CalendarInputOption = deepCloneObject(this.state.editedOption);
        if (data) {
            updatedOption.label = data.label;
            updatedOption.value = data.value;
            updatedOption.backgroundColor = data.color;
        }
        this.setState({editedOption: updatedOption});
    }

    private submitOption = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        if (!value.edit_option.label || !value.edit_option.value || !value.edit_option.color) {
            return;
        }
        const editedOption: CalendarInputOption = {
            label: value.edit_option.label,
            value: value.edit_option.value,
            backgroundColor: value.edit_option.color
        }
        this.props.onOptionUpdate(editedOption, this.props.editedOptionIndex);
    };
}

export default EditOptionForm;
