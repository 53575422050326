import React from 'react';
import {CustomCard, CustomModal, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {Subscription} from 'rxjs';
import UserInfoBox, {UserInfoType} from "../../Shared/UserInfoBox";
import UserConnectionList from "../../Shared/UserConnectionsList";
import {UserType} from '../../../model/models';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {isUserProfileLoadingSelector} from "../../../store/selectors/sagaSelectors";
import ArchiveProfileButton from "../../Shared/ArchiveProfileButton";
import EditProfileButton from "../../Shared/EditProfileButton";

interface IClubViewProps {
    onViewChange: (id: string | null) => void;
    itemView?: any;
    isModalView: boolean;
    isArchiveMode?: boolean;
    changeModal: typeof changeModal;
    isUserProfileLoading: boolean;
}

interface IClubViewState {
    clubView: any;
}

class ClubView extends React.Component<IClubViewProps, IClubViewState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscription: Subscription | null;

    constructor(props: IClubViewProps) {
        super(props);
        this.state = {
            clubView: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.itemView) this.setState({clubView: this.props.itemView});
    }

    componentDidUpdate(prevProps: Readonly<IClubViewProps>, prevState: Readonly<IClubViewState>) {
        if (this.props.itemView !== prevProps.itemView) {
            this.setState({clubView: this.props.itemView})
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        if (!this.state.clubView) {
            return (<CustomCard showLocalLoader={true} type={"view-upload"}/>);
        }

        if (this.props.isModalView) {
            return this.renderClubModal()
        }
        return this.renderClubView()
    }

    private renderClubView() {
        return (
            <CustomCard showLocalLoader={this.props.isUserProfileLoading}>
                <CustomCard.Header>
                    <div className="user-view-header">
                        <h2><Translation text={'userView.header.club'}/></h2>
                        <button className="btn-close" onClick={() => this.props.onViewChange(null)}>
                            <span className="feather icon-x" aria-hidden="true"/>
                        </button>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    {this.renderBody()}
                    {this.renderActionButtons()}
                    {this.renderConnectedUserLists()}
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderClubModal() {
        return (
            <CustomModal close={() => this.props.changeModal(null)}>
                <CustomModal.Header>
                    <h2><Translation text={'userView.header.club'}/></h2>
                </CustomModal.Header>
                <CustomModal.Body>
                    {this.renderBody()}
                    {this.renderConnectedUserLists()}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private renderBody() {
        return (
            <React.Fragment>
                <div className="separator"/>
                <UserInfoBox user={this.state.clubView} infoCellType={UserInfoType.Extended}/>
            </React.Fragment>
        )
    }

    private renderActionButtons() {
            return  <>
                <div className="separator"/>
                <div className="action-buttons-wrapper">
                    {!this.props.isArchiveMode &&
                    <EditProfileButton profileId={this.state.clubView.id} isClubProfile={true}/>}
                    <ArchiveProfileButton isArchiveMode={this.props.isArchiveMode} profile={this.state.clubView}/>
                </div>
            </>
    }

    private renderConnectedUserLists() {
        if (this.props.isArchiveMode) {
            return null;
        }
        return (<>
            <div className="separator"/>
                <div className='d-flex flex-column'>
                    {this.state.clubView.coaches &&
                    <React.Fragment>
                        <UserConnectionList dataList={this.state.clubView.coaches}
                                            listType={UserType.COACH}
                                            account={this.state.clubView}
                                            isModalView={this.props.isModalView}/>
                        <div className="separator"/>
                    </React.Fragment>
                    }
                    {this.state.clubView.contestants &&
                    <React.Fragment>
                        <UserConnectionList dataList={this.state.clubView.contestants}
                                            listType={UserType.CONTESTANT}
                                            account={this.state.clubView}
                                            isModalView={this.props.isModalView}/>
                        <div className="separator"/>
                    </React.Fragment>
                    }
                </div>

        </>
        );
    }
}

export default connect(
    (state: RootState) => ({
        isUserProfileLoading: isUserProfileLoadingSelector(state)
    }),
    {
        changeModal
    }
)(ClubView);
