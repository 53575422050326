import React from 'react';
import {CustomCard, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import UserInfoBox, {UserInfoType} from "../../Shared/UserInfoBox";
import {ModalType, Role, UserType} from '../../../model/models';
import {connect} from 'react-redux';
import {changeModal} from '../../../store/reducers/sagaSlice';
import Pagination, {IPaginationData} from "../../Shared/Pagination";
import {RootState} from "../../../store/reducers";
import {userRoleSelector} from "../../../store/selectors/userSelector";

interface IClubListProps {
    onViewChange: (id: string | null, profileType?: UserType) => void;
    list: any[] | null;
    onSpecificPageLoad: (pageNumber: number) => void;
    paginationData: IPaginationData | null;
    userRole: Role;
    isLoading: boolean;
    isArchiveMode?: boolean;
    changeModal: typeof changeModal;
}

interface IClubListState {
    clubList: any[] | null;
}

class ClubList extends React.Component<IClubListProps, IClubListState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IClubListProps) {
        super(props);
        this.state = {
            clubList: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.setState({clubList: this.props.list})
    }

    componentDidUpdate(prevProps: Readonly<IClubListProps>): void {
        if (this.props.list !== prevProps.list) this.setState({clubList: this.props.list});
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Body>
                    {this.renderButton()}
                    {this.renderTable()}
                </CustomCard.Body>
                <CustomCard.Footer>
                    <Pagination paginationData={this.props.paginationData}
                                onSpecificPageLoad={this.props.onSpecificPageLoad}/>
                </CustomCard.Footer>
            </CustomCard>
        )
    }

    private renderButton() {
        if ((this.props.userRole !== Role.Admin && this.props.userRole !== Role.HeadCoach) || this.props.isArchiveMode) return;

        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-theme btn-small"
                        onClick={this.openCreateClubModal}>
                    <span aria-hidden="true" className="feather icon-plus"/>
                    <Translation text={'userList.table.button.add.club'}/>
                </button>
            </div>
        )
    }

    private renderTable() {
        if (!this.state.clubList) {
            return;
        }

        const noClubsMessage = this.props.isArchiveMode ?
            <Translation text={'userList.table.noData.noArchivedClubs'}/> :
            <Translation text={'userList.table.noData.noClubs'}/>;

        if (!this.state.clubList.length) {
            return (
                <div className="d-flex justify-content-center display-4">
                    <span>
                        {noClubsMessage}
                    </span>
                </div>
            );
        }

        const rows: any = [];

        this.state.clubList.forEach((club: any) => rows.push(
            <tr key={club.id}>
                <td><UserInfoBox user={club} infoCellType={UserInfoType.Club}/></td>
                <td> {club?.coaches?.length}</td>
                <td> {club?.contestants?.length}</td>
                <td className="text-right">
                    <button className="btn btn-theme btn-small" onClick={() => this.props.onViewChange(club.id, this.props.isArchiveMode ? UserType.CLUB : undefined)}>
                        <span className="feather icon-edit" aria-hidden="true"/>
                        <Translation text={'userList.table.button.details'}/>
                    </button>
                </td>
            </tr>
        ));

        return (
            (
                <div className="mt-20">
                    <table className="data-table">
                        <thead>
                        <tr>
                            <th><Translation text={'userList.table.header.clubs'}/></th>
                            <th><Translation text={'userList.table.header.coaches'}/></th>
                            <th><Translation text={'userList.table.header.contestants'}/></th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            )
        )
    }

    private openCreateClubModal = () => {
        this.props.changeModal({
            type: ModalType.CREATE,
            role: UserType.CLUB,
        })
    }
}

export default connect(
    (state: RootState) => ({
        userRole: userRoleSelector(state)
    }),
    {
        changeModal
    }
)(ClubList);
