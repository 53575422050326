import React from 'react';
import TooltipWrapper, {ITooltip, TooltipPosition} from "../../../../Shared/TooltipWrapper";
import {
    CalendarGroup,
    CalendarInput,
    CalendarInputOption,
    InputGroupDefinitionType,
    RawInputGroup,
    RawProfileInput,
    Role,
} from "../../../../../model/models";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IFormConfigConverterService} from "../../../../../service/formConfigConverter";
import {connect} from "react-redux";
import {userRoleSelector} from "../../../../../store/selectors/userSelector";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {IDayItem} from "../../index";
import {CalendarIntoRawProfileConverterService} from "../../../../../service";
import {isNullOrUndefined} from '../../../../../utils/runtimeUtils';
import {WithTranslation, withTranslation} from "react-i18next";

type MatchParamsTypes = {
    host: string | undefined
    id: string | undefined
    profileId: string | undefined
    type: string | undefined
}

interface ITrainingPlanCellProps extends RouteComponentProps<MatchParamsTypes>,
    WithTranslation  {
    inputData?: RawProfileInput;
    valuesVisualOptions?: CalendarInputOption[];
    inputName?: string;
    calculatedGroup?: RawInputGroup;
    userRole: string;
    setEditedCellAndGroup: (editedCell?: RawProfileInput | CalendarInput, editedGroup?: RawInputGroup | CalendarGroup, date?: Date) => void
    day?: IDayItem;
    calendarInput?: CalendarInput;
    calendarGroup?: CalendarGroup;
    groupType: InputGroupDefinitionType | undefined;
}

interface ITrainingPlanCellState {
    isEdited: boolean;
    value: any;
    isFormValid: boolean;
    isLoading: boolean;
}

class TrainingPlanCell extends React.Component<ITrainingPlanCellProps, ITrainingPlanCellState> {
    @lazyInject('FormConfigConverterService') private formConfigConverterService: IFormConfigConverterService;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('CalendarIntoRawProfileConverterService') private calendarIntoRawProfileConverterService: CalendarIntoRawProfileConverterService;

    constructor(props: ITrainingPlanCellProps) {
        super(props);
        this.state = {
            isEdited: false,
            value: null,
            isFormValid: false,
            isLoading: false
        }
        fixInjectedProperties(this);
    }

    render() {
        const inputData = this.inputData();
        const {t} = this.props;

        let visualOptions = undefined;
        // if(!inputData || inputData?.inputResultValue?.value === null || !this.props.inputData || !this.props.day){

        if(!inputData ){
            return <td onDoubleClick={this.toggleEdit}/>
        }

        const inputResult = inputData.inputResultValue;
        let inputValue = inputResult?.value;
        if (this.props.valuesVisualOptions && this.props.valuesVisualOptions.length > 0) {
            const inputOption = this.props.valuesVisualOptions.find((option: CalendarInputOption) => option.value === inputValue?.toString());
            visualOptions = {backgroundColor: inputOption?.backgroundColor};
            inputValue = inputOption ? inputOption.value : inputValue;
        }

        let cellInfo = `${this.props.inputName || inputData.inputName} - `;
        if (isNullOrUndefined(inputValue)) {
            cellInfo = t('modal.trainingPlanView.addTrainingProfile')
        }
        const tooltip: ITooltip = {text: cellInfo, value: inputValue, position: TooltipPosition.Top};
        return (
            <TooltipWrapper tooltip={tooltip} key={'profile-button'}>
                <td onDoubleClick={this.toggleEdit} style={visualOptions ? visualOptions : undefined}>
                    {inputValue}
                </td>
            </TooltipWrapper>
          )
    }

    private inputData(): RawProfileInput | undefined {
        const calendarInput = this.props.calendarInput;

        if(!this.props.inputData && !calendarInput) {
            return undefined
        }

        if(this.props.inputData) {
            return this.props.inputData
        }

        if(calendarInput) {
            return this.calendarIntoRawProfileConverterService.convertCalendarInputIntoRawProfileInput(calendarInput, this.props?.day?.dateObject)
        }
    }

    private toggleEdit = () => {
        //Training Plan edit after the generation date is blocked or we are in contestants subview
        if(
            (this.props.groupType === InputGroupDefinitionType.TRAINING && this.props.match.params?.host === 'contestants' && this.props.userRole === Role.Coach) ||
            // (!this.props.inputData && this.props.day?.dateObject && new Date().getTime() > this.props.day?.dateObject?.getTime()) ||
            (!this.props.inputData && this.props.groupType === InputGroupDefinitionType.NORMAL)) {
            return
        }

        if(!this.props.inputData) {
            return this.props.setEditedCellAndGroup(this.props.calendarInput, this.props.calendarGroup, this.props.day?.dateObject)
        }

        return this.props.setEditedCellAndGroup(this.props.inputData, this.props.calculatedGroup)
    }
}


export default withTranslation()(connect(
    (state: any) => ({
        userRole: userRoleSelector(state)
    }),
    {}
)(withRouter(TrainingPlanCell)));

