import React, {PropsWithChildren} from 'react';
import {
    authTokenSelector,
    isAuthenticatedSelector,
    setAuthState,
    setUserRole,
    Sidebar,
    Translation,
    setLoginFailure,
    changeLoginLoading
} from 'judo-app-common-web';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import logo from "../../assets/images/polski_zwiazek_judo_logo.png";
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import Account from '../Account';
import {fontSizeSelector} from '../../store/selectors/sagaSelectors';
import {changeModal} from '../../store/reducers/sagaSlice';
import {changeUser} from '../../store/reducers/userSlice';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import Coaches from "../Coaches";
import Contestants from "../Contestants";
import Specialists from "../Specialists";
import Clubs from "../Clubs";
import FontSizer from '../Shared/FontSizer';
import FatigueProfileAlert from '../Shared/FatigueProfileAlert';
import ContestantFatigueCalender from "../ContestantFatigueCalendar";
import adminNavigation from './admin-navigation';
import coachNavigation from './coach-navigation';
import contestantNavigation from './contestant-navigation';
import specialistNavigation from './specialist-navigation';
import {ModalType, ProfileType, Role} from '../../model/models';
import Modals from './Modals';
import SpecialistTypes from "../SpecialistTypes";
import {of, Subscription, forkJoin} from 'rxjs';
import {getUserMeAPI} from '../../api/getUserMe';
import {catchError, first, tap} from 'rxjs/operators';
import {userSelector} from '../../store/selectors/userSelector';
import {getUserFatigueProfilesAPI} from "../../api/getUserFatigueProfiles";
import Archive from '../Archive';
import {EndpointNames} from "../Shared/AnimatedLayoutHost";
import FatigueProfilesView from '../DevelopmentProfiles/FatigueProfilesView';
import DevelopmentProfilesList from '../DevelopmentProfiles/DevelopmentProfilesList';
import {getUserTrainingProfilesAPI} from '../../api/getUserTrainingProfiles';

export interface MatchProcessParams {
    host?: string;
    id?: string;
    type?: ModalType | ProfileType | EndpointNames;
}

interface IPanelHostProps extends RouteComponentProps<MatchProcessParams> {
    readonly isProcessing: boolean;
    readonly authToken: string | null;
    readonly isAuthenticated: boolean;
    readonly fontSize: number;
    readonly user: any;
    readonly changeModal: typeof changeModal;
    readonly changeUser: typeof changeUser;
    readonly changeLoginLoading: typeof changeLoginLoading;
    readonly setAuthState: typeof setAuthState;
    readonly setLoginFailure: typeof setLoginFailure;
}

interface IPanelHostState {
    showFatigueProfileAlert: boolean;
    runUpdate: boolean;
    userRecentFatigueProfiles: [];
    userRecentTrainingProfiles: [];
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            showFatigueProfileAlert: false,
            runUpdate: false,
            userRecentFatigueProfiles: [],
            userRecentTrainingProfiles: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.authToken) this.retrieveUserAccount();
        if (this.props?.user?.user?.roles?.includes((Role.Contestant))) {
            return this.retrieveUserProfiles();
        }
        if (this.state.showFatigueProfileAlert) {
            this.setState({showFatigueProfileAlert: false});
        }
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>): void {
        if (!prevProps.authToken && this.props.authToken) {
            this.retrieveUserAccount();
        }

        if(!prevProps?.user?.user?.roles && this.props?.user?.user?.roles?.includes((Role.Contestant))) {
            this.setState({runUpdate: true});
        }

        if(!prevState.runUpdate && this.state.runUpdate) {
            this.retrieveUserProfiles()
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }


    render() {
        if (!this.props.isAuthenticated) {
            return (<Redirect push to={'/auth/login'}/>)
        }

        const role = this.getRoleClass();

        return (
            <div className={`panel-view ${role}`} style={{fontSize: this.props.fontSize + 'rem'}}>

                {this.renderSidebar()}

                <div className="content">
                    {this.renderHeader()}
                    {this.renderRouting()}

                    <FatigueProfileAlert isProfileInvalid={this.state.showFatigueProfileAlert}/>
                </div>

                <Modals/>
                {/*<AuthNotification />*/}
                <div id='react-select-portal' className='react-select'/>
            </div>
        );
    }

    retrieveUserAccount(): void {
        if (!this.props.authToken) return;
        this.subscriptions.push(
            getUserMeAPI(this.props.authToken).pipe(
                first(),
                tap((user: any) => {
                    this.props.changeUser(user)
                }),
                catchError((error: any) => {
                    if (error && error.status === 401) {
                        return of(
                            this.props.setLoginFailure(error.toString()),
                            this.alertManagerService.addAlert('general.alert.error.authenticationErrorLogout'),
                            this.props.setAuthState(null, null, null, false),
                            this.props.changeLoginLoading(false)
                        )
                    }
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe());
    }

    retrieveUserProfiles(): void {
        if (!this.props.authToken) return;
        const getTrainingProfilesCall = getUserTrainingProfilesAPI(this.props.authToken, undefined, undefined, this.props.user?.id).pipe(
            tap((response: any) => {
                if (response['hydra:member'] && response['hydra:member'].length) {
                    this.setState({userRecentTrainingProfiles: response['hydra:member']})
                }
                return response;
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error);
                return of();
            })),
            getFatigueProfilesCall = getUserFatigueProfilesAPI(this.props.authToken).pipe(
                tap((response: any) => {
                    if (response && response['hydra:member']) {
                        this.setState({
                            userRecentFatigueProfiles: response['hydra:member'],
                        })
                    }
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error);
                    return of();
                }));

        this.subscriptions.push(
            forkJoin([getFatigueProfilesCall, getTrainingProfilesCall]).subscribe(() => {
                const allProfilesArray = this.state.userRecentFatigueProfiles.concat(this.state.userRecentTrainingProfiles),
                    areProfilesNotFilled = allProfilesArray.some((profile: any) => profile.status === 'to_filled');
                this.setState({runUpdate: false, showFatigueProfileAlert: areProfilesNotFilled})
            })
        )
    }

    private renderHeader() {
        if (!this.props.match.params.host) return;

        let host = this.props.match.params.host.split('-')
            .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));

        return (
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="header">
                        <span><Translation text={`${host}.header`}/></span>
                        <FontSizer/>
                    </h1>
                </div>
            </div>
        )
    }

    private renderSidebar() {
        if (!this.props.authToken || !this.props?.user?.user?.roles?.[0]) {
            return null;
        }

        let nav: any = null;
        switch (this.props.user.user.roles[0]) {

            case Role.Admin:
            case Role.HeadCoach:
                nav = adminNavigation;
                break;
            case Role.Coach:
                nav = coachNavigation;
                break;
            case Role.Specialist:
                nav = specialistNavigation;
                break;
            case Role.Contestant:
                nav = contestantNavigation;
                break;
            default:
                throw new Error(`User role is invalid: ${this.props.user.user.roles[0]}`)
        }

        const onClick = () => this.props.changeModal({
            type: ModalType.LOGOUT
        });

        nav.items[0].children[nav.items[0].children.length - 1].onClick = onClick;

        return <Sidebar navigation={nav} logo={logo}/>;
    }

    private renderRouting() {
        if (!this.props.authToken || !this.props.user) {
            return null;
        }

        switch (this.props.user.user.roles[0]) {
            case Role.Admin:
            case Role.HeadCoach:
                return (
                    <Switch>
                        <Route path="/coaches/:id?/:type?" component={Coaches} key="coaches"/>
                        <Route path="/contestants/:id?/:type?" component={Contestants} key="contestants"/>
                        <Route path="/specialists/:id?/:type?" component={Specialists} key="specialists"/>
                        <Route path="/clubs/:id?/:type?" component={Clubs} key="clubs"/>
                        <Route exact path="/profiles/" component={DevelopmentProfilesList} key="profiles"/>
                        <Route path="/profiles/:id?/:type?" component={FatigueProfilesView} key="profile-view"/>
                        <Route path="/account/:id?/:type?" component={Account} key="account"/>
                        <Route path="/specialist-types" component={SpecialistTypes} key="specialist-types"/>
                        <Route path="/archive/:type?/:id?" component={Archive} key="archive"/>
                        <Route component={() => <Redirect to={"/profiles"}/>}/>
                    </Switch>
                );
            case Role.Coach:
                return (
                    <Switch>
                        <Route path="/contestants/:id?/:type?" component={Contestants} key="contestants"/>
                        <Route path="/specialists/:id?/:type?" component={Specialists} key="specialists"/>
                        <Route path="/account/:id?/:type?" component={Account} key="account"/>
                        <Route component={() => <Redirect to={"/contestants"}/>}/>
                    </Switch>
                );
            case Role.Specialist:
                return (
                    <Switch>
                        <Route path="/coaches/:id?/:type?" component={Coaches} key="coaches"/>
                        <Route path="/contestants/:id?/:type?" component={Contestants} key="contestants"/>
                        <Route path="/account/:id?/:type?" component={Account} key="account"/>
                        <Route component={() => <Redirect to={"/contestants"}/>}/>
                    </Switch>
                );
            case Role.Contestant:
                return (
                    <Switch>
                        <Route path="/account/:id?/:type?" component={Account} key="account"/>
                        <Route path="/profile/:id?" render={(props: PropsWithChildren<RouteComponentProps<any>> | PropsWithChildren<any>) =>
                            <ContestantFatigueCalender {...props} serverProfileWasUpdated={(shouldRun: boolean) => this.setState({runUpdate: shouldRun})} />} key="profile"/>
                        <Route component={() => <Redirect to={"/profile"}/>}/>
                    </Switch>
                );
            default:
                throw new Error(`User role is invalid: ${this.props.user.user.roles[0]}`)
        }
    }

    private getRoleClass(): string {
        return this.props.user?.user?.roles?.[0] === 'ROLE_CONTESTANT' ? 'contestant' : '';
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        fontSize: fontSizeSelector(state),
        user: userSelector(state),
    }),
    {
        setAuthState,
        setUserRole,
        setLoginFailure,
        changeModal,
        changeUser,
        changeLoginLoading,
    }
)(withRouter(PanelHost));
