import {InputType} from "../../../../model/models";

const trainingAimInputsTemplate = [
    {
        label: "Wolne od treningu",
        value: "WoT",
        backgroundColor: "#ea9999"
    },
    {
        label: "Mata nauczanie techniki",
        value: "MNT",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata doskonalenie techniki",
        value: "MDT",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata, technika, randori",
        value: "MTR",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata taktyka walki",
        value: "MTT",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata technika taktyka randori",
        value: "MTTR",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata, taktyka, randori",
        value: "MTKR",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata randori",
        value: "MR",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata, technika, siła",
        value: "MTS",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata, siła, moc",
        value: "MSM",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata trening szybkości",
        value: "MSZ",
        backgroundColor: "#00ff00"
    },
    {
        label: "Mata, trening wytrzymałości",
        value: "MW",
        backgroundColor: "#00ff00"
    },
    {
        label: "Inny trening na macie",
        value: "MI",
        backgroundColor: "#00ff00"
    },
    {
        label: "Inny trening program siły",
        value: "SI",
        backgroundColor: "#4a86e8"
    },
    {
        label: "Siłownia program siła",
        value: "S",
        backgroundColor: "#4a86e8"
    },
    {
        label: "Siłownia program moc",
        value: "SM",
        backgroundColor: "#4a86e8"
    },
    {
        label: "Siłownia program wytrzymałość siłowa",
        value: "SWS",
        backgroundColor: "#4a86e8"
    },
    {
        label: "Siłownia program siła moc",
        value: "SSM",
        backgroundColor: "#4a86e8"
    },
    {
        label: "Wytrzymałość tlenowa - bieg",
        value: "W1",
        backgroundColor: "#f90"
    },
    {
        label: "Wytrzymałość średniego czasu, dystanse (400-1500m)",
        value: "W2",
        backgroundColor: "#f90"
    },
    {
        label: "Wytrzymałość - interwały",
        value: "W2-3",
        backgroundColor: "#f90"
    },
    {
        label: "Wytrzymałość krótkiego czasu, sprinty (10-400m)",
        value: "W3",
        backgroundColor: "#f90"
    },
    {
        label: "Trening koordynacji, gimnastyka, akrobatyka",
        value: "TKGA",
        backgroundColor: "#0ff"
    },
    {
        label: "Trening mobilności, stabilności",
        value: "TMS",
        backgroundColor: "#0ff"
    },
    {
        label: "Gry",
        value: "G",
        backgroundColor: "#0ff"
    },
    {
        label: "Joga",
        value: "J",
        backgroundColor: "#0ff"
    },
    {
        label: "Joga + izometria",
        value: "J+J",
        backgroundColor: "#0ff"
    },
    {
        label: "Basen",
        value: "BA",
        backgroundColor: "#0ff"
    },
    {
        label: "Trening mentalny",
        value: "TM",
        backgroundColor: "#f0f"
    },
    {
        label: "Trening relaksacyjny",
        value: "TR",
        backgroundColor: "#f0f"
    },
    {
        label: "Odnowa biologiczna",
        value: "OB",
        backgroundColor: "#ff0"
    },
    {
        label: "Badania diagnostyczne / lekarskie",
        value: "B",
        backgroundColor: "#0000ff"
    },
    {
        label: "Przejazd na akcje szkoleniowe",
        value: "P",
        backgroundColor: "#a64d79"
    },
    {
        label: "Inny trening",
        value: "IT",
        backgroundColor: "#b6d7a8"
    },
    {
        label: "Zawody krajowe",
        value: "ZK",
        backgroundColor: "#ff0000"
    },
    {
        label: "Zawody międzynarodowe",
        value: "ZM",
        backgroundColor: "#ff0000"
    },
    {
        label: "Kontuzja",
        value: "K",
        backgroundColor: "#ea9999"
    },
    {
        label: "Choroba",
        value: "CH",
        backgroundColor: "#ea9999"
    },
    {
        label: "Jestem zmęczony/a nie trenuję",
        value: "NT",
        backgroundColor: "#ea9999"
    },
    {
        label: "Zajęcia szkoleniowe - teoretyczne",
        value: "ZT",
        backgroundColor: "#d9ead3"
    },
    {
        label: "Uczę się (szkoła, uczelnia)",
        value: "ZT",
        backgroundColor: "#FFF"
    }
];
const hours = ['06:00','06:15','06:30','06:45','07:00','07:15','07:30','07:45','08:00','08:15','08:30','08:45','09:00','09:15','09:30','09:45','10:00','10:15','10:30','10:45','11:00','11:15','11:30','11:45','12:00','12:15','12:30','12:45','13:00','13:15','13:30','13:45','14:00','14:15','14:30','14:45','15:00','15:15','15:30','15:45','16:00','16:15','16:30','16:45','17:00','17:15','17:30','17:45','18:00','18:15','18:30','18:45','19:00','19:15','19:30','19:45','20:00','20:15','20:30','20:45','21:00','21:15','21:30','21:45','22:00','22:15','22:30','22:45','23:00','23:15','23:30','23:45','00:00','00:15','00:30','00:45','01:00','01:15','01:30','01:45','02:00','02:15','02:30','02:45','03:00','03:15','03:30','03:45','04:00','04:15','04:30','04:45','05:00','05:15','05:30','05:45']
const hourInputTemplate = hours.map((hour: string) =>  {return {
    label: hour,
    value: hour,
    backgroundColor: "#d9ead3"
}});

const fatigueMeasurementInputTemplate = [
    {
        label: "Brak wysiłku 6",
        value: "6",
        backgroundColor: "#6aa84f"
    },
    {
        label: "Bardzo, bardzo lekki 7",
        value: "7",
        backgroundColor: "#78ac51"
    },
    {
        label: "Bardzo, bardzo lekki 8",
        value: "8",
        backgroundColor: "#87b153"
    },
    {
        label: "Bardzo lekki 9",
        value: "9",
        backgroundColor: "#96b655"
    },
    {
        label: "Bardzo lekki 10",
        value: "10",
        backgroundColor: "#a5bb58"
    },
    {
        label: "Lekki 11",
        value: "11",
        backgroundColor: "#b4c05a"
    },
    {
        label: "Umiarkowany 12",
        value: "12",
        backgroundColor: "#c3c55c"
    },
    {
        label: "Niezbyt ciężki 13",
        value: "13",
        backgroundColor: "#d2ca5f"
    },
    {
        label: "Dość ciężki 14",
        value: "14",
        backgroundColor: "#e1cf61"
    },
    {
        label: "Ciężki 15",
        value: "15",
        backgroundColor: "#f0d463"
    },
    {
        label: "Ciężki 16",
        value: "16",
        backgroundColor: "#ffd966"
    },
    {
        label: "Bardzo ciężki 17",
        value: "17",
        backgroundColor: "#ffa34d"
    },
    {
        label: "Bardzo ciężki 18",
        value: "18",
        backgroundColor: "#ff6d33"
    },
    {
        label: "Bardzo, bardzo ciężki 19",
        value: "19",
        backgroundColor: "#ff371a"
    },
    {
        label: "Maksymalny 20",
        value: "20",
        backgroundColor: "#ff0000"
    },

]

const traingIntensityInputTemplate = [
    {
        label: '10-19',
        value: '1',
        backgroundColor: '#6AA84F'
    },
    {
        label: '20',
        value: '1.5',
        backgroundColor: '#83B053'
    },
    {
        label: '21',
        value: '2',
        backgroundColor: '#9CB857'
    },
    {
        label: '22',
        value: '2.5',
        backgroundColor: '#B5C15B'
    },
    {
        label: '23',
        value: '3',
        backgroundColor: '#CDC95E'
    },
    {
        label: '24',
        value: '3.5',
        backgroundColor: '#E6D162'
    },
    {
        label: '25',
        value: '4',
        backgroundColor: '#FFD966'
    },
    {
        label: '26',
        value: '4.5',
        backgroundColor: '#FFBA57'
    },
    {
        label: '27',
        value: '5',
        backgroundColor: '#FF9B49'
    },
    {
        label: '28',
        value: '5.6',
        backgroundColor: '#FF7C3A'
    },
    {
        label: '29',
        value: '6',
        backgroundColor: '#FF5D2C'
    },
    {
        label: '30',
        value: '7.5',
        backgroundColor: '#FF3E1D'
    },
    {
        label: '31',
        value: '7',
        backgroundColor: '#FF1F0F'
    },
    {
        label: '32-33',
        value: '8',
        backgroundColor: '#ff0000'
    },
];

// URL żądania: https://api.judo-app.solvee.pl/input_definitions
export function getGroupInputs(inputGroupDefinitionId: string, calendarViewGroupId: string): any {
    return [
        {
            type: InputType.SELECT,
            name: "Cel Treningowy",
            enabled: true,
            parameters: {
                selectValues: trainingAimInputsTemplate
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 0,
            calendarViewGroupItems: [
                {
                    name: "Cel Treningowy",
                    itemOrder: 0,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: trainingAimInputsTemplate,
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.SELECT,
            name: "Godzina treningu",
            enabled: true,
            parameters: {
                selectValues: hourInputTemplate
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 1,
            calendarViewGroupItems: [
                {
                    name: "Godzina treningu",
                    itemOrder: 1,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: hourInputTemplate,
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.SELECT,
            name: "Główny cel treningowy",
            enabled: true,
            parameters: {
                selectValues: trainingAimInputsTemplate
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 2,
            calendarViewGroupItems: [
                {
                    name: "Główny cel treningowy",
                    itemOrder: 2,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: trainingAimInputsTemplate,
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.INPUT_TEXT,
            name: "Opis treningu",
            enabled: true,
            parameters: {
                selectValues: []
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 3,
            calendarViewGroupItems: [
                {
                    name: "Opis treningu",
                    itemOrder: 3,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: [],
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.INPUT_NUMERIC,
            name: "Czas treningu",
            enabled: true,
            parameters: {
                selectValues: []
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 4,
            calendarViewGroupItems: [
                {
                    name: "Czas treningu",
                    itemOrder: 4,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: [],
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.INPUT_NUMERIC,
            name: "Randori Tachi Waza min",
            enabled: true,
            parameters: {
                selectValues: []
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 5,
            calendarViewGroupItems: [
                {
                    name: "Randori Tachi Waza min",
                    itemOrder: 5,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: [],
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.INPUT_NUMERIC,
            name: "Randori Ne Waza min",
            enabled: true,
            parameters: {
                selectValues: []
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 6,
            calendarViewGroupItems: [
                {
                    name: "Randori Ne Waza min",
                    itemOrder: 6,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: [],
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.SELECT,
            name: "Skala oceny wysiłku",
            enabled: true,
            parameters: {
                selectValues: fatigueMeasurementInputTemplate
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 7,
            calendarViewGroupItems: [
                {
                    name: "Skala oceny wysiłku",
                    itemOrder: 7,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: fatigueMeasurementInputTemplate,
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.INPUT_NUMERIC,
            name: "Randori Ne Waza min",
            enabled: true,
            parameters: {
                selectValues: []
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 8,
            calendarViewGroupItems: [
                {
                    name: "Randori Ne Waza min",
                    itemOrder: 8,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: [],
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.SELECT,
            name: "Obciążenie treningowe",
            enabled: true,
            parameters: {
                selectValues: traingIntensityInputTemplate
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 9,
            calendarViewGroupItems: [
                {
                    name: "Obciążenie treningowe",
                    itemOrder: 9,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: traingIntensityInputTemplate,
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.SELECT,
            name: "Średnia intensywność",
            enabled: true,
            parameters: {
                selectValues: traingIntensityInputTemplate
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 10,
            calendarViewGroupItems: [
                {
                    name: "Średnia intensywność",
                    itemOrder: 10,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: traingIntensityInputTemplate,
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
        {
            type: InputType.INPUT_TEXT,
            name: "Uwagi",
            enabled: true,
            parameters: {
                selectValues: []
            },
            inputGroupDefinitionId: inputGroupDefinitionId,
            visualOptions: {},
            itemOrder: 11,
            calendarViewGroupItems: [
                {
                    name: "Uwagi",
                    itemOrder: 11,
                    enabled: true,
                    visualOptions: {},
                    valuesVisualOptions: [],
                    calendarViewGroupId: calendarViewGroupId
                }
            ]
        },
    ]
}

export function getGroup(groupNumber: number, calendarId: string, profileDefinitionId: string) {
    return {
        name: `Trening ${groupNumber}`,
        profileDefinitionId: profileDefinitionId,
        calendarViewGroups: [
            {
                name: `Trening ${groupNumber}`,
                itemOrder: groupNumber,
                calendarId: calendarId,
                visualOptions: {},
                enabled: true
            }
        ],
        type: "training"
    }
}