import { Observable } from "rxjs";
import {accountProviderAPI} from "./provider/accountProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function getAccountListFromUserListAPI(
    authToken: string,
    endpoint: string,
    pageNumber?: number,
    itemsPerPageValue?: number,
    isArchiveMode?: boolean
): Observable<any> {
    const page = pageNumber ? `&page=${pageNumber}` : '';
    const itemsPerPage = itemsPerPageValue ? `?itemsPerPage=${itemsPerPageValue}` : '?itemsPerPage=10';
    const getArchivedItemsQuery = isArchiveMode ? '&show_only_soft_deleted=true' : '';
    return accountProviderAPI.get(
        `${endpoint}${itemsPerPage}${page}${getArchivedItemsQuery}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
