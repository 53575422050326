import {authTokenSelector, Loader, LoaderType, Translation} from 'judo-app-common-web';
import React from 'react';
import {connect} from "react-redux";
import {Uploader} from 'rsuite';
import {Observable, of, Subscription} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {updateUserImageAPI} from "../../../api/updateUserImage";
import {updateUserProfileAPI} from "../../../api/updateUserProfile";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {RootState} from "../../../store/reducers";
import {changeShouldListUpdate} from '../../../store/reducers/sagaSlice';
import {EndpointNames} from "../AnimatedLayoutHost";
import TooltipWrapper from "../TooltipWrapper";
import styles from "../UserInfoBox/styles.module.scss";

export interface IFileUploadListElement {
    name: string;
    fileKey: string;
    url: string;
}
export interface FileType {
    name?: string;
    fileKey?: number | string;
    blobFile?: File;
    status?: 'inited' | 'uploading' | 'error' | 'finished';
    progress?: number;
    url?: string;
}

interface IAccountImageHostProps {
    readonly authToken: string;
    readonly account: any;
    readonly isClubProfile?: boolean;
    readonly onImageChange: (imageId: string) => void;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IAccountImageHostState {
    account: any;
    accountImage: string | Blob | null;
    value: any[];
    userImage: IFileUploadListElement | null;
    isProcessing: boolean;
}

class AccountImageHost extends React.Component<IAccountImageHostProps, IAccountImageHostState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IAccountImageHostProps) {
        super(props);
        this.state = {
            account: null,
            accountImage: null,
            userImage: null,
            value: [],
            isProcessing: false
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.account) this.setState({account: this.props.account});
        this.getAccountImage();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.account) {
            return null;
        }
        return (
            <div className={`${styles.userInfoImageWrapper} ${styles['edit-mode']}`}>
                <Loader type={LoaderType.Local} show={this.state.isProcessing} />
                {this.renderPlaceholder()}
                <Uploader draggable
                    removable={false}
                    multiple={false}
                    shouldQueueUpdate={this.shouldUpdate}
                    className={`edit-profile-image-host ${this.state.accountImage || this.state.userImage ? 'visible' : ''}`}
                    accept="image/png, image/jpeg"
                    listType="picture"
                    onChange={this.addFile}
                    autoUpload={false}
                    fileList={this.state.value}>
                    <div>
                        <TooltipWrapper tooltip={{text: `modal.editPersonalProfile.imageSizeWarning`}}
                            key={'add-edit-photo-button'}>
                            <div>
                                <span>
                                    <Translation text={`button.${this.state.userImage ? 'change' : 'add'}`} />
                                </span>
                                <span className="sr-only">
                                    <Translation
                                        text={`userList.table.button.${this.state.userImage ? 'edit' : 'add'}.userPhoto`} />
                                </span>
                            </div>
                        </TooltipWrapper>
                    </div>
                </Uploader>
                {this.state.accountImage &&
                    <React.Fragment>
                        <TooltipWrapper tooltip={{text: `modal.editPersonalProfile.saveProfileImage`}}
                            key={'save-photo-button'}>
                            <button className="edit-profile-image-accept-button" onClick={() => this.saveImage()}>
                                <span className={`feather icon-save`} aria-hidden="true" />
                                <span className="sr-only">
                                    <Translation text={`modal.editPersonalProfile.saveProfileImage`} />
                                </span>
                            </button>
                        </TooltipWrapper>

                        <TooltipWrapper tooltip={{text: `modal.editPersonalProfile.cancelProfileImage`}}
                            key={'cancel-photo-button'}>
                            <button className="edit-profile-image-remove-button" onClick={() => this.removeImage()}>
                                <span className={`feather icon-x-circle`} aria-hidden="true" />
                                <span className="sr-only">
                                    <Translation text={`modal.editPersonalProfile.cancelProfileImage`} />
                                </span>
                            </button>
                        </TooltipWrapper>
                    </React.Fragment>
                }
            </div>
        );
    }

    private shouldUpdate = (fileList: FileType[], newFile: FileType | FileType[]) => {
        const clonedNewFileObj = Object.assign({}, newFile as any),
            fileItem = clonedNewFileObj[0];
        if (fileItem && fileItem.blobFile) {
            const itShouldUpdate = fileItem.blobFile.size < 1048576;
            if (!itShouldUpdate) {
                this.alertManagerService.addAlert('modal.editPersonalProfile.imageTooBig');
            }
            return itShouldUpdate;
        }
        return false;
    }

    private addFile = (file: any) => {
        if (!file.length) {
            return this.setState({value: [], accountImage: null, userImage: null});
        }
        return this.setState({
            value: file.slice(file.length - 1),
            accountImage: file.length > 1 ? file[1].blobFile : file[0].blobFile,
        })
    };

    private removeImage = () => {
        if (this.state.userImage) {
            return this.setState({value: [this.state.userImage], accountImage: null})
        }
        return this.setState({value: [], accountImage: null});
    }

    private saveImage = () => {
        if (!this.state.accountImage) {
            return;
        }
        this.setState({isProcessing: true})
        const formData = new FormData();
        const imageData = this.state.accountImage;
        formData.append('file', imageData);
        formData.append('public', 'true');

        window.fetch(
            `${process.env.REACT_APP_API_URL as string}/media_objects`,
            {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    'Authorization': 'Bearer ' + this.props.authToken
                })
            }
        ).then((resp: any) => {
            return resp.json().then((fileUploadObj: any) => {
                const imageId = fileUploadObj['@id'].replace('/media_object/', '');
                const profileId = this.props.isClubProfile ? this.props.account.id : this.props.account.user.id;
                this.props.onImageChange(imageId ? imageId : null);
                if (this.props.isClubProfile) {
                    const coachesAccountIdArray = this.state.account.coaches.map((coachAccount: any) => coachAccount.coachId);
                    const payload = {
                        name: this.state.account.name,
                        coaches: coachesAccountIdArray,
                        logoId: imageId,
                        disabled: this.state.account.disabled
                    }
                    return this.updateProfileImage(updateUserProfileAPI(this.props.authToken, payload, EndpointNames.CLUBS, profileId))
                }
                return this.updateProfileImage(updateUserImageAPI(this.props.authToken, imageId, profileId))
            })
        })
    };


    private updateProfileImage(api: Observable<any>): void {
        this.subscriptions.push(
            api.pipe(
                tap((response) => {
                    const userPhoto = response.photo ? response.photo : response.login;
                    if (userPhoto) {
                        let mediaList: IFileUploadListElement = {
                            name: 'user_image',
                            fileKey: userPhoto.id,
                            url: userPhoto.contentUrl
                        };
                        this.setState({userImage: mediaList, value: [mediaList]});
                    }
                    this.alertManagerService.addAlert('modal.editPersonalProfile.editImageSuccess');
                    this.props.changeShouldListUpdate(true);
                    this.setState({isProcessing: false, accountImage: null});
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error.response);
                    this.setState({isProcessing: false});
                    return of();
                }
                )).subscribe());
    }

    private getAccountImage() {
        if (this.props.account) {
            let userImage = this.props.account.user?.photo ? this.props.account.user.photo : this.props.account.logo;
            if (userImage) {
                let mediaList: IFileUploadListElement = {
                    name: 'user_image',
                    fileKey: userImage.id,
                    url: userImage.filteredMediaObjectUrls.large_thumb
                };
                this.setState({userImage: mediaList, value: [mediaList]});
            }
        }
    }

    private renderPlaceholder() {
        if (this.state.userImage || this.state.accountImage || this.state.value.length) {
            return null
        }
        return <div className={styles.userPhotoPlaceholder}>
            <span>{this.renderUserInitials()}</span>
        </div>
    }

    private renderUserInitials() {
        if (!this.state.account) return 'XX';
        let initials = '';
        if (this.state.account.name) {
            initials = (this.state.account.name.charAt(0) || '');
            return initials;
        }
        initials = `${this.state.account.firstName.charAt(0)} ${this.state.account.lastName.charAt(0)}`
        initials.toUpperCase();
        return initials;
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeShouldListUpdate,
    }
)(AccountImageHost);
