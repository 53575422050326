import {FormControlType, IBasicFormControlConfig, InputType} from "judo-app-common-web";
export const loginInputConfig: {[key: string]: typeof IBasicFormControlConfig } = {
    login: {
        controlType: "control",
        hostClass: "",
        formControlType: FormControlType.INPUT,
        validationRules: [],
        disabled: true,
        placeholder: '',
        defaultValue: '',
        label: 'account.changePassword.form.login.label',
        type: InputType.EMAIL,
    }
}
