import React from "react";
import styles from '../styles.module.scss'
import {GraphParameter} from "../index";
import {Translation} from "judo-app-common-web";

interface Props {
    parameters: GraphParameter[]
    selectParameter: (parameter: GraphParameter) => void
    selectedParameters: GraphParameter[]
}

class ParametersList extends React.Component<Props> {
    render() {
        return <div className={styles.listHeight}>
            <h3 className={styles.listTitle}><Translation text={'profiles.modal.chart.parameters'}/></h3>
            <ul className="list-group">
                {this.props.parameters.map( parameter =>
                    <li className={`list-group-item ${styles.itemSelect}`} onClick={() => this.props.selectParameter(parameter)} key={parameter.id}>
                        <div className="form-check">
                            <input type="checkbox"
                                   className="form-check-input"
                                   readOnly
                                   checked={!!this.props.selectedParameters.find(param => param.id === parameter.id)}/>
                            <label className="form-check-label ml-2">{parameter.name}</label>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    }
}

export default ParametersList
