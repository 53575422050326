import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function updateUserProfileAPI(
    authToken: string,
    payload: any,
    userType: string,
    profileId: string
): Observable<any> {
    return baseDataProviderAPI.put(
        `${userType}/${profileId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
