import React from 'react';
import {connect} from 'react-redux';
import {registerFormConfig} from './registerFormConfig';
import {
    CustomCard,
    Form,
    IBasicFormControlConfig,
    loginLoadingSelector,
    Translation
} from "judo-app-common-web";
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {getAuthTokenDataAPI} from "../../../api/getAuthTokenData";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {registerAPI} from "../../../api/register";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {WithTranslation, withTranslation} from "react-i18next";

const queryString = require('query-string');

interface IRegisterProps extends RouteComponentProps,
    WithTranslation {
    isLoading: boolean;
}

interface IRegisterState {
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    authToken: string | null;
    login: string | null;
}

class Register extends React.Component<IRegisterProps, IRegisterState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IRegisterProps) {
        super(props);

        this.state = {
            formConfig: registerFormConfig,
            authToken: null,
            login: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        const searchParams = queryString.parse(this.props.location.search);

        this.setState({authToken: searchParams.authenticationToken}, () => {
            if (this.state.authToken) {
                getAuthTokenDataAPI(this.state.authToken).pipe(
                    map((response: any) => {
                        this.setState({login: response.subject.login}, () => {
                            this.updateFormValue();
                        });
                    }),
                    catchError((error: any) => {
                        this.alertManagerService.handleApiError(error);
                        return of();
                    })
                ).subscribe();
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<IRegisterProps>, prevState: Readonly<IRegisterState>, snapshot?: any): void {
        if (this.state.login !== prevState.login) {
            this.updateFormValue();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <CustomCard showLocalLoader={this.props.isLoading} type={"auth"} >
                    <CustomCard.Header>
                        <h3><Translation text={'auth.register.title'}/></h3>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <Form config={this.state.formConfig}
                              submitForm={this.register}
                              controlName={"registerForm"}
                              value={{}}/>

                        <p className="mb-2 text-muted">
                            <Translation text={'auth.haveAnAccount'}/>
                            <Link className="f-w-400 ml-2" to="/auth/login">
                                <Translation text={'auth.haveAnAccountLogin'}/>
                            </Link>
                        </p>
                    </CustomCard.Body>
                </CustomCard>
            </>
        );
    }

    private register =(event: any, value: any, touched: boolean, valid: boolean) => {
        if (!touched || !valid || !this.state.authToken) {
            return;
        }

        const {t} = this.props;

        this.subscriptions.push(
            registerAPI(this.state.authToken, this.state.login, value.password).pipe(
            map(() => {
               window.location.href = window.location.origin + "/auth/login";
                this.alertManagerService.addAlert(t('alert.registration.success'));
            }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
        ).subscribe());
    };

    private updateFormValue = () => {
        registerFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'email') {
                        control.controls[key].value = this.state.login;
                        control.controls[key].defaultValue = this.state.login;
                    }
                    return control.control;
                });
            }
            return control;
        });

        this.setState({formConfig: registerFormConfig});
    }
}

export default withTranslation()(connect(
    (state: any) => ({
        isLoading: loginLoadingSelector(state),
    }),
    {}
)(withRouter(Register)));
