import {BasicFormControlType, FormButtonType, IFormConfig, InputType, ValidationRules} from "judo-app-common-web";
import store from "../../../../store";
import {changeModal} from "../../../../store/reducers/sagaSlice";


export const editClubFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'create-group-form mb-3',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name_group',
            controlType: 'group',
            class: 'd-flex flex-row mt-3',
            controls: {
                name: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                    ],
                    placeholder: 'modal.editClub.form.name.placeholder',
                    isLabelHidden: false,
                    label: 'modal.editClub.form.name.label',
                    type: InputType.TEXT,
                    hostClass: "ml-3"
                }
            }
        },
        {
            key: 'buttons',
            controlType: 'group',
            class: 'button-group-wrapper mt-5',
            controls: {
                cancelButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    hostClass: 'button-container',
                    defaultContainerStyles: 'd-flex justify-content-end mx-3',
                    defaultInputStyles: 'btn btn-theme-outline',
                    defaultValue: null,
                    buttonDisabled: false,
                    btnText: `button.cancel`,
                    isLabelHidden: true,
                    customClickHandler: () => store.dispatch(changeModal(null))
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'button-container',
                    defaultContainerStyles: 'd-flex justify-content-end',
                    defaultInputStyles: 'btn btn-theme',
                    defaultValue: null,
                    btnText: `button.change`,
                    isLabelHidden: true,
                }
            }
        }
    ]
};
