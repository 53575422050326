import React from 'react';
import {authTokenSelector, CustomModal, Loader, LoaderType, setAuthState, Translation} from 'judo-app-common-web';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {ModalConfig, UserType} from "../../../../model/models";
import {changeModal, changeShouldListUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {of, Subscription} from "rxjs";
import {EndpointNames} from "../../../Shared/AnimatedLayoutHost";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {archiveProfileAPI} from "../../../../api/archiveProfile";
import {catchError, tap} from "rxjs/operators";


interface IModalArchiveProfileProps extends RouteComponentProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalArchiveProfileState {
    profile: any;
    isProcessing: boolean;
}

class ModalArchiveProfile extends React.Component<IModalArchiveProfileProps, IModalArchiveProfileState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalArchiveProfileProps) {
        super(props);
        this.state = {
            profile: null,
            isProcessing: true
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.data) {
            let updatedProfile = deepCloneObject(this.props.modal.data);
            if (this.props.modal.data.name) {
                updatedProfile.type = UserType.CLUB;
            }
            this.setState({
                profile: updatedProfile, isProcessing: false
            })
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.profile || !this.state.profile.id || !this.props.modal) {
            return null;
        }
        const profileName = this.state.profile.name ? this.state.profile.name : `${this.state.profile.firstName} ${this.state.profile.lastName}`
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class='assign-profile-modal'>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={`archive.archiveModal.header.${this.state.profile.type}`}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <p>
                        <span>
                            <Translation text={'archive.archiveModal.question'}/>
                        </span>
                        <span className="outlined-box">{profileName}</span>
                        <span>?</span>
                    </p>
                </CustomModal.Body>
                <CustomModal.Footer>
                    <button className="btn btn-theme btn-small ml-3" onClick={() => this.props.changeModal(null)}>
                        <span>
                            <Translation text={'button.cancel'}/>
                        </span>
                    </button>
                    <button className="btn btn-danger btn-small ml-3" onClick={this.archiveProfile}>
                        <span>
                            <Translation text={'button.archive'}/>
                        </span>
                    </button>
                </CustomModal.Footer>
            </CustomModal>
        );
    }


    private archiveProfile = () => {
        if (!this.state.profile || !this.state.profile.type || !this.props.authToken) {
            return null;
        }
        let endpointName: any = null,
            profileId = null;
        switch (this.state.profile.type) {
            case UserType.CONTESTANT:
                endpointName = EndpointNames.CONTESTANTS;
                profileId = this.state.profile.contestant.id;
                break;
            case UserType.COACH:
                endpointName = EndpointNames.COACHES;
                profileId = this.state.profile.coach.id;
                break;
            case UserType.SPECIALIST:
                endpointName = EndpointNames.SPECIALISTS;
                profileId = this.state.profile.specialist.id;
                break;
            case UserType.CLUB:
                endpointName = EndpointNames.CLUBS;
                profileId = this.state.profile.id;
                break;
            default:
                return null;
        }
        if (!endpointName || !profileId) return null;
        this.setState({isProcessing: true});
        return this.subscriptions.push(archiveProfileAPI(this.props.authToken, endpointName, profileId).pipe(
            tap(() => {
                this.alertManagerService.addAlert('archive.archiveModal.archiveSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldListUpdate(true);
                this.changeViewToList(endpointName);
                this.props.changeModal(null);
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe());
    }

    private changeViewToList(endpointName: any) {
        this.props.history.push(`/${endpointName}/`);
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldListUpdate,
        setAuthState,
    }
)(withRouter(ModalArchiveProfile));
