import React from 'react';
import AnimatedLayoutHost, {EndpointNames} from "../Shared/AnimatedLayoutHost";
import ClubList from "./ClubList";
import ClubView from "./ClubView";

interface IClubsProps {
    isArchiveMode: boolean;
}

class Clubs extends React.Component<IClubsProps> {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <AnimatedLayoutHost host={"clubs"}
                                            listComponent={ClubList}
                                            viewComponent={ClubView}
                                            endpointName={EndpointNames.CLUBS}
                                            isArchiveMode={this.props.isArchiveMode}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Clubs;
