import {IBaseFormConfig, IFormControlConfig} from "judo-app-common-web";
import {injectable} from "inversify";
import {fixInjectedProperties} from "../ioc";
import {InputType, RawInputGroup, RawProfile, RawProfileInput} from "../model/models";

export interface INextStep {
    nextStepId: string
}

export interface IStep {
    controls?: {[key: string]: any}[];
    id?: string;
    initial?: boolean;
    name?: string;
    nextStepRules?: INextStep[];
}

export interface IFormSteps {
    name: string;
    steps: IStep[];
    isTraining?: boolean;
}

export interface IFormConfigConverterService {
    convertDataToFormConfig(profile: {[key: string]: any}): typeof IBaseFormConfig[];
    convertTrainingDataToFormConfig(formGroup: RawInputGroup): typeof IBaseFormConfig[];
    convertFormToSteps(form: typeof IBaseFormConfig[], name: string, isTraining?: boolean): IFormSteps | null;
    setDefaultFormInput(formControl: RawProfileInput | undefined, index: number): typeof IFormControlConfig;
}

@injectable()
class FormConfigConverterService {
    constructor() {
        fixInjectedProperties(this);
    }

    convertDataToFormConfig(profile: RawProfile): typeof IBaseFormConfig[] {
        let formConfigs: typeof IBaseFormConfig[] = [];
        let formControl: typeof IFormControlConfig = {};
        profile.inputGroups.sort((a,b) => a.itemOrder < b.itemOrder ? -1 : 1).forEach((formGroup: RawInputGroup) => {
            if (!formGroup.inputGroupDefinitionEnabled) {
                return null;
            }
            let form: typeof IFormControlConfig = this.setDefaultFormConfig(formGroup.inputGroupName, formGroup.inputGroupDefinitionId);
            formGroup.profileInputs.sort((a,b) => a.itemOrder < b.itemOrder ? -1 : 1).forEach((control: RawProfileInput, index: number) => {
                formControl = this.setDefaultFormInput(control, index);
                form.controls.push(formControl);
                return form;
            });
            return formConfigs.push(form);
        });

        return formConfigs;
    }

    convertTrainingDataToFormConfig(formGroup: RawInputGroup): typeof IBaseFormConfig[] {
        let formControl: typeof IFormControlConfig = {};
        let form: typeof IFormControlConfig = this.setDefaultFormConfig(formGroup.inputGroupName, formGroup.inputGroupDefinitionId);
            formGroup.profileInputs.sort((a,b) => a.itemOrder < b.itemOrder ? -1 : 1).forEach((control: RawProfileInput, index: number) => {
                formControl = this.setDefaultFormInput(control, index, true);
                form.controls.push(formControl);
            });
        return [form];
    }


    convertFormToSteps(form: typeof IBaseFormConfig[], name: string, isTraining?: boolean): IFormSteps | null {
        if (!form) {
            return null;
        }

        let steps: IStep[] = [];
        form.forEach((item: any, index: number) => {
            let step: IStep = {};
            step['controls'] = item.controls;
            step['id'] = item.id;
            step['name'] = item.controlName;
            step['initial'] = item.index === 0;
            if (item.index >= form.length + 1 || isTraining) {
                step['nextStepRules'] = [];
            } else {
                step['nextStepRules'] = [{
                    nextStepId: form[index + 1]?.id || form.length.toString()
                }];
            }
            return steps.push(step);
        });

        let formSteps: IFormSteps = {
            name: name,
            steps: steps
        };
        if (!isTraining) {
            formSteps.steps.push(this.setSuccessFormControls(form.length.toString()));
        } else {
            formSteps.isTraining = true;
        }
         return formSteps;
    }

    private setDefaultFormConfig(controlName: string, id: string): typeof IBaseFormConfig {
        return {
            controlType: 'form',
            class: '',
            controlName: controlName,
            dataAccessor: (data: any) => data,
            outputDataMapper: (data: any, previousStateSnapshot: any) => {
                Object.assign(previousStateSnapshot, data);

                return previousStateSnapshot;
            },
            controls: [],
            locale: "pl_PL",
            id: id
        };
    }

    private setDefaultFormInput(formControl: RawProfileInput | null, index: number, isTrainingInput?: boolean): typeof IFormControlConfig {
        if(!formControl) {
            return
        }
        let selectOptions: any[] = [];
        if(formControl.inputParameters?.selectValues){
            formControl.inputParameters.selectValues.forEach((selectValue: any) => selectOptions.push({label: {translations: [{value: selectValue.label, language: "pl_PL"}]}, value: selectValue.value}));
        }
        return {
            id: formControl.inputDefinitionId,
            controlType: 'control',
            inputType: formControl.inputType === InputType.INPUT_NUMBER ? InputType.INPUT_NUMBER : null,
            formControlType: formControl.inputType ? (formControl.inputType === InputType.INPUT_NUMBER ? 'input' : formControl.inputType) : 'input',
            additionalClasses: [],
            outputDataMapper: (data: any) => {
                return data;
            },
            inputDataMapper: (data: any) => {
                return data || [];
            },
            name: formControl.inputId ? formControl.inputId : '',
            disabled: (isTrainingInput && formControl.inputName === 'Cel Treningowy') ? true : false,
            controlOptions: {
                id: formControl.inputDefinitionId,
                // todo name as label
                label: formControl.inputName,
                showLabel: true,
                // placeholder: formControl?.inputResultValue?.value,
                defaultValue: formControl?.inputResultValue?.value,
                value: formControl?.inputResultValue?.value,
                variables: [],
                expression: formControl?.inputParameters?.expression || null,
                formOptions: {
                    formOptions: selectOptions ? selectOptions : null
                },
                //todo fix below
                datepickerType:  formControl?.inputParameters?.type ? formControl.inputParameters.type : null,
                formDefaultOptions: [],
                renderType: formControl.inputType === InputType.SELECT ? InputType.SELECT : null
            },
            validationRules: [
                // {
                //     name: "required",
                //     params: []
                // }
            ],
            //todo name as label
            controlName: formControl?.inputDefinitionId,
            locale: "pl_PL",
        }
    }

    private setSuccessFormControls(id: string) {
        return {
            id: id,
            initial: false,
            name: "dziękujemy",
            nextStepRules: [],
            controls: [
                {
                    id: "success-step",
                    controlType: 'control',
                    // inputType: formControl.type ? formControl.type : 'textarea', // toDo how to add inputType
                    formControlType: "caption",
                    additionalClasses: [],
                    outputDataMapper: (data: any) => {
                        return data;
                    },
                    inputDataMapper: (data: any) => {
                        return data || [];
                    },
                    name: "success-step",
                    controlOptions: {
                        label: "Formularz został wypełniony",
                        showLabel: true,
                        required: false,
                        defaultValue: null,
                        variables: [],
                        formDefaultOptions: [],
                    },
                    validationRules: [],
                    controlName: "success-step"
                }
            ]
        }
    }
}

export default FormConfigConverterService;
