import React from 'react';
import {authTokenSelector, Form, IFormConfig, Translation} from 'judo-app-common-web';
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {deepCloneObject} from "../../../../../utils/runtimeUtils";
import {CalendarGroup} from '../../../../../model/models';
import {updateGroupFormConfig} from "./updateGroupFormConfig";

interface IUpdateGroupFormProps {
    calendarGroup: CalendarGroup;
    onGroupUpdate: (group: CalendarGroup, isGroupChanged: boolean) => void;
}

interface IUpdateGroupFormState {
    calendarGroup: CalendarGroup | null;
    formConfig: typeof IFormConfig;
    value: any;
}

class UpdateGroupForm extends React.Component<IUpdateGroupFormProps, IUpdateGroupFormState> {
    constructor(props: IUpdateGroupFormProps) {
        super(props);
        this.state = {
            calendarGroup: null,
            formConfig: updateGroupFormConfig({
                color: this.props.calendarGroup.visualOptions.backgroundColor,
                name: this.props.calendarGroup.name
            }),
            value: '',
        };
    }

    componentDidMount() {
        if (this.props.calendarGroup) {
            const group = this.props.calendarGroup;
            let formConfigUpdated = deepCloneObject(this.state.formConfig);
            if (group) {
                formConfigUpdated.controls[0].controls.name.value = group.name;
                formConfigUpdated.controls[0].controls.name.defaultValue = group.name;
                formConfigUpdated.controls[0].controls.color.color = group.visualOptions.backgroundColor ? group.visualOptions.backgroundColor : 'transparent';
                formConfigUpdated.controls[0].controls.color.defaultColor = group.visualOptions.backgroundColor ? group.visualOptions.backgroundColor : 'transparent';
            }
            this.setState({formConfig: formConfigUpdated, calendarGroup: group});
            this.props.onGroupUpdate(this.props.calendarGroup, false)
        }
    }

    componentDidUpdate(prevProps: Readonly<IUpdateGroupFormProps>, prevState: Readonly<IUpdateGroupFormState>) {
        if (this.props.calendarGroup !== prevProps.calendarGroup) {
            const group = this.props.calendarGroup;
            let formConfigUpdated = deepCloneObject(this.state.formConfig);
            if (group) {
                formConfigUpdated.controls[0].controls.name.defaultValue = group.name;
                formConfigUpdated.controls[0].controls.name.value = group.name;
                formConfigUpdated.controls[0].controls.color.color = group.visualOptions.backgroundColor ? group.visualOptions.backgroundColor : 'transparent';
                formConfigUpdated.controls[0].controls.color.defaultColor = group.visualOptions.backgroundColor ? group.visualOptions.backgroundColor : 'transparent';
            }
            this.setState({formConfig: formConfigUpdated, calendarGroup: group});
        }
    }

    componentWillUnmount() {
        this.setState({calendarGroup: null, value: null, formConfig: null})
    }

    render() {
        return <div className="add-input-form-wrapper">
            <h4 className="sr-only">
                <Translation text="userView.variableGroup.modal.addGroupForm.header"/>
            </h4>
            <Form
                config={this.state.formConfig}
                submitForm={this.props.onGroupUpdate}
                onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                controlName={"updateGroupForm"}
                value={{}}
            />
        </div>
    }

    private updateFormData(controlName: string, data: any) {
        let updatedGroup: CalendarGroup = deepCloneObject(this.state.calendarGroup);
        if (data) {
            updatedGroup.name = data.new_group.name?.trim();
            updatedGroup.visualOptions.backgroundColor = data.new_group.color ? data.new_group.color : this.state.formConfig.controls[0].controls.color.color;
        }
        const isDataChanged = (updatedGroup.name && this.props.calendarGroup.name !== updatedGroup.name) || this.props.calendarGroup.visualOptions.backgroundColor !== updatedGroup.visualOptions.backgroundColor;
        this.props.onGroupUpdate(updatedGroup, isDataChanged)
        this.setState({calendarGroup: updatedGroup});
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(UpdateGroupForm);
