import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Translation} from "judo-app-common-web";
import {CalendarGroup} from "../../../../model/models";
import {transformObjectToCSSProperties} from "../../../../utils/fatigueTableUtils";
import TooltipWrapper from "../../../Shared/TooltipWrapper";

interface MatchProcessParams {
    host?: string;
    profileId?: string
}

interface ICalendarSettingsGroupItemState {
    selectedVariableGroup: CalendarGroup | null
}

interface CalendarSettingsGroupItemProps extends RouteComponentProps<MatchProcessParams> {
    listItem: CalendarGroup;
    key: string;
    onArchiveItem: (id: string | null) => void;
    onGroupEditSelected: (group: CalendarGroup) => void;
    onGroupChange: (group: CalendarGroup) => void;
}

class CalendarSettingsGroupItem extends React.Component<CalendarSettingsGroupItemProps, ICalendarSettingsGroupItemState> {
    constructor(props: CalendarSettingsGroupItemProps) {
        super(props);
        this.state = {
            selectedVariableGroup: null
        }
    }

    componentDidUpdate(prevProps: Readonly<CalendarSettingsGroupItemProps>, prevState: Readonly<ICalendarSettingsGroupItemState>) {
        if (this.props.listItem !== prevProps.listItem && this.state.selectedVariableGroup) {
            this.setState({selectedVariableGroup: this.props.listItem});
        }
    }

    render() {
        return <React.Fragment>
            <div className="d-flex flex-wrap profile-wrapper">
                {this.renderItemColor()} {this.props.listItem.name}
            </div>
            {this.renderActionButtons()}
        </React.Fragment>
    }

    private renderItemColor() {
        if (!this.props.listItem.visualOptions) {
            return null;
        }
        const visualOptions = transformObjectToCSSProperties(this.props.listItem.visualOptions);
        return <div aria-hidden={true} className="list-item-color ml-2" style={visualOptions} />;
    }

    private renderActionButtons() {
        return <div className="button-wrapper">
            <TooltipWrapper tooltip={{text: 'userList.table.button.show.variableGroup'}} key={'profile-button'}>
                <button className="btn btn-list fatigue-profile mr-l"
                        onClick={() => this.props.onGroupEditSelected(this.props.listItem)}>
                    <span className="sr-only"><Translation text={'userList.table.button.show.variableGroup'}/></span>
                    <span className="feather icon-edit" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
{/*// TODO: When profile/group/variable archivisation will be ready uncomment and */}
            {/*<TooltipWrapper tooltip={{text: 'userList.table.button.archive.variableGroup'}} key={'fatigue-button'}>*/}
            {/*    <button className="btn btn-list fatigue-profile delete ml-3"*/}
            {/*            onClick={() => this.props.onArchiveItem(this.props.listItem.id)}>*/}
            {/*        <span className="sr-only"><Translation text={'userList.table.button.archive.variableGroup'}/></span>*/}
            {/*        <span className="feather icon-trash" aria-hidden="true"/>*/}
            {/*    </button>*/}
            {/*</TooltipWrapper>*/}
        </div>
    }
}

export default withRouter(CalendarSettingsGroupItem);
