import React from 'react';
import {Translation} from "judo-app-common-web";
import TooltipWrapper from '../../../../../Shared/TooltipWrapper';
import {CalendarInputOption} from '../../../../../../model/models';

interface SelectOptionsListItemProps {
    listItem: CalendarInputOption;
    listItemIndex: number;
    onDeleteItem: (index: number) => void;
    onEditItem: (option: CalendarInputOption, index: number) => void;
    onCreateItem: (option: CalendarInputOption) => void;
    editMode?: boolean;
}

interface SelectOptionsListItemState {
    editMode: boolean;
    createMode: boolean;
    newOption: CalendarInputOption | null;
}

class SelectOptionsListItem extends React.Component<SelectOptionsListItemProps, SelectOptionsListItemState> {
    constructor(props: SelectOptionsListItemProps) {
        super(props);
        this.state = {
            editMode: false,
            createMode: false,
            newOption: null,
        }
    }

    render() {
        return <React.Fragment>
            <div className="d-flex flex-wrap profile-wrapper">
                {this.renderItemColor()} {this.props.listItem.label} {this.props.listItem.value}
            </div>
            {this.renderActionButtons()}
        </React.Fragment>
    }

    private renderItemColor() {
        if (!this.props.listItem?.backgroundColor) {
            return null;
        }
        return <div aria-hidden={true} className="list-item-color ml-2"
                    style={{backgroundColor: this.props.listItem.backgroundColor}}/>;
    }

    private renderActionButtons() {
        return <div className="button-wrapper">
            <TooltipWrapper tooltip={{text: 'profileSettingsModal.button.edit.variableOption'}}
                            key={'variable-edit-button'}>
                <button className="btn btn-list fatigue-profile mr-l"
                        onClick={() => this.props.onEditItem(this.props.listItem, this.props.listItemIndex)}>
                    <span className="sr-only"><Translation
                        text={'profileSettingsModal.button.edit.variableOption'}/></span>
                    <span className="feather icon-edit" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            <TooltipWrapper tooltip={{text: 'profileSettingsModal.button.delete.variableOption'}} key={'fatigue-button'}>
                <button className="btn btn-list fatigue-profile delete ml-3"
                        onClick={() => this.props.onDeleteItem(this.props.listItemIndex)}>
                    <span className="sr-only"><Translation
                        text={'profileSettingsModal.button.delete.variableOption'}/></span>
                    <span className="feather icon-trash" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            <TooltipWrapper tooltip={{text: 'userList.table.button.clickToDrag'}} key={'drag-handle'}>
                <div data-movable-handle className="drag-handle ml-3">
                    <span className="sr-only"><Translation text={'userList.table.button.clickToDrag'}/></span>
                    <span className="feather icon-menu" aria-hidden="true"/>
                </div>
            </TooltipWrapper>
        </div>
    }
}

export default SelectOptionsListItem;
