import React from 'react';
import {authTokenSelector, CustomModal, Loader, LoaderType, Translation} from 'judo-app-common-web';
import {arrayMove, IItemProps, List} from "react-movable";
import FormVariableListItem from "./FormVariableListItem";
import {CalendarInput, InputDefinition, InputGroupDefinition, ModalConfig} from "../../../../model/models";
import {FormDragDropVariable} from "../../../../utils/fatigueProfilesUtils";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import FormAddItemMenu from "../../../DevelopmentProfiles/FatigueProfilesView/FatigueForm/FormGroupList/FormAddItemMenu";
import {getInputGroupDefinitionAPI} from "../../../../api/getInputGroupDefinition";
import {catchError, map, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {updateInputGroupDefinitionAPI} from "../../../../api/updateInputGroupDefinition";
import {changeModal} from "../../../../store/reducers/sagaSlice";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {ExecutableExpressionDTOFormatter} from "../../../../service";

interface IModalFormVariableListProps {
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly authToken: string;
    readonly changeModal: typeof changeModal;
}

interface IModalFormVariableListState {
    enabledItems: FormDragDropVariable[],
    disabledItems: FormDragDropVariable[],
    group: InputGroupDefinition | null,
    isProcessing: boolean;
}

class ModalFormVariableList extends React.Component<IModalFormVariableListProps, IModalFormVariableListState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('ExecutableExpressionDTOFormatter') private executableExpressionDTOFormatter: ExecutableExpressionDTOFormatter;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalFormVariableListProps) {
        super(props);
        this.state = {
            enabledItems: [],
            disabledItems: [],
            group: null,
            isProcessing: false,
        }
        fixInjectedProperties(this);

    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.id) {
            this.getFormGroup(this.props.modal.id);
        }
        if (this.state.group) {
            let sortedGroup: any = this.state.group.inputDefinitions;
            sortedGroup.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
            const splitDataGroups = this.splitInputsIntoGroups(sortedGroup);
            this.setState({
                enabledItems: splitDataGroups.enabled,
                disabledItems: splitDataGroups.disabled
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IModalFormVariableListProps>, prevState: Readonly<IModalFormVariableListState>) {
        if (this.state.group && prevState.group !== this.state.group) {
            let sortedGroup: any = this.state.group.inputDefinitions;
            sortedGroup.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
            const splitDataGroups = this.splitInputsIntoGroups(sortedGroup);
            this.setState({
                enabledItems: splitDataGroups.enabled,
                disabledItems: splitDataGroups.disabled
            });
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }


    render() {
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="fatigue-profile-variables-modal">
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <h2 className="ellipsis-host fatigue-variables-header">
                        <div className="ellipsis-item">
                            <Translation text={'userView.variableGroup.modal.header'}/>
                            {this.state.group?.name}
                        </div>
                    </h2>
                </CustomModal.Header>
                <CustomModal.Body>
                    {this.renderVariableList()}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private renderVariableList() {
        if (this.state.enabledItems) {
            const itemList = this.state.enabledItems;
            const removeGroupVariable = (index: number | undefined) => {
                if (index !== undefined) {
                    const updatedInputId = itemList[index].inputDefinition.id;
                    const updatedInputOrder = itemList[index].inputDefinition.itemOrder;

                    let updatedGroup = deepCloneObject(this.state.group);

                    updatedGroup.inputDefinitions = updatedGroup.inputDefinitions.map((input: CalendarInput) => {
                        if (input.itemOrder && input.itemOrder > updatedInputOrder) {
                            input.itemOrder = input.itemOrder - 1
                        }

                        if (input.id === updatedInputId) {
                            input.enabled = false;
                            input.itemOrder = updatedGroup.inputDefinitions.length
                        }
                        return input;
                    }).sort((a: CalendarInput, b: CalendarInput) => {
                        if (a.itemOrder && b.itemOrder) {
                            return a.itemOrder > b.itemOrder ? 1 : -1
                        }
                        return 0
                    })

                    this.updateGroupDefinition(updatedGroup);
                }
            };

            const liStyles = (props: IItemProps, isDragged: boolean, isSelected: boolean) => {
                return {
                    ...props.style,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem 1rem',
                    listStyle: 'none',
                    cursor: isDragged ? 'grabbing' : 'grab',
                    fontSize: '1.6rem',
                    fontFamily: `'Nunito', sans-serif`,
                    borderRadius: '5px',
                    verticalAlign: 'middle',
                    color: 'inherit',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF',
                    zIndex: 1451
                }
            }

            return <section className="user-connection-list-wrapper">
                <div className="user-connection-list-header fatigue-header justify-content-end">
                    <h3 className="sr-only">
                        <Translation text={'userView.header.variableGroup'}/> {this.state.group?.name}
                    </h3>
                    <FormAddItemMenu variableList={this.state.disabledItems}
                                     buttonLabel={'userView.variableGroup.modal.button.addVariable'}
                                     onGroupEnabled={() => null}
                                     onVariableEnabled={this.enableVariable}/>
                </div>
                <div className="separator"/>

                {this.renderListItems(itemList, liStyles, removeGroupVariable)}

            </section>
        }
    }


    private renderListItems(itemList: FormDragDropVariable[], liStyles: any, removeGroupVariable: any) {
        if (!itemList.length) {
            return <div className="no-data">
                <Translation text={'userView.noData.groupVariable'}/>
            </div>
        }
        return <List
            lockVertically
            values={itemList}
            onChange={({oldIndex, newIndex}) => {
                const updatedList = arrayMove(itemList, oldIndex, newIndex);
                this.listOrderChange(updatedList);
            }
            }
            renderList={({children, props}) => {
                return <ul className={`drag-and-drop-list`} {...props}>{children}</ul>
            }}
            renderItem={({value, props, index, isDragged, isSelected}) => {
                return <li {...props} className="drag-and-drop-list-item fatigue-group-item"
                           style={liStyles(props, isDragged, isSelected)}>
                    <FormVariableListItem listItem={value.inputDefinition}
                                          key={value.id.toString()}
                                          isDraggable={true}
                                          onDeleteItem={() => removeGroupVariable(index)}/>
                </li>
            }}
        />
    }

    private getFormGroup(groupDefinitionId: string) {
        if (!this.props.authToken) {
            return null;
        }
        this.setState({isProcessing: true})

        return this.subscriptions.push(getInputGroupDefinitionAPI(this.props.authToken, groupDefinitionId).pipe(
            map((response: any) => {
                if (response) {
                    this.setState({isProcessing: false})
                    return this.setState({group: response})
                }
            }),
            catchError((err: any) => {
                this.setState({isProcessing: false})
                this.alertManagerService.handleApiError(err)
                return of();
            })
        ).subscribe());

    }

    private splitInputsIntoGroups(inputGroup: InputDefinition[]): { enabled: FormDragDropVariable[], disabled: FormDragDropVariable[] } {
        let enabledItems: FormDragDropVariable[] = [],
            disabledItems: FormDragDropVariable[] = [];

        inputGroup.forEach((datalistItem: InputDefinition, i: number) => {
            datalistItem.enabled ? enabledItems.push({
                id: i,
                inputDefinition: datalistItem
            }) : disabledItems.push({id: i, inputDefinition: datalistItem});
        });
        return {enabled: enabledItems, disabled: disabledItems};
    }

    private enableVariable = (variableId: string) => {
        const updatedEnabledCalendarInputs = this.state.enabledItems.map((groupItem: FormDragDropVariable) => groupItem.inputDefinition);
        let disabledCalendarInputs = this.state.disabledItems.map((groupItem: FormDragDropVariable) => {
            if (groupItem.inputDefinition.id === variableId) {
                groupItem.inputDefinition.enabled = true;
                groupItem.inputDefinition.itemOrder = updatedEnabledCalendarInputs.length + 1
                return groupItem.inputDefinition;
            }
            return groupItem.inputDefinition
        });

        const groupListArray = updatedEnabledCalendarInputs.concat(disabledCalendarInputs);
        const updatedGroup = deepCloneObject(this.state.group);

        updatedGroup.inputDefinitions = groupListArray.sort((a: InputDefinition, b: InputDefinition) => {
            if (a.itemOrder && b.itemOrder) {
                return a.itemOrder > b.itemOrder ? 1 : -1
            }
            return 0
        })

        this.updateGroupDefinition(updatedGroup);
    }

    private listOrderChange(updatedEnabledList: FormDragDropVariable[]) {
        const updatedEnabledInputDefinitions = updatedEnabledList.map((groupItem: FormDragDropVariable, index: number) => {
                let updatedGroupItem = deepCloneObject(groupItem.inputDefinition);
                updatedGroupItem.itemOrder = index;
                return updatedGroupItem
            }),
            disabledInputDefinitions = this.state.disabledItems.map((groupItem: FormDragDropVariable) => groupItem.inputDefinition);
        const groupListArray = updatedEnabledInputDefinitions.concat(disabledInputDefinitions);
        let updatedGroup = deepCloneObject(this.state.group);
        updatedGroup.inputDefinitions = groupListArray.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        this.setState({group: updatedGroup});
        this.updateGroupDefinition(updatedGroup);
    }

    private updateGroupDefinition(updatedGroupDefinition: any) {
        let inputGroupDefinitionPayload: any = {
                itemOrder: updatedGroupDefinition.itemOrder,
                name: updatedGroupDefinition.name,
                profileDefinitionId: updatedGroupDefinition.profileDefinition.id,
                inputDefinitions: []
            },
            inputDefinitionPayloadArray: any[] = [];
        updatedGroupDefinition.inputDefinitions.forEach((inputDefinition: any, index: number) => {
            let inputDefinitionParameters = deepCloneObject(inputDefinition.parameters);
            if (inputDefinitionParameters.expression) {
                inputDefinitionParameters.expression = this.executableExpressionDTOFormatter.prepareExpression(inputDefinitionParameters.expression)
            }
            const inputDefinitionPayload = {
                id: inputDefinition.id,
                name: inputDefinition.name,
                type: inputDefinition.type,
                parameters: inputDefinitionParameters,
                inputGroupDefinitionId: updatedGroupDefinition.id,
                visualOptions: inputDefinition.visualOptions ? inputDefinition.visualOptions : {},
                itemOrder: index,
                enabled: inputDefinition.enabled
            }
            inputDefinitionPayloadArray.push(inputDefinitionPayload);
        })
        inputDefinitionPayloadArray = inputDefinitionPayloadArray.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        inputGroupDefinitionPayload.inputDefinitions = inputDefinitionPayloadArray;
        this.setState({isProcessing: true})

        return this.subscriptions.push(updateInputGroupDefinitionAPI(this.props.authToken, inputGroupDefinitionPayload, updatedGroupDefinition.id).pipe(
            catchError((error: any) => {
                this.setState({isProcessing: false})
                this.alertManagerService.handleApiError(error);
                return of();
            }),
            tap((response) => {
                this.setState({isProcessing: false, group: response})
                this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateGroupSuccess');
            })
        ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
    }
)(ModalFormVariableList);

