import React from 'react';
import {Translation} from 'judo-app-common-web';
import TooltipWrapper, {ITooltip} from "../TooltipWrapper";

export enum BtnType {
    Theme = 'theme',
    ThemeOutline = 'theme-outline',
    Primary = 'primary',
    PrimaryOutline = 'primary-outline',
    Secondary = 'secondary',
    SecondaryOutline = 'secondary-outline',
    Danger = 'danger',
    DangerOutline = 'danger-outline',
    Success = 'success',
    SuccessOutline = 'success-outline',
    Info = 'info',
    InfoOutline = 'info-outline',
    Warning = 'warning',
    WarningOutline = 'warning-outline',
    Custom = 'custom',
}

export enum BtnSize {
    Small = 'small',
    Default = 'default',
    Big = 'big',
    ExtraLarge = 'xl'
}

export enum ButtonBasicType {
    Button = 'button',
    Submit = 'submit',
    Reset = 'reset'
}

export type IButtonProps = {
    label?: string;
    icon?: string;
    onClick?: () => any;
    type?: BtnType;
    buttonType?: ButtonBasicType;
    size?: BtnSize;
    class?: string;
    disabled?: boolean;
    iconSet?: string;
    tooltip?: ITooltip;
    tabIndex?: number;
    form?: string;
}

type Props = IButtonProps;

class Button extends React.Component<Props> {

    render() {
        if (this.props.tooltip && !this.props.disabled) {
            return this.renderButtonWithTooltip(this.props.tooltip);
        }

        return this.renderButton();
    }

    private renderButtonWithTooltip(tooltip: ITooltip) {
        return (
            <TooltipWrapper tooltip={tooltip}>
                {this.renderButton()}
            </TooltipWrapper>
        );
    }

    private renderButton() {
        let type = null,
            size = null,
            classes: any = this.props.class;
        if (!this.props.class) {
            type = this.props.type ? this.props.type : BtnType.Theme;
            size = this.props.size ? this.props.size : null;

            classes = ['btn', `btn-${type}`];

            if (size) {
                classes.push(`btn-${size}`);
            }

            classes = classes.join(' ');
        }

        return (
            <button className={classes}
                    tabIndex={this.props.tabIndex}
                    disabled={this.props.disabled}
                    type={this.props.buttonType}
                    form={this.props.form}
                    onClick={this.props.onClick}>
                {this.renderLabel()}
                {this.renderIcon()}
            </button>
        );
    }

    private renderLabel() {
        if (!this.props.label) {
            return null;
        }

        return (<span className="button-label">{<Translation text={this.props.label}/>}</span>);
    }

    private renderIcon() {
        if (!this.props.icon) {
            return null;
        }

        const iconSet = this.props.iconSet ? this.props.iconSet : 'feather icon-';

        return (<i className={`${iconSet}${this.props.icon}`}/>);
    }

}

export default Button;
