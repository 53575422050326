import React from "react";
import {
    CalendarEvent,
    CalendarGroup,
    CalendarInput,
    CurrentFatigueCalendarView,
    InputGroupDefinitionType,
    RawProfile,
    RawProfileInput
} from "../../../model/models";
import ParametersList from "./ParametersList";
import DaySelector from "./DaySelector";
import '../../../../node_modules/react-vis/dist/style.css';
import LineChart from "./LineChart";

import styles from './styles.module.scss'
import RadarChart from "./RadarChart";
import {Translation} from "judo-app-common-web";

interface Props {
    calendar: CurrentFatigueCalendarView | null;
    calendarEvents: CalendarEvent[];
    numberOfDaysInSelectedMonth: number | null;
    profiles: RawProfile[];
    selectedMonth: number | null,
    selectedYear: number | null;
}
export interface GraphParameter {
    id: string | undefined;
    name: string;
    parameter: CalendarInput;
}

export interface GraphInput {
    input: RawProfileInput;
    inputDefinitionId: string | undefined;
}

interface State {
    selectedDay: number;
    selectedPlayers: string[];
    selectedParameters: GraphParameter[];
    selectedProfile: GraphInput[];
    graphType: GraphType
}

export enum GraphType {
    RADAR = 'radar',
    LINE = 'line'
}

class FatigueProfilesChart extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedDay: 1,
            selectedPlayers: [],
            selectedParameters: [],
            selectedProfile: [],
            graphType: GraphType.RADAR
        }
    }

    componentDidMount() {
        this.ProfilesToDate()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if(prevState?.selectedDay !== this.state.selectedDay) {
            this.ProfilesToDate()
        }

        //on calendar change switch to 1st day of month selected
        if(prevProps?.selectedMonth !== this.props.selectedMonth ||
            prevProps?.selectedYear !== this.props.selectedYear) {
            this.setState({selectedDay: 1})
        }

        if(prevProps.profiles !== this.props.profiles) {
            this.ProfilesToDate()
        }
    }

    render() {
        return (
            <div className="container-fluid d-flex flex-column">
                <div className="row">
                    <div className="col-3 mb-3">
                    <h2 className={styles.listTitle}>Graph Type</h2>
                    <div className="d-inline-flex align-items-center flex-wrap">
                        <span onClick={() => this.handleSwitchGraphType(false)}
                              className="mr-2 title btn p-1"><Translation text="profiles.modal.chart.radar"/></span>
                        <label className={styles.graphSwitch}>
                            <input onChange={(e) => this.handleSwitchGraphType(e.target.checked)}
                                   checked={this.state.graphType === GraphType.LINE}
                                   type="checkbox"/>
                            <span className="slider"/>
                        </label>
                        <span onClick={() => this.handleSwitchGraphType(true)}
                              className="ml-2 title btn p-1"><Translation text="profiles.modal.chart.line"/></span>
                    </div>
                    </div>
                </div>
                <div className="row flex-grow-1">
                <div className="col-3 d-flex flex-column">
                    <ParametersList
                        parameters={this.graphParameters}
                        selectedParameters={this.state.selectedParameters}
                        selectParameter={(parameter: GraphParameter) => this.handleParameterClick(parameter)}
                    />
                </div>
                <div className='col-9 d-flex'>
                    {this.state.graphType === GraphType.RADAR &&
                        <div className={"fatigue-profile-chart-wrapper"}>
                            <RadarChart
                                selectedParameters={this.state.selectedParameters}
                                selectedProfile={this.state.selectedProfile}
                                selectedDate={this.selectedDate}
                            />
                            <DaySelector
                                numberOfDaysInSelectedMonth={this.props.numberOfDaysInSelectedMonth}
                                selectedDay={this.state.selectedDay}
                                selectDay={(day:number) => this.setState({selectedDay: day})}
                                selectedMonth={this.props.selectedMonth}
                                selectedYear={this.props.selectedYear}
                            />
                        </div>
                    }
                    {this.state.graphType === GraphType.LINE &&
                        <LineChart
                            selectedParameters={this.state.selectedParameters}
                            profiles={this.props.profiles}
                            numberOfDaysInSelectedMonth={this.props.numberOfDaysInSelectedMonth}
                        />}
                </div>
            </div>
            </div>
        );
    }

    private handleSwitchGraphType(e:boolean) {
        this.setState(state =>
            ({graphType: state.graphType === GraphType.RADAR ? GraphType.LINE : GraphType.RADAR}))
    }

    private get selectedDate(): string {
        if(this.props.selectedYear && this.props.selectedMonth) {
            return new Date(this.props.selectedYear, this.props.selectedMonth, this.state.selectedDay).toDateString()
        }
        return ''
    }

    private get graphParameters(): GraphParameter[] {
        const graphParameters: GraphParameter[] = [];
        this.props.calendar?.calendarViewGroups.forEach( (group: CalendarGroup) => {
            if(group.inputGroupDefinition?.type !== InputGroupDefinitionType.TRAINING) {
                group.calendarViewGroupItems.forEach((groupInput: CalendarInput) => {
                    //todo selects only atm
                    groupInput.inputDefinition?.type === 'select' && graphParameters.push({
                        id: groupInput.inputDefinition?.id,
                        name: groupInput.name,
                        parameter: groupInput
                    })
                })
            }
        })
        return graphParameters;
    }

    private ProfilesToDate() {
        this.setState({selectedProfile: []})
        this.props.profiles.forEach((profile: RawProfile) => {
            const day = new Date(profile.validFrom).getDate();
            if(day === this.state.selectedDay) {
               return this.setState({selectedProfile: this.mapProfileToGetInputs(profile)})
            }
        })
    }

    private mapProfileToGetInputs(profile: RawProfile): GraphInput[] {
        let inputs: GraphInput[] = []
        profile.inputGroups.forEach(
            inputsGroup => inputsGroup.profileInputs.forEach(
              (input: RawProfileInput) => inputs.push(
                    {input: input, inputDefinitionId: input.inputDefinitionId}
                )
            )
        )
        return inputs
    }

    private handleParameterClick(parameter: GraphParameter) {
        if(!!this.state.selectedParameters.find(param => parameter.id === param.id)) {
            return this.setState((state) => ({selectedParameters: state.selectedParameters.filter(param => param.id !== parameter.id)  }))
        }

        return this.setState((state) => ({selectedParameters: [...state.selectedParameters, parameter]  }))
    }
}

export default FatigueProfilesChart;
