import {injectable} from 'inversify';
import {fixInjectedProperties} from "../ioc";
import {CalendarGroup, CalendarInput, InputType, RawInputGroup, RawProfileInput} from "../model/models";
import moment from "moment";

@injectable()
export class CalendarIntoRawProfileConverterService {
    constructor() {
        fixInjectedProperties(this);
    }

    public convertCalendarInputIntoRawProfileInput(calendarInput: CalendarInput, date?: Date): RawProfileInput {
        let result: any = undefined
        if (calendarInput?.inputDefinition?.inputResults) {
            const compareAgainstDate = moment(date);
            result = calendarInput.inputDefinition?.inputResults.find(
                (result) => compareAgainstDate.isSame(moment(result.resultForDate), 'day'));
        }

        return ({
            inputName: calendarInput.name,
            inputId: calendarInput.id,
            inputDefinitionId: calendarInput.inputDefinition?.id || '',
            inputParameters: calendarInput.inputDefinition?.parameters || {},
            inputResultId: result?.id,
            inputResultValue: result?.value,
            inputType: calendarInput.inputDefinition?.type as InputType || InputType.INPUT_TEXT,
            itemOrder: calendarInput.inputDefinition?.itemOrder || 0,
        })
    }

    public convertCalendarGroupIntoRawInputGroup(group: CalendarGroup, date?: Date): RawInputGroup {

        const profileInputs: RawProfileInput[] = group.calendarViewGroupItems.map(item => this.convertCalendarInputIntoRawProfileInput(item, date))

        return ({
            inputGroupDefinitionId: group.inputGroupDefinition?.id || '',
            inputGroupDefinitionEnabled: group.enabled,
            inputGroupId: group.id,
            inputGroupName: group.name,
            profileInputs: profileInputs,
            itemOrder: group.itemOrder
        })
    }
}
