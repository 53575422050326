import React from 'react';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {of, Subscription} from 'rxjs';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {
    CalendarGroup,
    CurrentFatigueCalendarView,
    InputGroupDefinition,
    ModalType,
    ProfileDefinition,
    ProfileType
} from "../../../model/models";
import FatigueForm from "./FatigueForm";
import FatigueCalendar from "./FatigueCalendar";
import {deepCloneObject} from "../../../utils/runtimeUtils";
import {MatchProcessParams} from "../../PanelHost";
import {catchError, map, tap} from "rxjs/operators";
import {getProfileDefinitionAPI} from "../../../api/getProfileDefinition";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {authTokenSelector} from 'judo-app-common-web';
import {updateFatigueCalendarAPI} from "../../../api/updateFatigueCalendar";
import {updateProfileDefinitionAPI} from "../../../api/updateProfileDefinition";
import {EndpointNames} from "../../Shared/AnimatedLayoutHost";
import {getBaseViewAPI} from '../../../api/getBaseView';
import {CustomCard} from 'judo-app-common-web';
import FatigueProfileSettings from '../FatigueProfileSettings';

interface IFatigueProfilesViewProps extends RouteComponentProps<MatchProcessParams> {
    readonly onViewChange: (id: string | null) => void;
    readonly userId: string | null;
    readonly authToken: string;
    readonly isArchiveMode?: boolean;
}

interface IFatigueProfilesViewState {
    view?: ModalType | ProfileType | EndpointNames;
    itemView: CurrentFatigueCalendarView | null;
    isProcessing: boolean;
    calendarData: CurrentFatigueCalendarView | undefined;
    formData: ProfileDefinition | undefined;
}

class FatigueProfilesView extends React.Component<IFatigueProfilesViewProps, IFatigueProfilesViewState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IFatigueProfilesViewProps) {
        super(props);
        this.state = {
            view: undefined,
            isProcessing: false,
            calendarData: undefined,
            formData: undefined,
            itemView: null
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.match.params.id) this.getViewItem(this.props.match.params.id);
        if (this.props.match.params.type) {
            this.setState({view: this.props.match.params.type})
        } else {
            this.getViewItem(null);
        }
    }

    componentDidUpdate(prevProps: Readonly<IFatigueProfilesViewProps>, prevState: Readonly<IFatigueProfilesViewState>) {
        if (this.props.match.params && prevProps.match.params.type !== this.props.match.params.type) {
            if (this.props.match.params.type) {
                this.setState({view: this.props.match.params.type})
            }
        }

        if (this.state.itemView !== prevState.itemView) {
            const calendarData = deepCloneObject(this.state.itemView);
            if (this.state.itemView?.profileDefinition && this.state.itemView.profileDefinition.id) {
                this.getFormData(this.state.itemView.profileDefinition.id);
            }
            this.setState({calendarData: calendarData})
        }
    }

    componentWillUnmount() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return <div className="row">
            <div className="col-xl-8">
                <CustomCard showLocalLoader={this.state.isProcessing || !this.state.calendarData}>
                    {this.renderCurrentView()}
                </CustomCard>
            </div>
        </div>
    }

    private renderCurrentView() {
        if (this.state.view) {
            switch (this.state.view) {
                case ProfileType.Form:
                    return this.state.formData ? <FatigueForm onViewChange={this.getViewItem}
                        formData={this.state.formData}
                        calendarId={this.state.calendarData?.id}
                        isProcessing={this.state.isProcessing}
                        onFormDataChange={this.updateFormData}
                    /> : null;
                case ProfileType.Calendar:
                    return this.state.calendarData ? <FatigueCalendar onViewChange={this.getViewItem}
                        calendarData={this.state.calendarData}
                        isProcessing={this.state.isProcessing}
                        onCalendarDataChange={this.updateCalendarData}
                        updateCalendarEvents={this.updateCalendarEventsData} /> : null;
                case ProfileType.Settings:
                    return <FatigueProfileSettings onClose={() => this.getViewItem(null)} />
                default:
                    return null;
            }
        }
        return null;
    }

    private updateFormData = (groups: InputGroupDefinition[]) => {
        let updatedFormData = deepCloneObject(this.state.formData);
        updatedFormData.inputGroupDefinitions = groups;
        this.updateForm(updatedFormData);
        this.setState({formData: updatedFormData})
    }

    private updateForm = (formData: any) => {
        let updatedInputGroupDefinitions: any = [];
        formData.inputGroupDefinitions.forEach((groupDefinition: any, index: number) => {
            const groupDefinitionPayload = {
                id: groupDefinition.id,
                itemOrder: index,
                name: groupDefinition.name,
                profileDefinitionId: formData.id,
                enabled: groupDefinition.enabled
            }
            updatedInputGroupDefinitions.push(groupDefinitionPayload);
        });
        let updatedProfileDefinitionPayload = {
            name: formData.name,
            inputGroupDefinitions: updatedInputGroupDefinitions
        }
        this.setState({isProcessing: true})
        return this.subscriptions.push(
            updateProfileDefinitionAPI(this.props.authToken, updatedProfileDefinitionPayload, formData.id).pipe(
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                }),
                tap((response) => {
                    this.setState({isProcessing: false})
                    this.getFormData(response.id)
                    this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateFormSuccess');
                })
            ).subscribe());
    }

    private getViewItem = (id: string | null) => {
        if (id === null) return this.props.history.push('/profiles');
        this.subscriptions.push(
            getBaseViewAPI(
                this.props.authToken,
                EndpointNames.CALENDARS,
                id,
                this.props.isArchiveMode
            ).pipe(
                tap((resp: any) => {
                    return this.setState(
                        {itemView: resp},
                        () => this.setState({isProcessing: false})
                    )
                }
                ),
                catchError((err: any) => {
                    this.alertManagerService.handleApiError(err);
                    this.setState({isProcessing: false})
                    return of();
                })
            ).subscribe());
    }

    private updateCalendarData = (groups: CalendarGroup[]) => {
        let updatedCalendarData = deepCloneObject(this.state.calendarData);
        updatedCalendarData.calendarViewGroups = groups;
        this.updateCalendar(updatedCalendarData);
    }

    private updateCalendarEventsData = (calendar: any) => {
        this.updateCalendar(calendar);
    }

    private updateCalendar = (calendar: any) => {
        if (!this.state.calendarData) {
            return null;
        }
        let updatedGroupsPayload: any = [];
        calendar.calendarViewGroups.forEach((calendarGroup: any, index: number) => {
            let updatedGroupItems: any = [];
            if (calendarGroup.calendarViewGroupItems) {
                calendarGroup.calendarViewGroupItems.forEach((calendarItem: any) => {
                    const itemPayload = {
                        id: calendarItem.id,
                        name: calendarItem.name,
                        calendarViewGroupId: calendarItem.calendarViewGroupId,
                        visualOptions: calendarItem.visualOptions,
                        itemOrder: calendarItem.itemOrder,
                        inputDefinitionId: calendarItem.inputDefinition.id,
                        valuesVisualOptions: calendarItem.valuesVisualOptions,
                        enabled: calendarItem.enabled
                    }
                    updatedGroupItems.push(itemPayload);
                })
            }
            const calendarGroupPayload = {
                id: calendarGroup.id,
                name: calendarGroup.name,
                itemOrder: index,
                calendarId: calendarGroup.calendarId,
                visualOptions: calendarGroup.visualOptions,
                inputGroupDefinitionId: calendarGroup.inputGroupDefinition.id,
                calendarViewGroupItems: updatedGroupItems,
                enabled: calendarGroup.enabled,
            }
            updatedGroupsPayload.push(calendarGroupPayload);
        })
        const calendarEventsPayload: any[] = [];
        calendar.calendarEvents.forEach((calendarEvent: any) => calendarEventsPayload.push({
            startsAt: calendarEvent.startsAt,
            endsAt: calendarEvent.endsAt,
            name: calendarEvent.name
        }))
        const updatedCalendar = {
            name: calendar.name,
            calendarEvents: calendarEventsPayload.length ? calendarEventsPayload : [],
            calendarViewGroups: updatedGroupsPayload,
            profileDefinitionId: calendar.profileDefinition.id,
        }
        this.setState({isProcessing: true})
        return this.subscriptions.push(
            updateFatigueCalendarAPI(this.props.authToken, calendar.id, updatedCalendar).pipe(
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                }),
                tap((response) => {
                    if (!response) return null;
                    this.setState({isProcessing: false, calendarData: calendar})
                    this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateCalendarSuccess');
                })
            ).subscribe());
    }

    private getFormData(profileDefinitionId: string) {
        if (!this.props.authToken) {
            return null;
        }
        this.setState({isProcessing: true})

        return this.subscriptions.push(
            getProfileDefinitionAPI(this.props.authToken, profileDefinitionId).pipe(
                map((response: any) => {
                    if (response) {
                        return this.setState({formData: response, isProcessing: false})
                    }
                }),
                catchError((err: any) => {
                    this.setState({isProcessing: false})
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
            ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(withRouter(FatigueProfilesView));
