import React from "react";
import {arrayMove, IItemProps, List} from "react-movable";
import {Translation} from "judo-app-common-web";
import {InputGroupDefinition, ModalType} from "../../../../../model/models";
import {FormDragDropGroup} from "../../../../../utils/fatigueProfilesUtils";
import FormGroupListItem from "./FormGroupListItem";
import FormAddItemMenu from "./FormAddItemMenu";
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {changeModal} from "../../../../../store/reducers/sagaSlice";

interface IFormGroupListProps {
    readonly enabledDataList: InputGroupDefinition[];
    readonly disabledDataList: InputGroupDefinition[];
    readonly onFormDataChange: (groups: InputGroupDefinition[]) => void;
    readonly onGroupDisabled: (groupId: string, newEnabledState: false) => void;
    readonly onGroupEnabled: (groupId: string, newEnabledState: true) => void;
    readonly onGroupOrderChange: (groups: InputGroupDefinition[]) => void;
    readonly changeModal: typeof changeModal;
}

interface IFormGroupListState {
    disabledItems: FormDragDropGroup[];
    items: FormDragDropGroup[];
    addGroupMenuOpen: boolean;
}

class FormGroupList extends React.Component<IFormGroupListProps, IFormGroupListState> {

    constructor(props: IFormGroupListProps) {
        super(props);
        this.state = {
            disabledItems: [],
            items: [],
            addGroupMenuOpen: false,
        }

    }

    private static getDataListFromGroup(inputGroupDefinitions: InputGroupDefinition[]): FormDragDropGroup[] {
        return inputGroupDefinitions.map((datalistItem: InputGroupDefinition, i: number) => {
            return {id: i, profileDefinition: datalistItem}
        });
    }

    componentDidMount() {
        const dataItems: FormDragDropGroup[] = FormGroupList.getDataListFromGroup(this.props.enabledDataList),
            disabledDataItems: FormDragDropGroup[] = FormGroupList.getDataListFromGroup(this.props.disabledDataList);
        this.setState({items: dataItems, disabledItems: disabledDataItems})
    }

    componentDidUpdate(prevProps: Readonly<IFormGroupListProps>, prevState: Readonly<IFormGroupListState>) {
        if (prevProps.enabledDataList !== this.props.enabledDataList) {
            const dataItems: FormDragDropGroup[] = FormGroupList.getDataListFromGroup(this.props.enabledDataList);
            this.setState({items: dataItems})
        }
        if (prevProps.disabledDataList !== this.props.disabledDataList) {
            const disabledDataItems: FormDragDropGroup[] = FormGroupList.getDataListFromGroup(this.props.disabledDataList);
            this.setState({disabledItems: disabledDataItems})
        }
    }

    render() {
        if (!this.props.enabledDataList.length) {
            return null
        }
        return <section className="user-connection-list-wrapper">
            <div className="user-connection-list-header fatigue-header">
                <h3>
                    <Translation text={'userView.header.variableGroup'}/>
                    <span className="item-counter">
                            {` ( ${this.props.enabledDataList.length} )`}
                        </span>
                </h3>
                <FormAddItemMenu groupList={this.state.disabledItems} buttonLabel={'userView.button.addVariableGroup'}
                                 onGroupEnabled={this.props.onGroupEnabled}
                                 onVariableEnabled={() => null}/>
            </div>
            <div className="separator"/>
            {this.renderListItems()}
        </section>;
    }

    private renderListItems() {
        if (this.state.items) {
            const itemList = this.state.items;
            const removeFatigueGroup = (index: number | undefined) => {
                if (index !== undefined) {
                    const groupId = this.state.items[index].profileDefinition.id as string;
                    this.props.onGroupDisabled(groupId, false);
                }
            };

            const liStyles = (props: IItemProps, isDragged: boolean, isSelected: boolean) => {

                return {
                    ...props.style,
                    cursor: isDragged ? 'grabbing' : 'grab',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem 1rem',
                    listStyle: 'none',
                    fontSize: '1.6rem',
                    fontFamily: `'Nunito', sans-serif`,
                    borderRadius: '5px',
                    verticalAlign: 'middle',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF'
                }
            }

            return <List
                values={itemList}
                onChange={({oldIndex, newIndex}) => {
                    const updatedList = arrayMove(itemList, oldIndex, newIndex);
                    this.listOrderChange(updatedList)
                }}
                renderList={({children, props}) => {
                    return <ul className="drag-and-drop-list form-groups-list" {...props}>{children}</ul>
                }}
                renderItem={({value, props, index, isDragged, isSelected}) => {
                    return <li {...props} className="drag-and-drop-list-item fatigue-group-item"
                               style={liStyles(props, isDragged, isSelected)}>
                        <FormGroupListItem listItem={value.profileDefinition}
                                           key={value.id.toString()}
                                           isDraggable={true}
                                           onDeleteItem={() => removeFatigueGroup(index)}
                                           onEditedItemSet={(item: any) => this.props.changeModal({
                                               type: ModalType.FORM_GROUP_VARIABLES,
                                               id: item.id
                                           })}/>
                    </li>
                }}
            />
        }
        return null;
    }

    private listOrderChange(groups: FormDragDropGroup[]) {
        const updatedEnabledInputGroupDefinitions = groups.map((groupItem: FormDragDropGroup) => groupItem.profileDefinition);
        this.props.onGroupOrderChange(updatedEnabledInputGroupDefinitions);
    }
}

export default connect(
    (state: RootState) => ({}),
    {
        changeModal,
    }
)(FormGroupList);
