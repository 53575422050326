import React from 'react';
import {
    authTokenSelector,
    CustomModal,
    Form,
    IFormConfig,
    Loader,
    LoaderType,
    setAuthState,
    Translation
} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {CurrentFatigueCalendarView, ModalConfig, UserType} from "../../../../model/models";
import {changeModal, changeShouldListUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription, forkJoin, concat} from "rxjs";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {assignFatigueProfileFormConfig, buttonGroup} from "./assignFatigueProfileFormConfig";
import {assignProfileDefinitionToAccountAPI} from "../../../../api/assignProfileDefinitionToAccount";
import {userSelector} from "../../../../store/selectors/userSelector";
import {getDevelopmentProfiles} from '../../../../api/getDevelopmentProfiles';
import {populateFatigueProfileAPI} from '../../../../api/populateFatigueProfileAPI';

interface IModalAssignFatigueProfileProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly user: any;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalAssignFatigueProfileState {
    accountId: string;
    isProcessing: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    fatigueProfiles: CurrentFatigueCalendarView[];
}

class ModalAssignFatigueProfile extends React.Component<IModalAssignFatigueProfileProps, IModalAssignFatigueProfileState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalAssignFatigueProfileProps) {
        super(props);
        this.state = {
            accountId: '',
            isProcessing: true,
            formConfig: assignFatigueProfileFormConfig,
            value: {},
            fatigueProfiles: [],
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.id) {
            this.setState({accountId: this.props.modal.id})
            this.retrieveProfileDefinitions();
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IModalAssignFatigueProfileProps>,
        prevState: Readonly<IModalAssignFatigueProfileState>,
        snapshot?: any
    ): void {
        if (this.state.fatigueProfiles !== prevState.fatigueProfiles) {
            let updatedFormConfig = deepCloneObject(assignFatigueProfileFormConfig);
            let multiselectProfiles: any[] = [];
            this.state.fatigueProfiles.forEach((fatigueProfile: CurrentFatigueCalendarView) => {
                let valuesArray =  [fatigueProfile.profileDefinition?.id];
                if (fatigueProfile.trainingProfile) {
                    valuesArray.push(fatigueProfile.trainingProfile.profileDefinition?.id);
                }
                multiselectProfiles.push({
                value: valuesArray,
                label: fatigueProfile.name
            })
        });
            updatedFormConfig.controls[0].controls.fatigueProfiles.multiselectOptions = multiselectProfiles;
            updatedFormConfig.controls.push(buttonGroup);
            this.setState({formConfig: updatedFormConfig});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.accountId) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class='assign-profile-modal'>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={'modal.assignDevelopmentProfile.title'}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <Form config={this.state.formConfig}
                          submitForm={this.assignProfile}
                          controlName={'assignProfile'}
                          value={{}}/>
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private retrieveProfileDefinitions() {
        if (!this.props.modal || !this.props.modal.id) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(
            getDevelopmentProfiles(
                this.props.authToken as string,
                1,
                1000,
            ).pipe(
                tap((response: any) => {
                    if (response && response['hydra:member']) {
                        let fatigueProfilesList = deepCloneObject(response['hydra:member']);
                        if (this.props.modal?.data) {
                            const assignedProfilesId = this.props.modal.data;
                            fatigueProfilesList = fatigueProfilesList.filter((listItem: any) => !assignedProfilesId.includes(listItem.id));
                        }
                        if (this.props.user.type === UserType.COACH) {
                            const currentlyAssignedProfileDefinitionsIdArray = deepCloneObject(this.props.user.profileDefinitions).map((profileDefinition: any) => profileDefinition.id);
                            fatigueProfilesList = fatigueProfilesList.filter((developmentProfile: any) => currentlyAssignedProfileDefinitionsIdArray.includes(developmentProfile.profileDefinition.id));
                        }
                        this.setState({fatigueProfiles: fatigueProfilesList, isProcessing: false})
                    }}),
                    catchError((err: any) => {
                        this.setState({isProcessing: false});
                        this.alertManagerService.handleApiError(err);
                        return of();
                    })
                ).subscribe());
    }

    private assignProfile = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken) {
            return;
        }

        const requestArray = value.fatigueProfileGroup.fatigueProfiles.value.map((profileId: string) => assignProfileDefinitionToAccountAPI(this.props.authToken, this.state.accountId, profileId)),
        requestFatigueProfileName = value.fatigueProfileGroup.fatigueProfiles.label,
        selectedTrainingProfileId = this.state.fatigueProfiles.find((fatigueProfile: any) => fatigueProfile.name === requestFatigueProfileName)?.trainingProfile?.profileDefinition?.id;
        this.setState({isProcessing: true});
        let assignProfileCall = forkJoin(requestArray);
        if (selectedTrainingProfileId) {
            const populateProfileCall = populateFatigueProfileAPI(this.props.authToken, selectedTrainingProfileId);
            assignProfileCall = concat(forkJoin(requestArray), populateProfileCall);
        }
        return assignProfileCall.pipe(
            tap((response: any) => {
                this.alertManagerService.addAlert('modal.assignDevelopmentProfile.assignSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldListUpdate(true);
                this.props.changeModal(null);
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe();
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
        user: userSelector(state)
    }),
    {
        changeModal,
        changeShouldListUpdate,
        setAuthState,
    }
)(ModalAssignFatigueProfile);
