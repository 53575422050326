import { Observable } from "rxjs";
import {RestQueryParams} from "judo-app-common-web";
import {developmentProfileProvider} from "./provider/developmentProfileProviderAPI";

export function getDevelopmentProfiles(
    authToken: string,
    pageNumber?: number,
    itemsPerPageValue?: number,
): Observable<any> {
    const page = pageNumber ? `&page=${pageNumber}` : '';
    const itemsPerPage = itemsPerPageValue ? `?itemsPerPage=${itemsPerPageValue}` : '?itemsPerPage=10';
    return developmentProfileProvider.get(
        `calendars/grouped${itemsPerPage}${page}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
