import {createAPIHandler} from "judo-app-common-web";
import {deepCloneObject} from "../../utils/runtimeUtils";
import {getAccountsFromUserItem} from "../../utils/dataAccess";

export const baseDataProviderAPI = createAPIHandler({
    url: process.env.REACT_APP_API_URL as string,
    defaultHeaders: {
        "Accept": "application/ld+json",
        "Content-type": "application/json"
    },
    mapper: (data: any): any => {
        let updatedData = deepCloneObject(data);
        if(data && !data['hydra:member']) {
            return getAccountsFromUserItem(updatedData);
        }
        if(data && data['hydra:member']) {
            // this can be removed if getAccountsFromUserItem has no side effects on item
            updatedData['hydra:member'].map((item: any) => getAccountsFromUserItem(item));

            return updatedData;
        }
       return data;
    },
    isList: false
});
