import React from 'react';
import {authTokenSelector, Translation} from 'judo-app-common-web';
import {ModalType} from '../../../model/models';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {isUserProfileLoadingSelector} from "../../../store/selectors/sagaSelectors";
import TooltipWrapper, {ITooltip} from "../../Shared/TooltipWrapper";

interface IArchiveProfileButtonProps {
    readonly profile: any;
    readonly isArchiveMode?: boolean;
    readonly changeModal: typeof changeModal;
}

class ArchiveProfileButton extends React.Component<IArchiveProfileButtonProps> {
    render() {
        if (!this.props.profile) {
            return null;
        }
        return this.renderArchiveButton()
    }

    private renderArchiveButton() {
        if (this.props.isArchiveMode) {
            return null;
            // const tooltip: ITooltip = {text: `userList.table.button.restore.${this.props.profile.type}`}
            //
            // return <div className='my-3 d-flex justify-content-center'>
            //     <TooltipWrapper tooltip={tooltip} key={'restore-profile-button'}>
            //         <button className="btn btn-danger btn-list restore"
            //                 onClick={this.openRestoreArchivedProfileModal}>
            //             <span className="feather icon-package" aria-hidden="true"/>
            //             <span className="sr-only">
            //                 <Translation text={`userList.table.button.restore.${this.props.profile.type}`}/>
            //             </span>
            //             <Translation text={'userList.table.button.restore.profile'}/>
            //         </button>
            //     </TooltipWrapper>
            // </div>
        }
        const tooltip: ITooltip = {text: `userList.table.button.archive.${this.props.profile.name ? 'club' : this.props.profile.type}`}
        return <div className="action-button ">
            <TooltipWrapper tooltip={tooltip} key={'archive-profile-button'}>
                <button className="btn btn-list delete" onClick={this.openArchiveModal}>
                    <span className="feather icon-trash" aria-hidden="true"/>
                    <span className="sr-only">
                        <Translation text={`userList.table.button.archive.${this.props.profile.type ? this.props.profile.type : 'club'}`}/>
                    </span>
                    {/*<Translation text={'userList.table.button.archive.profile'}/>*/}
                </button>
            </TooltipWrapper>
        </div>
    }

    private openArchiveModal = () => {
        if (!this.props.profile) return null;
        this.props.changeModal({
            type: ModalType.ARCHIVE,
            data: this.props.profile
        })
    }

    private openRestoreArchivedProfileModal = () => {
        if (!this.props.profile) return null;
        this.props.changeModal({
            type: ModalType.RESTORE,
            data: this.props.profile
        })
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isUserProfileLoading: isUserProfileLoadingSelector(state)
    }),
    {
        changeModal
    }
)(ArchiveProfileButton);
