import {
    BasicFormControlType,
    IFormConfig,
    InputType,
    MultiSelectType,
    ValidationRules,
} from "judo-app-common-web";
import {InputType as CalendarInputType} from "../../../../model/models";
import i18next from "i18next";


const outputDataMapper = (data: any) => {
    if (data === 'false') {
        return false;
    }
    if (data === 'true' || data === 'on') {
        return true;
    }
    return data;
};

export const updateInputFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'update_input',
            controlType: 'group',
            class: '',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 1}}
                    ],
                    placeholder: 'profileSettingsModal.form.addInputForm.name.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addInputForm.name.label',
                    type: InputType.INPUT_TEXT,
                    hostClass: ''
                },
                inputType: {
                    hostClass: 'form-select-input',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'profileSettingsModal.form.addInputForm.type.placeholder',
                    formControlType: BasicFormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    firstValue: 'profileSettingsModal.form.addInputForm.type.placeholder',
                    label: 'profileSettingsModal.form.addInputForm.type.label',
                    validationRules: [],
                    multiselectOptions: () => {
                        const inputTypes = [
                            {
                                value: CalendarInputType.INPUT_TEXT,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.INPUT_TEXT}`)
                            },
                            {
                                value: CalendarInputType.INPUT_NUMERIC,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.INPUT_NUMERIC}`)
                            },
                            {
                                value: CalendarInputType.INPUT_DATE,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.INPUT_DATE}`)
                            },
                            {
                                value: CalendarInputType.SELECT,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.SELECT}`)
                            },
                            {
                                value: CalendarInputType.CALCULATED,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.CALCULATED}`)
                            },

                        ]
                        return inputTypes;
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }
                        const inputTypes = [
                            {
                                value: CalendarInputType.INPUT_TEXT,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.INPUT_TEXT}`)
                            },
                            {
                                value: CalendarInputType.INPUT_NUMERIC,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.INPUT_NUMERIC}`)
                            },
                            {
                                value: CalendarInputType.INPUT_DATE,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.INPUT_DATE}`)
                            },
                            {
                                value: CalendarInputType.SELECT,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.SELECT}`)
                            },
                            {
                                value: CalendarInputType.CALCULATED,
                                label: i18next.t(`profileSettingsModal.form.addInputForm.type.options.${CalendarInputType.CALCULATED}`)
                            },

                        ]
                        const selectedType = inputTypes.find((inputType) => inputType.value === data);
                        return selectedType;
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                },
                enabled: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.CHECKBOX,
                    validationRules: [
                    ],
                    isLabelHidden: false,
                    isCheckboxLabelRaw: false,
                    checkboxLabel: `profileSettingsModal.form.addInputForm.enabled.label`,
                    outputDataMapper: outputDataMapper,
                }
            }
        },
    ]
};

