import React from 'react';
import styles from "./styles.module.scss";

export interface IProfileAccordionProps {
    isOpen: boolean;
    isFilled: boolean;
    profileId: string;
    profileName: string;
    toggleAccordion: (profileId: string) => void;
}

export interface IProfileAccordionState {
}

class ProfileAccordion extends React.Component<IProfileAccordionProps, IProfileAccordionState> {
    constructor(props: IProfileAccordionProps) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div className={styles.accordionWrapper}>
                <h3 className={styles.accordionSummary}>
                    <button aria-expanded={this.props.isOpen}
                        className={`${styles.accordionTrigger} ${this.props.isOpen ? styles.isOpen : ''}`}
                        aria-controls={`panel_${this.props.profileId}`}
                        id={`accordion_${this.props.profileId}`}
                        onClick={() => this.props.toggleAccordion(this.props.profileId)}>
                        <span className={styles.accordionTitle}>
                            {this.props.profileName}
                        </span>
                        <div className={styles.iconWrapper}>
                            <span className={`${styles.filledIcon} ${this.props.isFilled ? styles.isFilled : ''}`}></span>
                            <span className={`${styles.accordionIcon} ${this.props.isOpen ? styles.open : ''}`}></span>
                        </div>
                    </button>
                </h3>
                <div id={`panel_${this.props.profileId}`}
                    role="region"
                    aria-labelledby={`accordion_${this.props.profileId}`}
                    className={`${styles.accordionPanel} ${this.props.isOpen ? styles.isOpen : ''}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ProfileAccordion;
