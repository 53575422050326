import React from 'react';
import {CustomCard, Translation} from 'judo-app-common-web';
import {CalendarGroup, CalendarInput, InputGroupDefinitionType} from "../../../../model/models";
import SettingsVariableItem from '../SettingsVariableItem';
import UpdateGroupForm from "./updateGroupForm";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import TooltipWrapper from "../../../Shared/TooltipWrapper";
import Badge, {BadgeSize, BadgeType} from "../../../Shared/Badge";

interface IUpdateGroupListProps {
    calendarGroup: CalendarGroup;
    onGroupUpdate: (group: CalendarGroup) => void;
    onGroupArchive: (groupId: string) => void;
    onVariableArchive: (variableId: string) => void;
    onVariableSelected: (calendarInput: CalendarInput) => void;
    onOpenAddInputModal: (groupOnModalOpen: CalendarGroup) => void;
}

interface IUpdateGroupListState {
    calendarGroup: CalendarGroup | undefined;
    isProcessing: boolean;
    isAddVariableModalOpen: boolean;
    currentlyEditedVariable: CalendarInput | null;
    groupChanged: boolean;
}

class UpdateGroupList extends React.Component<IUpdateGroupListProps, IUpdateGroupListState> {
    constructor(props: IUpdateGroupListProps) {
        super(props);
        this.state = {
            calendarGroup: undefined,
            isProcessing: false,
            isAddVariableModalOpen: false,
            currentlyEditedVariable: null,
            groupChanged: false
        }
    }

    componentDidMount() {
        if (this.props.calendarGroup) this.setState({calendarGroup: this.props.calendarGroup});
    }

    componentDidUpdate(prevProps: Readonly<IUpdateGroupListProps>, prevState: Readonly<IUpdateGroupListState>) {
        if (this.props.calendarGroup !== prevProps.calendarGroup) this.setState({calendarGroup: this.props.calendarGroup});
    }

    render() {
        if (!this.state.calendarGroup) {
            return null;
        }

        return <CustomCard showLocalLoader={false}>
            <CustomCard.Body>
                <div className="user-connection-list-wrapper">
                    {this.renderUpdateGroupListHeader()}
                    {this.renderVariableList(this.props.calendarGroup)}
                </div>
            </CustomCard.Body>
        </CustomCard>
    }

    private renderUpdateGroupListHeader() {
        if (!this.state.calendarGroup) {
            return null;
        }
        return <React.Fragment>
            <div className="user-connection-list-header fatigue-header">
                <h3> {this.props.calendarGroup?.name}</h3>

                <div className="button-group-wrapper">
                    <button className="btn btn-theme btn-small ml-3"
                            onClick={() => this.updateGroup()}
                            disabled={!this.state.groupChanged || this.state.calendarGroup?.name?.trim()?.length < 1 }>
                        <span className="feather icon-check" aria-hidden="true"/>
                        <span><Translation text={'profileSettingsModal.button.update.variableGroup'}/></span>
                    </button>
                    {/*// TODO: When profile/group/variable archivisation will be ready uncomment and*/}

                    {/*<TooltipWrapper tooltip={{text: 'profileSettingsModal.button.archive.variableGroup'}}*/}
                    {/*                key={'variable-group-edit-button'}>*/}
                    {/*    <button className="btn btn-theme btn-small big-font ml-3"*/}
                    {/*            onClick={() => this.state.calendarGroup ? this.props.onGroupArchive(this.state.calendarGroup.id) : null}>*/}
                    {/*        <span className="feather icon-trash" aria-hidden="true"/>*/}
                    {/*        <span className="sr-only"><Translation*/}
                    {/*            text={'profileSettingsModal.button.archive.variableGroup'}/></span>*/}
                    {/*    </button>*/}
                    {/*</TooltipWrapper>*/}

                </div>
            </div>
            <UpdateGroupForm key={this.props.calendarGroup.name} calendarGroup={this.props.calendarGroup}
                             onGroupUpdate={this.updateGroupCredentials}/>
            {this.renderVariableBadge()}

        </React.Fragment>
    }

    private renderVariableBadge() {
        if (this.props.calendarGroup?.inputGroupDefinition?.type === InputGroupDefinitionType.TRAINING) {
            return <div className="badge-wrapper training-plan">
                <TooltipWrapper tooltip={{text: `profileSettingsModal.badges.trainingPlan.tooltipLabel`}}
                                key={`variable-badge-training-plan`}>
                    <div>
                         <Badge type={BadgeType.INFO} size={BadgeSize.SMALL}
                               label={`profileSettingsModal.badges.trainingPlan.label`}/>
                    </div>
                </TooltipWrapper>
            </div>
        }
    }

    private renderVariableList(group: CalendarGroup) {
        if (group && group.calendarViewGroupItems) {
            const inputGroup = group.calendarViewGroupItems;
            return (<React.Fragment>
                <h4 className="user-connection-list-header fatigue-header">
                    <span>
                        <Translation text={'profileSettingsModal.variableList.header'}/> {group?.name}
                    </span>
                    <button className="btn btn-theme btn-small ml-3"
                            onClick={() => this.props.onOpenAddInputModal(group)}>
                        <span className="feather icon-plus" aria-hidden="true"/>
                        <span><Translation text={'profileSettingsModal.button.create.variableItem'}/></span>
                    </button>
                </h4>
                <div className="separator"/>
                <ul className="user-connection-list fatigue-profile">
                    {inputGroup.map((input: CalendarInput) => <li
                        key={input.id.toString()}
                        className="user-connection-list-item  no-drag">
                        <SettingsVariableItem listItem={input}
                                              key={input.id.toString()}
                                              onArchiveItem={this.props.onVariableArchive}
                                              onUpdateItem={this.props.onVariableSelected}/>
                    </li>)
                    }
                    {!inputGroup.length && <div className="no-data">
                        <Translation text={'profileSettingsModal.variableList.noData'}/>
                    </div>}
                </ul>
            </React.Fragment>)
        }
    }

    private updateGroupCredentials = (group: CalendarGroup, isGroupChanged: boolean) => {
        if (!group) {
            return;
        }
        let updatedGroup = deepCloneObject(this.state.calendarGroup);
        updatedGroup.name = group.name;
        updatedGroup.visualOptions.backgroundColor = group.visualOptions.backgroundColor

        this.setState({calendarGroup: updatedGroup, groupChanged: isGroupChanged})
    };

    private updateGroup = () => {
        if (!this.state.calendarGroup) {
            return;
        }


        const updatedGroup: CalendarGroup = {
            id: this.state.calendarGroup.id,
            itemOrder: this.state.calendarGroup.itemOrder,
            name: this.state.calendarGroup.name,
            enabled: this.state.calendarGroup.enabled,
            visualOptions: this.state.calendarGroup.visualOptions,
            calendarViewGroupItems: this.state.calendarGroup.calendarViewGroupItems,
            inputGroupDefinitionId: this.state.calendarGroup.inputGroupDefinition?.id
        }

        this.props.onGroupUpdate(updatedGroup);
    };
}

export default UpdateGroupList;
