import { createSelector } from "redux-starter-kit";
import {IChangePasswordState} from "../reducers/changePasswordSlice";

export const selectChangePassword = (state: any): IChangePasswordState => {
  return state.changePassword;
};

export const passwordLoadingSelector = createSelector(
    [selectChangePassword],
    (state: IChangePasswordState) => state.isLoading
);
