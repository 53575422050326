import React from 'react';
import {CustomCard, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import UserInfoBox, {UserInfoType} from "../../Shared/UserInfoBox";
import {ModalType, Role, UserType} from '../../../model/models';
import {connect} from 'react-redux';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {RootState} from "../../../store/reducers";
import {userRoleSelector} from '../../../store/selectors/userSelector';
import Pagination, {IPaginationData} from "../../Shared/Pagination";

interface ISpecialistListProps {
    onViewChange: (id: string | null, profileType?: UserType) => void;
    list: any[] | null;
    onSpecificPageLoad: (pageNumber: number) => void;
    paginationData: IPaginationData | null;
    userRole: Role;
    isLoading: boolean;
    isArchiveMode?: boolean;
    changeModal: typeof changeModal;
}

interface ISpecialistListState {
    specialistList: any[] | null; // toDo interface
}

class SpecialistList extends React.Component<ISpecialistListProps, ISpecialistListState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: ISpecialistListProps) {
        super(props);
        this.state = {
            specialistList: [],
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if(this.props.list) this.setState({specialistList: this.props.list});
    }

    componentDidUpdate(
        prevProps: Readonly<ISpecialistListProps>,
        prevState: Readonly<ISpecialistListState>,
        snapshot?: any
    ): void {
        if (this.props.list !== prevProps.list) {
            this.setState({specialistList: this.props.list});
        }
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Body>
                    { this.renderButton() }
                    { this.renderTable() }
                </CustomCard.Body>
                <CustomCard.Footer>
                    <Pagination paginationData={this.props.paginationData} onSpecificPageLoad={this.props.onSpecificPageLoad}/>
                </CustomCard.Footer>
            </CustomCard>
        )
    }

    private renderButton() {
        if ((this.props.userRole !== Role.Admin && this.props.userRole !== Role.HeadCoach) || this.props.isArchiveMode) return;

        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-theme btn-small"
                        onClick={this.openCreateSpecialistModal}>
                    <span aria-hidden="true" className="feather icon-plus"/>
                    <Translation text={'userList.table.button.add.specialist'}/>
                </button>
            </div>
        )
    }

    private renderTable() {
        if (!this.state.specialistList) {
            return;
        }

        const noSpecialistsMessage = this.props.isArchiveMode ?
            <Translation text={'userList.table.noData.noArchivedSpecialists'}/> :
            <Translation text={'userList.table.noData.noSpecialists'}/>;

        if (!this.state.specialistList.length) {
            return (
                <div className="d-flex justify-content-center display-4 my-5">
                    <span>
                        {noSpecialistsMessage}
                    </span>
                </div>
            );
        }

        const rows: any = [];
        this.state.specialistList.forEach((specialist: any) => rows.push(
                <tr key={specialist.id}>
                    <td><UserInfoBox user={specialist} infoCellType={UserInfoType.Table}/></td>
                    <td> {specialist.countCoaches}</td>
                    <td> {specialist.countContestants}</td>
                    <td className="text-right">
                        <button className="btn btn-theme btn-small"
                                onClick={() => this.props.onViewChange(specialist.id, this.props.isArchiveMode ? UserType.SPECIALIST : undefined)}>
                            <span className="feather icon-edit" aria-hidden="true"/>
                            <Translation text={'userList.table.button.details'}/>
                        </button>
                    </td>
                </tr>
            ));

        return (
            (
                <div className="mt-20">
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th><Translation text={'userList.table.header.specialists'}/></th>
                                <th><Translation text={'userList.table.header.coaches'}/></th>
                                <th><Translation text={'userList.table.header.contestants'}/></th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            )
        )
    }

    private openCreateSpecialistModal = () => {
        this.props.changeModal({
            type: ModalType.CREATE,
            role: UserType.SPECIALIST,
        })
    }
}

export default connect(
    (state: RootState) => ({
        userRole: userRoleSelector(state)
    }),
    {
        changeModal
    }
)(SpecialistList);
