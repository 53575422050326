import React from 'react';
import {CustomCard, Form, IBasicFormControlConfig, Translation} from 'judo-app-common-web';
import {deepCloneObject} from "../../../../../utils/runtimeUtils";
import {CalendarEvent} from "../../../../../model/models";
import {editCalendarEventFormConfig} from "./editCalendarEventFormConfig";
import moment from "moment";
import {IAddCalendarEventFormValue} from "../../../../PanelHost/Modals/ModalAddCalendarEvent/AddCalendarEventForm";

interface IEditCalendarEventFormProps {
    editedCalendarEvent: CalendarEvent;
    editedCalendarEventIndex: number;
    onCalendarEventUpdate: (option: CalendarEvent, optionIndex: number) => void;
}

interface IEditCalendarEventFormState {
    editedCalendarEvent: CalendarEvent | null;
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    value: IAddCalendarEventFormValue;
}

class EditCalendarEventForm extends React.Component<IEditCalendarEventFormProps, IEditCalendarEventFormState> {
    constructor(props: IEditCalendarEventFormProps) {
        super(props);

        this.state = {
            editedCalendarEvent: null,
            formConfig: editCalendarEventFormConfig(),
            value: {},
        }
    }


    componentDidMount() {
        if (this.props.editedCalendarEvent) {
            const data: IAddCalendarEventFormValue = {}
            data.name =  this.props.editedCalendarEvent?.name;
            data.startsAt =  this.props.editedCalendarEvent.startsAt ? moment(this.props.editedCalendarEvent.startsAt).format('DD-MM-yyyy') : undefined;
            this.setState({
                value: data,
                formConfig: editCalendarEventFormConfig(data),
                editedCalendarEvent: this.props.editedCalendarEvent})
        }
    }

    render() {
        if (!this.props.editedCalendarEvent) {
            return null;
        }

        return <CustomCard showLocalLoader={false}>
            <div className="user-connection-list-wrapper">
                <h4>
                    <Translation text="profileSettingsModal.form.addCalendarEventForm.headerEdit"/>
                </h4>
                <CustomCard.Body>
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                        controlName={"editCalendarEventForm"}
                        value={{}}
                        submitForm={this.submitCalendarEvent}
                    />
                </CustomCard.Body>
            </div>
        </CustomCard>
    }

    private updateFormData(controlName: string, data: any) {
        let updatedCalendarEvent: CalendarEvent = deepCloneObject(this.state.value);
        if(data.name) {
            updatedCalendarEvent.name = data.name
        }

        if(data.startsAt) {
            updatedCalendarEvent.startsAt = moment(data.startsAt).format('DD-MM-yyyy')
        }

        this.setState({value: updatedCalendarEvent,
                            formConfig: editCalendarEventFormConfig(updatedCalendarEvent),
        });
    }

    private submitCalendarEvent = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        const submitValue = {
            name: value.name || this.props.editedCalendarEvent.name,
            startsAt: value.startsAt || this.props.editedCalendarEvent.startsAt,
            endsAt:  undefined
        }
        this.props.onCalendarEventUpdate(submitValue, this.props.editedCalendarEventIndex);
    };
}

export default EditCalendarEventForm;
