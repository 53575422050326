import React from 'react';
import {authTokenSelector, Translation} from 'judo-app-common-web';
import {ModalType} from '../../../model/models';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {isUserProfileLoadingSelector} from "../../../store/selectors/sagaSelectors";
import TooltipWrapper, {ITooltip} from "../../Shared/TooltipWrapper";

interface IEditProfileButtonProps {
    readonly profileId: string;
    readonly changeModal: typeof changeModal;
    readonly isClubProfile?: boolean;
}

class EditProfileButton extends React.Component<IEditProfileButtonProps> {
    render() {
        if (!this.props.profileId) {
            return null;
        }
        const tooltip: ITooltip = {text: `userList.table.button.edit.${this.props.isClubProfile ? 'clubProfile' : 'profile'}`}
        return <div className='action-button'>
                <TooltipWrapper tooltip={tooltip} key={'assign-fatigue-profile-button'}>
                    <button className="btn btn-list" onClick={this.openEditProfileModal}>
                        <span className="feather icon-edit" aria-hidden="true"/>
                        <Translation text={`userList.table.button.edit.${this.props.isClubProfile ? 'clubProfile' : 'profile'}`}/>
                    </button>
                </TooltipWrapper>
            </div>
    }

    private openEditProfileModal = () => {
        if (this.props.isClubProfile) {
          return  this.props.changeModal({
                type: ModalType.EDIT_CLUB,
                id: this.props.profileId
            })
        }
        this.props.changeModal({
            type: ModalType.EDIT_PROFILE,
            id: this.props.profileId
        })
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isUserProfileLoading: isUserProfileLoadingSelector(state)
    }),
    {
        changeModal
    }
)(EditProfileButton);
