import {
    FormButtonType,
    InputType, ValidationRules,
    IFormConfig, BasicFormControlType
} from "judo-app-common-web";

export const registerFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'auth_credentials',
            controlType: 'group',
            class: 'form-row login-form',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED, params: {}},
                        // {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: 'auth.login.form.email.placeholder',
                    isLabelHidden: true,
                    disabled: true,
                    label: 'auth.login.form.email.label',
                    type: InputType.EMAIL,
                    hostClass: ''
                },
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'auth.login.form.password.placeholder',
                    isLabelHidden: true,
                    label: 'auth.login.form.password.label',
                    type: InputType.PASSWORD,
                    hostClass: ''
                },
                repeatPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'auth.register.form.repeatPassword.placeholder',
                    isLabelHidden: true,
                    label: 'auth.register.form.repeatPassword.label',
                    type: InputType.PASSWORD,
                    hostClass: ''
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'button-container',
                    defaultContainerStyles: '',
                    containerStyles: 'd-flex justify-content-end mt-3',
                    defaultValue: null,
                    btnText: `auth.register.button`,
                    isLabelHidden: true,
                }
            }
        },
    ]
};

