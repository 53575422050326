import React from 'react';
import {authTokenSelector, CustomCard, Translation} from 'judo-app-common-web';
import {
    CurrentFatigueCalendarView,
    ModalConfig,
    ModalType,
    ProfileDefinition,
    ProfileType,
    Role
} from '../../../model/models';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import TooltipWrapper from "../../Shared/TooltipWrapper";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {of, Subscription, forkJoin} from "rxjs";
import {catchError, filter, tap, switchMap} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {addProfileDefinitionAPI} from "../../../api/addProfileDefinition";
import {userRoleSelector} from '../../../store/selectors/userSelector';
import {changeModal, changeShouldListUpdate} from "../../../store/reducers/sagaSlice";
import Pagination, {IPaginationData} from "../../Shared/Pagination";
import {modalSelector} from "../../../store/selectors/sagaSelectors";
import {getGroup, getGroupInputs} from './ModalAddDevelopmentProfile/trainingInputGroupTemplate';
import {addInputGroupDefinitionAPI} from '../../../api/addInputGroupDefinition';
import {addMultipleInputDefinitionsAPI} from '../../../api/addMultipleInputDefinitions';
import AddDevelopmentProfileFormModal from './ModalAddDevelopmentProfile';
import {getDevelopmentProfiles} from '../../../api/getDevelopmentProfiles';

const uuid = require("uuid/v4");

interface IDevelopmentProfilesListProps extends RouteComponentProps {
    readonly userRole: Role;
    readonly authToken: string | null;
    readonly isArchiveMode?: boolean;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
}

interface IDevelopmentProfilesListState {
    fatigueCalendarList: CurrentFatigueCalendarView[] | null;
    createdFatigueCalendar: CurrentFatigueCalendarView | null;
    isProcessing: boolean;
    list: any[] | null;
    paginationData: IPaginationData | null;

}

class DevelopmentProfilesList extends React.Component<IDevelopmentProfilesListProps, IDevelopmentProfilesListState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IDevelopmentProfilesListProps) {
        super(props);
        this.state = {
            createdFatigueCalendar: null,
            fatigueCalendarList: null,
            isProcessing: false,
            list: null,
            paginationData: null

        }

        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.userRole) this.retrieveList();
    }

    componentDidUpdate(
        prevProps: Readonly<IDevelopmentProfilesListProps>,
        prevState: Readonly<IDevelopmentProfilesListState>
    ) {
        if (this.state.list !== prevState.list) {
            this.setState({fatigueCalendarList: this.state.list});
        }
    }

    componentWillUnmount() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-12">
                    <CustomCard showLocalLoader={this.state.isProcessing}>
                        <CustomCard.Body>
                            {this.renderButton()}
                            {this.state.createdFatigueCalendar &&
                                <AddDevelopmentProfileFormModal onClose={this.addFatigueCalendarModal}
                                    onDevelopmentProfileAdd={this.addDevelopmentProfile}
                                    createdDevelopmentProfile={this.state.createdFatigueCalendar} />
                            }
                            {this.renderTable()}
                        </CustomCard.Body>
                        <CustomCard.Footer>
                            <Pagination paginationData={this.state.paginationData}
                                onSpecificPageLoad={(pageNumber: number) => this.retrieveList(pageNumber)} />
                        </CustomCard.Footer>
                    </CustomCard>
                </div>
            </div>
        )
    }

    private renderButton() {
        if ((this.props.userRole !== Role.Admin && this.props.userRole !== Role.HeadCoach) || this.props.isArchiveMode) return;

        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-theme btn-small ml-3"
                    onClick={this.openAddFatigueCalendarModal}>
                    <span className="feather icon-calendar" aria-hidden="true" />
                    <span><Translation text={'userView.button.addDevelopmentProfile'} /></span>
                </button>
            </div>
        )
    }

    private renderTable() {
        if (!this.state.fatigueCalendarList) {
            return null;
        }
        const noDevelopmentProfilesMessage = this.props.isArchiveMode ?
            <Translation text={'userList.table.noData.noArchivedDevelopmentProfiles'} /> :
            <Translation text={'userList.table.noData.noDevelopmentProfiles'} />;

        if (!this.state.fatigueCalendarList.length) {
            return (
                <div className="d-flex justify-content-center display-4">
                    <span>
                        {noDevelopmentProfilesMessage}
                    </span>
                </div>
            );
        }

        const rows: any = [];
        this.state.fatigueCalendarList.forEach((developmentProfile: CurrentFatigueCalendarView) => rows.push(
            <tr key={developmentProfile.id}>
                <td>{developmentProfile.name}</td>

                <td className="text-right">
                    <div className="d-flex justify-content-end button-wrapper">
                        <div className="btn-pair">
                            <button className="btn btn-theme btn-small ml-3"
                                disabled={!developmentProfile.trainingProfile}
                                onClick={() => developmentProfile.trainingProfile ? this.openTrainingPlanModal(developmentProfile.trainingProfile.id, developmentProfile.name) : null}>

                                <span className="feather icon-calendar" aria-hidden="true" />
                                <Translation text={'userList.table.button.edit.trainingPlan'} />
                            </button>
                            <button className="btn btn-theme btn-small"
                                disabled={!developmentProfile.trainingProfile}
                                onClick={() => developmentProfile.trainingProfile ? this.openFatigueCalendarModal(developmentProfile.trainingProfile.id) : null}>
                                <Translation text={'userList.table.button.view'} />
                            </button>
                        </div>

                        <div className="btn-pair">
                            <button className="btn btn-theme btn-small ml-3"
                                // onClick={() => this.props.onViewChange(developmentProfile.id, ProfileType.Form)}>
                                onClick={() => this.redirectToView(developmentProfile.id, ProfileType.Form)}>
                                <span className="feather icon-file-text" aria-hidden="true" />
                                <Translation text={'userList.table.button.edit.fatigueForm'} />
                            </button>
                            <button className="btn btn-theme btn-small"
                                // onClick={() => this.props.onViewChange(developmentProfile.id, ProfileType.Form)}>
                                onClick={() => this.redirectToView(developmentProfile.id, ProfileType.Form)}>
                                <Translation text={'userList.table.button.view'} />
                            </button>
                        </div>
                        <div className="btn-pair">

                            <button className="btn btn-theme btn-small ml-3"
                                // onClick={() => this.props.onViewChange(developmentProfile.id, ProfileType.Calendar)}>
                                onClick={() => this.redirectToView(developmentProfile.id, ProfileType.Calendar)}>
                                <span className="feather icon-calendar" aria-hidden="true" />
                                <Translation text={'userList.table.button.edit.fatigueCalendar'} />
                            </button>
                            <button className="btn btn-theme btn-small"
                                // onClick={() => this.props.onViewChange(developmentProfile.id, ProfileType.Form)}>
                                onClick={() => this.openFatigueCalendarModal(developmentProfile.id)}>
                                <Translation text={'userList.table.button.view'} />
                            </button>
                        </div>

                        {/*<TooltipWrapper tooltip={{text: 'userList.table.button.duplicateFatigueProfile'}}*/}
                        {/*                key={'duplicate-button'}>*/}
                        {/*    <button className="btn btn-theme btn-small ml-3"*/}
                        {/*            onClick={() => console.log('duplicate profile')}>*/}
                        {/*        <span className="feather icon-copy" aria-hidden="true"/>*/}
                        {/*        <span className="sr-only"><Translation*/}
                        {/*            text={'userList.table.button.duplicateFatigueProfile'}/></span>*/}
                        {/*    </button>*/}
                        {/*</TooltipWrapper>*/}

                        <TooltipWrapper tooltip={{text: 'userList.table.button.edit.developmentProfileSettings'}}
                            key={'settings-button'}>
                            <button className="btn btn-theme btn-small ml-3"
                                onClick={() => this.openDevelopmentProfileSettings(developmentProfile.id)}>
                                <span className="feather icon-settings" aria-hidden="true" />
                                <span><Translation
                                    text={'userList.table.button.edit.settings'} /></span>
                            </button>
                        </TooltipWrapper>
                    </div>
                </td>
            </tr>
        ));

        return (
            (
                <div className="mt-20">
                    <table className="data-table fatigue-profile-list">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text={'userList.table.header.name'} />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            )
        )
    }

    private openFatigueCalendarModal = (calendarId: string) => {
        this.props.changeModal({type: ModalType.CALENDAR, id: calendarId})
    }

    private openTrainingPlanModal = (calendarId: string, fatigueProfileName?: string) => {
        this.props.changeModal({type: ModalType.TRAINING_PLAN, id: calendarId, data: {fatigueProfileName: fatigueProfileName}});
    }

    private openDevelopmentProfileSettings = (calendarId: string) => {
        if (!calendarId) {
            return null
        }
        let path = ['', 'profiles'];
        path.push(calendarId);
        path.push(ProfileType.Settings);
        this.props.history.push(path.join('/'));
    }

    private openAddFatigueCalendarModal = () => {
        const emptyFatigueProfile: CurrentFatigueCalendarView = {
            id: '',
            name: '',
            calendarViewGroups: [],
            calendarEvents: []
        }

        this.setState({createdFatigueCalendar: emptyFatigueProfile})
    };

    private addFatigueCalendarModal = () => {
        this.setState({createdFatigueCalendar: null})
    };

    private addDevelopmentProfile = (developmentProfile: CurrentFatigueCalendarView) => {
        if (!this.state.fatigueCalendarList || !this.props.authToken) {
            return null;
        }
        const developmentProfileId = uuid();
        let createCalendarPayload = {
            name: developmentProfile.name
        };
        if (developmentProfile.calendarEvents.length) {
            const updatedPayload = Object.assign(createCalendarPayload, {calendarEvents: developmentProfile.calendarEvents});
            createCalendarPayload = updatedPayload;
        }
        let emptyProfileDefinition: ProfileDefinition = {
            name: developmentProfile.name,
            inputGroupDefinitions: [],
            calendars: [
                createCalendarPayload
            ],
            groupId: developmentProfileId
        };
        const trainingProfileDefinition: ProfileDefinition = {
            name: 'Trening',
            inputGroupDefinitions: [],
            calendars: [{name: 'Trening'}],
            groupId: developmentProfileId
        }

        const trainingProfileCreationObservable$ = addProfileDefinitionAPI(this.props.authToken, trainingProfileDefinition).pipe(
            switchMap((value: any) => {
                if (!this.props.authToken || !value) {
                    return of();
                }
                const profileDefinitionId = value.id,
                    calendarId = value.calendars[0].id,
                    inputGroupDefinitionsArray = [1, 2, 3, 4].map((value: number) => getGroup(value, calendarId, profileDefinitionId)),
                    inputGroupCreateCalls = [1, 2, 3, 4].map((value: number, index: number) => addInputGroupDefinitionAPI(this.props.authToken as string, inputGroupDefinitionsArray[index]));
                return forkJoin(inputGroupCreateCalls);
            }),
            switchMap((inputGroupDefinitionsArray: any) => {
                const inputDefinitionCalls = inputGroupDefinitionsArray.map((value: any, index: number) => {
                    const inputDefinitions = getGroupInputs(value.id, value.calendarViewGroups[0].id);
                    return addMultipleInputDefinitionsAPI(this.props.authToken as string, inputDefinitions);
                })
                return forkJoin(inputDefinitionCalls)
            }
            ));
        this.setState({isProcessing: true});
        this.subscriptions.push(
            addProfileDefinitionAPI(this.props.authToken, emptyProfileDefinition).pipe(
                tap((profileDefinitionResponse: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.addAlert('alert.fatigueProfileAlert.createProfileSuccess');
                    return this.retrieveList();
                }),
                catchError((err: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
            ).subscribe(),
            trainingProfileCreationObservable$.subscribe());
    }

    private setPaginationData(paginationResponse: any) {
        const currentPageNumber = paginationResponse['@id'].split('page=').pop(),
            lastPageNumber = paginationResponse['hydra:last'].split('page=').pop();
        if (!currentPageNumber || !lastPageNumber) {
            return this.setState({paginationData: null});
        }
        this.setState({paginationData: {lastPage: lastPageNumber, currentPage: currentPageNumber}})
    }

    private retrieveList = (pageNumber?: number) => {
        if (!this.props.authToken || !this.props.userRole) {
            return null;
        }

        this.setState({isProcessing: true});

        this.subscriptions.push(
            getDevelopmentProfiles(
                this.props.authToken as string,
                pageNumber,
                1000,
            ).pipe(
                filter(response => !!response),
                tap((resp: any) => {
                    if (resp['hydra:view'] && resp['hydra:view']['hydra:last']) this.setPaginationData(resp['hydra:view']);
                    if (resp['hydra:member']) {
                        this.setState({list: resp['hydra:member']})
                    }
                    this.setState({isProcessing: false})
                }),
                catchError((err: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
                ).subscribe(),
        );
    }
    private redirectToView(accountId: string, profileType: ProfileType) {
        let path = ['', 'profiles'];
        const profileDefinitionId = accountId,
            calendar = this.state.list?.find((calendar: any) => calendar.profileDefinition.id === profileDefinitionId);
        path.push(calendar ? calendar.id : accountId);
        path.push(profileType);

        this.props.history.push(path.join('/'));
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        userRole: userRoleSelector(state),
        modal: modalSelector(state)
    }),
    {
        changeShouldListUpdate,
        changeModal
    }
)(withRouter((DevelopmentProfilesList)));
