export enum Role {
    Admin = "ROLE_ADMIN",
    Coach = "ROLE_COACH",
    Contestant = "ROLE_CONTESTANT",
    Specialist = "ROLE_SPECIALIST",
    HeadCoach = "ROLE_HEAD_COACH",
}

export enum UserType {
    ADMIN = 'admin',
    CONTESTANT = 'contestant',
    SPECIALIST = 'specialist',
    COACH = 'coach',
    CLUB_COACH = 'CLUB_COACH',
    TENANT = 'tenant',
    CLUB = 'club',
    SPECIALIST_TYPE = 'specialist-type',
    FATIGUE_PROFILE = 'fatigueProfile'
}

export enum InputType {
    INPUT_TEXT = 'textarea',
    INPUT_NUMERIC = 'number',
    INPUT_DATE = 'datepicker',
    INPUT_NUMBER = 'number',
    SELECT = 'select',
    CALCULATED = 'calculated'
}

export enum ProfileStatus {
    FILLED = 'filled',
    TO_FILL = 'to_filled'
}

export enum ModalType {
    CREATE = 'create',
    CHANGE_PASSWORD = 'change_password',
    ARCHIVE = 'archive',
    RESTORE = 'restore',
    PROFILE = 'profile',
    EDIT_PROFILE = 'edit_profile',
    EDIT_CLUB = 'edit_club',
    FATIGUE_PROFILE = 'fatigue_profile',
    ASSIGN_FATIGUE_PROFILE = 'assign_fatigue_profile',
    ASSIGN_CONTESTANT = 'assign_contestant',
    ASSIGN_COACH = 'assign_coach',
    ASSIGN_SPECIALIST = 'assign_specialist',
    UNASSIGN = 'unassign',
    ADD_CALENDAR_EVENT = 'add_calendar_event',
    TRAINING_PLAN = 'training_plan',
    LOGOUT = 'logout',
    CALENDAR = 'calendar',
    FORM_GROUP_VARIABLES = 'form_group_variables',
    CALENDAR_GROUP_VARIABLES = 'calendar_group_variables',
    REMOVE = 'remove',
    NULL = 'null',
}

export enum ProfileType {
    Calendar = 'calendar',
    Form = 'form',
    Settings = 'settings',
}

export enum FatigueProfileViewType {
    List = 'list',
    View = 'view',
    Settings = 'settings',
}

export enum ArchiveType {
    Profile = 'profile',
    Contestant = 'contestant',
    Specialist = 'specialist',
    Coach = 'coach',
    Club = 'club',
}

export type ModalConfig = {
    type: ModalType;
    role? : UserType;
    id?: string;
    multipleIds?: string[];
    data?: any;
    userId?: string
}

export type VisualOptions = {
    [key: string]: string
}

export type CurrentFatigueCalendarView = {
    id: string;
    name: string;
    calendarViewGroups: CalendarGroup[];
    calendarEvents: CalendarEvent[];
    profileDefinition?: ProfileDefinition;
    trainingProfile?: CurrentFatigueCalendarView;
}

export type CalendarGroup = {
    id: string;
    itemOrder: number;
    name: string;
    enabled: boolean;
    visualOptions: VisualOptions;
    calendarViewGroupItems: CalendarInput[];
    inputGroupDefinitionId?: string;
    inputGroupDefinition?: InputGroupDefinition;
    calendarId?: string;
}

export type CalendarInput = {
    id: string;
    name: string;
    type: InputType;
    enabled: boolean;
    calendarViewGroupId?: string;
    itemOrder: number;
    valuesVisualOptions: CalendarInputOption[];
    inputDefinition?: InputDefinition;
    parameters?: CalendarInputParameters;
}

export type CalendarInputParameters = {
    selectValues?: any[];
    expression?: any;
    defaultValue?: any;
    label?: string | null;
    placeholder?: string | null;
    postfix?: string | null;
    prefix?: string | null;
    required?: boolean | null;
    showLabel?: boolean | null;
    type?: string;
}

export type CalendarInputOption = {
    value: string;
    label: string;
    backgroundColor: string;
}

export type CalendarEvent = {
    name: string;
    startsAt: string,
    endsAt?: string,
    id?: string
}

export type User = {
    id: string;
    login: string;
    registrationConfirmed: boolean;
    account: Account;
    photo?: string;
    password?: string;
    plainPassword?: string;
    roles?: string;
    resetPasswordComponentURL?: string;
};
export type Account = {
    id: string;
    userId: string;
    firstName?: string;
    lastName?: string;
    birthDate?: Date;
    phone?: string;
    address?: string;
    status: string;
    active: boolean;
    type: UserType;
    tenant?: Tenant;
    specialist?: Specialist;
    specialists?: SpecialistListItem[];
    contestants?: ContestantListItem[];
    contestant?: Contestant;
    coaches?: CoachListItem[];
    coach?: Coach;
    profiles?: Profile[];
    profileDefinitions?: ProfileDefinition[];
};


export type Specialist = {
    id: string;
    type: SpecialistType;
    accounts: Account[];
};

export type Coach = {
    id: string;
    // TODO: CoachType?
    type: CoachType;
    accounts: Account[];
    clubs: Club[];
};

export type Contestant = {
    id: string;
    weightCategory: number;
    clubId: string;
    club: Club;
};


export type Club = {
    id: string;
    name: string;
    logo: string;
    disabled: boolean;
    tenantId: string;
    contestants: Contestant[];
    coaches: Coach[];
};

export type ProfileListItem = {
    name: string;
    id: string;
};

export type Tenant = {
    id: string;
    accountId: string;
    clubs: Club[];
}

export type ContestantListItem = {
    id: string;
    firstName: string;
    lastName: string;
    profiles: ProfileListItem[]
}
export type SpecialistListItem = {
    id: string;
    firstName: string;
    lastName: string;
    type: SpecialistType;
}

export type CoachListItem = {
    id: string;
    firstName: string;
    lastName: string;
}

export type ClubListItem = {
    id: string;
    name: string;
}

export type SpecialistType = {
    id: string;
    label: string;
}

export type CoachType = {
    id: string;
    label: string;
}

export type SpecialistView = {
    id: string;
    login: string;
    photo: string;
    account: {
        firstName: string;
        lastName: string;
        phone: string;
        status: string;
        active: boolean;
        type: SpecialistType;
        coaches: CoachListItem[];
        contestants: ContestantListItem[];
        profiles: ProfileListItem[];
    }
}

export type ContestantView = {
    id: string;
    login: string;
    photo: string;
    account: {
        firstName: string;
        lastName: string;
        birthDate: Date;
        phone: string;
        status: string;
        active: boolean;
        club: ClubListItem;
        coaches: CoachListItem[];
        specialists: SpecialistListItem[];
        profiles: ProfileListItem[];
    }
}

export type CoachView = {
    id: string;
    login: string;
    photo: string;
    account: {
        firstName: string;
        lastName: string;
        birthDate: Date;
        phone: string;
        status: string;
        active: boolean;
        clubs: ClubListItem[];
        contestants: ContestantListItem[];
        profiles: ProfileListItem[];
    }
}

export type ClubView = {
    id: string;
    login: string;
    logo: string;
    account: {
        name: string;
        phone: string;
        status: string;
        active: boolean;
        coaches: CoachListItem[];
        contestants: ContestantListItem[];
    }
    // TODO: ustalic czy beda w klubie
    // profiles: ProfileListItem[];
    // specialists: SpecialistListItemItem[];
}

export type UserInvitationPayload = {
    email: string;
    firstName: string;
    type: UserType;
    lastName?: string;
    tenantId?: string;
    specialistTypeId?: string;
}

export type ProfileDefinition = {
    name: string;
    // status: string;
    // profiles: Profile[];
    // accountId: string;
    inputGroupDefinitions: InputGroupDefinition[];
    id?: string;
    createdAt?: Date;
    updatedAt?: Date;
    propagationDateTime?: Date;
    calendars? : any[];
    groupId?: string;
}

export type Profile = {
    id: string;
    createdAt: Date;
    validFrom: Date;
    validUntil: Date;
    status: string;
    accountId: string;
    profileDefinitionId: string;
    inputGroups: InputGroup[];
}


export type InputGroupDefinition = {
    itemOrder: number;
    name: string;
    profileDefinitionId: string;
    inputDefinitions: InputDefinition[];
    id?: string;
    enabled?: boolean;
    visualOptions?: string;
    inputGroups?: InputGroup[];
    type: InputGroupDefinitionType;
}

export enum InputGroupDefinitionType  {
    NORMAL ='normal',
    TRAINING="training"
}

export type InputDefinition = {
    type: string;
    name: string;
    parameters: CalendarInputParameters;
    visualOptions: string;
    enabled: boolean;
    itemOrder: number;
    inputGroupDefinitionId?: string;
    inputGroupDefinition?: InputGroupDefinition;
    id?: string;
    calendarViewGroupItems: CalendarInput[];
    inputs?: ProfileInput[];
    inputResults?: TrainingPlanResult[]
}

export type TrainingPlanResult = {
    '@id': string;
    '@type': "InputResult";
    createdAt: string;
    resultForDate: string;
    id: string
    value: {value: string | number}
}


export type InputGroup = {
    id: string;
    name: string;
    visualOptions: string;
    enabled: boolean;
    order: string;
    fatigueProfileId: string;
    inputGroupDefinitionId: string;
    inputs: ProfileInput[];
}

export type RawInputGroup = {
    inputGroupDefinitionId: string;
    inputGroupDefinitionEnabled: boolean;
    inputGroupId: string;
    inputGroupName: string;
    profileInputs: RawProfileInput[];
    itemOrder: number;
    isFilled?: boolean;
    status?: ProfileStatus;
}

export type RawProfileInput = {
    inputName: string;
    inputId: string;
    inputDefinitionId: string;
    inputParameters: CalendarInputParameters;
    inputResultId: string;
    inputResultValue: {value?: string | number};
    inputType: InputType;
    itemOrder: number;
    resultForDate?: string;
}

export type RawProfileDefinition = {
    id: string;
    name: string;
}

export type RawProfile = {
    '@id': string;
    inputGroups: RawInputGroup[];
    profileDefinition: RawProfileDefinition
    validFrom: string;
    status: ProfileStatus;
}

export type ProfileInput = {
    id: string;
    name: string;
    parameters: {[key: string]: any};
    type: string;
    visualOptions: string;
    enabled: boolean;
    order: string;
    inputGroupId: string;
    inputDefinitionId: string;
    inputResult: InputResult;
    inputResults: InputResult[];
    inputDefinition: InputDefinition;
}

export type InputResult = {
    id: string;
    value: any;
    createdAt: Date;
    updatedAt: Date;
    inputId: string;
}
