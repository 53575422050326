import React from "react";
import {
    authTokenSelector,
    FormControlChangeType,
    FuriaForm,
    Loader, LoaderType,
    Translation
} from "judo-app-common-web";
import {
    CalendarGroup,
    CalendarInput,
    RawInputGroup,
    RawProfileInput
} from "../../../../model/models";
import {IInputResultPayload} from "../../../../api/createInputResult";
import {catchError, mergeMap, take, tap} from "rxjs/operators";
import {forkJoin, of, Subject, Subscription, Observable} from "rxjs";
import {updateInputResultAPI} from "../../../../api/updateInputResult";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IFormConfigConverterService} from "../../../../service/formConfigConverter";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {connect} from "react-redux";
import {userRoleSelector} from "../../../../store/selectors/userSelector";
import moment from "moment";
import {CalendarIntoRawProfileConverterService} from "../../../../service";
import {deepCloneObject, isNotNullOrUndefined} from "../../../../utils/runtimeUtils";
import {createInputResultsAPI} from "../../../../api/createInputResults";


interface IModalEditTrainingPlanCellProps {
    inputData: RawProfileInput | CalendarInput;
    calculatedGroup?: RawInputGroup | CalendarGroup;
    userRole: string;
    authToken: string;
    closeModal: (editedCell?: RawProfileInput, editedGroup?: RawInputGroup) => void;
    getUpdatedProfilesData: () => Observable<void | string>;
    getUpdatedCalendarData: () => Observable<void | string>;
    date: Date | undefined
    isPreview?: boolean;
}

interface IModalEditTrainingPlanCellState {
    value: any;
    isFormValid: boolean;
    isLoading: boolean;
}

class ModalEditTrainingPlanCell extends React.Component<IModalEditTrainingPlanCellProps, IModalEditTrainingPlanCellState> {

    @lazyInject('FormConfigConverterService') private formConfigConverterService: IFormConfigConverterService;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('CalendarIntoRawProfileConverterService') private calendarIntoRawProfileConverterService: CalendarIntoRawProfileConverterService;


    private subscription: Subscription;
    private submitChangesSubject = new Subject()

    constructor(props: IModalEditTrainingPlanCellProps) {
        super(props);
        this.state = {
            value: null,
            isFormValid: false,
            isLoading: false
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscription = this.submitChangesSubject.pipe(
            tap(() => this.setState({isLoading: true})),
            mergeMap((apiCallsArray: any) => forkJoin(...apiCallsArray.map((result: any) => result.inputResultId ? this.updateInputResults(result.inputResultId, result.payload) : this.createInputResults(result.payload)))),
            mergeMap(() => (this.props?.inputData && 'inputName' in this.props?.inputData) ? this.props.getUpdatedProfilesData() : this.props.getUpdatedCalendarData()),
        ).subscribe(() => {
            this.setState({isLoading: false})
            this.props.closeModal()
        })
    }

    componentWillUnmount() {
        this.subscription.unsubscribe()
    }

    render() {
        if (!this.props.inputData || !this.convertedRawInput) {
            return null
        }
        const inputResult = this.convertedRawInput.inputResultValue;
        let inputValue = inputResult?.value;
        return (
            <div className="p-4 mt-5">
                <h1 className="mb-4"><Translation text={'profiles.editCellModal.title'} /></h1>
                <FuriaForm
                    config={this.config}
                    disabled={false}
                    onValidationStateChange={this.onValidationStateChange}
                    onValueStateChange={this.onValueStateChange}
                    value={this.state.value}
                    controlName={this.convertedRawInput.inputDefinitionId}
                />
                <footer className={`d-flex justify-content-end mt-5`}>
                    <button className="btn btn-danger mr-4" onClick={() => this.props.closeModal()}>
                        <Translation text={'button.cancel'} />
                    </button>
                    <button tabIndex={!this.state.isFormValid ? -1 : 0}
                        onClick={('inputResultId' in this.convertedRawInput && isNotNullOrUndefined(this.convertedRawInput.inputResultId)) ? this.submitUpdate : this.createTrainingPlan}
                        disabled={!this.state.isFormValid && this.state.value !== inputValue}
                        className="btn btn-theme">
                        <Translation text={'button.submit'} />
                    </button>
                </footer>
                <Loader show={this.state.isLoading} type={LoaderType.Local} />
            </div>
        )
    }

    private get convertedRawInput(): RawProfileInput {
        if ('inputName' in this.props.inputData) {
            return this.props.inputData
        }
        return this.calendarIntoRawProfileConverterService.convertCalendarInputIntoRawProfileInput(this.props.inputData, this.props.date)
    }

    private createTrainingPlan = () => {
        const allCallsToDo: any[] = [];
        let payloadArray: any[] = [];

        let payload = {
            id: null,
            value: {
                value: this.state.value[this.convertedRawInput?.inputDefinitionId]
            },
            resultForDate: new Date(moment(this.props.date).format('YYYY-MM-DD')).toISOString(),
            // inputId: this.convertedRawInput?.inputId,
            inputId: null,
            // inputDefinitionId: null,
            inputDefinitionId: this.convertedRawInput?.inputDefinitionId
        }

        if (this.props.calculatedGroup && 'calendarViewGroupItems' in this.props.calculatedGroup && this.props.calculatedGroup.calendarViewGroupItems.length > 0) {
            const groupItems = deepCloneObject(this.props.calculatedGroup.calendarViewGroupItems).filter((item: any) => item.inputDefinitionId !== this.convertedRawInput?.inputDefinitionId);
            groupItems.forEach((calculatedInput: CalendarInput) => {
                if (!calculatedInput.inputDefinition?.id) return;
                const firstInput = this.convertedRawInput?.inputDefinitionId === calculatedInput.inputDefinition?.id;
                payload = {
                    id: null,
                    value: {
                        value: firstInput ? this.state.value[this.convertedRawInput?.inputDefinitionId] : null,
                    },
                    resultForDate: new Date(moment(this.props.date).format('YYYY-MM-DD')).toISOString(),
                    // inputId: this.convertedRawInput?.inputId,
                    inputId: null,
                    // inputDefinitionId: null,
                    inputDefinitionId: calculatedInput.inputDefinition.id
                }
                payloadArray.push(payload);
            })
        }
        allCallsToDo.push({payload: payloadArray});
        return this.submitChangesSubject.next(allCallsToDo);
    }

    private submitUpdate = () => {
        if (!this.state.isFormValid || !this.props.inputData || !this.convertedRawInput) {
            return;
        }

        const allCallsToDo: any[] = []
        const inputResultId = this.convertedRawInput.inputResultId;
        const value = (this.state.value?.[this.convertedRawInput.inputDefinitionId])?.toString();
        const inputId = this.convertedRawInput?.inputId;

        let payload: IInputResultPayload = {
            // inputId: inputId,
            inputId: this.props.isPreview ? inputId : null,
            // inputDefinitionId: null,
            inputDefinitionId: this.convertedRawInput?.inputDefinitionId,
            value: {
                value: value
            }
        };

        //selected input:
        if (inputResultId) {
            allCallsToDo.push({payload, inputResultId});
        } else {
            allCallsToDo.push({payload})
        }

        return this.submitChangesSubject.next(allCallsToDo);
    }

    private createInputResults = (payload: IInputResultPayload[]) => {
    return (createInputResultsAPI(this.props.authToken, payload) as any).pipe(
        take(1),
        catchError((err: any) => {
            this.setState({isLoading: false});
            this.alertManagerService.handleApiError(err);
            return of();
        })
    )
}

    private updateInputResults = (id: string, payload: IInputResultPayload) =>
        (updateInputResultAPI(this.props.authToken, id, payload) as any).pipe(
            take(1),
            catchError((err: any) => {
                this.setState({isLoading: false});
                this.alertManagerService.handleApiError(err);
                return of();
            })
        )

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType, path: string) => {
        if (!this.convertedRawInput) {
            return;
        }
        if (this.convertedRawInput.inputDefinitionId !== controlName || changeType !== FormControlChangeType.User) {
            return;
        }

        this.setState({value: value});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        if (!this.convertedRawInput) {
            return;
        }
        if (this.convertedRawInput.inputDefinitionId === controlName) {
            this.setState({isFormValid: isValid});
        }
    };

    private get config() {
        return ({
            controlType: 'form',
            class: '',
            controls: [this.formConfigConverterService.setDefaultFormInput(this.convertedRawInput, 0)]
        })
    }
}

export default connect(
    (state: any) => ({
        userRole: userRoleSelector(state),
        authToken: authTokenSelector(state)
    }),
    {}
)(ModalEditTrainingPlanCell);