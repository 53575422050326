import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import pl_PL from './assets/i18n/pl_PL';
import en_US from './assets/i18n/en_US';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en_US: {
        translation: en_US
    },
    pl_PL: {
        translation: pl_PL
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,

        lng: 'pl_PL',

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
