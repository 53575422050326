import React from 'react';
import AnimatedLayoutHost, {EndpointNames} from "../Shared/AnimatedLayoutHost";
import SpecialistList from "./SpecialistList";
import SpecialistView from "./SpecialistView";

interface ISpecialistsProps {
    isArchiveMode: boolean;
}

class Specialists extends React.Component<ISpecialistsProps> {

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <AnimatedLayoutHost host={"specialists"}
                                            listComponent={SpecialistList}
                                            viewComponent={SpecialistView}
                                            endpointName={EndpointNames.SPECIALISTS}
                                            isArchiveMode={this.props.isArchiveMode}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default Specialists;

