import {BasicFormControlType, FormButtonType, IFormConfig, InputType, ValidationRules} from "judo-app-common-web";


export const addDevelopmentProfileFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'new_fatigue_profile',
            controlType: 'group',
            class: 'd-flex',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'userList.addDevelopmentProfile.form.name.placeholder',
                    isLabelHidden: false,
                    label: 'userList.addDevelopmentProfile.form.name.label',
                    type: InputType.INPUT_TEXT,
                    hostClass: ''
                }
            }
        },
        {
            key: 'buttons',
            controlType: 'group',
            class: 'button-group-wrapper mt-5',
            controls: {
                cancelButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    hostClass: 'button-container',
                    defaultContainerStyles: 'd-flex justify-content-end',
                    defaultInputStyles: 'btn btn-theme-outline btn-small',
                    defaultValue: null,
                    buttonDisabled: false,
                    btnText: `button.cancel`,
                    isLabelHidden: true,
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'button-container ml-3',
                    defaultContainerStyles: 'd-flex justify-content-end',
                    defaultInputStyles: 'btn btn-theme btn-small',
                    defaultValue: null,
                    btnText: `userList.table.button.add.developmentProfile`,
                    isLabelHidden: true,
                }
            }
        }
    ]
};

