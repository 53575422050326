import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {ProfileDefinition} from "../model/models";

export function addProfileDefinitionAPI(
    authToken: string,
    payload: ProfileDefinition,
): Observable<any> {
    return baseDataProviderAPI.post(
        "profile_definitions",
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
