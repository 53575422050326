import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function sendResetPasswordMailAPI(
    email: string,
    returnUrl: string,
): Observable<any> {
    const payload = {
        email: email,
        returnUrl: returnUrl
    };
    return baseDataProviderAPI.post(
        "send_reset_password_link",
        payload,
        new RestQueryParams()
    );
}
