import React from 'react';
import {
    authTokenSelector,
    BasicFormControl,
    CustomCard,
    IBasicFormControlConfig,
    Translation
} from 'judo-app-common-web';
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {deepCloneObject} from "../../utils/runtimeUtils";
import {loginInputConfig} from "./loginInputConfig";
import Button, {ButtonBasicType} from "../Shared/Button";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {changeModal, changeShouldViewUpdate} from "../../store/reducers/sagaSlice";
import {ModalType} from "../../model/models";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {getUserMeAPI} from "../../api/getUserMe";
import {shouldViewUpdateSelector} from "../../store/selectors/sagaSelectors";
import {userSelector} from "../../store/selectors/userSelector";
import {changeUser} from '../../store/reducers/userSlice';
import {passwordLoadingSelector} from "../../store/selectors/changePasswordSelectors";
import UserInfoBox, {UserInfoType} from "../Shared/UserInfoBox";

interface IConnectedAccountProps {
    readonly isLoading: boolean;
    readonly authToken: string;
    readonly shouldViewUpdate: boolean;
    readonly changeModal: typeof changeModal;
    readonly changeUser: typeof changeUser;
    readonly changeShouldViewUpdate: typeof changeShouldViewUpdate;
}

interface IAccountProps extends IConnectedAccountProps, RouteComponentProps {
    readonly user: any;
}

interface IAccountState {
    formConfig: { [key: string]: typeof IBasicFormControlConfig }
    account: any;
    isProcessing: boolean;
}

export enum AccountModal {
    Password = 'password',
}

class Account extends React.Component<IAccountProps, IAccountState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IAccountProps) {
        super(props);
        const updatedFormConfig = deepCloneObject(loginInputConfig);
        updatedFormConfig['login'].defaultValue = this.props.user.user.login;
        this.state = {
            formConfig: updatedFormConfig,
            account: null,
            isProcessing: true,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.user) this.setState({account: this.props.user});
    }

    componentDidUpdate(
        prevProps: Readonly<IAccountProps>,
        prevState: Readonly<IAccountState>,
    ): void {
        if (this.props.shouldViewUpdate &&
            this.props.shouldViewUpdate !== prevProps.shouldViewUpdate) {
            this.retrieveAccount();
            this.props.changeShouldViewUpdate(false);
        }
        if (this.props.user !== prevProps.user) {
            this.setState({account: this.props.user})
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.account) {
            return null;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4">
                        <CustomCard showLocalLoader={this.props.isLoading} type={"account"}>
                            <CustomCard.Header>
                                <h3><Translation text={'account.user.header'}/></h3>
                                <Button label={'account.changePassword.button'}
                                        buttonType={ButtonBasicType.Submit}
                                        onClick={() => this.props.changeModal({
                                            type: ModalType.CHANGE_PASSWORD,
                                            id: this.state.account.id
                                        })}/>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <BasicFormControl config={this.state.formConfig['login']}
                                                  controlName={'login'}
                                                  submitTouched={false}
                                                  key={'login'}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-4">
                        <CustomCard showLocalLoader={this.props.isLoading} type={"account"}>
                            <CustomCard.Header>
                                <h3><Translation text={'account.personalProfile.header'}/></h3>
                                <Button label={'account.personalProfile.button'}
                                        buttonType={ButtonBasicType.Submit}
                                        onClick={() => this.props.changeModal({
                                            type: ModalType.EDIT_PROFILE,
                                            id: this.state.account.id
                                        })}/>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <UserInfoBox user={this.state.account} infoCellType={UserInfoType.Extended}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private retrieveAccount() {
        if (!this.props.authToken) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(
            getUserMeAPI(this.props.authToken).pipe(
                map((response: any) => {
                    if (response) {
                        this.props.changeUser(response);
                    }
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe())
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: passwordLoadingSelector(state),
        authToken: authTokenSelector(state),
        user: userSelector(state),
        shouldViewUpdate: shouldViewUpdateSelector(state),
    }),
    {
        changeModal,
        changeUser,
        changeShouldViewUpdate
    }
)(withRouter(Account));
