import React, {CSSProperties} from 'react';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IDayItem} from "../index";
import TrainingPlanCell from "./TrainingPlanCell";
import {transformObjectToCSSProperties} from "../../../../utils/fatigueTableUtils";
import {
    CalendarGroup,
    CalendarInput,
    InputType,
    RawInputGroup,
    RawProfile,
    RawProfileInput
} from "../../../../model/models";
import {Subscription} from "rxjs";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {DatepickerType, Translation} from "judo-app-common-web";
import moment from "moment";
import {CalendarIntoRawProfileConverterService} from "../../../../service";

interface ITrainingPlanGroupProps {
    calendarGroup: CalendarGroup;
    profilesFromMonth: RawProfile[];
    month: IDayItem[];
    authToken: string;
    isPreview?: boolean;
    setEditedCellAndGroup: (editedCell?: RawProfileInput | CalendarInput, editedGroup?: RawInputGroup | CalendarGroup, date?: Date) => void
}

interface ITrainingPlanGroupState {
    fatigueProfilesData: any;
    summaryCalculations: SummaryCalculations;
    isGroupCollapsed: boolean;
}


interface GroupDateCalculations {
    mostOftenId: string
}

interface SummaryCalculations {
    [inputDefinitionId: string]: GroupDateCalculations
}

export interface InputToGenerateTable {
    input: RawProfileInput;
    inputDefinitionId: string;
    calculatedGroup: RawInputGroup | undefined;
}

class TrainingPlanGroup extends React.Component<ITrainingPlanGroupProps, ITrainingPlanGroupState> {
    private subscription: Subscription | null;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('CalendarIntoRawProfileConverterService') private calendarIntoRawProfileConverterService: CalendarIntoRawProfileConverterService;


    constructor(props: ITrainingPlanGroupProps) {
        super(props);
        this.state = {
            fatigueProfilesData: null,
            summaryCalculations: {},
            isGroupCollapsed: false,
        }
        fixInjectedProperties(this);
    }

    shouldComponentUpdate(nextProps: Readonly<ITrainingPlanGroupProps>, nextState: Readonly<ITrainingPlanGroupState>, nextContext: any): boolean {
        if (this.props.profilesFromMonth !== nextProps.profilesFromMonth ||
            this.props.calendarGroup !== nextProps.calendarGroup ||
            this.props.month !== nextProps.month ||
            this.state.isGroupCollapsed !== nextState.isGroupCollapsed) {
            return true
        }
        return false
    }


    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        if (!this.props.month) {
            return null;
        }
        const calendarGroup = this.props.calendarGroup;
        return <React.Fragment>
            {this.renderInputGroup(calendarGroup.calendarViewGroupItems, this.state.isGroupCollapsed, this.props.isPreview)}
        </React.Fragment>
    }

    private renderInputGroup(inputs: CalendarInput[], isGroupCollapsed: boolean, isPreview?: boolean) {
        return isPreview ? this.renderGroupInputsPreview(inputs, isGroupCollapsed) : this.renderGroupInputsSettings(inputs, isGroupCollapsed);
    }

    private renderGroupInputsSettings(inputs: CalendarInput[], isGroupCollapsed: boolean): CalendarInput[] {
        const calendarGroup = this.props.calendarGroup;
        const sortedInputs = inputs.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        let inputsTable: any = [],
            rowVisualOptions: CSSProperties | undefined = undefined;
        if (calendarGroup.visualOptions) {
            rowVisualOptions = transformObjectToCSSProperties(calendarGroup.visualOptions);
        }
        const trainingAimInput = sortedInputs[0];
        if (trainingAimInput) {
            inputsTable = [
                <tr style={rowVisualOptions}
                    key={`row-${trainingAimInput?.id}`}>
                    <th aria-hidden={true} className='dummy-cell'></th>
                    {trainingAimInput.enabled && this.renderInputRow(trainingAimInput, this.props.month, 0, calendarGroup.name)}
                </tr>
            ]
        }
        return inputsTable;

    }

    private renderGroupInputsPreview(inputs: CalendarInput[], isGroupCollapsed: boolean): CalendarInput[] {
        const calendarGroup = this.props.calendarGroup;
        const sortedInputs = inputs.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        let inputsTable: any = [],
            rowVisualOptions: CSSProperties | undefined = undefined;
        if (calendarGroup.visualOptions) {
            rowVisualOptions = transformObjectToCSSProperties(calendarGroup.visualOptions);
        }
        isGroupCollapsed ?
            inputsTable = [<tr style={rowVisualOptions}
                key={`row-${calendarGroup.id}`}>
                <th className="fatigue-profile-group-name" colSpan={this.props.month.length + 1}>
                    <div>
                        <button className="collapse-button horizontal" onClick={() => this.toggleGroupCollapse()}>
                            <Translation text={'button.expand'} />
                        </button>
                        <span className="group-name">
                            {calendarGroup.name}
                        </span>
                    </div>
                </th>
            </tr>]
            :
            sortedInputs.forEach((input: any, i: number) => inputsTable.push(<tr style={rowVisualOptions}
                key={`row-${input.id}`}>
                {i === 0 && <th className="fatigue-profile-group-name" scope="rowGroup" rowSpan={inputs.length}>
                    <div className="group-name-wrapper">
                        <button className="collapse-button vertical" onClick={() => this.toggleGroupCollapse()}>
                            <Translation text={'button.collapse'} />
                        </button>
                        <span className="group-name">
                            {calendarGroup.name}
                        </span>
                    </div>
                </th>}
                {input.enabled && this.renderInputRow(input, this.props.month, i)}
            </tr>));


        return inputsTable;
    }

    private toggleGroupCollapse() {
        this.setState({isGroupCollapsed: !this.state.isGroupCollapsed});
    }

    private renderInputRow = (input: CalendarInput, month: IDayItem[], inputIndex: number, groupName?: string) => {
        let inputRow: any[] = [<th scope="row" key={`${input.id + inputIndex}`}
            className="input-name">
            {groupName && <div>{groupName}</div>}
            <div>{input.name}</div>
        </th>];

        let allRowCellValues: (number | string)[] = [];
        let singleInputData: RawProfileInput | null = null;
        month.forEach((day: IDayItem) => {
            const profileFromDay = this.getProfileFromDay(day.dateObject);
            let inputData: InputToGenerateTable | undefined;
            if (profileFromDay) {
                const inputGroup = profileFromDay.inputGroups?.find((inputGroup: RawInputGroup) => inputGroup.inputGroupDefinitionId === input.inputDefinition?.inputGroupDefinition?.id);
                const inputFromProfile = inputGroup?.profileInputs.find((profileInput: RawProfileInput) => profileInput.inputDefinitionId === input.inputDefinition?.id);
                if (inputGroup && inputFromProfile) {
                    inputData = {
                        input: inputFromProfile,
                        inputDefinitionId: inputFromProfile?.inputDefinitionId,
                        calculatedGroup: inputGroup
                    };
                }
                if (inputData) {
                    if (!singleInputData) {
                        singleInputData = inputData.input
                    }
                    const result = inputData?.input?.inputResultValue?.value;
                    if (result) {
                        allRowCellValues.push(result);
                    }
                    return inputRow.push(
                        <TrainingPlanCell key={`cell-${day.dayNumber}-${day.daysName}`}
                            groupType={this.props.calendarGroup.inputGroupDefinition?.type}
                            inputData={inputData.input}
                            calculatedGroup={inputData.calculatedGroup}
                            setEditedCellAndGroup={this.props.setEditedCellAndGroup}
                            valuesVisualOptions={input.valuesVisualOptions}
                            inputName={input.name} />
                    )
                }
            }

            if (input.inputDefinition?.inputResults && input.inputDefinition?.inputResults?.length > 0) {
                const dayPlanResult = input.inputDefinition?.inputResults.find(
                    (result) =>
                        new Date(moment(day.dateObject).format('YYYY-MM-DD')).getTime() === new Date(result.resultForDate).getTime())
                if (dayPlanResult) {
                    const result = input.inputDefinition?.type === InputType.INPUT_DATE ? this.formatDate(this.calendarIntoRawProfileConverterService.convertCalendarInputIntoRawProfileInput(input, day.dateObject)) : dayPlanResult.value?.value;
                    allRowCellValues.push(result as string)
                }
            }

            if (!singleInputData) {
                singleInputData = this.calendarIntoRawProfileConverterService.convertCalendarInputIntoRawProfileInput(input, day.dateObject)
            }
            return inputRow.push(
                <TrainingPlanCell key={`cell-${day.dayNumber}-${day.daysName}`}
                    day={day}
                    calendarInput={input}
                    groupType={this.props.calendarGroup.inputGroupDefinition?.type}
                    calendarGroup={this.props.calendarGroup}
                    setEditedCellAndGroup={this.props.setEditedCellAndGroup}
                    valuesVisualOptions={input.valuesVisualOptions}
                    inputName={input.name} />
            )
        })
        return inputRow;
    }


    private formatDate(input: RawProfileInput): string | null {
        if (!input?.inputResultValue?.value) {
            return null
        }

        if (isNaN(+input?.inputResultValue?.value)) {
            return `${input?.inputResultValue?.value}`
        }

        if (input.inputParameters.type === DatepickerType.DATE_TIME) {
            return moment(+input?.inputResultValue?.value).format('YYYY-MM-DD hh:mm')
        }

        if (input.inputParameters.type === DatepickerType.DATE) {
            return moment(+input?.inputResultValue?.value).format('YYYY-MM-DD')
        }

        return moment(+input?.inputResultValue?.value).format('hh:mm')
    }

    private getProfileFromDay(dayDate: Date): any {
        let profileFromDay = null;

        if (this.props.profilesFromMonth.length) {
            const profile = this.props.profilesFromMonth.find((profile: any) => new Date(profile.validFrom).toDateString() === dayDate.toDateString());
            if (profile) {
                profileFromDay = profile;
            }
        }
        return profileFromDay;
    }
}

export default TrainingPlanGroup;
