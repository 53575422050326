import React from 'react';
import {Translation} from "judo-app-common-web";
import {CalendarGroup} from "../../../../../../model/models";
import TooltipWrapper from "../../../../../Shared/TooltipWrapper";
import {transformObjectToCSSProperties} from "../../../../../../utils/fatigueTableUtils";

interface ICalendarGroupListItemState {
    selectedVariableGroup: CalendarGroup | null
}

interface CalendarGroupListItemProps {
    listItem: CalendarGroup;
    key: string;
    isDraggable: boolean;
    onDeleteItem: (id: string | null) => void;
    onEditedItemSet: (item: any) => void;
}

class CalendarGroupListItem extends React.Component<CalendarGroupListItemProps, ICalendarGroupListItemState> {
    constructor(props: CalendarGroupListItemProps) {
        super(props);
        this.state = {
            selectedVariableGroup: null
        }
    }

    render() {
        return <div className="ellipsis-host">
            <div className="d-flex profile-wrapper ellipsis-item">
                {this.renderItemColor()} {this.props.listItem.name}
            </div>
            {this.renderActionButtons()}
        </div>

    }

    private renderItemColor() {
        if (!this.props.listItem.visualOptions) {
            return null;
        }
        const visualOptions = transformObjectToCSSProperties(this.props.listItem.visualOptions);
        return <div aria-hidden={true} className="list-item-color ml-2" style={visualOptions}></div>;
    }

    private renderActionButtons() {
        return <div className="d-flex ellipsis-item justify-content-end">
            <TooltipWrapper tooltip={{text: 'userList.table.button.show.variableGroup'}} key={'profile-button'}>
                <button className="btn btn-list fatigue-profile"
                        onClick={() => this.openVariableGroupModal(this.props.listItem)}>
                    <span className="sr-only"><Translation text={'userList.table.button.show.variableGroup'}/></span>
                    <span className="feather icon-edit" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            <TooltipWrapper tooltip={{text: 'userList.table.button.remove.variableGroup'}} key={'fatigue-button'}>
                <button className="btn btn-list fatigue-profile delete ml-3"
                        onClick={() => this.props.onDeleteItem(this.props.listItem.id)}>
                    <span className="sr-only"><Translation text={'userList.table.button.remove.variableGroup'}/></span>
                    <span className="feather icon-trash" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            {this.renderDragHandle()}
        </div>
    }

    private renderDragHandle() {
        if (this.props.isDraggable) {
            return <TooltipWrapper tooltip={{text: 'userList.table.button.clickToDrag'}} key={'drag-handle'}>
                <div data-movable-handle className="drag-handle ml-3">
                    <span className="sr-only"><Translation text={'userList.table.button.clickToDrag'}/></span>
                    <span className="feather icon-menu" aria-hidden="true"/>
                </div>
            </TooltipWrapper>
        }
        return null;
    }

    private openVariableGroupModal(variableGroup: CalendarGroup) {
        this.props.onEditedItemSet(variableGroup);
    }
}

export default CalendarGroupListItem;
