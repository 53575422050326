import React, {CSSProperties} from 'react';
import {fixInjectedProperties} from '../../../../ioc';
import {IDayItem} from "../index";
import {CalendarEvent} from "../../../../model/models";
import moment, {Moment} from "moment";
import TooltipWrapper, {ITooltip, TooltipPosition} from "../../../Shared/TooltipWrapper";
import {Translation} from 'judo-app-common-web';
import i18next from "i18next";

interface ICalendarEventGroupProps {
    month: IDayItem[];
    calendarEvents: CalendarEvent[];
}

class CalendarEventGroup extends React.Component<ICalendarEventGroupProps> {

    constructor(props: ICalendarEventGroupProps) {
        super(props);
       fixInjectedProperties(this);
    }

    render() {
        if (!this.props.month || !this.props.calendarEvents) {
            return null;
        }

        return <React.Fragment>
            {this.renderCalendarEvents()}
        </React.Fragment>
    }

    private renderCalendarEvents(): any[] {
        let calendarEventsTable: any = [];

        const rowVisualOptions: CSSProperties = {backgroundColor: '#e4e8ec'},
            calendarEvents = this.props.calendarEvents;
        calendarEvents.forEach((calendarEvent: CalendarEvent, i: number) => calendarEventsTable.push(<tr
            style={rowVisualOptions}
            key={`${calendarEvent.name} - ${i}`}>
            {i === 0 &&
            <th className="fatigue-profile-group-name" scope="rowGroup" rowSpan={calendarEvents.length}>
                <div className="group-name-wrapper icon">
                    <span className="sr-only">
                        <Translation text={'profiles.modal.table.calendarEvent.header'}/>
                    </span>
                    <span aria-hidden="true"><i className="feather icon-calendar"/></span>
                </div>
            </th>}
            {this.renderCalendarEventRow(calendarEvent, this.props.month, i)}
            {i + 1 === calendarEvents.length && this.renderSummaryLabelsRow()}
        </tr>));
        return calendarEventsTable;
    }

    private renderSummaryLabelsRow() {
        return (
            <>
                <TooltipWrapper
                    tooltip={CalendarEventGroup.createTooltipConfig(`${i18next.t('profiles.modal.table.summary.sum')}`)}>
                    <td>Suma</td>
                </TooltipWrapper>
                <TooltipWrapper
                    tooltip={CalendarEventGroup.createTooltipConfig(`${i18next.t('profiles.modal.table.summary.avg')}`)}>
                    <td>Śr.</td>
                </TooltipWrapper>
                <TooltipWrapper
                    tooltip={CalendarEventGroup.createTooltipConfig(`${i18next.t('profiles.modal.table.summary.max')}`)}>
                    <td>Max</td>
                </TooltipWrapper>
                <TooltipWrapper
                    tooltip={CalendarEventGroup.createTooltipConfig(`${i18next.t('profiles.modal.table.summary.min')}`)}
                >
                    <td>Min</td>
                </TooltipWrapper>
            </>
        )
    }

    private static createTooltipConfig(cellInfo: string, inputValue?: number) {
        return ({text: cellInfo, value: inputValue, position: TooltipPosition.Top})
    }

    private renderCalendarEventRow = (calendarEvent: CalendarEvent, month: IDayItem[], inputIndex: number) => {
        let inputRow: any[] = [<th scope="row" key={`${calendarEvent.name} - ${inputIndex}`}
                                   className="input-name">{calendarEvent.name}</th>];

        const startsAtDate = new Date(calendarEvent.startsAt);
        const timeToEvent = moment(startsAtDate, "DD.MM.YYYY");
        month.forEach((day: IDayItem) => {
            const timeAtDate = moment(day.dateObject, "DD.MM.YYYY");
            return inputRow.push(
                this.renderRemainingDaysCell(timeToEvent, timeAtDate, calendarEvent.name)
            )
        })
        return inputRow;
    }

    private renderRemainingDaysCell(timeToEvent: Moment, timeAtDate: Moment, calendarEventName: string): any {
        const remainingDays = this.getDaysToEvent(timeToEvent, timeAtDate);
        let cellInfo = `${calendarEventName} - ${remainingDays.value}`;
        if (remainingDays.errorMessage) {
            cellInfo = remainingDays.errorMessage;
        }
        const tooltip: ITooltip = {text: cellInfo, position: TooltipPosition.Top};
        return <TooltipWrapper tooltip={tooltip} key={`${calendarEventName + timeAtDate}`}>
            <td>
                {remainingDays.value}
            </td>
        </TooltipWrapper>
    }

    private getDaysToEvent(timeToEvent: Moment, timeAtDate: Moment): any {
        if (new Date(moment(timeToEvent).format('YYYY-MM-DD')).getTime() === new Date(moment(timeAtDate).format('YYYY-MM-DD')).getTime()) {
            return {value: 'XX', errorMessage: 'profiles.modal.table.calendarEvent.startsToday'}
        }

        if (moment(timeToEvent, 'DD-MM-YYYY').isBefore(timeAtDate)) {
            return {value: '--', errorMessage: 'profiles.modal.table.calendarEvent.isDue'}
        }
        const daysTillEvent = timeToEvent.diff(timeAtDate, 'days');
        return {value: daysTillEvent, errorMessage: null};
    }
}


export default CalendarEventGroup;

