import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function updateCalendarGroupAPI(
    authToken: string,
    groupId: string,
    payload: any,
): Observable<any> {
    return baseDataProviderAPI.put(
        `calendar_view_groups/${groupId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
