import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {InputDefinition} from "../model/models";

export function updateInputDefinitionAPI(
    authToken: string,
    inputDefinitionId: string,
    payload: InputDefinition
): Observable<any> {
    return baseDataProviderAPI.put(
        `input_definitions/${inputDefinitionId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
