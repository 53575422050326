import {createAPIHandler} from "judo-app-common-web";
import {deepCloneObject} from "../../utils/runtimeUtils";

export const userAccountProviderAPI = createAPIHandler({
    url: process.env.REACT_APP_API_URL as string,
    defaultHeaders: {
        "Accept": "application/ld+json",
        "Content-type": "application/json"
    },
    mapper: (data: any): any => {
        let updatedData = deepCloneObject(data);
        if (data.coaches) {
            let coachesAccounts: any = [];
            data.coaches.forEach((coach: any) => {
                let coachAccount = deepCloneObject(coach);
                coachAccount.account['clubs'] = coach['clubs'];
                coachAccount.account['coachType'] = coach['type'];
                coachAccount = coachAccount.account
                coachesAccounts.push(coachAccount);
            })
            updatedData.coaches = coachesAccounts;
        }
        if (data.specialists) {
            let specialistAccounts: any = [];
            data.specialists.forEach((specialist: any) => {
                let specialistAccount = deepCloneObject(specialist);
                specialistAccount.account['specialistType'] = specialist['type'];
                specialistAccount = specialistAccount.account;
                specialistAccounts.push(specialistAccount);
            });
            updatedData.specialists = specialistAccounts;
        }
        return updatedData;
    },
    isList: false
});
