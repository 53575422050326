import React from 'react';
import styles from './styles.module.scss'
import {Translation} from "judo-app-common-web";
import {Link} from "react-router-dom";
import {UserType} from "../../../model/models";
import {isNullOrUndefined} from "../../../utils/runtimeUtils";

export enum UserInfoType {
    Table = 'table',
    Extended = 'extended',
    Club = 'club',
    Edit = 'edit-mode'
}

interface IUserInfoBoxProps {
    user: any;
    infoCellType: UserInfoType;
}

class UserInfoBox extends React.Component<IUserInfoBoxProps> {
    render() {
        return (
            <div className={`${styles.userInfoBox} ${styles[this.props.infoCellType]} ${styles[this.props.user.role]}`}>
                <div className={`${styles.userInfoImageWrapper} ${styles[this.props.infoCellType]}`}>
                    {this.renderUserImage()}
                </div>
                <div className={`${styles.userPersonalInfoWrapper} ellipsis-host`}>
                    {this.renderUserInfo()}
                    {this.renderShowFatigueProfileButton()}
                </div>
            </div>
        )
    }

    private renderUserImage() {
        if (this.props.user && (this.props.user.user?.photo || this.props.user.logo)) {
            const imageUrl = this.props.user.user?.photo.filteredMediaObjectUrls ? this.props.user.user.photo.filteredMediaObjectUrls.small_thumb : this.props.user.logo.filteredMediaObjectUrls.small_thumb;
            return <div className={styles.userInfoImage} style={{backgroundImage: "url('" + imageUrl +" ')"}}>
            </div>
        }
        return (
            <div className={styles.userPhotoPlaceholder}>
                <span>{this.renderUserInitials()}</span>
            </div>
        )
    }

    private renderUserInfo() {
        let clubName = this.props.user.name ? this.props.user.name : null,
            club = this.props.user.club ? this.props.user.club.name :
                (this.props.user.clubs && this.props.user.clubs.length ? this.props.user.clubs[0].name : null),
            firstName = this.props.user.firstName,
            lastName = this.props.user.lastName,
            specialistType = this.props.user.specialistType ? this.props.user.specialistType.name : this.props.user.specialist?.type.name,
            phone = this.props.user.phone,
            email = this.props.user?.user?.login;

        return (
            <React.Fragment>
                <p className={`${styles.userName}  ellipsis-item`}>
                    {clubName ? clubName :
                        (`${firstName} ${lastName}`)
                    }
                </p>
                <span className={`${styles.userClubName} ellipsis-item`}>
                    {club}
                </span>
                <span className="ellipsis-item">
                    {specialistType}
                </span>
                {this.props.infoCellType === UserInfoType.Extended &&
                <div className={`${styles.contactDetails} ellipsis-item`}>
                    <span className="ellipsis-item">{phone}</span>
                    <span className="ellipsis-item">{email}</span>
                </div>
                }
            </React.Fragment>
        )
    }

    private renderShowFatigueProfileButton() {
        if (this.props.user.role !== UserType.CONTESTANT) return null;
        if (this.props.user.fatigueProfiles?.length && this.props.infoCellType === UserInfoType.Extended ) {
            return <Link className="btn btn-list mt-4"
                         to={`/profile/${this.props.user.fatigueProfiles[0].id}`}>
                <Translation text={'userView.button.fatigueProfile'}/>
            </Link>
        }
        return null;
    }

    private renderUserInitials() {
        const clubName = this.props.user.name;
        let firstName: string,
            lastName: string | null = null;

        if (!isNullOrUndefined(clubName)) {
            firstName = clubName;
        } else {
            firstName = this.props.user.firstName;
            lastName = this.props.user.lastName;
        }

        let initials = firstName ? `${firstName.charAt(0)}`.toUpperCase() : 'XX';
        if (lastName) {
            initials += `${lastName.charAt(0)}`.toUpperCase();
        }
        return initials

    }
}

export default UserInfoBox;
