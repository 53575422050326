import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function updateUserImageAPI(
    authToken: string,
    imageId: any,
    profileId: string
): Observable<any> {
    return baseDataProviderAPI.put(
        `users/${profileId}`,
        {mediaObjectId: imageId},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
