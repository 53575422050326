import {
    BasicFormControlType,
    FormButtonType,
    IFormConfig,
    IFormControlConfig,
    InputType,
    MultiSelectType,
    ValidationRules,
    IFormGroupConfig
} from "judo-app-common-web";
import store from "../../../../store";
import { changeModal } from "../../../../store/reducers/sagaSlice";


export const editPersonalProfileFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'create-group-form mb-3',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name_group',
            controlType: 'group',
            class: 'd-flex flex-row mt-3',
            controls: {
                firstName: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                    ],
                    placeholder: 'modal.editPersonalProfile.form.firstName.placeholder',
                    isLabelHidden: false,
                    label: 'modal.editPersonalProfile.form.firstName.label',
                    type: InputType.TEXT,
                    hostClass: "ml-3"
                },
                lastName: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'modal.editPersonalProfile.form.lastName.placeholder',
                    isLabelHidden: false,
                    label: 'modal.editPersonalProfile.form.lastName.label',
                    type: InputType.TEXT,
                    hostClass: "ml-3"
                }
            }
        }, {
            key: 'details_group',
            controlType: 'group',
            class: 'd-flex flex-row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'modal.editPersonalProfile.form.phone.placeholder',
                    isLabelHidden: false,
                    label: 'modal.editPersonalProfile.form.phone.label',
                    type: InputType.INPUT_TEXT,
                    hostClass: 'ml-3'
                }
            }
        },
    ]
};

export const addSpecialistTypeGroup: typeof IFormGroupConfig = {
    key: 'specialist_group',
    controlType: 'group',
    class: 'd-flex flex-row',
    controls: {
        specialistType: {
            hostClass: 'form-select-input ml-3',
            controlType: 'control',
            defaultValue: null,
            placeholder: 'modal.addUser.form.specialistType.placeholder',
            formControlType: BasicFormControlType.AUTOCOMPLETE,
            multiselectType: MultiSelectType.SINGLE,
            label: 'modal.addUser.form.specialistType.label',
            validationRules: [],
            multiselectOptions: [],
            inputDataMapper: (data: any) => {
                if (!data) {
                    return [];
                }
                return {
                    value: data.id,
                    label: data.name,
                };
            },
            outputDataMapper: (data: any) => {
                if (Array.isArray(data)) {
                    return (data.length > 0) ? data[0].value : null;
                }
                if (data && typeof data === 'object') {
                    return data.value;
                }

                return data;
            },
        }
    }
};

export const buttonGroup: typeof IFormGroupConfig = {

    key: 'buttons',
    controlType: 'group',
    class: 'button-group-wrapper mt-5',
    controls: {
        cancelButton: {
            controlType: 'control',
            formControlType: BasicFormControlType.BUTTON,
            buttonType: FormButtonType.BUTTON,
            hostClass: 'button-container',
            defaultContainerStyles: 'd-flex justify-content-end mx-3',
            defaultInputStyles: 'btn btn-theme-outline',
            defaultValue: null,
            buttonDisabled: false,
            btnText: `button.cancel`,
            isLabelHidden: true,
            customClickHandler: () => store.dispatch(changeModal(null))
        },
        submitButton: {
            controlType: 'control',
            formControlType: BasicFormControlType.BUTTON,
            buttonType: FormButtonType.SUBMIT,
            hostClass: 'button-container',
            defaultContainerStyles: 'd-flex justify-content-end',
            defaultInputStyles: 'btn btn-theme',
            defaultValue: null,
            btnText: `button.change`,
            isLabelHidden: true,
        }
    }
};


export const addClubInput: typeof IFormControlConfig = {
    club: {
        hostClass: 'form-select-input ml-3',
        controlType: 'control',
        defaultValue: null,
        placeholder: 'modal.addUser.form.club.placeholder',
        formControlType: BasicFormControlType.AUTOCOMPLETE,
        multiselectType: MultiSelectType.SINGLE,
        label: 'modal.editPersonalProfile.form.club.label',
        validationRules: [],
        multiselectOptions: [],
        inputDataMapper: (data: any) => {
            if (!data) {
                return [];
            }
            return {
                value: data.id,
                label: data.name,
            };
        },
        outputDataMapper: (data: any) => {
            if (Array.isArray(data)) {
                return (data.length > 0) ? data[0].value : null;
            }
            if (data && typeof data === 'object') {
                return data.value;
            }

            return data;
        },
    }
};
