import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {InputDefinition} from "../model/models";

export function addMultipleInputDefinitionsAPI(
    authToken: string,
    payload: InputDefinition[],
): Observable<any> {

    return baseDataProviderAPI.post(
        "many_input_definitions",
        {inputDefinitions: payload},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
