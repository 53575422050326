import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function assignProfileDefinitionToAccountAPI(
    authToken: string,
    accountId: string,
    profileDefinitionId: string,
): Observable<any> {
    return baseDataProviderAPI.put(
        `accounts/${accountId}/assign_profile_definition`,
        {profileDefinitionId},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
