import {createAPIHandler} from "judo-app-common-web";

export const developmentProfileProvider = createAPIHandler({
    url: process.env.REACT_APP_API_URL as string,
    defaultHeaders: {
        "Accept": "application/ld+json",
        "Content-type": "application/json"
    },
    mapper: (data: any): any => {
        if(data && data['hydra:member']) {
            const arr = data['hydra:member']
            .filter((developmentProfile: any) => developmentProfile !== null)
            .map((developmentProfile: any) => {
                let developmentProfileItem = developmentProfile.calendars.find((calendarItem: any) => calendarItem.name !== 'Trening');
                developmentProfileItem.trainingProfile = developmentProfile.calendars.find((calendarItem: any) => calendarItem.name === 'Trening');
                return developmentProfileItem;
            });
            data['hydra:member'] = arr;
            return data;
        }
       return data;
    },
    isList: false
});
