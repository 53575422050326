import {Observable} from 'rxjs';
import {RestQueryParams} from 'judo-app-common-web';
import {baseDataProviderAPI} from './provider/baseDataProviderAPI';
import moment from 'moment';
import {map} from 'rxjs/operators';

export function getUserFatigueProfilesAPI(
    authToken: string,
    fromDate?: string,
    toDate?: string,
    userId?: string,
): Observable<any> {
    const queryDateStart = fromDate ? fromDate : moment().utc().subtract(3, 'days').startOf('day').toISOString();
    const queryDateEnd = toDate ? toDate : moment().utc().endOf('day').toISOString();
    const queryUserId = userId ? `&account.id=${userId}` : ''
    const endpointString = `profiles/raw_inputs?validFrom[after]=${queryDateStart}&validFrom[before]=${queryDateEnd}&itemsPerPage=50` + queryUserId;

    return baseDataProviderAPI.get(
        endpointString,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    ).pipe(
        map((response: { profiles: any[], parameters: any[] }) => {
            response.profiles.forEach(profile => profile.inputGroups.forEach(
                (inputGroup: any) => inputGroup.profileInputs.forEach(
                    (profileInput: any) => {
                        const inputId = profileInput.inputId;

                        const found = response.parameters.find(candidate => -1 !== candidate.i.indexOf(inputId));
                        profileInput.inputParameters = (undefined === found) ? null : JSON.parse(JSON.stringify(found.p));

                        return profileInput;
                    })));

            // simulate json-ld response
            return {
                'hydra:member': response.profiles,
            };
        })
    );
}
