import {BasicFormControlType, IFormConfig, InputType, ValidationRules} from 'judo-app-common-web';
import 'moment/locale/pl'


export const monthSelectFormConfig: typeof IFormConfig = (value?: any) => ({
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'selectedMonth',
            class: 'form-row',
            controls: {
                monthSelected: {
                    hostClass: 'month-select-datepicker',
                    controlType: 'control',
                    defaultValue: new Date(),
                    additionalStyles: 'horizontal',
                    formControlType: BasicFormControlType.DATE,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: '',
                    label: 'Miesiąc profili zmęczeniowych',
                    isLabelHidden: true,
                    showMonthYearPicker: true,
                    showFullMonthYearPicker: true,
                    showPopperArrow: false,
                    dateFormat: "MMMM yyyy",
                    type: InputType.DATE,
                },
            }
        }
    ]
});
