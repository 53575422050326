import React from 'react';
import AnimatedLayoutHost, {EndpointNames} from '../Shared/AnimatedLayoutHost';
import CoachList from './CoachList';
import CoachView from './CoachView';

interface ICoachesProps {
    isArchiveMode: boolean;
}

class Coaches extends React.Component<ICoachesProps> {

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <AnimatedLayoutHost host={"coaches"}
                                            listComponent={CoachList}
                                            viewComponent={CoachView}
                                            endpointName={EndpointNames.COACHES}
                                            isArchiveMode={this.props.isArchiveMode}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Coaches;
