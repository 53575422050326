import React from "react";
import {
    Hint,
    HorizontalGridLines,
    LineMarkSeries,
    LineMarkSeriesPoint,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis
} from "react-vis";
import {GraphParameter} from "../index";
import {CalendarInputOption, InputType, RawProfile} from "../../../../model/models";
import {Translation} from "judo-app-common-web";

interface LineGraphDataBase{
    x: number;
    y: number;
}

interface Props {
    selectedParameters: GraphParameter[];
    profiles: RawProfile[];
    numberOfDaysInSelectedMonth: number | null;
}

interface State {
    value: LineMarkSeriesPoint | null
}

const tipStyle = {
    display: 'flex',
    color: '#fff',
    background: '#000',
    alignItems: 'center',
    padding: '5px'
};

class LineChart extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            value: null
        }
    }


    render() {
        const xMax = this.props.numberOfDaysInSelectedMonth ? this.props.numberOfDaysInSelectedMonth : 'auto';
        const ticks = this.props.numberOfDaysInSelectedMonth ? this.props.numberOfDaysInSelectedMonth : undefined;

        if(!this.props.profiles || this.props.profiles?.length === 0) {
            return (
            <div style={{width: '73rem', height: '45rem'}}
                 className="d-flex justify-content-center align-items-center">
                <Translation text={'profiles.modal.chart.noMonthData'}/>
            </div>
            )
        }

        return (
            <div className="d-flex justify-content-center align-items-center flex-grow-1">
                <XYPlot yDomain={[0, 100]} xDomain={[1, xMax]} width={730} height={450}>
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis title={'Data'} tickTotal={ticks} />
                    <YAxis title={'%'} tickTotal={11} tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
                    {
                        Object.keys(this.dataToGraph).map(id =>
                            <LineMarkSeries key={id}
                                            onValueMouseOver={(e) => this.setState({value: e})}
                                            onValueMouseOut={(e) => this.setState({value: null})}
                                            data={this.sortedGraphData(this.dataToGraph[id])}/>)
                    }
                    {this.state.value &&
                    <Hint value={this.state.value} style={tipStyle}>
                        <div className="d-flex flex-column">
                            <p>Dzień: {this.state.value.x}</p>
                            <p>Wartość {(+this.state.value.y).toFixed(2)}%</p>
                        </div>
                    </Hint>
                    }
                </XYPlot>
            </div>
        );
    }



    private get dataToGraph() {
        let dataToGenerateGraph: {[parameterId: string]: LineGraphDataBase[] | []}= {};
        let scales: {[parameterId: string]: [number, number]} = {}

        if(this.props.profiles && this.props.selectedParameters) {

            this.props.selectedParameters.forEach((parameter: GraphParameter) => {
                if(parameter && parameter.id){
                    dataToGenerateGraph[parameter.id] = []
                    if(parameter.parameter.inputDefinition?.type === InputType.SELECT) {
                        scales[parameter.id] = this.parameterScale(parameter.parameter.valuesVisualOptions)
                    }
                }
            })

            this.props.profiles.forEach( (profile: RawProfile) => {
                profile.inputGroups.forEach( group => {
                    group.profileInputs.forEach(
                        input => {
                            if(dataToGenerateGraph[input.inputDefinitionId] && input.inputResultValue.value) {
                               (dataToGenerateGraph[input.inputDefinitionId] as LineGraphDataBase[]).push({
                                    y: (+input.inputResultValue.value  / scales[input.inputDefinitionId][1]) * 100,
                                    x: +(profile?.validFrom?.split('T')?.[0]?.split('-')?.pop() || 0)
                                })
                            }
                        }
                    )
                })
            })
        }

        return dataToGenerateGraph
    }

    private parameterScale(data: CalendarInputOption[]): [number, number] {
        const valuesArray: number[] = data.map(el => (el.value && !isNaN(+el.value)) ? +el.value : 0)
        return [Math.min(...valuesArray), Math.max(...valuesArray)]
    }

    private sortedGraphData(lineData: any) {
        return (lineData as LineGraphDataBase[]).sort( (a, b) =>
            a.x < b.x ? -1 : 1)
    }
}

export default LineChart;
