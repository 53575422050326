import {BasicFormControlType, FormButtonType, IFormConfig, InputType} from "judo-app-common-web";
import {CalendarEvent} from "../../../../../model/models";
import moment from "moment";

export const editCalendarEventFormConfig: typeof IFormConfig =  (data: CalendarEvent = {name: '', startsAt: ''}) => ( {
    controlType: 'form',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'edit_option',
            controlType: 'group',
            class: 'd-flex flex-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: data['name'] || '',
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                    ],
                    placeholder: 'profileSettingsModal.form.addCalendarEventForm.name.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addCalendarEventForm.name.label',
                    type: InputType.INPUT_TEXT,
                    hostClass: ''
                },
                startsAt: {
                    controlType: 'control',
                    defaultValue: data['startsAt'] ? moment(data['startsAt'], 'DD-MM-yyyy').toDate() : '',
                    formControlType: BasicFormControlType.DATE,
                    validationRules: [
                    ],
                    placeholder: 'profileSettingsModal.form.addCalendarEventForm.startsAt.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addCalendarEventForm.startsAt.label',
                    hostClass: 'ml-4',
                    maxDate: data['endsAt'],
                    dateFormat: "dd-MM-yyyy"
                }
            }
        },
        {
            key: 'buttons',
            controlType: 'group',
            class: 'button-group-wrapper mt-5',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'button-container',
                    defaultContainerStyles: 'd-flex justify-content-end',
                    defaultInputStyles: 'btn btn-theme btn-small',
                    defaultValue: null,
                    btnText: `button.change`,
                    isLabelHidden: true,
                }
            }
        }
    ]
});

