import React from 'react';
import {authTokenSelector, CustomModal, Loader, LoaderType} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import CoachView from "../../../Coaches/CoachView";
import ContestantView from "../../../Contestants/ContestantView";
import SpecialistView from "../../../Specialists/SpecialistView";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import ClubView from "../../../Clubs/ClubView";
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {modalSelector} from '../../../../store/selectors/sagaSelectors';
import {changeModal} from '../../../../store/reducers/sagaSlice';
import {ModalConfig, UserType} from '../../../../model/models';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {getAccountAPI} from '../../../../api/getAccount';
import {Observable, of, Subscription} from 'rxjs';
import {getBaseViewAPI} from '../../../../api/getBaseView';
import {EndpointNames} from '../../../Shared/AnimatedLayoutHost';
import {getConnectedAccountsAPI} from "../../../../api/getConnectedAccounts";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {passwordLoadingSelector} from "../../../../store/selectors/changePasswordSelectors";

interface IModalUserProfileProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
}

interface IModalUserProfileState {
    account: any;
    isProcessing: boolean;
}

class ModalUserProfile extends React.Component<IModalUserProfileProps, IModalUserProfileState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalUserProfileProps) {
        super(props);

        this.state = {
            account: null,
            isProcessing: true
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal) this.retrieveUser();
    }

    componentDidUpdate(
        prevProps: Readonly<IModalUserProfileProps>,
        prevState: Readonly<IModalUserProfileState>,
        snapshot?: any
    ): void {
        if (this.props.modal && this.props.modal.id !== prevProps.modal?.id) {
            this.retrieveUser();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.props.modal) {
            return null;
        }

        return (
            <CustomModal class="profile-modal"
                         isOpen={this.props.modal}
                         close={this.props.onClose}>
                {this.renderModalBody()}

                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>

            </CustomModal>
        );
    }

    private renderModalBody() {
        if (!this.props.modal || !this.state.account) return;

        switch (this.props.modal.role) {
            case UserType.CONTESTANT:
                return <ContestantView itemView={this.state.account}
                                       onViewChange={this.props.onClose}
                                       isModalView={true}/>;
            case UserType.SPECIALIST:
                return <SpecialistView itemView={this.state.account}
                                       onViewChange={this.props.onClose}
                                       isModalView={true}/>;

            case UserType.COACH:
                return <CoachView itemView={this.state.account}
                                  onViewChange={this.props.onClose}
                                  isModalView={true}/>;

            case UserType.CLUB:
                return <ClubView itemView={this.state.account}
                                 onViewChange={this.props.onClose}
                                 isModalView={true}/>;

            default:
                throw new Error(`Invalid role: ${this.props.modal.role}.`);
        }
    }

    private retrieveUser(): void {
        if (!this.props.modal || !this.props.modal.id) return;

        if (this.props.modal.role === UserType.CLUB) {
            this.retrieveClubData(getBaseViewAPI(
                this.props.authToken,
                EndpointNames.CLUBS,
                this.props.modal.id,
            ));
            return;
        }

        this.retrieveUserData();
    }

    private retrieveUserData() {
        if (!this.props.modal || !this.props.modal.id) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(
            getAccountAPI(this.props.authToken, this.props.modal.id).pipe(
                map((response: any) => {
                    if (response.type !== UserType.COACH || response.type !== UserType.SPECIALIST) {
                        this.setState({isProcessing: false, account: response})
                    }
                    return response;
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                }),
                filter((response: any) => {
                    return response.type === UserType.SPECIALIST || response.type === UserType.COACH
                }),
                switchMap((coachOrSpecialistAccount: any) => {
                    if (!coachOrSpecialistAccount) return of();
                    this.setState({isProcessing: true});
                    const coachOrSpecialistAccountId = coachOrSpecialistAccount.type === UserType.COACH ? coachOrSpecialistAccount.coach.id : coachOrSpecialistAccount.specialist.id,
                        responseEndpoint = coachOrSpecialistAccount.type === UserType.COACH ? EndpointNames.COACHES : EndpointNames.SPECIALISTS;
                    return getConnectedAccountsAPI(this.props.authToken, responseEndpoint, coachOrSpecialistAccountId, undefined, 1000).pipe(
                        tap((response) => {
                            if (response && response['hydra:member']) {
                                const coachAssignedAccounts = response['hydra:member'];
                                let updatedAccount = deepCloneObject(coachOrSpecialistAccount);
                                updatedAccount.contestants = coachAssignedAccounts;
                                return this.setState({account: updatedAccount, isProcessing: false})
                            }
                        }),
                        catchError((error: any) => {
                            this.setState({isProcessing: false});
                            this.alertManagerService.handleApiError(error);
                            return of();
                        }),
                    )
                })
            ).subscribe())
    }

    private retrieveClubData(api: Observable<any>): void {
        this.setState({isProcessing: true});
        this.subscriptions.push(
            api.pipe(
                tap((resp: any) => this.setState({
                    account: resp,
                    isProcessing: false
                })),
                catchError((err: any) => {
                    this.setState({isProcessing: false});
                    this.alertManagerService.handleApiError(err);
                    return of()
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: passwordLoadingSelector(state),
        authToken: authTokenSelector(state),
        modal: modalSelector(state)
    }),
    {
        changeModal
    }
)(ModalUserProfile);
