import { RootState } from "../reducers";
import { createSelector } from "reselect";
import { ISagaState } from "../reducers/sagaSlice";

export const selectLink = (state: RootState): ISagaState => {
  return state.saga;
};

export const languageSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.language
);

export const fontSizeSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.fontSize
);

export const isProcessingSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.isProcessing
);

export const isProfileValidSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.fatigueProfileValid
);

export const modalSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.modal
);

export const shouldListUpdateSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.shouldListUpdate
);

export const shouldViewUpdateSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.shouldViewUpdate
);

export const isUserProfileLoadingSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.isUserProfileLoading
);
