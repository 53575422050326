import React from 'react';
import {
    authTokenSelector,
    CustomModal,
    Form,
    IFormConfig,
    Loader,
    LoaderType,
    setAuthState,
    Translation
} from 'judo-app-common-web';
import "react-datepicker/dist/react-datepicker.css";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {CoachView, ModalConfig} from "../../../../model/models";
import {changeModal, changeShouldListUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {assignCoachFormConfig, buttonGroup} from "./assignCoachFormConfig";
import {getBaseListAPI} from "../../../../api/getBaseList";
import {assignAccountToAccountAPI} from "../../../../api/assignAccountToAccount";
import {EndpointNames} from "../../../Shared/AnimatedLayoutHost";

interface IModalAssignCoachProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalAssignCoachState {
    accountId: string;
    isProcessing: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    coaches: CoachView[];
}

class ModalAssignCoach extends React.Component<IModalAssignCoachProps, IModalAssignCoachState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalAssignCoachProps) {
        super(props);
        this.state = {
            accountId: '',
            isProcessing: true,
            formConfig: assignCoachFormConfig,
            value: {},
            coaches: [],
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.id) {
            this.setState({accountId: this.props.modal.id})
            this.retrieveCoaches()
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IModalAssignCoachProps>,
        prevState: Readonly<IModalAssignCoachState>,
        snapshot?: any
    ): void {
        if (this.state.coaches !== prevState.coaches) {
            let updatedFormConfig = deepCloneObject(assignCoachFormConfig);
            let multiselectProfiles: any[] = [];
            this.state.coaches.forEach((coach: any) => multiselectProfiles.push({
                value: coach.account.id,
                label: `${coach.account.firstName} ${coach.account.lastName}`
            }));
            updatedFormConfig.controls[0].controls.coaches.multiselectOptions = multiselectProfiles;
            updatedFormConfig.controls.push(buttonGroup);
            this.setState({formConfig: updatedFormConfig});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.accountId) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class='assign-profile-modal'>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={'modal.assignCoach.title'}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <Form config={this.state.formConfig}
                          submitForm={this.assignCoach}
                          controlName={'assignCoach'}
                          value={{}}/>
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private retrieveCoaches = () => {
        if (!this.props.authToken) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(getBaseListAPI(this.props.authToken, EndpointNames.COACHES, undefined, 1000).pipe(
            tap((response: any) => {
                if (response && response['hydra:member']) {
                    let coachesList = response['hydra:member'];
                    if (this.props.modal?.data) {
                        const assignedProfilesId = this.props.modal.data;
                        coachesList = coachesList.filter((listItem: any) => !assignedProfilesId.includes(listItem.account.id));
                    }
                    return this.setState({coaches: coachesList, isProcessing: false})
                }

            }),
            catchError((err: any) => {
                this.setState({isProcessing: false});
                this.alertManagerService.handleApiError(err);
                return of();
            })
        ).subscribe());
    };


    private assignCoach = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken) {
            return;
        }
        const chosenProfileId = value.coachGroup.coaches.value;
        const assignAccountPayload = {
            accountId: chosenProfileId,
        }

        this.setState({isProcessing: true});
        return this.subscriptions.push(assignAccountToAccountAPI(this.props.authToken, this.state.accountId, assignAccountPayload).pipe(
            tap((response: any) => {
                this.alertManagerService.addAlert('modal.assignCoach.assignSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldListUpdate(true);
                this.props.changeModal(null);
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldListUpdate,
        setAuthState,
    }
)(ModalAssignCoach);
