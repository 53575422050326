import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import styles from './styles.module.scss'
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";

interface IAuthProps {}

class Auth extends React.Component<IAuthProps> {

    render() {
        return (
            <div className={styles.viewContainer}>
                <div className={styles.infoContainer}/>
                <div className={styles.authContainer}>
                    <div className={styles.logoContainer} />
                    <Switch>
                        <Route exact path="/auth/login" component={Login} key="auth"/>
                        <Route exact path="/auth/register" component={Register} key="register"/>
                        <Route exact path="/auth/reset-password" component={ResetPassword} key="reset-password"/>
                        <Route exact path="/auth/new-password" component={ChangePassword} key="new-password"/>
                        <Route component={() => <Redirect to={"/auth/login"}/>}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default Auth;
