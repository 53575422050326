import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {InputGroupDefinition} from "../model/models";

export function updateInputGroupDefinitionAPI(
    authToken: string,
    payload: InputGroupDefinition,
    inputGroupId: string
): Observable<any> {
    return baseDataProviderAPI.put(
        `input_group_definitions/${inputGroupId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
