import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function updateAccountAPI(
    authToken: string,
    accountId: string,
    payload: any
): Observable<any> {
    return baseDataProviderAPI.put(
        `accounts/${accountId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
