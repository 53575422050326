import React from 'react';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import FatigueProfilesGroup from "./FatigueProfileGroup";
import {CustomModal, Loader, LoaderType, Translation} from "judo-app-common-web";
import {
    CalendarEvent,
    CalendarGroup, CalendarInput,
    CurrentFatigueCalendarView,
    RawInputGroup,
    RawProfile,
    RawProfileInput
} from "../../../model/models";
import CalendarEventGroup from "./CalendarEventGroup";
import {Subscription, Observable} from "rxjs";
import ModalEditFatigueProfileCell from "./ModalEditFatigueProfileCell";

//todo CLEAN CODE THIS

export interface IDayItem {
    dayNumber: number;
    daysName: string;
    dateObject: Date;
}

interface IFatigueProfilesTableProps {
    dateSelected: Date;
    authToken: string;
    calendar: CurrentFatigueCalendarView | null;
    calendarEvents: CalendarEvent[];
    profiles: RawProfile[];
    getUpdatedProfiles: () => Observable<void | string>;
    getUpdatedCalendar: () =>  Observable<any>
}

interface IFatigueProfilesTableState {
    month: IDayItem[];
    isLoading: boolean;
    editedInput: RawProfileInput | CalendarInput | undefined;
    editedGroup: RawInputGroup | CalendarGroup | undefined;
    editedDate: Date | undefined
}

class FatigueProfilesTable extends React.Component<IFatigueProfilesTableProps, IFatigueProfilesTableState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscription: Subscription | null;

    constructor(props: IFatigueProfilesTableProps) {
        super(props);
        this.state = {
            month: [],
            isLoading: false,
            editedGroup: undefined,
            editedInput: undefined,
            editedDate: undefined
        }

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getMonthData();
    }

    componentDidUpdate(
        prevProps: Readonly<IFatigueProfilesTableProps>,
        prevState: Readonly<IFatigueProfilesTableState>,
        snapshot?: any): void {
        if (this.props.dateSelected !== prevProps.dateSelected) {
            const chosenMonth = this.props.dateSelected.getMonth(),
                chosenYear = this.props.dateSelected.getFullYear(),
                month = this.getDaysArray(chosenYear, chosenMonth);
            this.setState({month: month});
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <div className='fatigue-profile-table-wrapper'>
                <Loader show={this.state.isLoading} type={LoaderType.Local}/>
                <table>
                    <colgroup>
                        <col/>
                        <col className="name-column"/>
                        {this.state.month.map(day => <col key={`${day.daysName}-${day.dayNumber}`}/>)}
                    </colgroup>
                    <caption className="sr-only">
                        <Translation text={'profiles.modal.header'}/>
                    </caption>
                    {this.renderTableHeader()}
                    <tbody>
                    {this.renderTableBody()}
                    </tbody>
                </table>
                <CustomModal isOpen={!!this.state.editedInput}
                             close={() => this.setEditedCellAndGroup()}
                             class="fatigue-calendar-edit-input-modal">
                    {this.state.editedInput &&
                        <ModalEditFatigueProfileCell
                            inputData={this.state.editedInput}
                            calculatedGroup={this.state.editedGroup}
                            closeModal={this.setEditedCellAndGroup}
                            getUpdatedProfilesData={() => this.props.getUpdatedProfiles()}
                            getUpdatedCalendarData={() => this.props.getUpdatedCalendar()}
                            date={this.state.editedDate}
                        />
                    }
                </CustomModal>
            </div>
        );
    }

    private setEditedCellAndGroup = (editedCell?: RawProfileInput | CalendarInput, editedGroup?: RawInputGroup | CalendarGroup, date?: Date) => {
        this.setState((state) => ({
            editedInput: editedCell,
            editedGroup: editedGroup,
            editedDate: date
        }))
    }

    private renderTableHeader() {
        let dayNumberArray = [
            <th key="day-number-dummy" aria-hidden={true} className='dummy-cell'></th>,
            <th key="day-input-name" scope="row" className='input-name'>
                <Translation text="profiles.modal.table.monthDay"/>
            </th>
        ];
        let weekDayNameArray = [
            <th key="week-number-dummy" aria-hidden={true} className='dummy-cell'></th>,
            <th key="week-input-name" scope="row" className='input-name'>
                <Translation text="profiles.modal.table.weekDay"/>
            </th>
        ];
        this.state.month?.forEach((monthDay: IDayItem, i: number) => {
            dayNumberArray.push(<th key={`${monthDay.dayNumber + i}`} scope="col">{monthDay.dayNumber}</th>);
            weekDayNameArray.push(<th key={`${monthDay.daysName + i}`} scope="col">{monthDay.daysName}</th>);
        });
        return <thead>
        <tr>
            {dayNumberArray}
        </tr>
        <tr>{weekDayNameArray}</tr>
        </thead>

    }

    private renderTableBody() {
        if (!this.props.calendar || !this.props.calendar.calendarViewGroups) {
            return null
        }

        const tableGroups = this.props.calendar.calendarViewGroups.sort(  (a, b) => a.itemOrder - b.itemOrder);
        const tableBody: any = [];



        if (this.props.calendarEvents.length && this.state.month.length) {
            tableBody.push(<CalendarEventGroup key={`calendarEvent-00`} month={this.state.month}
                                               calendarEvents={this.props.calendarEvents}/>)
        }

        if (tableGroups.length && this.state.month.length) {

            tableGroups.forEach((group: CalendarGroup) => {

                if (group.enabled) {
                    tableBody.push(<FatigueProfilesGroup
                        setEditedCellAndGroup={this.setEditedCellAndGroup}
                        profilesFromMonth={this.props.profiles}
                        key={group.id}
                        authToken={this.props.authToken}
                        calendarGroup={group}
                        month={this.state.month}
                    />)
                }
            })
            return tableBody;
        }
        return null;
    }

    private getMonthData() {
        const chosenMonth = this.props.dateSelected.getMonth(),
            chosenYear = this.props.dateSelected.getFullYear(),
            month = this.getDaysArray(chosenYear, chosenMonth);
        this.setState({month: month})
    }


    private getDaysArray = (year: number, month: number) => {
        const monthIndex = month
        const names = Object.freeze(
            ['nd', 'pn', 'wt', 'śr', 'cz', 'pt', 'sb']);
        const date = new Date(year, monthIndex, 1);
        const result = [];
        while (date.getMonth() === monthIndex) {
            const day: IDayItem = {
                dayNumber: date.getDate(),
                daysName: names[date.getDay()],
                dateObject: new Date(year, month, date.getDate())
            }
            result.push(day);
            date.setDate(date.getDate() + 1);
        }
        return result;
    }
}


export default FatigueProfilesTable;

