import {SubmissionError} from 'redux-form';
// import get from 'lodash/get';
import has from 'lodash/has';
import {deepCloneObject} from "./runtimeUtils";
// import mapValues from 'lodash/mapValues';

const MIME_TYPE = 'application/ld+json';

export function fetch(id: string, authToken: string, options = {}) {
    if ('undefined' === typeof (options as any).headers) (options as any).headers = new Headers();
    if (null === (options as any).headers.get('Accept'))
        (options as any).headers.set('Accept', MIME_TYPE);

    if (null === (options as any).headers.get('Authorization'))
        (options as any).headers.set('Authorization', `Bearer ${authToken}`);

    if (
        'undefined' !== (options as any).body &&
        !((options as any).body instanceof FormData) &&
        null === (options as any).headers.get('Content-Type')
    )
        (options as any).headers.set('Content-Type', 'application/json');

    return (global as any).fetch(new URL(id, process.env.REACT_APP_AUTH_API_URL), options).then((response: any) => {
        if (response.ok) return response;

        return response.json().then(
            (json: any) => {
                const error =
                    json['hydra:description'] ||
                    json['hydra:title'] ||
                    'An error occurred.';
                if (!json.violations) throw Error(error);

                let errors = {_error: error};
                json.violations.forEach((violation: any) =>
                    (errors as any)[violation.propertyPath]
                        ? ((errors as any)[violation.propertyPath] +=
                        '\n' + (errors as any)[violation.propertyPath])
                        : ((errors as any)[violation.propertyPath] = violation.message)
                );

                throw new SubmissionError(errors);
            },
            () => {
                throw new Error(response.statusText || 'An error occurred.');
            }
        );
    });
}

export function mercureSubscribe(url: any, topics: any) {
    topics.forEach((topic: any) =>
        url.searchParams.append('topic', new URL(topic, process.env.REACT_APP_AUTH_API_URL))
    );

    return new EventSource(url.toString());
}

export function normalize(data: any) {
    if (has(data, 'hydra:member')) {
        // Normalize items in collections
        data['hydra:member'] = data['hydra:member'].map((item: any) => normalize(item));

        return data;
    }

    // Flatten nested documents
    // return mapValues(data, value =>
    //   Array.isArray(value)
    //     ? value.map(v => get(v, '@id', v))
    //     : get(value, '@id', value)
    // );
    return data;
}

export function extractHubURL(response: any) {
    const linkHeader = response.headers.get('Link');
    if (!linkHeader) return null;

    const matches = linkHeader.match(
        /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
    );

    return matches && matches[1] ? new URL(matches[1], process.env.REACT_APP_AUTH_API_URL) : null;
}

export function getAccountsFromUserItem(item: any) {
    if (!item) return null;
    let newItem = item.account ? item.account : item;
    if (item.countContestants || item.countContestants === 0) newItem.countContestants = item.countContestants;
    if (item.club) newItem.club = item.club;
    if (item.weightCategory) newItem.weightCategory = item.weightCategory;
    if (item.specialists) {
        let specialistAccounts: any = [];
        item.specialists.forEach((specialist: any) => {
            let specialistAccount = deepCloneObject(specialist);
            specialistAccount.account['countContestants'] = specialist['countContestants'];
            specialistAccount.account['specialistType'] = specialist['type'];
            specialistAccount = specialistAccount.account;
            specialistAccounts.push(specialistAccount);
        });
        newItem.specialists = specialistAccounts;
    }
    if (item.coaches) {
        let coachAccounts: any = [];
        item.coaches.forEach((coach: any) => {
            let coachAccount = deepCloneObject(coach);
            if (typeof coachAccount.account !== 'string') {
                coachAccount.account['countContestants'] = coach['countContestants'];
                coachAccount.account['clubs'] = coach['clubs'];
                coachAccount.account['coachType'] = coach['type'];
                coachAccount.account['coachId'] = coach['id'];
                coachAccount = coachAccount.account;
            }
            coachAccounts.push(coachAccount);
        })
        newItem.coaches = coachAccounts;
    }
    if (item.contestants) {
        let contestantAccounts: any = [];
        item.contestants.forEach((contestant: any) => {
            let contestantAccount = deepCloneObject(contestant);
            contestantAccount.account['weightCategory'] = contestant['weightCategory'];
            contestantAccount = contestantAccount.account;
            contestantAccounts.push(contestantAccount);
        })
        newItem.contestants = contestantAccounts;
    }
    if (item.inputGroupDefinitions) {
        let updatedGroupDefinitions: any = [];
        item.inputGroupDefinitions.forEach((groupDefinition: any, index: number) => {
            let updatedGroupDefinition = deepCloneObject(groupDefinition);
            if (updatedGroupDefinition.itemOrder < 0) {
                updatedGroupDefinition.itemOrder = index;
            }
            if (groupDefinition.inputDefinitions) {
                let updatedInputDefinitions: any = [];
                groupDefinition.inputDefinitions.forEach((inputDefinition: any, index: number) => {
                    let updatedInputDefinition = deepCloneObject(inputDefinition);
                    if (updatedInputDefinition.itemOrder < 0) {
                        updatedInputDefinition.itemOrder = index;
                    }
                    updatedInputDefinitions.push(updatedInputDefinition);
                    updatedInputDefinitions = updatedInputDefinitions.filter((inputItem: any) => typeof (inputItem) === 'object')
                    updatedInputDefinitions.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
                    updatedGroupDefinition.inputDefinitions = updatedInputDefinitions;
                })
            }
            updatedGroupDefinitions.push(updatedGroupDefinition);
        })
        updatedGroupDefinitions.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        newItem.inputGroupDefinitions = updatedGroupDefinitions;

    }
    if (item.inputDefinitions) {
        let updatedInputDefinitions: any = [];
        item.inputDefinitions.forEach((inputDefinition: any, index: number) => {
            let updatedInputDefinition = deepCloneObject(inputDefinition);
            if (updatedInputDefinition.itemOrder < 0) {
                updatedInputDefinition.itemOrder = index;
            }
            updatedInputDefinitions.push(updatedInputDefinition);
            updatedInputDefinitions = updatedInputDefinitions.filter((inputItem: any) => typeof (inputItem) === 'object')
        })
        updatedInputDefinitions.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        newItem.inputDefinitions = updatedInputDefinitions;
    }

    if (item.calendarViewGroups) {
        let updatedCalendarGroups: any = [];
        item.calendarViewGroups.forEach((calendarGroup: any, index: number) => {
            let updatedCalendarGroup = deepCloneObject(calendarGroup);
            if (updatedCalendarGroup.itemOrder < 0) {
                updatedCalendarGroup.itemOrder = index;
            }
            if (calendarGroup.calendarViewGroupItems) {
                let updatedCalendarGroupItems: any = [];
                calendarGroup.calendarViewGroupItems.forEach((calendarGroupItem: any, index: number) => {
                    let updatedCalendarGroupItem = deepCloneObject(calendarGroupItem);
                    if (updatedCalendarGroupItem.itemOrder < 0) {
                        updatedCalendarGroupItem.itemOrder = index;
                    }
                    updatedCalendarGroupItems.push(updatedCalendarGroupItem);
                    updatedCalendarGroupItems = updatedCalendarGroupItems.filter((calendarGroupItem: any) => typeof (calendarGroupItem) === 'object')
                    updatedCalendarGroupItems.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
                    updatedCalendarGroup.calendarViewGroupItems = updatedCalendarGroupItems;
                })
            }
            updatedCalendarGroups.push(updatedCalendarGroup);
        })
        updatedCalendarGroups.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        newItem.inputGroupDefinitions = updatedCalendarGroups;
    }

    if (item.calendarViewGroupItems) {
        let updatedCalendarGroupItems: any = [];
        item.calendarViewGroupItems.forEach((calendarGroupItem: any, index: number) => {
            let updatedCalendarGroupItem = deepCloneObject(calendarGroupItem);
            if (updatedCalendarGroupItem.itemOrder < 0) {
                updatedCalendarGroupItem.itemOrder = index;
            }
            updatedCalendarGroupItems.push(updatedCalendarGroupItem);
            updatedCalendarGroupItems = updatedCalendarGroupItems.filter((calendarGroupItem: any) => typeof (calendarGroupItem) === 'object')
        })
        updatedCalendarGroupItems.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
        newItem.calendarViewGroupItems = updatedCalendarGroupItems;
    }

    return newItem
}
