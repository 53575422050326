import React from 'react';
import {connect} from 'react-redux';
import {resetPasswordFormConfig} from './resetPasswordFormConfig';
import {CustomCard, Form, IBasicFormControlConfig, loginLoadingSelector, Translation} from "judo-app-common-web";
import {Link} from 'react-router-dom';
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {sendResetPasswordMailAPI} from "../../../api/sendResetPasswordMail";
import {catchError, tap} from "rxjs/operators";

interface IResetPasswordProps {
    isLoading: boolean;
}

interface IResetPasswordState {
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[],
}

class ResetPassword extends React.Component<IResetPasswordProps, IResetPasswordState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IResetPasswordProps) {
        super(props);
        this.state = {
            formConfig: resetPasswordFormConfig,
        };

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <CustomCard showLocalLoader={this.props.isLoading} type={"auth"}>
                    <CustomCard.Header>
                        <h3><Translation text={'auth.resetPassword.title'}/></h3>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <Form config={this.state.formConfig}
                              submitForm={this.resetPassword}
                              controlName={"resetPasswordForm"}
                              value={{}}/>
                        <p className="mb-2 text-muted">
                            <Translation text={'auth.haveAnAccount'}/>
                            <Link className="f-w-400 ml-2" to="/auth/login">
                                <Translation text={'auth.haveAnAccountLogin'}/>
                            </Link>
                        </p>
                    </CustomCard.Body>
                </CustomCard>
            </>
        );
    }

    private resetPassword = (event: any,
                             value: any,
                             valid: boolean,
                             touched: boolean
    ) => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }
        const url = window.location.origin + '/auth/new-password';
        this.subscriptions.push(
            sendResetPasswordMailAPI(value.email, url).pipe(
                tap(() => {
                    window.location.href = window.location.origin + "/auth/login";
                    this.alertManagerService.addAlert('alert.sendResetPasswordMail.success');
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe());
    };
}

export default connect(
    (state: any) => ({
        isLoading: loginLoadingSelector(state),
    }),
    {}
)(ResetPassword);
