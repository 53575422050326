import React from 'react';
import {
    FormControlChangeType,
    FuriaForm,
    IProcessStep,
    Loader,
    LoaderType,
    Translation,
    withFlowManager
} from "judo-app-common-web";
import {IFormSteps} from "../../../../service/formConfigConverter";
import styles from './styles.module.scss'
import {forkJoin, of, Subject, Subscription} from "rxjs";
import {catchError, mergeMap, tap} from "rxjs/operators";
import {createInputResultAPI, IInputResultPayload} from "../../../../api/createInputResult";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import cloneDeep from 'lodash/cloneDeep';
import {InputType, ProfileStatus, RawProfile, RawProfileInput} from "../../../../model/models";
import {updateInputResultsAPI} from '../../../../api/updateInputResults';

interface IConnectedStepViewProps {
}

interface IExternalStepViewProps {
    step: typeof IProcessStep;
    value: any;
    initialValues: any;
    hasPrev: boolean;
    hasNext: boolean;
    canHaveNext: boolean;
    goPrev: () => void;
    goNext: () => void;
    onStepValueChange: (value: any) => void;
    active: boolean;
    process: IFormSteps;
    profile: RawProfile;
    authToken: string;
    updateProfile: (profile: any) => void
    serverProfileWasUpdated?: (shouldRun: boolean) => void;
}

interface IStepViewProps extends IConnectedStepViewProps,
    IExternalStepViewProps {
}

interface IStepViewState {
    isFormValid: boolean;
    wizardStepNo: number;
    value: {[key: string]: any} | null;
    step: any;
    isLoading: boolean;
}

class StepView extends React.Component<IStepViewProps, IStepViewState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    private subscriptions: Subscription[] = [];
    private apiCallSubject: Subject<{payload: any, updateCall?: boolean }[]> =  new Subject();

    constructor(props: IStepViewProps) {
        super(props);

        this.state = {
            isFormValid: true,
            wizardStepNo: 0,
            value: null,
            step: null,
            isLoading: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.apiCallSubject.pipe(
            tap(() => this.setState({isLoading: true})),
            mergeMap( apiCallsArray => forkJoin(...apiCallsArray.map(result => result.updateCall ? this.updateInputResults(result.payload) : this.createInputResults(result.payload))))
        ).subscribe(() => {
           this.setState({isLoading: false})
            if(this.props?.serverProfileWasUpdated) {
                this.props?.serverProfileWasUpdated(true);
            }
            if (!this.props.process.isTraining) {
                this.props.goNext()
            }
        })

        if (this.props.process) {
            this.setState({wizardStepNo: this.props.process.steps.length});
        }

        if (this.props.step) {
            this.setState({step: this.props.step});
        }
    }

    componentDidUpdate(prevProps: Readonly<IStepViewProps>) {

        if (this.props.step.id !== prevProps.step.id) {
            this.setState({isFormValid: true});
        }

        if (this.props.process !== prevProps.process) {
            this.setState({wizardStepNo: this.props.process.steps.length});
        }

        if (JSON.stringify(this.props.step) !== JSON.stringify(prevProps.step)) {
            this.setState({step: this.props.step});
        }
        if(this.props.process.name !== prevProps.process.name) {
            this.setState({value: null})
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.apiCallSubject.unsubscribe();
    }

    render() {
        return (
            <React.Fragment>

                {this.renderWizardSteps()}

                <FuriaForm config={this.state.step}
                      disabled={false}
                      onValidationStateChange={this.onValidationStateChange}
                      onValueStateChange={this.onValueStateChange}
                      value={this.props.value}
                      controlName={this.props.step.id}
                       inputDataMapper={this.inputDataMapper}
                      //  outputDataMapper={this.outputDataMapper}
                />

                <footer className={`d-flex   ${this.props.hasPrev ?  'justify-content-between' : 'justify-content-end'} mt-5`}>
                    {this.props.hasPrev &&
                    <button tabIndex={ !this.state.isFormValid ? -1 : 1}
                            onClick={this.goPrev}
                            disabled={!this.props.hasPrev}
                            className="btn btn-theme-outline">
                        <Translation text={'button.prev'}/>
                    </button> }

                    {(this.props.canHaveNext || this.props.process.isTraining) &&
                    <button tabIndex={!this.state.isFormValid ? -1 : 0}
                            onClick={this.goNext}
                            disabled={!this.state.isFormValid || (this.props.process.isTraining && !this.state.value)}
                            className="btn btn-theme">
                        <Translation text={`button.${this.props.process.isTraining ? 'fillTraining' : 'next'}`}/></button>}
                </footer>
                <Loader show={this.state.isLoading} type={LoaderType.Local}/>
            </React.Fragment>
        );
    }

    private inputDataMapper = (data: any) => {
        if (data) {
            return data[this.state.step.id];
        }
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType, path: string) => {

        if (this.props.step.id !== controlName || changeType !== FormControlChangeType.User) {
            return;
        }
        this.setState({value: value});
        this.props.onStepValueChange(value);
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        if (this.props.step.id === controlName) {
            this.setState({isFormValid: isValid});
        }
    };

    private goPrev = () => {
        if (this.props.hasPrev) {
            this.props.goPrev();
        }
    };

    private goNext = () => {
        if (this.state.value !== null) {
            const dataToSend: {payload: any, inputResultId?: string}[] = [];
            let updatedInputs: any[] = [];
            Object.keys(this.state.value).map((valueKey: string) => {
                return Object.keys(this.props.step.controls).forEach((key: string) => {
                    if (valueKey === key &&
                        this.state.value &&
                        this.state.value[key] !== null) {
                        let inputId = this.props.step.controls[key].controlName;
                        let payload: IInputResultPayload = {
                            inputId: this.props.step.controls[key].name,
                            value: {
                                value: (!(this.state.value[key]).toString() && this.props.step.controls[key].inputType === InputType.INPUT_NUMBER) ?
                                    null : (this.state.value[key]).toString()
                            }
                        };

                        const currentStep = this.props.profile.inputGroups.find(group => group.inputGroupDefinitionId === this.props.step.id)
                        if(currentStep) {
                            currentStep.profileInputs.forEach((input: RawProfileInput, index: number) => {
                                if (input.inputDefinitionId === inputId &&
                                    // TODO: check if that doesnt bug other parts of the code
                                    // input?.inputResultValue?.value &&
                                    input.inputResultId &&
                                    payload.value.value !== this.props.initialValues?.[this.props.step.id]?.[input.inputDefinitionId]) {
                                        const inputResultId = input.inputResultId,
                                    updatedInputPayload = {
                                        id: inputResultId,
                                        value: payload.value,
                                        resultForDate: input.resultForDate,
                                        inputId: input.inputId,
                                        inputDefinitionId: input.inputDefinitionId,
                                    };

                                   return updatedInputs.push(updatedInputPayload);
                                }
                                // TODO: check if that doesnt bug other parts of the code
                                // if (input.inputDefinitionId === inputId && !input.inputResultValue?.value) {
                                if (input.inputDefinitionId === inputId && !input.inputResultId) {
                                    dataToSend.push({payload})
                                }

                            });
                        }
                    }
                });
            });
            if(dataToSend?.length > 0) {
                return this.apiCallSubject.next(dataToSend);
            }
            if (updatedInputs?.length > 0) {
                return  this.apiCallSubject.next([{payload: updatedInputs, updateCall: true}] )
            }
            this.props.goNext();
        } else {
            this.props.goNext();
        }
    };

    private createInputResults = (payload: IInputResultPayload) =>
            (createInputResultAPI(this.props.authToken, payload) as any).pipe(
                tap(result => this.updateProcessOnCreate(payload, result)),
                catchError((err: any) => {
                    this.setState({isLoading: false});
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
        );

    private updateProcessOnCreate(payload: IInputResultPayload, result?: any) {
        const profile = cloneDeep(this.props.profile)
        let isFilled = true;
        profile.inputGroups =  profile.inputGroups.map( group => {
            if(group.inputGroupDefinitionId === this.props.step.id) {
                group.profileInputs = group.profileInputs.map((input: RawProfileInput, index: number) => {
                    if (input.inputId === payload.inputId) {
                        input.inputResultId = result?.id
                        input.inputResultValue = result?.value
                    }
                    return input
                })
            }
            return group
        })
        profile.inputGroups.forEach(group => group.profileInputs.forEach(input => {
            if(!input.inputResultValue.value && input.inputType !== InputType.INPUT_TEXT) {
                isFilled = false
            }
        }))

        profile.status =  isFilled ? ProfileStatus.FILLED : ProfileStatus.TO_FILL

        return this.props.updateProfile(profile)
    }


    private updateProcessOnUpdate(payload: IInputResultPayload[], result?: any) {
        const profile = cloneDeep(this.props.profile)
        let isFilled = true;
        profile.inputGroups =  profile.inputGroups.map( group => {
            let isGroupFilled = true;
            if(group.inputGroupDefinitionId === this.props.step.id) {
                group.profileInputs = group.profileInputs.map((input: RawProfileInput, index: number) => {
                    const foundInputProfile = payload.find((p: any) => p.inputId === input.inputId);
                     if (foundInputProfile && foundInputProfile.id) {
                        input.inputResultId = foundInputProfile.id;
                        input.inputResultValue = foundInputProfile.value;
                    }
                    if(!input.inputResultValue?.value || input.inputResultValue.value === null) {
                        isGroupFilled = false;
                    }
                    return input
                })
                if(group.isFilled !== undefined) {
                    group.isFilled = isGroupFilled;
                }
            }

            return group
        })
        profile.inputGroups.forEach(group => group.profileInputs.forEach(input => {
            if(!input.inputResultValue.value && input.inputType !== InputType.INPUT_TEXT) {
                isFilled = false;
            }
        }))

        profile.status =  isFilled ? ProfileStatus.FILLED : ProfileStatus.TO_FILL;

        return this.props.updateProfile(profile);
    }

    private updateInputResults = (payload: IInputResultPayload[]) =>
            (updateInputResultsAPI(this.props.authToken, payload) as any).pipe(
                tap((result: any) => {
                    if (result && result['hydra:member']) {
                    const resultArray: any[] = result['hydra:member'];
                    this.updateProcessOnUpdate(payload, resultArray)}
                    })
                ,
                catchError((err: any) => {
                    this.setState({isLoading: false});
                    this.alertManagerService.handleApiError(err);
                    return of();
                })
        );

    private renderWizardSteps = () => {
        if (this.props.process.isTraining) return;
        const stepIndex = this.props.process.steps.findIndex( step => step.id === this.props.step.id)
        const step = this.props.process.steps.find( step => step.id === this.props.step.id)

        let currentWizardStepNo = stepIndex  ? stepIndex+ 1 : 1;
        let currentWizardStepName = step?.name || '';

        let isLastStep = currentWizardStepNo === this.state.wizardStepNo;
        return (
            <p className={styles.wizard}>
                <span className={styles.wizardStep}>
                    {!isLastStep ? (`${currentWizardStepNo} / ${this.state.wizardStepNo - 1}`) : null}
                </span>
                <span className={styles.wizardName}>{currentWizardStepName}</span>
            </p>
        )
    };
}

export default withFlowManager(StepView);
