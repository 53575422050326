import {Container} from 'inversify';
import getDecorators, {fixInjectedProperties} from './decorators';
import {
    AlertManagerService,
    FormConfigConverterService,
    VariablesFromGroupService,
    ExecutableExpressionDTOFormatter,
    CalendarIntoRawProfileConverterService
} from '../service';
import {
    VariableNameControlService,
    JSONLogicService,
    ApiPagingListService,
} from 'judo-app-common-web';


const container = new Container();

container.bind<typeof ApiPagingListService>('ApiPagingListService').to(ApiPagingListService).inSingletonScope();
container.bind<typeof JSONLogicService>('JSONLogicService').to(JSONLogicService).inSingletonScope();

container.bind<typeof VariableNameControlService>('VariableNameControlService').to(VariableNameControlService).inSingletonScope();

container.bind<CalendarIntoRawProfileConverterService>('CalendarIntoRawProfileConverterService').to(CalendarIntoRawProfileConverterService).inSingletonScope();
container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
container.bind<FormConfigConverterService>('FormConfigConverterService').to(FormConfigConverterService).inSingletonScope();
container.bind<VariablesFromGroupService>('VariablesFromGroupService').to(VariablesFromGroupService).inSingletonScope();
container.bind<ExecutableExpressionDTOFormatter>('ExecutableExpressionDTOFormatter').to(ExecutableExpressionDTOFormatter).inSingletonScope();

const {lazyInject} = getDecorators(container);

export {container, lazyInject, fixInjectedProperties};
