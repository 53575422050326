import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function updatePopulatedFatigueProfilesAPI(
    authToken: string,
    profileId: string,
): Observable<any> {
    return baseDataProviderAPI.put(
        `profile_definitions/${profileId}/append_to_assigned_profiles`,
        {},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
