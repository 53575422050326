import React from 'react';
import {authTokenSelector, CustomModal, Loader, LoaderType, Translation} from 'judo-app-common-web';
import {arrayMove, IItemProps, List} from "react-movable";
import {CalendarGroup, CalendarInput, ModalConfig} from "../../../../model/models";
import {DragDropVariable} from "../../../../utils/fatigueProfilesUtils";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {catchError, map, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {changeModal} from "../../../../store/reducers/sagaSlice";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {updateCalendarGroupAPI} from "../../../../api/updateFatigueCalendarGroup";
import {getCalendarGroupAPI} from "../../../../api/getCalendarGroup";
import AddItemMenu from '../../../DevelopmentProfiles/FatigueProfilesView/FatigueCalendar/CalendarGroupList/AddItemMenu';
import VariableListItem
    from "./VariableListItem";

interface IModalCalendarVariableListProps {
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly authToken: string;
    readonly changeModal: typeof changeModal;
}

interface IModalCalendarVariableListState {
    enabledItems: DragDropVariable[],
    disabledItems: DragDropVariable[],
    group: CalendarGroup | null
    isProcessing: boolean;
}

class ModalCalendarVariableList extends React.Component<IModalCalendarVariableListProps, IModalCalendarVariableListState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalCalendarVariableListProps) {
        super(props);
        this.state = {
            enabledItems: [],
            disabledItems: [],
            group: null,
            isProcessing: false,
        }
        fixInjectedProperties(this);

    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.id) {
            this.getCalendarGroup(this.props.modal.id);
        }
        if (this.state.group) {
            let sortedGroup: any = this.state.group.calendarViewGroupItems;
            sortedGroup.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
            const splitDataGroups = this.splitInputsIntoGroups(sortedGroup);
            this.setState({
                enabledItems: splitDataGroups.enabled,
                disabledItems: splitDataGroups.disabled
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IModalCalendarVariableListProps>, prevState: Readonly<IModalCalendarVariableListState>) {
        if (this.state.group && prevState.group !== this.state.group) {
            let sortedGroup: any = this.state.group.calendarViewGroupItems;
            sortedGroup.sort((a: any, b: any) => a.itemOrder - b.itemOrder);
            const splitDataGroups = this.splitInputsIntoGroups(sortedGroup);
            this.setState({
                enabledItems: splitDataGroups.enabled,
                disabledItems: splitDataGroups.disabled
            });
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }


    render() {
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="fatigue-profile-variables-modal">
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <h2 className="ellipsis-host fatigue-variables-header">
                        <div className="ellipsis-item">
                            <Translation text={'userView.variableGroup.modal.header'}/>
                            {this.state.group?.name}
                        </div>
                    </h2>
                </CustomModal.Header>
                <CustomModal.Body>
                    {this.renderVariableList()}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private renderVariableList() {
        if (this.state.enabledItems) {
            const itemList = this.state.enabledItems;
            const removeGroupVariable = (index: number | undefined) => {
                if (index !== undefined) {
                    const updatedInputId = itemList[index].calendarInput.id;
                    const updatedInputOrder = itemList[index].calendarInput.itemOrder;

                    let updatedGroup = deepCloneObject(this.state.group);

                    updatedGroup.calendarViewGroupItems = updatedGroup.calendarViewGroupItems.map((input: CalendarInput) => {
                        if (input.itemOrder && input.itemOrder > updatedInputOrder) {
                            input.itemOrder = input.itemOrder - 1
                        }

                        if (input.id === updatedInputId) {
                            input.enabled = false;
                            input.itemOrder = updatedGroup.calendarViewGroupItems.length
                        }
                        return input;
                    }).sort((a: CalendarInput, b: CalendarInput) => {
                        if (a.itemOrder && b.itemOrder) {
                            return a.itemOrder > b.itemOrder ? 1 : -1
                        }
                        return 0
                    })

                    this.updateCalendarGroup(updatedGroup);
                }
            };

            const liStyles = (props: IItemProps, isDragged: boolean, isSelected: boolean) => {
                return {
                    ...props.style,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem 1rem',
                    listStyle: 'none',
                    cursor: isDragged ? 'grabbing' : 'grab',
                    fontSize: '1.6rem',
                    fontFamily: `'Nunito', sans-serif`,
                    borderRadius: '5px',
                    verticalAlign: 'middle',
                    color: 'inherit',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF',
                    zIndex: 1451
                }
            }

            return <section className="user-connection-list-wrapper">
                <div className="user-connection-list-header fatigue-header justify-content-end">
                    <h3 className="sr-only">
                        <Translation text={'userView.header.variableGroup'}/> {this.state.group?.name}
                    </h3>
                    <AddItemMenu variableList={this.state.disabledItems}
                                 buttonLabel={'userView.variableGroup.modal.button.addVariable'}
                                 onGroupEnabled={() => null}
                                 onVariableEnabled={this.enableVariable}/>
                </div>
                <div className="separator"/>

                {this.renderListItems(itemList, liStyles, removeGroupVariable)}

            </section>
        }
    }


    private renderListItems(itemList: DragDropVariable[], liStyles: any, removeGroupVariable: any) {
        if (!itemList.length) {
            return <div className="no-data">
                <Translation text={'userView.noData.groupVariable'}/>
            </div>
        }
        return <List
            lockVertically
            values={itemList}
            onChange={({oldIndex, newIndex}) => {
                const updatedList = arrayMove(itemList, oldIndex, newIndex);
                this.listOrderChange(updatedList);
            }
            }
            renderList={({children, props}) => {
                return <ul className={`drag-and-drop-list`} {...props}>{children}</ul>
            }}
            renderItem={({value, props, index, isDragged, isSelected}) => {
                return <li {...props} className="drag-and-drop-list-item fatigue-group-item"
                           style={liStyles(props, isDragged, isSelected)}>
                    <VariableListItem listItem={value.calendarInput}
                                          key={value.id.toString()}
                                          isDraggable={true}
                                          onDeleteItem={() => removeGroupVariable(index)}/>
                </li>
            }}
        />
    }

    private getCalendarGroup(calendarGroupId: string) {
        if (!this.props.authToken) {
            return null;
        }
        this.setState({isProcessing: true})

        return this.subscriptions.push(getCalendarGroupAPI(this.props.authToken, calendarGroupId).pipe(
            map((response: any) => {
                if (response) {
                    this.setState({isProcessing: false})
                    return this.setState({group: response})
                }
            }),
            catchError((err: any) => {
                this.setState({isProcessing: false})
                this.alertManagerService.handleApiError(err)
                return of();
            })
        ).subscribe());

    }

    private splitInputsIntoGroups(inputGroup: CalendarInput[]): { enabled: DragDropVariable[], disabled: DragDropVariable[] } {
        let enabledItems: DragDropVariable[] = [],
            disabledItems: DragDropVariable[] = [];

        inputGroup.forEach((datalistItem: CalendarInput, i: number) => {
            datalistItem.enabled ? enabledItems.push({
                id: i,
                calendarInput: datalistItem
            }) : disabledItems.push({id: i, calendarInput: datalistItem});
        });
        return {enabled: enabledItems, disabled: disabledItems};
    }

    private enableVariable = (variableId: string) => {
        const updatedEnabledCalendarInputs = this.state.enabledItems.map((groupItem: DragDropVariable) => groupItem.calendarInput);
        let disabledCalendarInputs = this.state.disabledItems.map((groupItem: DragDropVariable) => {
            if (groupItem.calendarInput.id === variableId) {
                groupItem.calendarInput.enabled = true;
                return groupItem.calendarInput;
            }
            return groupItem.calendarInput
        });

        const groupListArray = updatedEnabledCalendarInputs.concat(disabledCalendarInputs);
        const updatedGroup = deepCloneObject(this.state.group);

        updatedGroup.calendarViewGroupItems = groupListArray.sort((a: CalendarInput, b: CalendarInput) => {
            if (a.itemOrder && b.itemOrder) {
                return a.itemOrder > b.itemOrder ? 1 : -1
            }
            return 0
        })

        this.updateCalendarGroup(updatedGroup);
    }

    private listOrderChange(updatedEnabledList: DragDropVariable[]) {
        const updatedEnabledCalendarInputs = updatedEnabledList.map((groupItem: DragDropVariable) => groupItem.calendarInput),
            disabledCalendarInputs = this.state.disabledItems.map((groupItem: DragDropVariable) => groupItem.calendarInput);
        const groupListArray = updatedEnabledCalendarInputs.concat(disabledCalendarInputs);
        let updatedGroupList: any[] = [];
        groupListArray.forEach((groupListItem: any, index: number) => {
            let updatedGroupListItem = deepCloneObject(groupListItem);
            updatedGroupListItem.itemOrder = index;
            updatedGroupList.push(updatedGroupListItem);
        });
        let updatedGroup = deepCloneObject(this.state.group);
        updatedGroup.calendarViewGroupItems = updatedGroupList;
        this.setState({group: updatedGroup});
        this.updateCalendarGroup(updatedGroup);
    }

    private updateCalendarGroup(updatedCalendarGroup: any) {
        let updatedGroupItems: any = [];
        if (updatedCalendarGroup.calendarViewGroupItems) {
            updatedCalendarGroup.calendarViewGroupItems.forEach((calendarItem: any) => {
                const itemPayload = {
                    id: calendarItem.id,
                    name: calendarItem.name,
                    calendarViewGroupId: calendarItem.calendarViewGroupId,
                    visualOptions: calendarItem.visualOptions,
                    inputDefinitionId: calendarItem.inputDefinition.id,
                    itemOrder: calendarItem.itemOrder,
                    valuesVisualOptions: calendarItem.valuesVisualOptions,
                    enabled: calendarItem.enabled
                }
                updatedGroupItems.push(itemPayload);
            })
        }
        const calendarGroupPayload = {
            id: updatedCalendarGroup.id,
            name: updatedCalendarGroup.name,
            itemOrder: updatedCalendarGroup.itemOrder,
            calendarId: updatedCalendarGroup.calendar.id,
            visualOptions: updatedCalendarGroup.visualOptions,
            inputGroupDefinitionId: updatedCalendarGroup.inputGroupDefinition.id,
            calendarViewGroupItems: updatedGroupItems,
            enabled: updatedCalendarGroup.enabled,
        }
        this.setState({isProcessing: true})

        return this.subscriptions.push(updateCalendarGroupAPI(this.props.authToken, updatedCalendarGroup.id, calendarGroupPayload).pipe(
            catchError((error: any) => {
                this.setState({isProcessing: false});
                this.alertManagerService.handleApiError(error)
                return of();
            }),
            tap((response) => {
                this.setState({isProcessing: false});
                this.alertManagerService.addAlert('alert.fatigueProfileAlert.updateGroupSuccess');
                this.getCalendarGroup(response.id);
            })
        ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
    }
)(ModalCalendarVariableList);

