import React from 'react';
import {Translation} from 'judo-app-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {isProfileValidSelector} from '../../../store/selectors/sagaSelectors';

interface IFatigueProfileAlertProps {
    isProfileInvalid: boolean;
}

interface IFatigueProfileAlertState {
    isClosed: boolean;
}

class FatigueProfileAlert extends React.Component<IFatigueProfileAlertProps, IFatigueProfileAlertState> {

    constructor(props: IFatigueProfileAlertProps) {
        super(props);
        this.state = {
            isClosed: false
        }
    }

    render() {
        if (this.props.isProfileInvalid) {
            const hide = this.state.isClosed ? 'hide' : '';
            return (
                <div className={`fatigue-profile-alert ${hide}`}>
                    <div className="profile-alert-icon">
                        <i className="feather icon-alert-circle"/>
                    </div>
                    <div className="main-message-wrapper">
                        <p><Translation text={'alert.fatigueProfileAlert.profileInvalid'}/></p>
                        <span><Translation text={'alert.fatigueProfileAlert.addDays'}/></span>
                    </div>
                    <div className="profile-alert-icon" onClick={this.close}>
                        <i className="feather icon-x-circle"/>
                    </div>
                </div>
            )
        }
        return null;
    }

    private close = () => {
        this.setState({isClosed: true})
    }
}

export default connect(
    (state: RootState) => ({
        isProfileValid: isProfileValidSelector(state),
    }),
    {
    }
)(FatigueProfileAlert);
