export default {
  items: [
    {
      id: 'Dashboard',
      title: 'Insurance Cover',
      type: 'group',
      icon: '',
      children: [
          // {
          //     id: 'fatigue-profiles',
          //     title: 'menu.fatigueProfiles',
          //     type: 'item',
          //     url: '/profiles',
          //     icon: 'feather icon-folder',
          // },
          {
              id: 'contestants',
              title: 'menu.contestants',
              type: 'item',
              url: '/contestants',
              icon: 'feather icon-users',
          },
          {
              id: 'specialists',
              title: 'menu.specialists',
              type: 'item',
              url: '/specialists',
              icon: 'feather icon-activity',
          },
          {
              type: 'spacer',
              class: 'nav-spacer',
          },
          {
              id: 'account',
              title: 'menu.account',
              type: 'item',
              url: '/account',
              icon: 'feather icon-settings',
          },
          {
              id: 'logout',
              title: 'menu.logout',
              type: 'item',
              url: `/logout`,
              icon: 'feather icon-log-out',
          },
      ]
    },
  ]
};