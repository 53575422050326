import React from 'react';
import {
    FuriaForm,
    FormControlChangeType,
    IBaseFormConfig,
    ProcessVariable
} from 'judo-app-common-web';
import addCalculatedFieldFormConfig from './addCalculatedFieldFormConfig';
import cloneDeep from 'lodash/cloneDeep';
import {ExecutableExpressionDTOFormatter} from '../../../../../service/executableExpressionDTOFormatter';
import {fixInjectedProperties, lazyInject} from '../../../../../ioc';
import {LanguageLocale} from "../../../../../constants/locales";

interface IModalConfirmConnectionProps {
    confirmFunction: (expression: any) => void;
    variables: typeof ProcessVariable[];
    initialValue?: any;
}

interface IModalConfirmConnectionState {
    readonly control: typeof IBaseFormConfig | null;
    value: any;
    isFormValid: boolean;
}

class AddCalculatedFieldForm extends React.Component<IModalConfirmConnectionProps, IModalConfirmConnectionState> {

    @lazyInject('ExecutableExpressionDTOFormatter') private executableExpressionDTOFormatter: ExecutableExpressionDTOFormatter;


    constructor(props: IModalConfirmConnectionProps) {
        super(props);

        this.state = {
            control: null,
            value: {expression: this.getInitialValue()},
            isFormValid: true,
        };

        fixInjectedProperties(this);
    }

    render() {
        return (
            <div className="formula-editor-modal">
                <FuriaForm config={this.config}
                      value={this.state.value}
                      controlName={'form'}
                      locale={LanguageLocale.PL}
                      layouts={addCalculatedFieldFormConfig(LanguageLocale.PL, {variables: this.props.variables}).layouts}
                      onValueStateChange={this.onValueStateChange}
                      onValidationStateChange={this.onValidationStateChange}/>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType, path: string) => {
        if (controlName !== 'expression' || changeType !== FormControlChangeType.User) {
            return;
        }

        this.setState({
            isFormValid: (!(value?.['args'] && Array.isArray(value?.['args']) && value?.['args'].length === 0)),
            value
        }, () => this.submit());
    };

    private getInitialValue() {
        if(!this.props.initialValue) {
            return '';
        }

        return this.props.initialValue
    }

    private submit = () => {
        if (!this.state.isFormValid) {
           return this.props.confirmFunction(null);
        }
        const control = cloneDeep(this.config.controls[0]);
        control.controlOptions = Object.assign(control.controlOptions || {}, this.state.value);

        const preparedServerExpression = this.executableExpressionDTOFormatter.prepareExpression(this.state.value);
        this.props.confirmFunction(preparedServerExpression);
    };

    private onValidationStateChange = (controlName: string, isValid: boolean, path: string) => {};

    private get config(): typeof IBaseFormConfig {
        return {
            controlType: 'form',
            class: '',
            controls: addCalculatedFieldFormConfig(LanguageLocale.PL, {variables: this.props.variables}).controls,
        };
    }

}

export default AddCalculatedFieldForm;
