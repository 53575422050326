import {authTokenSelector, CustomModal, Form, IFormConfig, Loader, LoaderType, Translation} from 'judo-app-common-web';
import moment from "moment";
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {catchError, map, take, tap} from "rxjs/operators";
import {getBaseListAPI} from "../../api/getBaseList";
import {getUserTrainingProfilesAPI} from '../../api/getUserTrainingProfiles';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {CurrentFatigueCalendarView, ModalConfig, RawProfile} from "../../model/models";
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import {changeModal} from "../../store/reducers/sagaSlice";
import {modalSelector} from "../../store/selectors/sagaSelectors";
import {MatchProcessParams} from "../PanelHost";
import {monthSelectFormConfig} from "./monthSelectFormConfig";
import styles from "./styles.module.scss";
import TrainingPlansTable from "./TrainingPlansTable";

interface IModalTrainingPlanProps extends RouteComponentProps<MatchProcessParams> {
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly authToken: string | null;
    readonly changeModal: typeof changeModal;
}

interface IModalTrainingPlanState {
    isModalOpen: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    selectedMonth: number | null,
    selectedYear: number | null;
    isLoading: boolean;
    calendar: CurrentFatigueCalendarView | null;
    profiles: RawProfile[];
}


class ModalTrainingPlan extends React.Component<IModalTrainingPlanProps, IModalTrainingPlanState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscription: Subscription | null;

    constructor(props: IModalTrainingPlanProps) {
        super(props);
        const date = moment(new Date()).locale("pl").format('MMMM yyyy')
        const dateInString = date.split(' '),
            monthName = dateInString[0],
            monthNumber = moment.months().findIndex(element => element === monthName),
            yearSelected = +(dateInString[1]);

        this.state = {
            isModalOpen: false,
            formConfig: monthSelectFormConfig,
            value: null,
            selectedMonth: monthNumber,
            selectedYear: yearSelected,
            isLoading: false,
            calendar: null,
            profiles: []
        }

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.subscription =  forkJoin([this.getCalendarData(), this.getProfilesForCurrentMonth()]).subscribe(
            (res) => this.setState({isLoading: false}));
    }

    componentDidUpdate(prevProps: Readonly<IModalTrainingPlanProps>, prevState: Readonly<IModalTrainingPlanState>) {

        if(prevState.selectedMonth !== undefined &&
            prevState.selectedMonth !== null &&
            prevState.selectedYear !== undefined &&
            prevState.selectedYear !== null &&
            ((this.state.selectedMonth !== prevState?.selectedMonth) || (this.state.selectedYear !== prevState?.selectedYear))) {
            this.getProfilesForCurrentMonth()?.pipe(
                take(1),
            ).subscribe()
        }
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe()
    }

    render() {
        if (!this.props.modal) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="fatigue-profile-calendar-modal">
                <CustomModal.Header>
                    <div className={styles.trainingPlanHeader}>
                        <h2 className={styles.trainingPlanTitle}>
                            <Translation text={'modal.trainingPlanView.title'}/>
                            {this.state.calendar &&
                                <span>{` - ${this.props.modal.data?.fatigueProfileName}`}</span>
                            }
                        </h2>
                        <div className={styles.headerInputsWrapper}>
                        {/*    <Link className={`btn btn-fatigue-profile ${this.state.calendar ? '' :  'disabled'}`}*/}
                        {/*          to={`/profiles/settings/${this.state.calendar?.id}`}>*/}
                        {/*        <span aria-hidden="true" className="feather icon-settings"/>*/}
                        {/*        <Translation text={'profiles.modal.button.settings'}/>*/}
                        {/*    </Link>*/}
                            <div>
                                <Form config={monthSelectFormConfig(this.state.value?.monthSelected)}
                                      controlName={'monthSelector'}
                                      onValueStateChange={this.onValueStateChange}
                                      value={this.state.value}/>
                            </div>
                        </div>
                    </div>

                </CustomModal.Header>
                <CustomModal.Body>
                    <Loader type={LoaderType.Local} show={this.state.isLoading}/>
                    {this.renderTable(this.props.modal.data?.isPreview)}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private get daysInSelectedMonth() {
        if(this.state.selectedYear && this.state.selectedMonth !== null) {
            return new Date(this.state.selectedYear, this.state.selectedMonth+1, 0).getDate()
        }
        return null
    }

    private renderTable(isPreview?: boolean) {
        if (this.state.selectedMonth !== null && this.state.selectedYear !== null && this.props.modal && this.props.modal.id && this.props.authToken) {
            return <TrainingPlansTable authToken={this.props.authToken}
                                         calendar={this.state.calendar}
                                         profiles={this.state.profiles}
                                         getUpdatedProfiles={() => this.getProfilesForCurrentMonth()}
                                         getUpdatedCalendar={() => this.getCalendarData()}
                                         dateSelected={new Date(this.state.selectedYear, this.state.selectedMonth)}
                                         isPreview={isPreview}/>
        }
        return null;
    }

    private getCalendarData() {
        if(!this.props.modal?.id || !this.props.authToken) {
            return of('completed')
        }
        const endpointString = `calendars/${this.props.modal.id}`;
        return getBaseListAPI(this.props.authToken, endpointString).pipe(
            map((response: any) => {
                if (response) {
                    this.setState({calendar: response})
                }
            }),
            catchError((err: any) => {
                this.setState({isLoading: false})
                this.alertManagerService.handleApiError(err);
                return of();
            })
        )
    }



    private getProfilesForCurrentMonth(): Observable<void | string> {
        if (this.state.selectedMonth === null ||
            this.state.selectedYear === null ||
            !this.props.authToken ||
            this.props.match.params.host === "profiles") {
            return of('completed');
        }

        const startOfMonthDate = new Date(this.state.selectedYear, this.state.selectedMonth, 1).toISOString().slice(0, 10),
            endOfMonthDate = new Date(this.state.selectedYear, this.state.selectedMonth + 1, 1).toISOString().slice(0, 10);

        this.setState({isLoading: true})
        return getUserTrainingProfilesAPI(this.props.authToken, startOfMonthDate, endOfMonthDate, this.props.modal?.userId).pipe(
            tap((response: any) => {
                if (response && response['hydra:member']) {
                    let profiles = response['hydra:member'];
                    this.setState({profiles: profiles, isLoading: false});
                }
            }),
            catchError((err: any) => {
                this.setState({isLoading: false})
                this.alertManagerService.handleApiError(err);
                return of('');
            })
        )
    }


    private onValueStateChange = (controlName: string, value: any) => {
        const formattedDate: any = moment(value.monthSelected).locale("pl").format('MMMM yyyy')
        const dateInString = formattedDate.split(' '),
            monthName = dateInString[0],
            monthNumber = moment.months().findIndex(element => element === monthName),
            yearSelected = +dateInString[1];
        this.setState({value: value, selectedYear: yearSelected, selectedMonth: monthNumber});
    };
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),

    }),
    {
        changeModal
    }
)(withRouter(ModalTrainingPlan));

