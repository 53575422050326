import React from 'react';
import {authTokenSelector, CustomCard, Form, IFormConfig, Loader, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {of, Subscription, combineLatest} from 'rxjs';
import {calendarFormConfig} from "./calendarFormConfig";
import styles from "./styles.module.scss";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {MatchProcessParams} from "../PanelHost";
import {deepCloneObject} from "../../utils/runtimeUtils";
import {IFormConfigConverterService} from "../../service/formConfigConverter";
import DailyProfile from "./DailyProfile";
import moment from 'moment';
import {RootState} from "../../store/reducers";
import {connect} from "react-redux";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {ModalType, ProfileStatus, RawInputGroup, RawProfile, RawProfileInput} from "../../model/models";
import {changeModal} from "../../store/reducers/sagaSlice";
import {userSelector} from '../../store/selectors/userSelector';
import {getUserFatigueProfilesAPI} from "../../api/getUserFatigueProfiles";
import ProfilesAccordion from './ProfilesAccordion';
import {getDevelopmentProfiles} from '../../api/getDevelopmentProfiles';
import {getUserTrainingProfilesAPI} from '../../api/getUserTrainingProfiles';

interface IConnectedContestantFatigueCalendarProps {
    readonly authToken: string;
    readonly user: any;
}

interface IExternalContestantFatigueCalendarProps {
    readonly userId: string | null;
    readonly onViewChange: (id: string | null) => void;
    readonly isModalView?: boolean;
    readonly changeModal: typeof changeModal;
    readonly serverProfileWasUpdated?: (shouldRun: boolean) => void;
}

interface IContestantFatigueCalendarProps extends IConnectedContestantFatigueCalendarProps,
    IExternalContestantFatigueCalendarProps,
    RouteComponentProps<MatchProcessParams> {
}

interface IContestantFatigueCalendarState {
    fatigueProfiles: RawProfile[];
    fatigueCalendar: any;
    selectedFatigueProfile: RawProfile | null;
    selectedProfileId: string | null;
    trainingProfiles: RawProfile[];
    selectedTrainingProfile: RawProfile | null;
    calendar: {[key: string]: any} | null;
    formConfig: typeof IFormConfig;
    value: any;
    isLoading: boolean;
    selectedDate: Date | null;
    currentMonth: number;
    currentMonthFullDate: Date;
    timeSlots: {[key: string]: any}[] | null;
    timezone: string | null;
}

class ContestantFatigueCalendar extends React.Component<IContestantFatigueCalendarProps, IContestantFatigueCalendarState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    @lazyInject('FormConfigConverterService') private formConfigConverterService: IFormConfigConverterService;
    private subscriptions: Subscription[] = [];

    constructor(props: IContestantFatigueCalendarProps) {
        super(props);
        this.state = {
            fatigueProfiles: [],
            fatigueCalendar: null,
            selectedFatigueProfile: null,
            trainingProfiles: [],
            selectedProfileId: null,
            selectedTrainingProfile: null,
            calendar: null,
            formConfig: calendarFormConfig,
            value: null,
            isLoading: false,
            selectedDate: null,
            timeSlots: null,
            currentMonth: new Date().getMonth() + 1,
            currentMonthFullDate: new Date(),
            timezone: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.retrieveFatigueProfileData();
        this.updateFatigueCalendar();
    }

    componentDidUpdate(
        prevProps: Readonly<IContestantFatigueCalendarProps>,
        prevState: Readonly<IContestantFatigueCalendarState>,
        snapshot?: any
    ): void {
        if (this.state.fatigueProfiles !== prevState.fatigueProfiles) {
            this.updateFatigueCalendar();
        }
        if (this.state.trainingProfiles !== prevState.trainingProfiles) {
            this.updateFatigueCalendar();
        }
        if (this.state.currentMonth !== prevState.currentMonth) {
            const currentMonthFullDate = new Date(this.state.currentMonthFullDate);
            this.retrieveFatigueProfileData(currentMonthFullDate);
        }

        if ((prevState.fatigueProfiles && prevState.fatigueProfiles.length !== 0) &&
            (!this.state.fatigueProfiles || this.state.fatigueProfiles.length === 0)) {
            this.setState({
                selectedDate: null,
                selectedFatigueProfile: null
            })
        }

        if ((prevState.trainingProfiles && prevState.trainingProfiles.length !== 0) &&
            (!this.state.trainingProfiles || this.state.trainingProfiles.length === 0)) {
            this.setState({
                selectedDate: null,
                selectedTrainingProfile: null
            })
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const calendarName = this.state.fatigueProfiles.find((fatigueProfile: any) => fatigueProfile.profileDefinition.name !== 'Trening')?.profileDefinition?.name || '';
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-6">
                        <CustomCard>
                            <CustomCard.Header>
                                <div className="subheader d-flex justify-content-between">
                                    <h2>
                                        {calendarName}
                                    </h2>
                                    <button disabled={!this.state.calendar}
                                        className="btn btn-theme btn-small d-none d-lg-flex"
                                        onClick={this.openFatigueCalendarModal}>
                                        <span className="feather icon-calendar" aria-hidden="true" />
                                        <span>
                                            <Translation text={'profiles.view.dailyForm.showFatigueCalendar'} />
                                        </span>
                                    </button>
                                </div>
                                <div className="spacer" />
                            </CustomCard.Header>
                            <CustomCard.Body>
                                {this.renderFatigueProfileDatepicker()}
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                    <div className="col-xl-6">
                        <CustomCard type={this.state.selectedDate ? 'daily-form' : ''}>
                            <CustomCard.Header>
                                <div className="subheader">
                                    <h2 className="d-flex justify-content-between">
                                        {this.state.selectedDate &&
                                            <p>{moment(this.state.selectedDate).format('DD-MM-YYYY')}</p>}
                                    </h2>
                                </div>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                {this.renderProfilesList(this.state.selectedFatigueProfile, this.state.selectedTrainingProfile)}
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    private renderFatigueProfileDatepicker() {
        if (this.state.isLoading) {
            return <Loader show={this.state.isLoading} />
        }
        return (
            <div className={styles.calendarContainer}>
                <div className={styles.calendarWrapper}>
                    <div className={styles.formWrapper}>
                        <Form config={this.state.formConfig}
                            controlName={'calendarForm'}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value} />
                    </div>
                </div>
            </div>
        )
    }


    private onValueStateChange = (controlName: string, value: any) => {
        Object.keys(value).forEach(key => {
            // if (value[key] === null) {
            //     return;
            // }
            if (value[key]?.[key] && value[key][key].hasOwnProperty("name") && value[key][key].name === 'month') {
                return this.onMonthChange(value[key][key].value)
            }

            if (value[key]?.[key] && value[key][key].hasOwnProperty("name") && value[key][key].name === 'date') {
                this.onDayChange(value[key][key].value);
                this.setState({selectedDate: value[key][key].value});
            }

            if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'selectedDate') {
                this.setState({selectedDate: value.date.value});
            }
        })
    };

    private onMonthChange = (monthValue: any) => {
        let currentMonth = monthValue.getMonth() + 1;
        let config = calendarFormConfig;
        config.controls[0].controls.date.timeSlots = null;
        config.controls[0].controls.date.selectedDate = Date.UTC(monthValue.getUTCFullYear(), monthValue.getUTCMonth(), monthValue.getUTCDate());
        this.setState({
            currentMonth: currentMonth,
            currentMonthFullDate: monthValue,
            selectedFatigueProfile: null,
            selectedTrainingProfile: null,
            timeSlots: null,
            selectedDate: null,
            formConfig: config
        });
    };

    private onDayChange = (dayValue: any) => {
        let selectedFatigueProfile: any = this.state.fatigueProfiles.find((profile: any) => {
            return new Date(profile.validFrom).setHours(0, 0, 0, 0) === new Date(moment(dayValue).format()).setHours(0, 0, 0, 0)
        });
        if (selectedFatigueProfile) {
            const month = new Date(selectedFatigueProfile.validFrom).getMonth();
            if (month + 1 !== this.state.currentMonth) {
                this.onMonthChange(new Date(selectedFatigueProfile.validFrom));
            }
        }
        let selectedTrainingProfile: any = this.state.trainingProfiles.find((profile: any) => {
            return new Date(profile.validFrom).setHours(0, 0, 0, 0) === new Date(moment(dayValue).format()).setHours(0, 0, 0, 0)
        });

        this.setState({selectedFatigueProfile: selectedFatigueProfile, selectedTrainingProfile: selectedTrainingProfile});
    };

    private renderProfilesList(fatigueProfile: RawProfile | null, trainingProfile: RawProfile | null) {
        if (this.state.isLoading) return null;
        if (fatigueProfile)  {
            let trainingProfileGroups = trainingProfile?.inputGroups;
            if (trainingProfileGroups) {
                const tableGroups = deepCloneObject(trainingProfileGroups).sort(  (a: RawInputGroup, b: RawInputGroup) =>{
                    const aNameIndex = parseInt(a.inputGroupName.charAt( a.inputGroupName.length - 1));
                    const bNameIndex = parseInt(b.inputGroupName.charAt(b.inputGroupName.length - 1));
                    return aNameIndex - bNameIndex;
                });
                trainingProfileGroups = tableGroups;
            }
            return <div>
                {trainingProfile && trainingProfileGroups && trainingProfileGroups.map((group: any, index: number) =>{
                    const trainingFreeGroupInput = group.profileInputs.length && group.profileInputs.some((input: RawProfileInput) => input.inputResultValue.value === 'WoT');
                    if (trainingFreeGroupInput) return null;
                    return <ProfilesAccordion key={`${group.inputGroupName}_${index}`} profileName={group.inputGroupName} profileId={group.inputGroupId} isFilled={group.isFilled} isOpen={this.state.selectedProfileId === group.inputGroupId} toggleAccordion={this.toggleAccordion}>
                        {this.renderTrainingProfileForm(group, trainingProfile)}
                    </ProfilesAccordion>}
                )}
                <ProfilesAccordion key={`${fatigueProfile.profileDefinition.name}`} profileName={fatigueProfile.profileDefinition.name} profileId={fatigueProfile.profileDefinition.id} isFilled={fatigueProfile.status === ProfileStatus.FILLED} isOpen={this.state.selectedProfileId === fatigueProfile.profileDefinition.id} toggleAccordion={this.toggleAccordion}>
                    {this.renderFatigueProfileForm()}
                </ProfilesAccordion>
            </div>

        }
        if (!this.props.user.profileDefinitions.length) {
            return <div className={styles.profileGenerationInfoHost}>
                <span className={styles.infoText}>
                    <Translation text={'profiles.view.dailyForm.noProfileDefinition'} />
                </span>
            </div>
        }
         if (this.state.selectedDate && this.state.selectedDate.getMonth() + 1 !== this.state.currentMonth) {
            const isPastMonth = this.state.selectedDate.getMonth() + 1 < this.state.currentMonth;
            const message = isPastMonth ? 'profiles.view.dailyForm.notCurrentMonth' : 'profiles.view.dailyForm.pastMonth';
            return <div className={styles.profileGenerationInfoHost}>
                <span className={styles.infoText}>
                    <Translation text={message} />
                </span>
            </div>
        }
        if (this.state.selectedDate && this.state.selectedDate.toDateString() === new Date().toDateString()) {
            const isGenerationHour = new Date().getHours() === 0 && new Date().getMinutes() < 20,
                message = isGenerationHour ? 'profiles.view.dailyForm.profileIsGenerating' : 'profiles.view.dailyForm.profileCreatedOnSameDay';
            return <div className={styles.profileGenerationInfoHost}>
                <span className={styles.infoText}>
                    <Translation text={message} /> <br />
                    {isGenerationHour &&
                        <div className={styles.warning}>
                            <Translation text='profiles.view.dailyForm.createdOnSameDayWarning' />
                        </div>
                    }
                </span>
            </div>
        }
        return <div className={styles.profileGenerationInfoHost}>
        <span className={styles.infoText}>
            <Translation text='profiles.view.dailyForm.wrongDay'
                additionalData={{date: this.state.selectedDate?.toLocaleDateString()}} />
        </span>
    </div>

    }


    private renderTrainingProfileForm(formGroup: RawInputGroup, profile: RawProfile) {
        let form = this.formConfigConverterService.convertTrainingDataToFormConfig(formGroup);
        let stepConfig = this.formConfigConverterService.convertFormToSteps(form, profile.validFrom, true);
        return (<DailyProfile updateProfile={(profileUpdated: any) => this.updateTrainingProfiles(profileUpdated)}
            process={stepConfig}
            profile={profile}
            serverProfileWasUpdated={(shouldRun: boolean) => this.trainingProfileUpdated(shouldRun)}
            authToken={this.props.authToken} />);
    }


    private renderFatigueProfileForm() {

        if (this.state.selectedFatigueProfile) {
            const profile = this.state.selectedFatigueProfile;
            let form = this.formConfigConverterService.convertDataToFormConfig(profile);
            let stepConfig = this.formConfigConverterService.convertFormToSteps(form, this.state.selectedFatigueProfile.validFrom);
            return (<DailyProfile updateProfile={(profileUpdated: any) => this.updateProfiles(profileUpdated)}
                process={stepConfig}
                profile={profile}
                serverProfileWasUpdated={this.props.serverProfileWasUpdated}
                authToken={this.props.authToken} />);
        }


        return <div className={styles.profileGenerationInfoHost}>
            <span className={styles.infoText}>
                <Translation text='profiles.view.dailyForm.wrongDay'
                    additionalData={{date: this.state.selectedDate?.toLocaleDateString()}} />
            </span>
        </div>
    }


    private trainingProfileUpdated = (shouldRun: boolean) => {
        this.setState({selectedProfileId: null});
        if (this.props.serverProfileWasUpdated) {
            return this.props.serverProfileWasUpdated(shouldRun);
        }
    }

    private updateProfiles(profileUpdated: RawProfile): void {

        const profiles = this.state.fatigueProfiles.map((profile: RawProfile) => {
            if (profile["@id"] === profileUpdated["@id"] && profile.validFrom.valueOf() === profileUpdated.validFrom.valueOf()) {
                return profileUpdated
            }
            return profile
        })

        this.setState({
            fatigueProfiles: profiles,
            selectedFatigueProfile: profileUpdated
        })
    }
    private updateTrainingProfiles(trainingProfileUpdated: RawProfile): void {

        const profiles = this.state.trainingProfiles.map((profile: RawProfile) => {
            if (profile["@id"] === trainingProfileUpdated["@id"] && profile.validFrom.valueOf() === trainingProfileUpdated.validFrom.valueOf()) {
                return trainingProfileUpdated
            }
            return profile
        })

        this.setState({
            trainingProfiles: profiles,
            selectedTrainingProfile: trainingProfileUpdated,
            selectedProfileId: null
        })
    }

    private retrieveFatigueProfileData(date?: Date): void {
        if (!this.props.user) return;

        const momentDate = date ? moment(date) : moment(),
        monthInFuture = momentDate.month() > moment().month();

        if (monthInFuture) return;

        this.setState({isLoading: true});
        const startOfMonthDate = momentDate.utc().startOf('month').toISOString(),
            endOfMonthDate = momentDate.utc().endOf('month').toISOString();

        const getUserFatigueProfilesCall = getUserFatigueProfilesAPI(this.props.authToken, startOfMonthDate, endOfMonthDate).pipe(
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error);
                return of();
            }),
            map((response: any) => {
                this.setState({fatigueProfiles: response['hydra:member']})
                if (!this.state.selectedDate) {
                    this.setState({selectedDate: new Date()})
                }
                if (new Date().getMonth() + 1 === this.state.currentMonth && !this.state.selectedFatigueProfile) {
                    this.onDayChange(new Date());
                }
                return response['hydra:member'];
            }),
            switchMap((response: any) => {
                const profileDefinitionId = response?.[0]?.profileDefinition?.id;
                if (!profileDefinitionId) {
                    this.setState({isLoading: false});
                    return of()
                }
                return getDevelopmentProfiles(this.props.authToken as string, 1, 1000).pipe(
                    map((response) => {
                        if (response && response['hydra:member']) {
                            const calendar = response['hydra:member'].find((calendarItem: any) => calendarItem.profileDefinition.id === profileDefinitionId);
                            this.setState({calendar: calendar});
                        }
                    })
                )
            })
        );
        const getUserTrainingProfilesCall = getUserTrainingProfilesAPI(this.props.authToken, startOfMonthDate.slice(0, 10), endOfMonthDate.slice(0, 10), this.props.user?.id).pipe(
            map((response: any) => {
                if (response['hydra:member'] && response['hydra:member'].length) {
                    this.setState({trainingProfiles: response['hydra:member']})
                }
                return response;
            }),
        );

        this.subscriptions.push(
            combineLatest([getUserTrainingProfilesCall, getUserFatigueProfilesCall]).pipe(
                tap(() => this.setState({isLoading: false})),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManagerService.handleApiError(error);
                    return of();
                }),
            ).subscribe()
        );
    }

    private updateFatigueCalendar(): void {
        let completeProfileDates: Date[] = [];
        let incompleteProfileDates: Date[] = [];
        if (this.state.fatigueProfiles) {
            this.state.fatigueProfiles.forEach((profile: any) => {
                const trainingProfile = this.state.trainingProfiles.find((trainingProfile: any) => new Date(moment(trainingProfile.validFrom).format()).setHours(0, 0, 0, 0) === new Date(moment(profile.validFrom).format()).setHours(0, 0, 0, 0));
                if (profile.status === ProfileStatus.FILLED) {
                    (trainingProfile && trainingProfile.status === ProfileStatus.TO_FILL) ?
                        incompleteProfileDates.push(new Date(profile.validFrom))
                        :
                        completeProfileDates.push(new Date(profile.validFrom));
                }

                if (profile.status === ProfileStatus.TO_FILL) {
                    incompleteProfileDates.push(new Date(profile.validFrom));
                }
            });
        }
        let updatedConfig = deepCloneObject(calendarFormConfig);
        updatedConfig.controls[0].controls.date.completeProfileDates = completeProfileDates;
        updatedConfig.controls[0].controls.date.incompleteProfileDates = incompleteProfileDates;
        updatedConfig.controls[0].controls.date.calendarStart = null

        this.setState({formConfig: updatedConfig});
    }

    private toggleAccordion = (profileId: string) => {
        this.setState({selectedProfileId: this.state.selectedProfileId === profileId ? null : profileId});
    }

    private openFatigueCalendarModal = () => {
        if (!this.state.calendar) {
            return null
        }
        this.props.changeModal({type: ModalType.CALENDAR, id: this.state.calendar.id})
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        user: userSelector(state),
    }),
    {
        changeModal
    }
)(withRouter(ContestantFatigueCalendar));
