import {combineEpics} from 'redux-observable';
import {loginEpic, renewAuthTokenEpic,reauthenticateEpic} from "judo-app-common-web";
import changePasswordEpic from "./changePasswordEpic";

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    changePasswordEpic
);
