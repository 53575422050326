import React from 'react';
import {authTokenSelector, CustomModal, Form, IFormConfig, Loader, LoaderType, Translation} from 'judo-app-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {IAlertManagerService} from '../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import styles from "./styles.module.scss"
import {monthSelectFormConfig} from "./monthSelectFormConfig";
import FatigueProfilesTable from "./FatigueProfilesTable";
import moment from "moment";
import {modalSelector} from "../../store/selectors/sagaSelectors";
import {changeModal} from "../../store/reducers/sagaSlice";
import {CalendarEvent, CurrentFatigueCalendarView, ModalConfig, RawProfile} from "../../model/models";
import FatigueProfilesChart from "./FatigueProfilesCharts";
import {getBaseListAPI} from "../../api/getBaseList";
import {catchError, map, tap, take} from "rxjs/operators";
import {of, Subscription, forkJoin, Observable} from "rxjs";
import {getUserFatigueProfilesAPI} from "../../api/getUserFatigueProfiles";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {MatchProcessParams} from "../PanelHost";

interface IModalFatigueProfileProps extends RouteComponentProps<MatchProcessParams> {
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly authToken: string | null;
    readonly changeModal: typeof changeModal;
}

interface IModalFatigueProfileState {
    isModalOpen: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    selectedMonth: number | null,
    selectedYear: number | null;
    view: CalendarViewType;
    isLoading: boolean;
    calendar: CurrentFatigueCalendarView | null;
    calendarEvents: CalendarEvent[];
    profiles: RawProfile[];
}

enum CalendarViewType {
    CALENDAR = 'calendar',
    CHARTS='charts'
}

class ModalFatigueProfile extends React.Component<IModalFatigueProfileProps, IModalFatigueProfileState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscription: Subscription | null;

    constructor(props: IModalFatigueProfileProps) {
        super(props);
        const date = moment(new Date()).locale("pl").format('MMMM yyyy')
        const dateInString = date.split(' '),
            monthName = dateInString[0],
            monthNumber = moment.months().findIndex(element => element === monthName),
            yearSelected = +(dateInString[1]);

        this.state = {
            isModalOpen: false,
            formConfig: monthSelectFormConfig,
            value: null,
            selectedMonth: monthNumber,
            selectedYear: yearSelected,
            view: CalendarViewType.CALENDAR,
            isLoading: false,
            calendar: null,
            calendarEvents: [],
            profiles: []
        }

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setState({isLoading: true})
        this.subscription =  forkJoin([this.getCalendarData(), this.getProfilesForCurrentMonth()]).subscribe(
            (res) => this.setState({isLoading: false}));
    }

    componentDidUpdate(prevProps: Readonly<IModalFatigueProfileProps>, prevState: Readonly<IModalFatigueProfileState>) {

        if(prevState.selectedMonth !== undefined &&
            prevState.selectedMonth !== null &&
            prevState.selectedYear !== undefined &&
            prevState.selectedYear !== null &&
            ((this.state.selectedMonth !== prevState?.selectedMonth) || (this.state.selectedYear !== prevState?.selectedYear))) {
            this.getProfilesForCurrentMonth()?.pipe(
                take(1),
            ).subscribe()
        }
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe()
    }

    render() {
        if (!this.props.modal) {
            return null;
        }
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="fatigue-profile-calendar-modal">
                <CustomModal.Header>
                    <div className={styles.fatigueProfileHeader}>
                        <h2 className={styles.fatigueProfileTitle}>
                            <Translation text={'profiles.modal.header'}/>
                            {this.state.calendar &&
                                <span>{` - ${this.state.calendar?.name}`}</span>
                            }
                        </h2>
                        <div className={styles.headerInputsWrapper}>
                        {/*    <Link className={`btn btn-fatigue-profile ${this.state.calendar ? '' :  'disabled'}`}*/}
                        {/*          to={`/profiles/settings/${this.state.calendar?.id}`}>*/}
                        {/*        <span aria-hidden="true" className="feather icon-settings"/>*/}
                        {/*        <Translation text={'profiles.modal.button.settings'}/>*/}
                        {/*    </Link>*/}
                            <div>
                                <Form config={monthSelectFormConfig(this.state.value?.monthSelected)}
                                      controlName={'monthSelector'}
                                      onValueStateChange={this.onValueStateChange}
                                      value={this.state.value}/>
                            </div>
                            <button className="btn btn-fatigue-profile"
                                    disabled={this.state.isLoading}
                                    onClick={this.switchView}>
                                <span aria-hidden="true" className="feather icon-pie-chart"/>
                                <Translation text={`profiles.modal.button.${this.state.view === CalendarViewType.CALENDAR ? CalendarViewType.CHARTS : CalendarViewType.CALENDAR}`}/>
                            </button>
                        </div>
                    </div>

                </CustomModal.Header>
                <CustomModal.Body>
                    <Loader type={LoaderType.Local} show={this.state.isLoading}/>
                    {this.state.view === CalendarViewType.CALENDAR && this.renderTable()}
                    {this.state.view === CalendarViewType.CHARTS && <FatigueProfilesChart
                        calendar={this.state.calendar}
                        profiles={this.state.profiles}
                        calendarEvents={this.state.calendarEvents}
                        numberOfDaysInSelectedMonth={this.daysInSelectedMonth}
                        selectedMonth={this.state.selectedMonth}
                        selectedYear={this.state.selectedYear}
                    />}
                </CustomModal.Body>
            </CustomModal>
        );
    }

    private get daysInSelectedMonth() {
        if(this.state.selectedYear && this.state.selectedMonth !== null) {
            return new Date(this.state.selectedYear, this.state.selectedMonth+1, 0).getDate()
        }
        return null
    }

    private switchView = () =>
        this.setState((state) => ({view: state.view === CalendarViewType.CALENDAR ? CalendarViewType.CHARTS : CalendarViewType.CALENDAR}))

    private renderTable() {
        if (this.state.selectedMonth !== null && this.state.selectedYear !== null && this.props.modal && this.props.modal.id && this.props.authToken) {
            return <FatigueProfilesTable authToken={this.props.authToken}
                                         calendar={this.state.calendar}
                                         calendarEvents={this.state.calendarEvents}
                                         profiles={this.state.profiles}
                                         getUpdatedProfiles={() => this.getProfilesForCurrentMonth()}
                                         getUpdatedCalendar={() => this.getCalendarData()}
                                         dateSelected={new Date(this.state.selectedYear, this.state.selectedMonth)}/>
        }
        return null;
    }

    private getCalendarData() {
        if(!this.props.modal?.id || !this.props.authToken) {
            return of('completed')
        }
        const endpointString = `calendars/${this.props.modal.id}`;
        return getBaseListAPI(this.props.authToken, endpointString).pipe(
            map((response: any) => {
                if (response) {
                    let calendarEvents: CalendarEvent[] = []
                    if(response.calendarEvents.length) {
                        response.calendarEvents.forEach((item: CalendarEvent) => calendarEvents.push(item));
                    }

                    this.setState({calendar: response, calendarEvents: calendarEvents})
                }
            }),
            catchError((err: any) => {
                this.setState({isLoading: false})
                this.alertManagerService.handleApiError(err);
                return of();
            })
        )
    }



    private getProfilesForCurrentMonth(): Observable<void | string> {
        if (this.state.selectedMonth === null ||
            this.state.selectedYear === null ||
            !this.props.authToken ||
            this.props.match.params.host === "profiles") {
            return of('compleated');
        }

        const startOfMonthDate = new Date(this.state.selectedYear, this.state.selectedMonth, 1).toISOString(),
            endOfMonthDate = new Date(this.state.selectedYear, this.state.selectedMonth + 1, 1).toISOString();

        this.setState({isLoading: true})
        return getUserFatigueProfilesAPI(this.props.authToken, startOfMonthDate, endOfMonthDate, this.props.modal?.userId).pipe(
            tap((response: any) => {
                if (response && response['hydra:member']) {
                    let profiles = response['hydra:member'];
                    this.setState({profiles: profiles, isLoading: false});
                }
            }),
            catchError((err: any) => {
                this.setState({isLoading: false})
                this.alertManagerService.handleApiError(err);
                return of('');
            })
        )
    }


    private onValueStateChange = (controlName: string, value: any) => {
        const formattedDate: any = moment(value.monthSelected).locale("pl").format('MMMM yyyy')
        const dateInString = formattedDate.split(' '),
            monthName = dateInString[0],
            monthNumber = moment.months().findIndex(element => element === monthName),
            yearSelected = +dateInString[1];
        this.setState({value: value, selectedYear: yearSelected, selectedMonth: monthNumber});
    };
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),

    }),
    {
        changeModal
    }
)(withRouter(ModalFatigueProfile));

