import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {catchError, mergeMap, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {PayloadAction} from "redux-starter-kit";
import {
    changeIsPasswordChanged,
    changePasswordLoading,
    changePasswordStart,
    IChangePasswordStart,
    setChangePasswordFailure
} from "../reducers/changePasswordSlice";
import {changePasswordAPI} from "../../api/changePasswordAPI";
import {addAlert} from "judo-app-common-web";
import {changeModal} from "../reducers/sagaSlice";

const changePasswordStartEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(changePasswordStart.type),
        switchMap((action: PayloadAction<IChangePasswordStart>): any => {
            return changePasswordAPI(
                action.payload.oldPassword,
                action.payload.newPassword,
                (state$ as any).value.auth.authToken
            ).pipe(
                mergeMap(() => {
                    return of(
                        changeIsPasswordChanged(true),
                        addAlert({message: 'account.changePassword.alert.success'}),
                        changePasswordLoading(false),
                        changeModal(null)
                    )
                }),
                catchError((error: any) => {
                        let errorMessage = error.response['hydra:description'];
                        if (errorMessage === 'Old password is invalid') {
                            errorMessage = 'account.changePassword.alert.oldPasswordInvalid'
                        }
                        return of(
                            setChangePasswordFailure(error.toString()),
                            addAlert({message: errorMessage})
                        )
                    }
                )
            )
        })
    );

const changePasswordEpic = combineEpics(
    changePasswordStartEpic
);

export default changePasswordEpic;
