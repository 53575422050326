import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {InputDefinition} from "../model/models";

export function addInputDefinitionAPI(
    authToken: string,
    payload: InputDefinition,
): Observable<any> {
    const newGroupDefinition = {
        type: payload.type,
        name: payload.name,
        enabled: payload.enabled,
        visualOptions: payload.visualOptions,
        parameters: payload.parameters,
        inputGroupDefinitionId: payload.inputGroupDefinitionId,
        itemOrder: payload.itemOrder,
        calendarViewGroupItems: payload.calendarViewGroupItems
    };
    return baseDataProviderAPI.post(
        "input_definitions",
        newGroupDefinition,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
