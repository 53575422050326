export default {
  items: [
    {
      id: 'Dashboard',
      title: 'Insurance Cover',
      type: 'group',
      icon: '',
      children: [
          {
              id: 'club-coach',
              title: 'menu.clubCoaches',
              type: 'item',
              url: '/coaches',
              icon: 'feather icon-award',
          },
          {
              id: 'contestants',
              title: 'menu.contestants',
              type: 'item',
              url: '/contestants',
              icon: 'feather icon-users',
          },
          {
              type: 'spacer',
              class: 'nav-spacer',
          },
          {
              id: 'account',
              title: 'menu.account',
              type: 'item',
              url: '/account',
              icon: 'feather icon-settings',
          },
          {
              id: 'logout',
              title: 'menu.logout',
              type: 'item',
              url: `/logout`,
              icon: 'feather icon-log-out',
          },
      ]
    },
  ]
};
