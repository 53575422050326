const en_US = {
    menu: {
        clubCoaches: 'Club Coaches',
        contestants: 'Contestants',
        specialists: 'Specialists',
        fatigueProfiles: 'Fatigue Profiles',
        account: 'Account',
        logout: 'Logout'
    },
    formControl: {
        error: {
            isRequired: "The field is required",
            minLength: "The input is too short",
            isEmail: "Provide a valid email address",
            areFieldsSame: "Passwords should be the same",

        }
    },
    button: {
        yes: 'Yes',
        no: 'No',
        delete: 'Delete',
        duplicate: 'Duplicate',
        remove: 'Remove',
        edit: 'Edit',
        create: 'Create',
        confirm: 'Confirm',
        cancel: 'Cancel',
        close: 'Close',
        save: 'Save',
        next: 'Next',
        prev: 'Prev',
        submit: 'Submit'
    },
    home: {
        header: 'Home'
    },
    logout: {
        modal: {
            title: 'Logout modal',
            question: 'Do you want to log out?'
        },
        alerts: {
            success: 'Logged out successfully',
        }
    },

    formList: {
        header: 'Forms',
        noSearchItems: 'There are no forms suited your filters setup.',
        label: {
            performance: `Lead's Performance`,
            name: 'Form name',
            createdAt: 'Created at',
            leadsNumber: 'Leads',
            status: 'Status'
        },
        action: {
            create: {
                label: 'Create Form',
                modal: {
                    title: 'New Form creation',
                    question: 'Are you sure you want create Form?',
                    form: {
                        newFormName: {
                            label: "New Form name",
                            placeholder: "Type new Form name"
                        }
                    }
                }
            },

            duplicate: {
                label: 'Duplicate Form',
                modal: {
                    title: 'Duplicate Form',
                    question: 'Are you sure you want duplicate Form',
                    form: {
                        duplicationFormName: {
                            label: "Duplicated Form name",
                            placeholder: "Type name of duplicated Form"
                        }
                    }
                }
            },
            delete: {
                label: 'Delete Form',
                modal: {
                    title: 'Delete Form',
                    question: 'Are you sure you want delete Form',
                    warning: 'That action will be irreversible.',
                }
            },
        }
    },
    leads: {
        header: 'Leads'
    },
    profiles: {
        editCellModal: {
            title: 'Edycja pola'
        },
        chart: {
            noDate: 'Results from selected Day',
            noDataToGraph: 'Select Date and parameters to generate chart',
            parameters: 'Aviable parameters',
            noMonthData: 'In selected month no results were provided'
        }
    },
    account: {
        header: 'Account',
        user: {
            header: 'User'
        },
        changePassword: {
            form: {
                login: {
                    label: 'Login',
                },
                oldPassword: {
                    label: 'Old password',
                    placeholder: 'Your current password'
                },
                newPassword: {
                    label: 'New password',
                    placeholder: 'Type new password'
                },
                repeatPassword: {
                    label: 'Confirm password',
                    placeholder: 'Repeat new password'
                }
            },
            button: 'Edit',
            alert: {
                success: 'Your password was successfully changed'
            }
        }
    },
    auth: {
        register: 'Register',
        login: {
            title: 'Login',
            form: {
                email: {
                    label: 'E-mail',
                    placeholder: 'example@xxx.com'
                },
                password: {
                    label: "Password",
                    placeholder: 'min 4 characters'
                }
            },
            button: "Login"
        },
        forgotPassword: 'Forgot password?',
        title: 'Title ?'

    },

    alert: {
        logout: {
            success: 'Logged out successfully',
        }
    },

    badge: {
        draft: 'Draft',
        active: 'Active',
        inactive: 'Inactive'
    },
    list: {
        filter: {
            byType: {
                title: 'Filter by type',
                options: {
                    all: 'All',
                    draft: 'Draft',
                    active: 'Active',
                    inactive: 'Inactive'
                }
            },
            byDate: 'Filter by date',

        },
        itemsPerPageLabel: 'Forms per page:',
        search: 'Search'
    },
    modal: {
        createProcessStepDefinition: {
            title: "Create new Form's Step",
            form: {
                newStepDefinitionName: {
                    label: 'Step Name',
                    placeholder: 'Type name of new step'
                }
            }
        },
        logout: {
            title: 'Logout',
            question: 'Do you want to log out?'
        }
    },
    general: {
        actionButtons: {
            options: 'Options',
            delete: 'Delete',
            cancel: 'Cancel',
            save: 'Save',
            close: 'Close',
        }
    },
    formValidation: {
        errors: {
            minLength: "The input is too short",
            isEmailValid: "Provide a valid email address",
            isRequired: "The field is required",
            isTrue: "Must be checked",
            isPasswordSame: "Passwords not match",
            isStartDateValid: "Date must be after current day",
            isStartDateGreaterOrEvenValid: "Date must be at or after current day",
            isEndDateValid: "Date of return must be after departure"
        }
    },
};

export default en_US;
