import { injectable, inject } from 'inversify';
import {fixInjectedProperties} from "../ioc";
import { ProcessVariable, VariableNameControlService} from "judo-app-common-web";
import cloneDeep from "lodash/cloneDeep";
import {CalendarInput} from "../model/models";

@injectable()
class VariablesFromGroupService {

    constructor() {
        fixInjectedProperties(this);
    }

    @inject('VariableNameControlService') private variableNameControlService: typeof VariableNameControlService;

    getGroupVariables(groupControls: CalendarInput[], id: string): typeof ProcessVariable[] {
        return groupControls.map( control => {
            if(!control?.inputDefinition?.id) {
                return null;
            }

            return ({
                control: control,
                path: `${id}.${control.inputDefinition.id}`
            })
        })
    }

    createNameWithPrefix(variables: typeof ProcessVariable[] ) {
        const clonedControl = cloneDeep(variables)
        clonedControl.forEach((vars,index) => vars.control.name = this.variableNameControlService.createNameWithPrefix(index, vars.control.name))
        return clonedControl;
    }
}

export default VariablesFromGroupService;
