import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Translation} from "judo-app-common-web";

export enum TooltipPosition {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right'
}

export interface ITooltip {
    text: string;
    value?: any;
    id?: string;
    position?: TooltipPosition;
    showDelay?: number;
    hideDelay?: number;
}


type Props = {
    tooltip: ITooltip
    children?: any,
};

const TooltipWrapper = (tooltipProps: Props) => {
    const position = tooltipProps.tooltip?.position ? tooltipProps.tooltip?.position : TooltipPosition.Bottom,
        show = tooltipProps.tooltip?.showDelay ? tooltipProps.tooltip?.showDelay : 100,
        hide = tooltipProps.tooltip?.hideDelay ? tooltipProps.tooltip?.hideDelay : 100;

    const renderTooltip = (props:any) => {
        return (
            <Tooltip {...props}>
                <Translation text={tooltipProps.tooltip.text}/>
                {tooltipProps.tooltip.value &&
                <span>{tooltipProps.tooltip.value}</span>}
            </Tooltip>
        )
    };

    return (
        <OverlayTrigger
            placement={position}
            delay={{ show: show, hide: hide }}
            rootClose
            overlay={renderTooltip}>
                {tooltipProps.children}
        </OverlayTrigger>
    );
};



export default TooltipWrapper;
