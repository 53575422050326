import React from "react";
import {arrayMove, IItemProps, List} from "react-movable";
import {Loader, LoaderType, Translation} from "judo-app-common-web";
import CalendarGroupListItem from "./CalendarGroupListItem";
import {CalendarGroup, ModalType} from "../../../../../model/models";
import AddItemMenu from "./AddItemMenu";
import {deepCloneObject} from "../../../../../utils/runtimeUtils";
import {DragDropGroup} from "../../../../../utils/fatigueProfilesUtils";
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {changeModal} from "../../../../../store/reducers/sagaSlice";

interface ICalendarGroupListProps {
    readonly enabledDataList: CalendarGroup[];
    readonly disabledDataList: CalendarGroup[];
    readonly isProcessing: boolean;
    readonly onCalendarDataChange: (groups: CalendarGroup[]) => void;
    readonly onGroupDisabled: (groupId: string, newEnabledState: false) => void;
    readonly onGroupEnabled: (groupId: string, newEnabledState: true) => void;
    readonly onGroupOrderChange: (groups: CalendarGroup[]) => void;
    readonly changeModal: typeof changeModal;
}

interface ICalendarGroupListState {
    disabledItems: DragDropGroup[];
    items: DragDropGroup[];
    addGroupMenuOpen: boolean;
}

class CalendarGroupList extends React.Component<ICalendarGroupListProps, ICalendarGroupListState> {
    constructor(props: ICalendarGroupListProps) {
        super(props);
        this.state = {
            disabledItems: [],
            items: [],
            addGroupMenuOpen: false
        }
    }

    private static getDataListFromGroup(calendarList: CalendarGroup[]) {
        let calendarListSorted: any = [];
        calendarList.forEach((datalistItem: CalendarGroup, i: number) => {
            const dataListIndex = datalistItem.itemOrder;
            calendarListSorted[dataListIndex] = datalistItem;
        });
        return calendarListSorted.map((datalistItem: CalendarGroup, i: number) => {
            return {id: i, calendarGroup: datalistItem}
        });
    }

    componentDidMount() {
        const dataItems: DragDropGroup[] = CalendarGroupList.getDataListFromGroup(this.props.enabledDataList),
            disabledDataItems: DragDropGroup[] = CalendarGroupList.getDataListFromGroup(this.props.disabledDataList);
        this.setState({items: dataItems, disabledItems: disabledDataItems})
    }

    componentDidUpdate(prevProps: Readonly<ICalendarGroupListProps>, prevState: Readonly<ICalendarGroupListState>) {
        if (prevProps.disabledDataList !== this.props.disabledDataList) {
            const disabledDataItems: DragDropGroup[] = CalendarGroupList.getDataListFromGroup(this.props.disabledDataList);
            this.setState({disabledItems: disabledDataItems})
        }
        if (prevProps.enabledDataList !== this.props.enabledDataList) {
            const dataItems: DragDropGroup[] = CalendarGroupList.getDataListFromGroup(this.props.enabledDataList);
            this.setState({items: dataItems})
        }
    }

    render() {
        if (!this.props.enabledDataList.length) {
            return null
        }
        return <section className="user-connection-list-wrapper ">
            <Loader show={this.props.isProcessing} type={LoaderType.Local}/>

            <div className="user-connection-list-header fatigue-header">
                <h3>
                    <Translation text={'userView.header.variableGroup'}/>
                    <span className="item-counter">
                            {` ( ${this.props.enabledDataList.length} )`}
                        </span>
                </h3>
                <AddItemMenu groupList={this.state.disabledItems} buttonLabel={'userView.button.addVariableGroup'}
                             onGroupEnabled={this.props.onGroupEnabled}
                             onVariableEnabled={() => null}/>
            </div>
            <div className="separator"/>
            {this.renderListItems()}
        </section>;
    }

    private renderListItems() {
        if (this.state.items) {
            let itemList = this.state.items;
            const removeFatigueGroup = (index: number | undefined) => {
                if (index !== undefined) {
                    const groupId = this.state.items[index].calendarGroup.id;
                    let updatedGroup: any[] = [];
                    this.state.items.forEach((group: any) => {
                        if (group !== null) {
                            updatedGroup.push(group);
                        }
                    })
                    updatedGroup.filter((item: any) => item.calendarGroup.id !== groupId);
                    this.setState({items: updatedGroup});
                    this.props.onGroupDisabled(groupId, false);
                }
            };

            const liStyles = (props: IItemProps, isDragged: boolean, isSelected: boolean) => {

                return {
                    ...props.style,
                    cursor: isDragged ? 'grabbing' : 'grab',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem 1rem',
                    listStyle: 'none',
                    fontSize: '1.6rem',
                    fontFamily: `'Nunito', sans-serif`,
                    borderRadius: '5px',
                    verticalAlign: 'middle',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF'
                }
            }
            return <List
                values={itemList}
                onChange={({oldIndex, newIndex}) => {
                    const updatedList = arrayMove(itemList, oldIndex, newIndex);
                    let noEmptyPlacesList: any = [];
                    updatedList.forEach((item: any) => {
                        noEmptyPlacesList.push(item);
                    });
                    this.listOrderChange(noEmptyPlacesList)
                }}
                renderList={({children, props}) => {
                    return <ul className="drag-and-drop-list calendar-groups-list" {...props}>{children}</ul>
                }}
                renderItem={({value, props, index, isDragged, isSelected}) => {
                    if (!value) return null;
                    return <li {...props} key={`calendar_item_${index}`} className="drag-and-drop-list-item fatigue-group-item"
                               style={liStyles(props, isDragged, isSelected)}>
                        <CalendarGroupListItem listItem={value.calendarGroup}
                                               key={value.id.toString()}
                                               isDraggable={true}
                                               onDeleteItem={() => removeFatigueGroup(index)}
                                               onEditedItemSet={(item: any) => this.props.changeModal({
                                                   type: ModalType.CALENDAR_GROUP_VARIABLES,
                                                   id: item.id
                                               })}/>
                    </li>
                }}
            />
        }
        return null;
    }

    private listOrderChange(groups: DragDropGroup[]) {
        const updatedEnabledCalendarGroups = groups.map((groupItem: DragDropGroup, index: number) => {
            let updatedGroup = deepCloneObject(groupItem.calendarGroup);
            updatedGroup.itemOrder = index;
            return updatedGroup;
        });
        this.props.onGroupOrderChange(updatedEnabledCalendarGroups);
    }

    private updateGroup = (group: CalendarGroup) => {
        const updatedList = deepCloneObject(this.state.items);
        let updatedListArray: CalendarGroup[] = [];
        updatedList.forEach((listItem: DragDropGroup) => {
            if (listItem.calendarGroup.id === group.id) {
                return updatedListArray.push(group);
            }
            return updatedListArray.push(listItem.calendarGroup);
        })
        this.props.onCalendarDataChange(updatedListArray);
    }
}

export default connect(
    (state: RootState) => ({}),
    {
        changeModal,
    }
)(CalendarGroupList);
