import React from 'react';
import {connect} from 'react-redux';
import {CustomCard, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import UserInfoBox, {UserInfoType} from "../../Shared/UserInfoBox";
import {ModalType, Role, UserType} from '../../../model/models';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {userRoleSelector} from "../../../store/selectors/userSelector";
import {RootState} from "../../../store/reducers";
import Pagination, {IPaginationData} from "../../Shared/Pagination";

interface ICoachListProps {
    onViewChange: (id: string | null, profileType?: UserType) => void;
    onIsProcessingChange: () => void;
    onSpecificPageLoad: (pageNumber: number) => void;
    paginationData: IPaginationData | null;
    list: any[] | null;
    userRole: Role;
    isLoading: boolean;
    isArchiveMode?: boolean;
    changeModal: typeof changeModal;
}

interface ICoachListState {
    coachList: any[] | null; // toDo interface
}

class CoachList extends React.Component<ICoachListProps, ICoachListState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: ICoachListProps) {
        super(props);
        this.state = {
            coachList: [],
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setState({coachList: this.props.list})
    }

    componentDidUpdate(
        prevProps: Readonly<ICoachListProps>,
        prevState: Readonly<ICoachListState>,
        snapshot?: any
    ): void {
        if (this.props.list !== prevProps.list) {
            this.setState({coachList: this.props.list});
        }
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Body>
                    {this.renderButton()}
                    {this.renderTable()}
                </CustomCard.Body>
                <CustomCard.Footer>
                    <Pagination paginationData={this.props.paginationData} onSpecificPageLoad={this.props.onSpecificPageLoad}/>
                </CustomCard.Footer>
            </CustomCard>
        )
    }

    private renderButton() {
        if ((this.props.userRole !== Role.Admin && this.props.userRole !== Role.HeadCoach) || this.props.isArchiveMode) return;

        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-theme btn-small"
                        onClick={this.openCreateCoachModal}>
                    <span aria-hidden="true" className="feather icon-plus"/>
                    <Translation text={'userList.table.button.add.coach'}/>
                </button>
            </div>
        )
    }


    private renderTable() {
        if (!this.state.coachList) {
            return;
        }
        const noCoachesMessage = this.props.isArchiveMode ?
            <Translation text={'userList.table.noData.noArchivedCoaches'}/> :
            <Translation text={'userList.table.noData.noCoaches'}/>;

        if (!this.state.coachList.length) {
            return (
                <div className="d-flex justify-content-center display-4 my-5">
                    <span>
                        {noCoachesMessage}
                    </span>
                </div>
            );
        }
        const rows: any = [];

        this.state.coachList.forEach((coach: any) => {
            return rows.push(
                <tr key={coach.id}>
                    <td><UserInfoBox user={coach} infoCellType={UserInfoType.Table}/></td>
                    <td>{coach.countContestants}</td>
                    <td>{coach.countSpecialists}</td>
                    <td className="text-right">
                        {this.renderActionButtons(coach.id)}
                    </td>
                </tr>
            )
        });

        return (
            (
                <div className="mt-20">
                    <table className="data-table">
                        <thead>
                        <tr>
                            <th><Translation text={'userList.table.header.coaches'}/></th>
                            <th><Translation text={'userList.table.header.contestants'}/></th>
                            <th><Translation text={'userList.table.header.specialists'}/></th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            )
        )
    }

    private renderActionButtons(coachId: string) {
        return <React.Fragment>
            <button className="btn btn-theme btn-small ml-2" onClick={() => this.props.onViewChange(coachId, this.props.isArchiveMode ? UserType.COACH : undefined)}>
                <span className="feather icon-edit" aria-hidden="true"/>
                <Translation text={'userList.table.button.details'}/>
            </button>
        </React.Fragment>
    }

    private openCreateCoachModal = () => {
        this.props.changeModal({
            type: ModalType.CREATE,
            role: UserType.COACH,
        })
    }
}

export default connect(
    (state: RootState) => ({
        userRole: userRoleSelector(state)
    }),
    {
        changeModal
    }
)(CoachList);
