import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function getConnectedAccountsAPI(
    authToken: string,
    endpoint: string,
    userId: string,
    pageNumber?: number,
    itemsPerPageValue?: number,
): Observable<any> {
    const page = pageNumber ? `&page=${pageNumber}` : '';
    const itemsPerPage = itemsPerPageValue ? `?itemsPerPage=${itemsPerPageValue}` : '?itemsPerPage=10';
    return baseDataProviderAPI.get(
        `${endpoint}/${userId}/accounts${itemsPerPage}${page}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
