import React from 'react';
import {changePasswordFormConfig} from "./changePasswordFormConfig";
import {CustomCard, Form, IFormConfig, Translation} from "judo-app-common-web";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {resetPasswordAPI} from "../../../api/resetPassword";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";

type Props = {}
type State = {
    password: string;
    newPassword: string;
    isLoading: boolean;
    formConfig: typeof IFormConfig
};

class ChangePassword extends React.Component<Props, State> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: Props) {
        super(props);

        this.state = {
            password: '',
            newPassword: '',
            isLoading: false,
            formConfig: changePasswordFormConfig
        }
        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return <>
            <CustomCard showLocalLoader={this.state.isLoading} type={"auth"}>
                <CustomCard.Header>
                    <h3><Translation text={'auth.setNewPassword.title'}/></h3>
                </CustomCard.Header>
                <CustomCard.Body>
                    <Form
                        config={this.state.formConfig}
                        submitForm={this.confirmResetPassword}
                        controlName={"changePasswordForm"}
                        value={{}}
                    />
                </CustomCard.Body>
            </CustomCard>
        </>
    }

    private confirmResetPassword = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }
        const url = window.location.href;
        const token = url.substring(url.lastIndexOf("=") + 1);
        this.setState({isLoading: true})
        this.subscriptions.push(
            resetPasswordAPI(value.password, token).pipe(
                tap(() => {
                    window.location.href = window.location.origin + "/auth/login";
                    this.alertManagerService.addAlert('alert.resetPassword.success');
                    this.setState({isLoading: false})
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error);
                    this.setState({isLoading: false})
                    return of();
                })
            ).subscribe());
    };
}

export default ChangePassword;
