import {
    BasicFormControlType,
    FormButtonType,
    IFormConfig,
    IFormGroupConfig,
    MultiSelectType
} from "judo-app-common-web";
import store from "../../../../store";
import {changeModal} from "../../../../store/reducers/sagaSlice";


export const assignSpecialistFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'create-group-form mb-3',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls:
        [
            {
                key: 'specialistGroup',
                controlType: 'group',
                class: 'd-flex flex-row mt-3',
                controls:
                    {
                        specialists: {
                            hostClass: 'form-select-input ml-3',
                            controlType: 'control',
                            defaultValue: null,
                            placeholder: 'modal.assignSpecialist.form.specialists.placeholder',
                            formControlType: BasicFormControlType.AUTOCOMPLETE,
                            multiselectType: MultiSelectType.SINGLE,
                            label: 'modal.assignSpecialist.form.specialists.label',
                            validationRules: [],
                            multiselectOptions: [],
                            inputDataMapper: (data: any) => {
                                if (!data) {
                                    return [];
                                }
                                const fullName = `${data.firstName} ${data.lastName}`;
                                return {
                                    value: data.id,
                                    label: fullName,
                                };
                            },
                            outputDataMapper: (data: any) => {
                                if (Array.isArray(data)) {
                                    return (data.length > 0) ? data[0].value : null;
                                }
                                if (data && typeof data === 'object') {
                                    return data.value;
                                }

                                return data;
                            },
                        }
                    }
            }
        ]
};
export const buttonGroup: typeof IFormGroupConfig = {

    key: 'buttons',
    controlType: 'group',
    class: 'button-group-wrapper mt-5',
    controls: {
        cancelButton: {
            controlType: 'control',
            formControlType: BasicFormControlType.BUTTON,
            buttonType: FormButtonType.BUTTON,
            hostClass: 'button-container',
            defaultContainerStyles: 'd-flex justify-content-end mx-3',
            defaultInputStyles: 'btn btn-theme-outline',
            defaultValue: null,
            buttonDisabled: false,
            btnText: `button.cancel`,
            isLabelHidden: true,
            customClickHandler: () => store.dispatch(changeModal(null))
        },
        submitButton: {
            controlType: 'control',
            formControlType: BasicFormControlType.BUTTON,
            buttonType: FormButtonType.SUBMIT,
            hostClass: 'button-container',
            defaultContainerStyles: 'd-flex justify-content-end',
            defaultInputStyles: 'btn btn-theme',
            defaultValue: null,
            btnText: `button.assign`,
            isLabelHidden: true,
        }
    }
};

