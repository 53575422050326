import { createSlice } from "redux-starter-kit";



export interface IUserState {
  user: any;
  isLoading: boolean;
  error: string | null;
}


export interface ISetUserState {
  readonly payload: {
    user: any,
  }
}

export interface IUpdateUserState {
  readonly payload: {
    user: any
  }
}

export interface ISetUserStateFailure {
  readonly payload: {
    error: string;
  }
}

export interface IChangeUserStateLoading {
  readonly payload: {
    isLoading: boolean;
  }
}

const initialState: IUserState = {
  user: null,
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  slice: "user",
  initialState: initialState,
  reducers: {
    changeUser: {
      reducer: (state: IUserState, action: ISetUserState) => {
        return {
          user: action.payload.user,
          isLoading: state.isLoading,
          error: state.error
        };
      },
      prepare(user: any) {
        return {
          payload: {
            user: user,
          }
        };
      }
    },
    setUserStateFailure: {
      reducer: (state: IUserState, action: ISetUserStateFailure) => {
        return {
          user: state.user,
          isLoading: state.isLoading,
          error: action.payload.error
        };
      },
      prepare(error: string) {
        return {
          payload: {
            error: error
          }
        };
      }
    },
    changeUserStateLoading: {
      reducer: (state: IUserState, action: IChangeUserStateLoading) => {
        return {
          user: state.user,
          isLoading: action.payload.isLoading,
          error: state.error
        };
      },
      prepare(isLoading: boolean) {
        return {
          payload: {
            isLoading: isLoading
          }
        };
      }
    },
  }
});

export const {
  changeUser,
  setUserStateFailure,
  changeUserStateLoading
} = userSlice.actions;

export default userSlice.reducer;
