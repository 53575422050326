import React from "react";

interface IOutsideClickerProps {
    onOutsideClick: (e: any) => void;
}

class OutsideClicker extends React.Component<IOutsideClickerProps> {
    clickCaptured = false;

    componentDidMount() {
        document.addEventListener("mousedown", this.handleBodyClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleBodyClick);
    }

    handleMouseDown = () => {
        this.clickCaptured = true;
    };

    handleBodyClick = (e: any) => {
        if (!this.clickCaptured) {
            this.props.onOutsideClick && this.props.onOutsideClick(e);
        }
        this.clickCaptured = false;
    };

    render() {
        return <div onMouseDown={this.handleMouseDown}>{this.props.children}</div>;
    }
}

export default OutsideClicker;
