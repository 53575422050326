import {Observable} from "redux";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export interface IInputResultPayload {
    value: {
        value: string
    },
    inputId?: string | null,
    inputDefinitionId?: string | null,
    id?: string | null
}

export function createInputResultAPI(
    authToken: string,
    payload: IInputResultPayload
): Observable<any> {
    return baseDataProviderAPI.post(
        "input_results",
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
