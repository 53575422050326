import {CSSProperties} from "react";

export function transformObjectToCSSProperties(obj: any = {}) {

    const keyValues = Object.keys(obj).map(key => {
        let cssProps: CSSProperties = {};
        cssProps = {[key]: obj[key]}
        return cssProps;
    });
    return Object.assign({}, ...keyValues);
}

export function getRandomColor() {
    const color = "hsl(" + Math.random() * 360 + ", 100%, 80%)";
    return color;
}

