import React from 'react';
import {authTokenSelector, CustomModal, Loader, LoaderType, Translation} from 'judo-app-common-web';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {ModalConfig} from "../../../../model/models";
import {changeModal, changeShouldListUpdate} from '../../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {modalSelector} from "../../../../store/selectors/sagaSelectors";
import {of, Subscription} from "rxjs";
import {deepCloneObject} from "../../../../utils/runtimeUtils";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {catchError, tap} from "rxjs/operators";
import {removeSpecialistTypeAPI} from "../../../../api/removeSpecialistType";


interface IModalRemoveSpecialistTypeProps extends RouteComponentProps {
    readonly authToken: string;
    readonly onClose: () => void;
    readonly modal: ModalConfig | null;
    readonly changeModal: typeof changeModal;
    readonly changeShouldListUpdate: typeof changeShouldListUpdate;
}

interface IModalRemoveSpecialistTypeState {
    profile: any;
    isProcessing: boolean;
}

class ModalRemoveSpecialistType extends React.Component<IModalRemoveSpecialistTypeProps, IModalRemoveSpecialistTypeState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IModalRemoveSpecialistTypeProps) {
        super(props);
        this.state = {
            profile: null,
            isProcessing: true
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.modal && this.props.modal.data) {
            let updatedProfile = deepCloneObject(this.props.modal.data);
            this.setState({
                profile: updatedProfile, isProcessing: false
            })
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.profile || !this.state.profile.id || !this.props.modal) {
            return null;
        }
        const profileName = this.state.profile.name;
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class='assign-profile-modal'>
                <Loader show={this.state.isProcessing} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={`modal.removeSpecialistType.header`}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <p>
                        <span>
                            <Translation text={'modal.removeSpecialistType.question'}/>
                        </span>
                        <span className="outlined-box">{profileName}</span>
                        <span>?</span>
                    </p>
                </CustomModal.Body>
                <CustomModal.Footer>
                    <button className="btn btn-theme btn-small ml-3" onClick={() => this.props.changeModal(null)}>
                        <span>
                            <Translation text={'button.cancel'}/>
                        </span>
                    </button>
                    <button className="btn btn-danger btn-small ml-3" onClick={this.removeSpecialistType}>
                        <span>
                            <Translation text={'button.remove'}/>
                        </span>
                    </button>
                </CustomModal.Footer>
            </CustomModal>
        );
    }


    private removeSpecialistType = () => {
        if (!this.state.profile || !this.props.modal?.id || !this.props.authToken) {
            return null;
        }

        this.setState({isProcessing: true});
        return this.subscriptions.push(removeSpecialistTypeAPI(this.props.authToken, this.props.modal.id).pipe(
            tap(() => {
                this.alertManagerService.addAlert('alert.specialistType.removalSuccess');
                this.setState({isProcessing: false});
                this.props.changeShouldListUpdate(true);
                this.props.changeModal(null);
            }),
            catchError((error: any) => {
                this.alertManagerService.handleApiError(error.response);
                this.setState({isProcessing: false});
                return of();
            }),
        ).subscribe());
    }
}

export default connect(
    (state: RootState) => ({
        modal: modalSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeModal,
        changeShouldListUpdate,
    }
)(withRouter(ModalRemoveSpecialistType));
