import { Observable } from "rxjs";
import {RestQueryParams} from "judo-app-common-web";
import {judoRegistrationAPI} from "judo-app-common-web";

export function changePasswordAPI(
    oldPassword: string,
    newPassword: string,
    authToken: string
): Observable<any> {
    return judoRegistrationAPI.post(
        "change_password",
        {
            oldPassword: oldPassword,
            newPassword: newPassword
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
