import {BasicFormControlType, FormButtonType, IFormConfig, InputType, ValidationRules} from "judo-app-common-web";

export const updateGroupFormConfig: typeof IFormConfig = (name: string, disabled: boolean) => ({
    controlType: 'form',
    class: 'update-group-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'profile_definition_name',
            controlType: 'group',
            class: 'd-flex flex-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: name,
                    formControlType: BasicFormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 1}}
                    ],
                    placeholder: 'profileSettingsModal.form.addGroupForm.name.placeholder',
                    isLabelHidden: false,
                    label: 'profileSettingsModal.form.addGroupForm.name.label',
                    type: InputType.INPUT_TEXT,
                    hostClass: ''
                }
            }
        },
        {
            key: 'buttons',
            controlType: 'group',
            class: 'button-group-wrapper ',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: BasicFormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'button-container',
                    defaultContainerStyles: 'd-flex justify-content-end',
                    defaultInputStyles: 'btn btn-theme btn-small',
                    defaultValue: null,
                    buttonDisabled: disabled,
                    btnText: `button.change`,
                    isLabelHidden: true,
                }
            }}
    ]
});

