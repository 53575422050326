import React from 'react';
import {CustomCard, IBasicFormControlConfig, Translation} from 'judo-app-common-web';
import {CalendarGroup, CurrentFatigueCalendarView,} from "../../../../model/models";
import CalendarSettingsGroupItem from '../CalendarSettingsGroupItem';
import {createGroupFormConfig} from "../CreateGroupList/createGroupFormConfig";

interface ISettingsGroupListProps {
    calendarData: CurrentFatigueCalendarView;
    onGroupEditSelected: (group: CalendarGroup) => void;
    onGroupCreationSelected: () => void;
    onGroupArchive: (groupId: string) => void;
}

interface ISettingsGroupListState {
    fatigueCalendarSettingsView?: CurrentFatigueCalendarView;
    isProcessing: boolean;
    isAddGroupMenuOpen: boolean;
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    groupIdToArchive: string | null;
}

class SettingsGroupList extends React.Component<ISettingsGroupListProps, ISettingsGroupListState> {

    constructor(props: ISettingsGroupListProps) {
        super(props);
        this.state = {
            fatigueCalendarSettingsView: undefined,
            isProcessing: false,
            isAddGroupMenuOpen: false,
            formConfig: createGroupFormConfig,
            groupIdToArchive: null
        }
    }

    componentDidMount() {
        if (this.props.calendarData) this.setState({fatigueCalendarSettingsView: this.props.calendarData});
    }

    componentDidUpdate(prevProps: Readonly<ISettingsGroupListProps>, prevState: Readonly<ISettingsGroupListState>) {
        if (this.props.calendarData !== prevProps.calendarData) {
            this.setState({fatigueCalendarSettingsView: this.props.calendarData});
        }
    }

    render() {
        if (!this.state.fatigueCalendarSettingsView) {
            return null;
        }
        return <CustomCard showLocalLoader={false}>
            <div className="user-connection-list-wrapper">
                {this.renderSettingsGroupListHeader()}
                {this.renderSettingsGroupListBody(this.state.fatigueCalendarSettingsView)}

            </div>
        </CustomCard>
    }

    private renderSettingsGroupListHeader() {
        return <CustomCard.Header>
            <h3 className="user-connection-list-header fatigue-header">
                    <span>
                        <Translation text={'profileSettingsModal.groupList.header'}/>
                    </span>
                <button className="btn btn-theme btn-small ml-3"
                        onClick={() => this.props.onGroupCreationSelected()}>
                    <span className="feather icon-plus" aria-hidden="true"/>
                    <span><Translation text={'profileSettingsModal.button.add.variableGroup'}/></span>
                </button>
            </h3>
        </CustomCard.Header>
    }

    private renderSettingsGroupListBody(profileView: CurrentFatigueCalendarView) {

        return <CustomCard.Body>
            <div className="separator"/>
            <ul className="user-connection-list fatigue-profile">
                {profileView.calendarViewGroups.map((group: CalendarGroup, index: number) => <li
                    key={`${group.id + index}`}
                    className="user-connection-list-item  no-drag">
                    <CalendarSettingsGroupItem listItem={group}
                                               key={group.id.toString()}
                                               onGroupEditSelected={this.props.onGroupEditSelected}
                                               onGroupChange={(group: CalendarGroup) => null}
                                               onArchiveItem={() => this.props.onGroupArchive(group.id)}/>
                </li>)
                }
                {!profileView.calendarViewGroups.length && <div className="no-data">
                    <Translation text={'profileSettingsModal.groupList.noData'}/>
                </div>}
            </ul>
        </CustomCard.Body>
    }
}

export default SettingsGroupList;
