import { Observable } from "rxjs";
import {RestQueryParams} from "judo-app-common-web";
import {baseDataProviderAPI} from './provider/baseDataProviderAPI';

export function restoreProfileAPI(
    authToken: string,
    endpoint: string,
    id: string
): Observable<any> {
    return baseDataProviderAPI.post(
        `${endpoint}/restore`,
        {resourceIdToRestore: id},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
