import React from 'react';
import {Translation} from 'judo-app-common-web';
import {CalendarInputOption} from "../../../../../model/models";
import SelectOptionsListItem from './SelectOptionsListItem';
import {arrayMove, IItemProps, List} from "react-movable";


export interface DragDropOption {
    id: number;
    option: CalendarInputOption;
}

interface ISelectOptionsListProps {
    onOptionsUpdate: (valuesVisualOptions: CalendarInputOption[]) => void;
    onCurrentlyEditedOption: (currentlyEditedOption: CalendarInputOption, index: number) => void;
    onCreateOption: () => void;
    onDeleteOption: (itemIndex: number) => void;
    options: CalendarInputOption[];
}

interface ISelectOptionsListState {
    newCalendarOptions: CalendarInputOption[];
    items: DragDropOption[];
    isProcessing: boolean;
    value: any;
}

class SelectOptionsList extends React.Component<ISelectOptionsListProps, ISelectOptionsListState> {
    constructor(props: ISelectOptionsListProps) {
        super(props);

        this.state = {
            newCalendarOptions: [],
            items: [],
            isProcessing: false,
            value: {},
        }
    }

    private static getDataListFromGroup(optionList: CalendarInputOption[]): DragDropOption[] {
        return optionList.map((datalistItem: CalendarInputOption, i: number) => {
            return {id: i, option: datalistItem}
        });
    }

    componentDidMount() {
        if (this.props.options) {
            const dataItems: DragDropOption[] = SelectOptionsList.getDataListFromGroup(this.props.options);
            this.setState({items: dataItems})
        }
    }

    componentDidUpdate(prevProps: Readonly<ISelectOptionsListProps>, prevState: Readonly<ISelectOptionsListState>) {
        if (this.props.options !== prevProps.options) {
            const dataItems: DragDropOption[] = SelectOptionsList.getDataListFromGroup(this.props.options);
            this.setState({items: dataItems})
        }
    }

    render() {
        return <div className="user-connection-list-wrapper">
            {this.renderOptionsList(this.state.items)}
        </div>
    }

    private renderOptionsList(group: DragDropOption[]) {
        if (group) {
            return <React.Fragment>
                <h4 className="user-connection-list-header fatigue-header">
                    <span>
                        <Translation text={'profileSettingsModal.optionList.header'}/>
                    </span>
                    <button className="btn btn-theme btn-small ml-3"
                            onClick={() => this.createOption()}>
                        <span className="feather icon-plus" aria-hidden="true"/>
                        <span><Translation text={'profileSettingsModal.button.add.variableOption'}/></span>
                    </button>
                </h4>
                <div className="separator"/>
                {this.renderListItems()}
            </React.Fragment>
        }
    }

    private renderListItems() {
        if (this.state.items) {
            const itemList = this.state.items;
            // const removeOption = (index: number | undefined) => {
            //     if (index !== undefined) {
                    // const groupId = this.state.items[index].option.id;
                    // this.props.onGroupDisabled(groupId, false);
            //     }
            // };

            const liStyles = (props: IItemProps, isDragged: boolean, isSelected: boolean) => {

                return {
                    ...props.style,
                    cursor: isDragged ? 'grabbing' : 'grab',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem 1rem',
                    listStyle: 'none',
                    fontSize: '1.6rem',
                    fontFamily: `'Nunito', sans-serif`,
                    borderRadius: '5px',
                    verticalAlign: 'middle',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF'
                }
            }
            if (!this.state.items.length) {
                return <div className="no-data">
                    <Translation text={'profileSettingsModal.optionList.noData'}/>
                </div>
            }
            return <List
                lockVertically
                values={itemList}
                onChange={({oldIndex, newIndex}) => {
                    const updatedList = arrayMove(itemList, oldIndex, newIndex);
                    this.listOrderChange(updatedList)
                }
                }
                renderList={({children, props}) => {
                    return <ul className="drag-and-drop-list" {...props}>{children}</ul>
                }}
                renderItem={({value, props, index, isDragged, isSelected}) => {
                    return <li {...props} className="drag-and-drop-list-item fatigue-group-item"
                               style={liStyles(props, isDragged, isSelected)}>
                        <SelectOptionsListItem listItem={value.option}
                                               listItemIndex={value.id}
                                               key={value.option?.label}
                                               onDeleteItem={this.deleteOption}
                                               onEditItem={this.editOption}
                                               onCreateItem={this.createOption}/>
                    </li>
                }}
            />
        }
        return null;
    }

    private listOrderChange(items: DragDropOption[]) {
        const updatedEnabledOptions = items.map((groupItem: DragDropOption) => groupItem.option);
        this.props.onOptionsUpdate(updatedEnabledOptions);
    }

    private deleteOption = (index: number) => {
        this.props.onDeleteOption(index);
    }

    private editOption = (editedOption: CalendarInputOption, index: number) => {
        this.props.onCurrentlyEditedOption(editedOption, index);
    }

    private createOption = () => {
        this.props.onCreateOption();
    }

}

export default SelectOptionsList;
