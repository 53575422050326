import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function createClubAPI(
    authToken: string,
    clubName: string,
    coaches?: string[],
    logoMediaObjectId?: string,
    disabled?: boolean
): Observable<any> {

    let payload: {[key: string]: any} = {
        name: clubName,
        coaches: []
    };

    if (coaches) {
        payload['coaches'] = coaches;
    }

    if (logoMediaObjectId) {
        payload['logoMediaObjectId'] = logoMediaObjectId;
    }

    if (logoMediaObjectId) {
        payload['logoMediaObjectId'] = logoMediaObjectId;
    }

    if (disabled) {
        payload['disabled'] = disabled;
    }

    return baseDataProviderAPI.post(
        'clubs',
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
