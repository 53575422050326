import React from 'react';
import styles from './styles.module.scss';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {fontSizeSelector, isProcessingSelector} from '../../../../store/selectors/sagaSelectors';

export enum LayoutState {
    List = 'List',
    View = 'View',
}

export interface IAnimationHostProps {
    fontSize: number;
    isProcessing: boolean;
    list: any,
    view: any,
    layoutState: LayoutState,
}

export interface IAnimationHostState {
    listWidth: string;
    viewWidth: string;
    viewPosition: string | undefined;
}

const VIEW_WIDTH = 55;

class AnimationHost extends React.Component<IAnimationHostProps, IAnimationHostState> {

    readonly container: any; // toDo

    constructor(props: IAnimationHostProps) {
        super(props);

        this.container = React.createRef()

        this.state = {
            listWidth: '100%',
            viewWidth: '0%',
            viewPosition: undefined
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWidth);
    }

    componentDidUpdate(prevProps: Readonly<IAnimationHostProps>) {
        if (prevProps.layoutState !== this.props.layoutState ||
            prevProps.fontSize !== this.props.fontSize) {
            this.updateWidth();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWidth);
    }

    render() {
        const style = this.props.layoutState === LayoutState.View ? styles.transform : '',
            viewStyleOpened = this.props.layoutState === LayoutState.View ? {right: this.state.viewPosition} : undefined

        // ${this.container?.current?.offsetWidth < 1530 ? styles['columnView'] : ''}
        return (
            <div ref={this.container}
                 className={`${styles.animationHost}`}>
                <div className={styles.listContainer} style={{width: this.state.listWidth}}>
                    {this.props.list}
                </div>
                <div className={`${styles.viewContainer} ${style} ${viewStyleOpened ? '' :  styles.viewStyleClosed}`} style={viewStyleOpened}>
                    <div className={styles.viewWrapper} style={{width: this.state.viewWidth}}>
                        {this.props.view}
                    </div>
                </div>
            </div>
        );
    }

    private updateWidth = () => {
        let listWidth: any = '100%';
        let viewWidth = this.state.viewWidth;
        let viewPosition = this.state.viewWidth;

        if (this.props.layoutState === LayoutState.View && this.container?.current?.offsetWidth) {
            const viewWidthNo = this.props.fontSize <= 1.6 ? VIEW_WIDTH : VIEW_WIDTH + (this.props.fontSize - 1.6) * 10;

            listWidth = ((this.container.current.offsetWidth) / 10) - viewWidthNo + 'rem';
            viewWidth = viewWidthNo + 'rem';
            viewPosition = (viewWidthNo + 4) + 'rem';
        }

        this.setState({
            listWidth: listWidth,
            viewWidth: viewWidth,
            viewPosition: viewPosition,
        });
    };

}

export default connect(
    (state: RootState) => ({
        fontSize: fontSizeSelector(state),
        isProcessing: isProcessingSelector(state),
    }),
    {}
)(AnimationHost);
