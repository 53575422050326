import React from 'react';
import {authTokenSelector, CustomCard, CustomModal, Translation} from 'judo-app-common-web';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {Subscription} from 'rxjs';
import UserInfoBox, {UserInfoType} from "../../Shared/UserInfoBox";
import UserConnectionList from "../../Shared/UserConnectionsList";
import {Role, UserType} from '../../../model/models';
import {changeModal} from '../../../store/reducers/sagaSlice';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {isUserProfileLoadingSelector} from "../../../store/selectors/sagaSelectors";
import EditProfileButton from "../../Shared/EditProfileButton";
import ArchiveProfileButton from "../../Shared/ArchiveProfileButton";
import {userRoleSelector} from "../../../store/selectors/userSelector";

interface ICoachViewProps {
    readonly onViewChange: (id: string | null) => void;
    readonly itemView?: any;
    readonly isModalView: boolean;
    readonly authToken: string;
    readonly isArchiveMode?: boolean;
    readonly userRole: Role;
    readonly isUserProfileLoading: boolean;
    readonly changeModal: typeof changeModal;
}

interface ICoachViewState {
    coachView: any;
}

class CoachView extends React.Component<ICoachViewProps, ICoachViewState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: ICoachViewProps) {
        super(props);
        this.state = {
            coachView: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (!this.props.itemView || !this.props.itemView.id) return;
        this.setState({coachView: this.props.itemView});
    }


    componentDidUpdate(prevProps: Readonly<ICoachViewProps>, prevState: Readonly<ICoachViewState>) {
        if (this.props.itemView !== prevProps.itemView) {
            this.setState({coachView: this.props.itemView})
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.coachView) {
            return (<CustomCard showLocalLoader={true} type={"view-upload"}/>);
        }

        return this.renderCoachView()
    }


    private renderCoachView() {
        let header: any = null;
        if (this.props.isModalView) {
            header = (
                <CustomModal.Header>
                    <h2><Translation text={'userView.header.coach'}/></h2>
                </CustomModal.Header>
            );
        } else {
            header = (
                <CustomCard.Header>
                    <div className="user-view-header">
                        <h2><Translation text={'userView.header.coach'}/></h2>
                        <button className="btn-close" onClick={() => this.props.onViewChange(null)}>
                            <span className="feather icon-x" aria-hidden="true"/>
                        </button>
                    </div>
                </CustomCard.Header>
            );
        }
        if (this.props.isModalView) {
            return <React.Fragment>
                {header}
                <CustomModal.Body>
                    {this.renderBody()}
                    {this.renderConnectedUserLists()}
                </CustomModal.Body>
            </React.Fragment>
        }
        return (
            <CustomCard showLocalLoader={this.props.isUserProfileLoading}>
                {header}
                <CustomCard.Body>
                    {this.renderBody()}
                    {this.renderActionButtons()}
                    {this.renderConnectedUserLists()}
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderBody() {
        return (
            <React.Fragment>
                <div className="separator"/>
                <UserInfoBox user={this.state.coachView} infoCellType={UserInfoType.Extended}/>
                <div className="separator"/>
            </React.Fragment>
        );
    }

    private renderActionButtons() {
        if (this.props.userRole === Role.Admin ||
            this.props.userRole === Role.HeadCoach) {
            return <React.Fragment>
                <div className="action-buttons-wrapper">
                    {!this.props.isArchiveMode &&
                    <EditProfileButton profileId={this.state.coachView.id}/>}
                    <ArchiveProfileButton isArchiveMode={this.props.isArchiveMode} profile={this.state.coachView}/>
                </div>
                <div className="separator"/>
            </React.Fragment>
        }
        return null;
    }

    private renderConnectedUserLists() {
        if (this.props.isArchiveMode) {
            return null;
        }
        return <div className='d-flex flex-column'>
            <UserConnectionList dataList={this.state.coachView.profileDefinitions}
                                listType={UserType.FATIGUE_PROFILE}
                                account={this.state.coachView}
                                isModalView={this.props.isModalView}/>
            <div className="separator"/>
            <UserConnectionList dataList={this.state.coachView.contestants}
                                listType={UserType.CONTESTANT}
                                account={this.state.coachView}
                                isModalView={this.props.isModalView}/>

            <div className="separator"/>
            <UserConnectionList dataList={this.state.coachView.specialists}
                                listType={UserType.SPECIALIST}
                                account={this.state.coachView}
                                isModalView={this.props.isModalView}/>
        </div>
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isUserProfileLoading: isUserProfileLoadingSelector(state),
        userRole: userRoleSelector(state)
    }),
    {
        changeModal
    }
)(CoachView);
