import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function getAuthTokenDataAPI(
    token: string
): Observable<any> {
    return baseDataProviderAPI.get(
        `authentication_tokens/${token}`,
        new RestQueryParams(),
        {}
    );
}
