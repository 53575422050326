import React from "react";
import {Translation} from "judo-app-common-web";
import OutsideAlerter from "../../../../../Shared/OutsideClicker";
import {DragDropGroup, DragDropVariable} from "../../../../../../utils/fatigueProfilesUtils";

interface IAddItemMenuProps {
    groupList?: DragDropGroup[];
    variableList?: DragDropVariable[];
    buttonLabel: string;
    onGroupEnabled: (groupId: string, newEnabledState: true) => void;
    onVariableEnabled: (groupId: string) => void;
}

interface IAddItemMenuState {
    addGroupMenuOpen: boolean;
}

class AddItemMenu extends React.Component<IAddItemMenuProps, IAddItemMenuState> {
    constructor(props: IAddItemMenuProps) {
        super(props);
        this.state = {
            addGroupMenuOpen: false
        }
    }

    render() {
        return <div className="add-item-menu-wrapper">
            <button className={`btn btn-theme btn-small btn-add-item`} disabled={!this.props.groupList?.length && !this.props.variableList?.length} onClick={() => this.toggleAddItem()}>
                <span className="feather icon-plus" aria-hidden="true"/>
                <Translation text={this.props.buttonLabel}/>
                <span className="feather icon-chevron-down" aria-hidden="true"/>
            </button>
            {this.renderAddGroupMenu()}
        </div>;
    }

    private toggleAddItem() {
        if (this.props.groupList?.length || this.props.variableList?.length) {
            this.setState({addGroupMenuOpen: !this.state.addGroupMenuOpen});
        }
        return null;
    }

    private renderAddGroupMenu() {
        if (this.state.addGroupMenuOpen) {
            return <React.Fragment>
                <h4 className="sr-only">
                    <Translation text={this.props.buttonLabel}/>
                </h4>
                <ul className="add-item-menu-list">
                    {this.renderAddGroupItems()}
                </ul>
            </React.Fragment>
        }
    }

    private renderAddGroupItems() {
        if (this.props.groupList && this.props.groupList.length) {
            return <OutsideAlerter onOutsideClick={() => this.onOverlayClick()}>
                {this.props.groupList.map(
                    (disabledItem: DragDropGroup) =>
                        <li className="add-item-menu-item"
                            key={disabledItem.id + disabledItem.calendarGroup.id}
                            onClick={() => this.enableGroup(disabledItem.calendarGroup.id)}>
                            {disabledItem.calendarGroup.name}
                        </li>)
                }
            </OutsideAlerter>
        }
        if (this.props.variableList && this.props.variableList.length) {
            return <OutsideAlerter onOutsideClick={() => this.onOverlayClick()}>
                {this.props.variableList.map(
                    (disabledItem: DragDropVariable) =>
                        <li className="add-item-menu-item"
                            key={disabledItem.id + disabledItem.calendarInput.id}
                            onClick={() => this.enableVariable(disabledItem.calendarInput.id)}>
                            {disabledItem.calendarInput.name}
                        </li>)
                }
            </OutsideAlerter>
        }
        return null
    }

    private onOverlayClick() {
        this.setState({addGroupMenuOpen: false})
    }

    private enableGroup(groupId: string) {
        this.props.onGroupEnabled(groupId, true);
        this.setState({addGroupMenuOpen: false})
    }

    private enableVariable(variableId: string) {
        this.props.onVariableEnabled(variableId);
        this.setState({addGroupMenuOpen: false})
    }
}

export default AddItemMenu;
