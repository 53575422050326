import React from 'react';
import {CustomCard, Form, IBasicFormControlConfig, Translation} from 'judo-app-common-web';
import {deepCloneObject} from "../../../../../utils/runtimeUtils";
import {CalendarInputOption} from "../../../../../model/models";
import {addOptionFormConfig} from "./addOptionFormConfig";

interface IAddOptionFormProps {
    onOptionCreation: (option: CalendarInputOption) => void;
}

interface IAddOptionFormState {
    editedOption: CalendarInputOption | null;
    formConfig: { [key: string]: typeof IBasicFormControlConfig }[];
    value: any;
}

class AddOptionForm extends React.Component<IAddOptionFormProps, IAddOptionFormState> {
    constructor(props: IAddOptionFormProps) {
        super(props);
        const emptyOption: CalendarInputOption = {value: '', label: '', backgroundColor: ''}
        this.state = {
            editedOption: emptyOption,
            formConfig: addOptionFormConfig,
            value: {},
        }
    }

    render() {
        if (!this.state.editedOption) {
            return null;
        }
        return <CustomCard showLocalLoader={false}>
            <div className="user-connection-list-wrapper">
                <CustomCard.Header>
                    <h4>
                        <Translation text="profileSettingsModal.form.addOptionForm.header"/>
                    </h4>
                </CustomCard.Header>
                <CustomCard.Body>
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={(controlName: string, data: any) => this.updateFormData(controlName, data)}
                        controlName={"addOptionForm"}
                        value={{}}
                        submitForm={this.addOption}
                    />
                </CustomCard.Body>
            </div>
        </CustomCard>
    }

    private updateFormData(controlName: string, data: any) {
        let updatedOption: CalendarInputOption = deepCloneObject(this.state.editedOption);
        if (data) {
            updatedOption.label = data.label;
            updatedOption.value = data.value;
            updatedOption.backgroundColor = data.color;
        }
        this.setState({editedOption: updatedOption});
    }

    private addOption = (
        event: any,
        value: any,
        valid: boolean,
        touched: boolean
    ) => {
        if (!value.label || !value.value || !value.color) {
            return;
        }
        const addedOption: CalendarInputOption = {
            label: value.label,
            value: value.value,
            backgroundColor: value.color
        }

        this.props.onOptionCreation(addedOption);
    };
}

export default AddOptionForm;
