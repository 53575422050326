import { Observable } from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function registerAPI(
    authToken: string,
    login: string | null,
    plainPassword: string,
): Observable<any> {
    return baseDataProviderAPI.post(
        `register`,
        {
          login: login,
          plainPassword: plainPassword
        },
        new RestQueryParams(),
        {
            'X-Authentication-Token': authToken,
        },
    );
}
