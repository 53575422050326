import {Observable} from "redux";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";
import {IInputResultPayload} from "./createInputResult";

export function updateInputResultsAPI(
    authToken: string,
    payload: IInputResultPayload[]
): Observable<any> {
    return baseDataProviderAPI.post(
        `input_results/update_input_results`,
        {inputResults: payload},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
