import React from 'react';
import {CustomModal, Translation} from 'judo-app-common-web';

interface IModalArchiveCalendarGroupProps {
    onClose: () => void;
    onGroupArchiveConfirm: () => void;
}

class ModalArchiveCalendarGroup extends React.Component<IModalArchiveCalendarGroupProps> {
    render() {
        return (
            <CustomModal isOpen={true} close={this.props.onClose} class="delete-group-modal">
                <CustomModal.Header>
                    <h2>
                        <Translation text={'profileSettingsModal.modal.deleteGroup.header'}/>
                    </h2>
                </CustomModal.Header>
                <CustomModal.Body>
                    <div>
                        <p>
                            <Translation text={'profileSettingsModal.modal.deleteGroup.question'}/>
                        </p>
                    </div>
                </CustomModal.Body>
                <CustomModal.Footer>
                    <div className="button-group-wrapper">
                        <button className="btn btn-danger btn-small ml-3"
                                onClick={this.confirmArchiving}>
                            <span className="feather icon-trash" aria-hidden="true"/>
                            <span><Translation text={'button.archive'}/></span>
                        </button>
                        <button className="btn btn-theme btn-small ml-3"
                                onClick={this.props.onClose}>
                            <span className="feather icon-x-circle" aria-hidden="true"/>
                            <span><Translation text={'button.cancel'}/></span>
                        </button>
                    </div>
                </CustomModal.Footer>
            </CustomModal>
        );
    }

    private confirmArchiving = () => {
        this.props.onGroupArchiveConfirm();
        this.props.onClose()
    }
}

export default ModalArchiveCalendarGroup;

