import {createAPIHandler} from "judo-app-common-web";
import {UserType} from "../../model/models";

export const accountProviderAPI = createAPIHandler({
    url: process.env.REACT_APP_API_URL as string,
    defaultHeaders: {
        "Accept": "application/ld+json",
        "Content-type": "application/json"
    },
    mapper: (data: any): any => {
        if(data && data['hydra:member']) {
            const arr = data['hydra:member'].map((item: any) => {
                let newItem = item.account;
                if (item.account.type === UserType.SPECIALIST) {
                    if (item.countContestants || item.countContestants === 0) newItem.countContestants = item.countContestants;
                    if (item.type) newItem.specialistType = item.type;
                }
                if (item.account.type === UserType.CONTESTANT) {
                    if (item.club) newItem.club = item.club ;
                    if (item.weightCategory)  newItem.weightCategory = item.weightCategory;
                }
                if (item.account.type === UserType.COACH) {
                    if (item.clubs) newItem.clubs = item.clubs ;
                    if (item.type)  newItem.coachType = item.type;
                    if (item.countContestants || item.countContestants === 0) newItem.countContestants = item.countContestants;
                }
                return newItem
            });
            data['hydra:member'] = arr;
            return data;
        }
       return data;
    },
    isList: false
});
