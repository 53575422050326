import React from 'react';
import {CustomModal, Loader, LoaderType, Translation} from 'judo-app-common-web';
import ChangePasswordForm from "./ChangePasswordForm";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {passwordLoadingSelector} from "../../../../store/selectors/changePasswordSelectors";

interface IConnectedModalChangePasswordProps {
    readonly isLoading: boolean;
}

interface IModalChangePasswordProps extends IConnectedModalChangePasswordProps {
    onClose: () => void;
}

class ModalChangePassword extends React.Component<IModalChangePasswordProps> {
    render() {
        return (
            <CustomModal isOpen={true} close={this.props.onClose}>
                <Loader show={this.props.isLoading} type={LoaderType.Local}/>
                <CustomModal.Header>
                    <Translation text={'modal.changePassword.title'}/>
                </CustomModal.Header>
                <CustomModal.Body>
                    <ChangePasswordForm />
                </CustomModal.Body>
            </CustomModal>
        );
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: passwordLoadingSelector(state),
    }),
    {}
)(ModalChangePassword);
