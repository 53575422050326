import { Observable } from "rxjs";
import {RestQueryParams} from "judo-app-common-web";
import {baseDataProviderAPI} from './provider/baseDataProviderAPI';

export function getBaseViewAPI(
    authToken: string,
    endpoint: string,
    id: string,
    isArchiveMode?: boolean
): Observable<any> {
    const getArchivedItemsQuery = isArchiveMode ? '?show_soft_deleted=true' : '';
    return baseDataProviderAPI.get(
        `${endpoint}/${id}${getArchivedItemsQuery}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
