import {Observable} from "rxjs";
import {baseDataProviderAPI} from "./provider/baseDataProviderAPI";
import {RestQueryParams} from "judo-app-common-web";

export function unassignAccountFromAccountAPI(
    authToken: string,
    chosenAccountId: string,
    unassignedAccountId: string
): Observable<any> {
    return baseDataProviderAPI.put(
        `accounts/${chosenAccountId}/remove_user_from_user`,
        {accountId: unassignedAccountId},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
