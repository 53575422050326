import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {authSlice, alertSlice, loginSlice} from 'judo-app-common-web';
import sagaSlice from './sagaSlice';
import userSlice from './userSlice';
import changePasswordSlice from "./changePasswordSlice";

const history = createBrowserHistory();

const rootReducer = combineReducers({
    saga: sagaSlice,
    user: userSlice,
    auth: authSlice,
    login: loginSlice,
    alert: alertSlice,
    changePassword: changePasswordSlice,
    router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
