export default {
  items: [
    {
      id: 'Dashboard',
      title: 'Insurance Cover',
      type: 'group',
      icon: '',
      children: [
          {
              id: 'development-profiles',
              title: 'menu.developmentProfiles',
              type: 'item',
              url: '/profiles',
              icon: 'feather icon-folder',
          },
          {
              id: 'club-coach',
              title: 'menu.clubCoaches',
              type: 'item',
              url: '/coaches',
              icon: 'feather icon-award',
          },
          {
              id: 'contestants',
              title: 'menu.contestants',
              type: 'item',
              url: '/contestants',
              icon: 'feather icon-users',
          },
          {
              id: 'specialists',
              title: 'menu.specialists',
              type: 'item',
              url: '/specialists',
              icon: 'feather icon-activity',
          },
          {
              id: 'clubs',
              title: 'menu.clubs',
              type: 'item',
              url: '/clubs',
              icon: 'feather icon-shield',
          },
          {
              id: 'specialist-types',
              title: 'menu.specialistTypes',
              type: 'item',
              url: '/specialist-types',
              icon: 'feather icon-type',
          },
          {
              type: 'spacer',
              class: 'nav-spacer',
          },
        //   {
        //       id: 'archive',
        //       title: 'menu.archive',
        //       type: 'item',
        //       url: '/archive/contestants',
        //       icon: 'feather icon-package',
        //   },
          {
              id: 'account',
              title: 'menu.account',
              type: 'item',
              url: '/account',
              icon: 'feather icon-settings',
          },
          {
              id: 'logout',
              title: 'menu.logout',
              type: 'item',
              url: `/logout`,
              icon: 'feather icon-log-out',
          },
      ]
    },
  ]
};
