import React from 'react';
import {CalendarInput} from "../../../../../model/models";
import TooltipWrapper from "../../../../Shared/TooltipWrapper";
import {Translation} from "judo-app-common-web";

interface VariableListItemProps {
    listItem: CalendarInput;
    key: string;
    onDeleteItem: () => void;
    isDraggable: boolean;
}

class VariableListItem extends React.Component<VariableListItemProps> {
    constructor(props: VariableListItemProps) {
        super(props);
        this.state = {}
    }

    render() {
        return <React.Fragment>
            <div className="d-flex flex-wrap profile-wrapper"> {this.props.listItem.name} </div>
            {this.renderActionButtons()}
        </React.Fragment>

    }

    private renderActionButtons() {
        return <div className="button-wrapper">
            <TooltipWrapper tooltip={{text: 'userList.table.button.remove.variable'}} key={'fatigue-button'}>
                <button className="btn btn-list fatigue-profile delete ml-3" onClick={() => this.props.onDeleteItem()}>
                    <span className="sr-only"><Translation text={'userList.table.button.remove.variable'}/></span>
                    <span className="feather icon-trash" aria-hidden="true"/>
                </button>
            </TooltipWrapper>
            {this.renderDragHandle()}
        </div>
    }

    private renderDragHandle() {
        if(this.props.isDraggable) {
            return <TooltipWrapper tooltip={{text: 'userList.table.button.clickToDrag'}} key={'drag-handle'}>
                <div data-movable-handle className="drag-handle ml-3">
                    <span className="sr-only"><Translation text={'userList.table.button.clickToDrag'}/></span>
                    <span className="feather icon-menu" aria-hidden="true"/>
                </div>
            </TooltipWrapper>
        }
        return null;
    }
}

export default VariableListItem;
